import {
  uiStartLoading,
  uiStopLoading
} from "../index";
import {
  REGULATIONS_DATA,
  REGULATIONS_NB,
  LIST_REGULATIONS_ERR,
  REGULATION_DATA
} from "../actionTypes";
import {
  REGULATION_DELETE,
  DELETE_REGULATION_ERR,
  STATUS_REGULATION_DELETE
} from "../actionTypes";
import {
  REGULATION_CREATE
} from "../actionTypes";
import {
  CREATE_REGULATION_ERR
} from "../actionTypes";
import {
  STATUS_REGULATION_CREATE
} from "../actionTypes";
import {
  REGULATION_EDIT,
  EDIT_REGULATION_ERR,
  STATUS_REGULATION_EDIT
} from "../actionTypes";
import {
  Rules_EDIT,
  EDIT_Rules_ERR,
  STATUS_Rules_EDIT,
  Rules_Data
} from "../actionTypes";


export const createRegulation = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(CreateRegulationstatus(""));
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/Rules/add`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          Regulation: data.Regulation,
          ARRegulation: data.ARRegulation,
          Title: data.Title,
          ARTitle: data.ARTitle,
          active: data.active
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(CreateRegulationstatus(""));
        dispatch(uiStopLoading())

        if (parsedRes.status === "fail") {
          dispatch(CreateRegulationerr(parsedRes.error));
        } else {
          dispatch(CreateRegulationerr(""));
          dispatch(CreateRegulation(parsedRes.data));
          dispatch(CreateRegulationstatus(parsedRes.status));

        }

      });
  };
};

export const getRegulations = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/Rules/getList`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          pageNumber: data.pageNumber

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        if (parsedRes.status === "failure") {
          dispatch(listRegulationsErr(parsedRes.error));

        } else {
          dispatch(listRegulationsErr(""));
          dispatch(regulationsData(parsedRes.data));
          dispatch(regulationsNb(parsedRes.Length));
        }

      });
  };
};
export const editRegulation = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/Rules/edit`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          regulationID: data.regulationID,
          Title: data.Title,
          ARTitle: data.ARTitle,
          Regulation: data.Regulation,
          ARRegulation: data.ARRegulation,
          active: data.active
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(editRegulationStatus(""));
        dispatch(uiStopLoading())

        if (parsedRes.status === "fail") {

          dispatch(EditRegulationerr(parsedRes.error));
        } else {
          dispatch(EditRegulationerr(""));
          dispatch(editregulation(parsedRes.data));
          dispatch(editRegulationStatus(parsedRes.status));
        }

      });
  };


};
export const saveRule = data => {

  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/Rules/Addrule`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          Rules: data.Rules,
          ARRules: data.ARRules
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(editRulesStatus(""));
        dispatch(uiStopLoading())

        if (parsedRes.status === "fail") {

          dispatch(EditRuleserr(parsedRes.error));
        } else {
          dispatch(editRulesStatus(""));
          dispatch(editRules(parsedRes.data));
          dispatch(editRulesStatus(parsedRes.status));
        }

      });
  };
};
export const getRule = () => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/Rules/getrule`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },

      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        dispatch(RulesData(parsedRes.data));

      });
  };
};
export const RulesData = value => {
  return {
    type: Rules_Data,
    RulesData: value
  };
};
export const EditRuleserr = value => {
  return {
    type: EDIT_Rules_ERR,
    EditRuleserr: value
  };
};

export const editRulesStatus = value => {
  return {
    type: STATUS_Rules_EDIT,
    editRulesStatus: value
  };
};
export const editRules = value => {
  return {
    type: Rules_EDIT,
    editRules: value
  };
};
//
export const EditRegulationerr = value => {
  return {
    type: EDIT_REGULATION_ERR,
    EditRegulationerr: value
  };
};

export const editRegulationStatus = value => {
  return {
    type: STATUS_REGULATION_EDIT,
    editRegulationStatus: value
  };
};
export const editregulation = value => {
  return {
    type: REGULATION_EDIT,
    editregulation: value
  };
};
export const getRegulation = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/Rules/get`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          regulationID: data.regulationID
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        dispatch(regulationData(parsedRes.data));

      });
  };
};

export const regulationData = value => {
  return {
    type: REGULATION_DATA,
    regulationData: value
  };
};

export const DeleteRegulation = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    dispatch(deleteRegulationstatus(""));

    fetch(`${process.env.REACT_APP_DOMAIN}/api/Rules/delete`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          regulationID: data.regulationID,

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(deleteRegulationstatus(""));
        dispatch(uiStopLoading())
        if (parsedRes.status === "fail") {
          dispatch(DeleteRegulationerr(parsedRes.error));
        } else {
          dispatch(DeleteRegulationerr(""));
          dispatch(deleteRegulation(parsedRes.data));
          dispatch(deleteRegulationstatus(parsedRes.status));
        }

      });
  };
};
export const deleteRegulation = value => {
  return {
    type: REGULATION_DELETE,
    deleteRegulation: value
  };
};
export const DeleteRegulationerr = value => {
  return {
    type: DELETE_REGULATION_ERR,
    DeleteRegulationerr: value
  };
};
export const deleteRegulationstatus = value => {
  return {
    type: STATUS_REGULATION_DELETE,
    deleteRegulationstatus: value
  };
};


export const regulationsNb = value => {
  return {
    type: REGULATIONS_NB,
    regulationsNb: value
  }
}

export const regulationsData = value => {
  return {
    type: REGULATIONS_DATA,
    regulationsData: value
  };
};
export const listRegulationsErr = value => {
  return {
    type: LIST_REGULATIONS_ERR,
    listRegulationsErr: value
  };
};
export const CreateRegulation = value => {
  return {
    type: REGULATION_CREATE,
    CreateRegulation: value
  };
};
export const CreateRegulationerr = value => {
  return {
    type: CREATE_REGULATION_ERR,
    CreateRegulationerr: value
  };
};
export const CreateRegulationstatus = value => {
  return {
    type: STATUS_REGULATION_CREATE,
    CreateRegulationstatus: value
  };
};