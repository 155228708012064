import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardFooter, Col, Row, Button, Form, InputGroup, InputGroupAddon, InputGroupText, Input, Container } from 'reactstrap';
import { getUserDetails } from "../../../store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import { Trans } from "react-i18next";


class UserDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: null,
            email: null,
            phone: null,
            gender: null,
            city: null,
            address: null
        }
    }

    componentDidUpdate(prevProps) {
        if ((this.props.userDataDetails !== prevProps.userDataDetails) && (this.props.userDataDetails !== undefined)) {
            this.setState({
                name: this.props.userDataDetails.name,
                email: this.props.userDataDetails.email,
                phone: this.props.userDataDetails.phone,
                city: this.props.userDataDetails.city,
                address: this.props.userDataDetails.address,
                gender: this.props.userDataDetails.gender == 1 ? "male" : "Female",
            })
        }
    }
    componentDidMount() {
        var id = this.props.match.params.id
        this.props.getUserDetails(id)
    }


    render() {

        return (
            <div className="animated fadeIn">
                <Row className="justify-content-center">
                    <Col lg={8}>
                        <Card>
                            <CardHeader>
                                <strong><i className="icon-info pr-1"></i><Trans i18nKey="Users.UserDetails" /></strong>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <Row >
                                        <Col lg={6} md={6}>
                                            <p><strong><Trans i18nKey="Users.Name" /></strong></p>
                                            <p>{this.state.name}</p>
                                        </Col>
                                        <Col lg={6} md={6}>
                                            <strong><Trans i18nKey="Users.Email" /></strong>
                                            <p>{this.state.email}</p>
                                        </Col>
                                        <Col lg={6} md={6}>
                                            <strong><Trans i18nKey="Users.Gender" /></strong>
                                            <p>{this.state.gender}</p>
                                        </Col>
                                        <Col lg={6} md={6}>
                                            <strong><Trans i18nKey="Users.Phone" /></strong>

                                            <p>{this.state.phone}</p>
                                        </Col>
                                        <Col lg={6} md={6}>
                                            <strong><Trans i18nKey="Users.Address" /></strong>
                                            <p>{this.state.address}</p>
                                        </Col>
                                        <Col lg={6} md={6}>
                                            <strong><Trans i18nKey="Users.City" /></strong>
                                            <p>{this.state.city}</p>
                                        </Col>

                                    </Row>

                                </Form>
                            </CardBody>
                            <CardFooter>


                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        isLoading: state.ui.isLoading,
        userDataDetails: state.userDataDetails.userDataDetails,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        getUserDetails: (data) => dispatch(getUserDetails(data)),
    };
};
export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(UserDetails);
