import React from "react";
import { Redirect, Route } from 'react-router-dom';


export const PrivateRouter = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    localStorage.getItem('adminToken') && localStorage.getItem('adminToken') !=="" 
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
)

/*export const PrivateRouter = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('adminToken') && localStorage.getItem('adminToken') !=="" ? (
         <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
*/



