import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import { getUsersTokens } from "../../../store/actions";
import { compose } from "redux";
import history from "../../../history";
import { connect } from "react-redux";
import { Trans } from "react-i18next";
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

class UsersTokens extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current: 1
    };
  }
  onChange = (page) => {
    this.setState({
      current: page
    });
    const data = { pageNumber: page };
    this.props.getUsersTokens(data)
  }

  componentDidMount() {
    var token = localStorage.getItem("adminToken")
    var jwtDecode = require('jwt-decode');
    var decoded = jwtDecode(token);
    var expirationDate = decoded.exp;
    var seconds = 1000;
    var d = new Date();
    var t = d.getTime();

    if (expirationDate < Math.round(t / seconds)) {
      localStorage.setItem("adminToken", "")
      history.push("/login");

    }
    else {
      const data = { pageNumber: 1 };
      this.props.getUsersTokens(data)
    }
  }
  render() {

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xl={12}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> <Trans i18nKey="Users.UsersList" />
              </CardHeader>
              <CardBody>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col"><Trans i18nKey="Users.UserId" /></th>
                      <th scope="col"><Trans i18nKey="Users.UserToken" /></th>
                      <th scope="col"><Trans i18nKey="Reservations.created_at" /></th>
                      <th scope="col"><Trans i18nKey="Reservations.modified_at" /></th>

                    </tr>
                  </thead>
                  <tbody>
                    {this.props.ListTokenerr === "" ?

                      Object.values(this.props.usersTokensData).map((userData) => {
                        var cre = userData.createdAt;
                        var upd = userData.updatedAt;

                        return <tr key={userData._id} id={userData._id}>
                          <td>{userData._id}</td>
                          <td>{userData.registrationToken}</td>
                          <td>{cre.substring(0, 10)}</td>
                          <td>{upd.substring(0, 10)}</td>
                        </tr>

                      })
                      : <tr><td><Trans i18nKey="Users.noUser" /></td></tr>} 



                  </tbody>
                </Table>
                <Pagination onChange={this.onChange} showTitle={false} current={this.state.current} total={this.props.usersNbr} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

//export default Users;
const mapStateToProps = state => {
  return {
    usersTokensData: state.usersTokensData.usersTokensData,
    isLoading: state.ui.isLoading,
    ListTokenerr: state.ListTokenerr.ListTokenerr,
    usersNbr: state.usersNbr.usersNbr
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUsersTokens: (data) => dispatch(getUsersTokens(data))
  };
};


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UsersTokens);