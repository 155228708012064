import { FACILITIES_DATA, FACILITY_NB } from "../../actions/actionTypes";
import { FACILITY_DATA } from "../../actions/actionTypes";
import { LIST_FACILITIES_ERR } from "../../actions/actionTypes";

import { FACILITY_EDIT } from "../../actions/actionTypes";
import { EDIT_FACILITY_ERR } from "../../actions/actionTypes";
import { STATUS_FACILITY_EDIT } from "../../actions/actionTypes";

import { DELETE_FACILITY_ERR } from "../../actions/actionTypes";
import { STATUS_FACILITY_DELETE } from "../../actions/actionTypes";
import { FACILITY_DELETE } from "../../actions/actionTypes";

import { CREATE_FACILITY_ERR } from "../../actions/actionTypes";
import { STATUS_FACILITY_CREATE } from "../../actions/actionTypes";
import { FACILITY_CREATE } from "../../actions/actionTypes";
import { LIST_ACT_Facilities_ERR, ACT_Facilities_DATA } from "../../actions/actionTypes";


const initialState = {
  FacilitiesData: [],
  FacilitiesNbr: 0,
  facilityData: [],
  editfacility: [],
  EditFacilityerr: '',
  listFacilitiesErr: '',
  editFacilityStatus: '',
  deleteFacility: [],
  DeleteFacilityerr: '',
  deleteFacilityStatus: '',
  CreateFacility: [],
  CreateFacilityerr: '',
  CreateFacilityStatus: '',
  listActFacilitiesErr: '',
  ActFacilitiesData: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FACILITY_NB:
      return {
        ...state,
        FacilitiesNbr: action.FacilitiesNbr
      };
    case FACILITIES_DATA:
      return {
        ...state,
        FacilitiesData: action.FacilitiesData
      };

    case FACILITY_DATA:
      return {
        ...state,
        facilityData: action.facilityData
      };
    case FACILITY_EDIT:
      return {
        ...state,
        editfacility: action.editfacility
      };
    case LIST_FACILITIES_ERR:
      return {
        ...state,
        listFacilitiesErr: action.listFacilitiesErr
      };
    case EDIT_FACILITY_ERR:
      return {
        ...state,
        EditFacilityerr: action.EditFacilityerr
      };
    case STATUS_FACILITY_EDIT:
      return {
        ...state,
        editFacilityStatus: action.editFacilityStatus
      };
    case DELETE_FACILITY_ERR:
      return {
        ...state,
        DeleteFacilityerr: action.DeleteFacilityerr
      };
    case STATUS_FACILITY_DELETE:
      return {
        ...state,
        deleteFacilityStatus: action.deleteFacilityStatus
      };
    case FACILITY_DELETE:
      return {
        ...state,
        deleteFacility: action.deleteFacility
      };

    case CREATE_FACILITY_ERR:
      return {
        ...state,
        CreateFacilityerr: action.CreateFacilityerr
      };
    case STATUS_FACILITY_CREATE:
      return {
        ...state,
        CreateFacilityStatus: action.CreateFacilityStatus
      };
    case FACILITY_CREATE:
      return {
        ...state,
        CreateFacility: action.CreateFacility
      };
    case LIST_ACT_Facilities_ERR:
      return {
        ...state,
        listActFacilitiesErr: action.listActFacilitiesErr
      };
    case ACT_Facilities_DATA:
      return {
        ...state,
        ActFacilitiesData: action.ActFacilitiesData
      };

    default:
      return state;
  }
};

export default reducer;
