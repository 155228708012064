import { CREATE_FAQ_ERR } from "../../actions/actionTypes";
import { STATUS_FAQ_CREATE } from "../../actions/actionTypes";
import { FAQ_CREATE } from "../../actions/actionTypes";
import { FAQS_DATA, FAQS_NB, LIST_FAQS_ERR } from "../../actions/actionTypes";
import { FAQ_DELETE, DELETE_FAQ_ERR, STATUS_FAQ_DELETE } from "../../actions/actionTypes";
import { FAQ_EDIT, EDIT_FAQ_ERR, STATUS_FAQ_EDIT, FAQ_DATA } from "../../actions/actionTypes";
import { FAQDesc_EDIT, EDIT_FAQDesc_ERR, STATUS_FAQDesc_EDIT, FAQDesc_Data } from "../../actions/actionTypes";


const initialState = {
  CreateFaq: [],
  CreateFaqerr: '',
  CreateFaqstatus: '',
  listFaqsErr: '',
  faqsData: [],
  faqsNb: '',
  deleteFaqstatus: '',
  DeleteFaqerr: '',
  deleteFaq: [],
  editFaqStatus: '',
  editfaq: [],
  EditFaqerr: '',
  faqData: [],
  editFaqDescStatus: '',
  editfaqDesc: [],
  EditFaqDescerr: '',
  faqDescData: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_FAQ_ERR:
      return {
        ...state,
        CreateFaqerr: action.CreateFaqerr
      };
    case STATUS_FAQ_CREATE:
      return {
        ...state,
        CreateFaqstatus: action.CreateFaqstatus
      };
    case FAQ_CREATE:
      return {
        ...state,
        CreateFaq: action.CreateFaq
      };
    case FAQS_DATA:
      return {
        ...state,
        faqsData: action.faqsData
      };
    case LIST_FAQS_ERR:
      return {
        ...state,
        listFaqsErr: action.listFaqsErr
      };
    case FAQS_NB:
      return {
        ...state,
        faqsNb: action.faqsNb
      };
    case FAQ_DELETE:
      return {
        ...state,
        deleteFaq: action.deleteFaq
      };
    case DELETE_FAQ_ERR:
      return {
        ...state,
        DeleteFaqerr: action.DeleteFaqerr
      };
    case STATUS_FAQ_DELETE:
      return {
        ...state,
        deleteFaqstatus: action.deleteFaqstatus
      };
    //
    case FAQ_EDIT:
      return {
        ...state,
        editfaq: action.editfaq
      };
    case EDIT_FAQ_ERR:
      return {
        ...state,
        EditFaqerr: action.EditFaqerr
      };
    case STATUS_FAQ_EDIT:
      return {
        ...state,
        editFaqStatus: action.editFaqStatus
      };
    case FAQ_DATA:
      return {
        ...state,
        faqData: action.faqData
      };
    //
    case FAQDesc_EDIT:
      return {
        ...state,
        editfaqDesc: action.editfaqDesc
      };
    case EDIT_FAQDesc_ERR:
      return {
        ...state,
        EditFaqDescerr: action.EditFaqDescerr
      };
    case STATUS_FAQDesc_EDIT:
      return {
        ...state,
        editFaqDescStatus: action.editFaqDescStatus
      };
    case FAQDesc_Data:
      return {
        ...state,
        faqDescData: action.faqDescData
      };



    default:
      return state;
  }
};

export default reducer;
