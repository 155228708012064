import React from 'react';
import {
  Trans
} from "react-i18next";


const Users = React.lazy(() => import('./views/Pages/Users/Users'));
const UsersList = React.lazy(() => import('./views/Pages/Users/UsersTokens'));
const EventReservations = React.lazy(() => import('./views/Pages/Reservations/EventReservations'));
const ReservationsByEvent = React.lazy(() => import('./views/Pages/Reservations/ReservationsByEvent'));
const GetEventReservations = React.lazy(() => import('./views/Pages/Reservations/GetEventReservations'));
const GetPlaceReservations = React.lazy(() => import('./views/Pages/Reservations/GetPlaceReservations'));
const ReservationsByPlace = React.lazy(() => import('./views/Pages/Reservations/ReservationsByPlace'));
const PlaceReservations = React.lazy(() => import('./views/Pages/Reservations/PlaceReservations'));
const Events = React.lazy(() => import('./views/Pages/Events/Events'));
const Event = React.lazy(() => import('./views/Pages/Events/Event'));
const createEvent = React.lazy(() => import('./views/Pages/Events/createEvent'));
const newscast = React.lazy(() => import('./views/Pages/Newscast/Newscast'));
const Beacons = React.lazy(() => import('./views/Pages/Beacons/Beacons'));
const Beacon = React.lazy(() => import('./views/Pages/Beacons/Beacon'));
const createBeacon = React.lazy(() => import('./views/Pages/Beacons/createBeacon'));
const Places = React.lazy(() => import('./views/Pages/Places/Places'));
const Place = React.lazy(() => import('./views/Pages/Places/Place'));
const Photos = React.lazy(() => import('./views/Pages/Medias/Photos/Photos'));
const Photo = React.lazy(() => import('./views/Pages/Medias/Photos/Photo'));
const createPhotoAlbum = React.lazy(() => import('./views/Pages/Medias/Photos/createPhotoAlbum'));
const Videos = React.lazy(() => import('./views/Pages/Medias/Videos/Videos'));
const Video = React.lazy(() => import('./views/Pages/Medias/Videos/Video'));
const createVideoAlbum = React.lazy(() => import('./views/Pages/Medias/Videos/createVideoAlbum'));
const createPlace = React.lazy(() => import('./views/Pages/Places/createPlace'));
const ThingsToDo = React.lazy(() => import('./views/Pages/Category/Categories'));
const ThingToDo = React.lazy(() => import('./views/Pages/Category/Category'));
const createThingToDo = React.lazy(() => import('./views/Pages/Category/createCategory'));

const Facilities = React.lazy(() => import('./views/Pages/Facility/Facilities'));
const Facility = React.lazy(() => import('./views/Pages/Facility/Facility'));
const createFacility = React.lazy(() => import('./views/Pages/Facility/createFacility'));

const Banners = React.lazy(() => import('./views/Pages/Banners/Banners'));
const Banner = React.lazy(() => import('./views/Pages/Banners/Banner'));
const createBanner = React.lazy(() => import('./views/Pages/Banners/createBanner'));
const Notifications = React.lazy(() => import('./views/Pages/Notifications/Notifications'));
const Notification = React.lazy(() => import('./views/Pages/Notifications/Notification'));
const createNotification = React.lazy(() => import('./views/Pages/Notifications/createNotification'));
const NewsList = React.lazy(() => import('./views/Pages/News/NewsList'));
const News = React.lazy(() => import('./views/Pages/News/News'));
const createNews = React.lazy(() => import('./views/Pages/News/createNews'));

const Parkings = React.lazy(() => import('./views/Pages/Parkings/Parkings'));
const Parking = React.lazy(() => import('./views/Pages/Parkings/Parking'));
const createParking = React.lazy(() => import('./views/Pages/Parkings/createParking'));

const FAQs = React.lazy(() => import('./views/Pages/FAQs/FAQs'));
const FAQ = React.lazy(() => import('./views/Pages/FAQs/FAQ'));
const createFAQ = React.lazy(() => import('./views/Pages/FAQs/createFAQ'));
const contact = React.lazy(() => import('./views/Pages/Contact/Contact'));

const Subject = React.lazy(() => import('./views/Pages/Subjects/Subjects'));
const createSubject = React.lazy(() => import('./views/Pages/Subjects/createSubjects'));

const Timings = React.lazy(() => import('./views/Pages/Timing/Timings'));

const HomePage = React.lazy(() => import('./views/Pages/Timing/homePage'));
const AboutUs = React.lazy(() => import('./views/Pages/Timing/aboutUs'));
const ParkInformation = React.lazy(() => import('./views/Pages/Timing/parkInformation'));

const Timing = React.lazy(() => import('./views/Pages/Timing/Timing'));
const createTiming = React.lazy(() => import('./views/Pages/Timing/createTiming'));

const Rules = React.lazy(() => import('./views/Pages/Rules/Rules'));
const Rule = React.lazy(() => import('./views/Pages/Rules/Rule'));
const createRules = React.lazy(() => import('./views/Pages/Rules/createRules'));

const Admins = React.lazy(() => import('./views/Pages/Admin/Admins'));
const Profile = React.lazy(() => import('./views/Pages/Profile/Profile'));
const UserDetails = React.lazy(() => import('./views/Pages/Profile/UserDetails'));
const createAdmin = React.lazy(() => import('./views/Pages/Admin/createAdmin'));
const Admin = React.lazy(() => import('./views/Pages/Admin/Admin'));

const Merchants = React.lazy(() => import('./views/Pages/Merchants/Merchants'));
const Merchant = React.lazy(() => import('./views/Pages/Merchants/Merchant'));
const createMerchant = React.lazy(() => import('./views/Pages/Merchants/createMerchant'));

const routes = [{
    path: '/',
    exact: true,
    name: < Trans i18nKey = "Home" / >
  },
  // { path: '/dashboard', name:  <Trans i18nKey="Dashboard" />, component: Dashboard },
  {
    path: '/users',
    exact: true,
    name: < Trans i18nKey = "Users.Users" / > ,
    component: Users
  },
  {
    path: '/users_list',
    exact: true,
    name: < Trans i18nKey = "Users.UsersList" / > ,
    component: UsersList
  },
  {
    path: '/reservations/events',
    exact: true,
    name: < Trans i18nKey = "EventReservations.EventReservations" / > ,
    component: EventReservations
  },
  {
    path: '/reservations/by_event',
    exact: true,
    name: < Trans i18nKey = "EventReservations.EventReservations" / > ,
    component: ReservationsByEvent
  },
  {
    path: '/reservations/by_event/:id',
    exact: true,
    name: < Trans i18nKey = "EventReservations.ReservationsByEvent" / > ,
    component: GetEventReservations
  },
  {
    path: '/reservations/by_place',
    exact: true,
    name: < Trans i18nKey = "PlaceReservations.PlaceReservations" / > ,
    component: ReservationsByPlace
  },
  {
    path: '/reservations/places',
    exact: true,
    name: < Trans i18nKey = "PlaceReservations.PlaceReservations" / > ,
    component: PlaceReservations
  },
  {
    path: '/reservations/by_place/:id',
    exact: true,
    name: < Trans i18nKey = "PlaceReservations.ReservationsByPlace" / > ,
    component: GetPlaceReservations
  },
  {
    path: '/homeSlider',
    exact: true,
    name: < Trans i18nKey = "Newscast.Newscast" / > ,
    component: newscast
  },
  {
    path: '/events',
    exact: true,
    name: < Trans i18nKey = "Events.Events" / > ,
    component: Events
  },
  {
    path: '/events/:id',
    exact: true,
    name: < Trans i18nKey = "Events.EventDetails" / > ,
    component: Event
  },
  {
    path: '/event/create',
    exact: true,
    name: < Trans i18nKey = "Events.CreateEvent" / > ,
    component: createEvent
  },
  {
    path: '/ibeacons',
    exact: true,
    name: < Trans i18nKey = "IBeacons.IBeacons" / > ,
    component: Beacons
  },
  {
    path: '/ibeacons/:id',
    exact: true,
    name: < Trans i18nKey = "IBeacons.IBeaconDetails" / > ,
    component: Beacon
  },
  {
    path: '/ibeacon/create',
    exact: true,
    name: < Trans i18nKey = "IBeacons.CreateIBeacon" / > ,
    component: createBeacon
  },
  {
    path: '/places',
    exact: true,
    name: < Trans i18nKey = "Places.Places" / > ,
    component: Places
  },
  {
    path: '/places/:id',
    exact: true,
    name: < Trans i18nKey = "Places.PlaceDetails" / > ,
    component: Place
  },
  {
    path: '/place/create',
    exact: true,
    name: < Trans i18nKey = "Places.CreatePlace" / > ,
    component: createPlace
  },

  {
    path: '/Activity',
    exact: true,
    name: < Trans i18nKey = "ThingsToDo.ThingsToDo" / > ,
    component: ThingsToDo
  },
  {
    path: '/Activity/:id',
    exact: true,
    name: < Trans i18nKey = "ThingsToDo.ThingToDoDetails" / > ,
    component: ThingToDo
  },
  {
    path: '/thingtodo/create',
    exact: true,
    name: < Trans i18nKey = "ThingsToDo.CreateThingToDo" / > ,
    component: createThingToDo
  },

  {
    path: '/facilities',
    exact: true,
    name: < Trans i18nKey = "Facilities.Facilities" / > ,
    component: Facilities
  },
  {
    path: '/facilities/:id',
    exact: true,
    name: < Trans i18nKey = "Facilities.FacilityDetails" / > ,
    component: Facility
  },
  {
    path: '/facility/create',
    exact: true,
    name: < Trans i18nKey = "Facilities.CreateFacility" / > ,
    component: createFacility
  },

  {
    path: '/notifications',
    exact: true,
    name: < Trans i18nKey = "Notifications.Notifications" / > ,
    component: Notifications
  },
  {
    path: '/notifications/:id',
    exact: true,
    name: < Trans i18nKey = "Notifications.NotifDetails" / > ,
    component: Notification
  },
  {
    path: '/notification/create',
    exact: true,
    name: < Trans i18nKey = "Notifications.CreateNotif" / > ,
    component: createNotification
  },
  {
    path: '/news',
    exact: true,
    name: < Trans i18nKey = "News.News" / > ,
    component: NewsList
  },
  {
    path: '/news/:id',
    exact: true,
    name: < Trans i18nKey = "News.NewsDetails" / > ,
    component: News
  },
  {
    path: '/new/create',
    exact: true,
    name: < Trans i18nKey = "News.CreateNews" / > ,
    component: createNews
  },
  {
    path: '/medias/photos',
    exact: true,
    name: < Trans i18nKey = "Photos.Photos" / > ,
    component: Photos
  },
  {
    path: '/medias/photos/:id',
    exact: true,
    name: < Trans i18nKey = "Photos.PhotoDetails" / > ,
    component: Photo
  },
  {
    path: '/medias/photo/create_album',
    exact: true,
    name: < Trans i18nKey = "Videos.CreateVideo" / > ,
    component: createPhotoAlbum
  },
  {
    path: '/medias/videos',
    exact: true,
    name: < Trans i18nKey = "Videos.Videos" / > ,
    component: Videos
  },
  {
    path: '/medias/videos/:id',
    exact: true,
    name: < Trans i18nKey = "Videos.VideoDetails" / > ,
    component: Video
  },
  {
    path: '/medias/video/create_album',
    exact: true,
    name: < Trans i18nKey = "Videos.CreateVideo" / > ,
    component: createVideoAlbum
  },
  {
    path: '/splash',
    exact: true,
    name: < Trans i18nKey = "Banners.Banners" / > ,
    component: Banners
  },
  {
    path: '/splash/:id',
    exact: true,
    name: < Trans i18nKey = "Banners.BannerDetails" / > ,
    component: Banner
  },
  {
    path: '/create/splash',
    exact: true,
    name: < Trans i18nKey = "Banners.CreateBanner" / > ,
    component: createBanner
  },

  {
    path: '/parkings',
    exact: true,
    name: < Trans i18nKey = "Parkings.Parkings" / > ,
    component: Parkings
  },
  {
    path: '/parkings/:id',
    exact: true,
    name: < Trans i18nKey = "Parkings.ParkingDetails" / > ,
    component: Parking
  },
  {
    path: '/parking/create',
    exact: true,
    name: < Trans i18nKey = "Parkings.CreateParking" / > ,
    component: createParking
  },

  {
    path: '/FAQs',
    exact: true,
    name: < Trans i18nKey = "FAQs.FAQs" / > ,
    component: FAQs
  },
  {
    path: '/FAQs/:id',
    exact: true,
    name: < Trans i18nKey = "FAQs.FAQDetails" / > ,
    component: FAQ
  },
  {
    path: '/FAQ/create',
    exact: true,
    name: < Trans i18nKey = "FAQs.CreateFAQ" / > ,
    component: createFAQ
  },

  {
    path: '/contact',
    exact: true,
    name: < Trans i18nKey = "contact" / > ,
    component: contact
  },
  {
    path: '/subjects/:id',
    exact: true,
    name: < Trans i18nKey = "SUBJECTs.SUBJECTDetails" / > ,
    component: Subject
  },
  {
    path: '/subject/create',
    exact: true,
    name: < Trans i18nKey = "SUBJECTs.CreateSUBJECT" / > ,
    component: createSubject
  },

  {
    path: '/timings',
    exact: true,
    name: < Trans i18nKey = "Timings.Timings" / > ,
    component: Timings
  },
  {
    path: '/aboutUs',
    exact: true,
    name: < Trans i18nKey = "aboutUs" / > ,
    component: AboutUs
  },
  {
    path: '/homePage',
    exact: true,
    name: < Trans i18nKey = "homePage" / > ,
    component: HomePage
  },
  {
    path: '/parkInformation',
    exact: true,
    name: < Trans i18nKey = "parkInformation" / > ,
    component: ParkInformation
  },
  {
    path: '/timings/:id',
    exact: true,
    name: < Trans i18nKey = "Timings.TimingDetails" / > ,
    component: Timing
  },
  {
    path: '/timing/create',
    exact: true,
    name: < Trans i18nKey = "Timings.CreateTiming" / > ,
    component: createTiming
  },

  {
    path: '/Rules',
    exact: true,
    name: < Trans i18nKey = "Rules.Rules" / > ,
    component: Rules
  },
  {
    path: '/Rules/:id',
    exact: true,
    name: < Trans i18nKey = "Rules.RuleDetails" / > ,
    component: Rule
  },
  {
    path: '/Rule/create',
    exact: true,
    name: < Trans i18nKey = "Rules.CreateRule" / > ,
    component: createRules
  },

  {
    path: '/profile',
    exact: true,
    name: < Trans i18nKey = "profile" / > ,
    component: Profile
  },
  {
    path: '/user/:id',
    exact: true,
    name: < Trans i18nKey = "Users.UserDetails" / > ,
    component: UserDetails
  },
  
  {
    path: '/admins',
    exact: true,
    name: < Trans i18nKey = "Admins.Admins" / > ,
    component: Admins
  },
  {
    path: '/admin/create',
    exact: true,
    name: < Trans i18nKey = "Admins.CreateAdmin" / > ,
    component: createAdmin
  },
  {
    path: '/admin/:id',
    exact: true,
    name: < Trans i18nKey = "Admins.EditAdmin" / > ,
    component: Admin
  },

  {
    path: '/merchants',
    exact: true,
    name: < Trans i18nKey = "Merchants.Merchants" / > ,
    component: Merchants
  },
  {
    path: '/merchants/:id',
    exact: true,
    name: < Trans i18nKey = "Merchants.MerchantDetails" / > ,
    component: Merchant
  },
  {
    path: '/merchant/create',
    exact: true,
    name: < Trans i18nKey = "Merchants.CreateMerchant" / > ,
    component: createMerchant
  }
];

export default routes;