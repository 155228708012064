import {
  uiStartLoading,
  uiStopLoading
} from "../index";
import {
  EV_RESERV_ERR,
  EventR_NB,
  EvRes_Nbr,
  PLRes_Nbr,
  PlaceR_NB
} from "../actionTypes";
import {
  EV_RESERV
} from "../actionTypes";
import {
  BY_EV_RESERV_ERR
} from "../actionTypes";
import {
  BY_EV_RESERV
} from "../actionTypes";
import {
  GET_EV_RESERV_ERR
} from "../actionTypes";
import {
  GET_EV_RESERV
} from "../actionTypes";

import {
  PL_RESERV_ERR
} from "../actionTypes";
import {
  PL_RESERV
} from "../actionTypes";
import {
  BY_PL_RESERV_ERR
} from "../actionTypes";
import {
  BY_PL_RESERV
} from "../actionTypes";
import {
  GET_PL_RESERV_ERR
} from "../actionTypes";
import {
  GET_PL_RESERV
} from "../actionTypes";

export const getEventReservations = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/EventReservation/list`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          pageNumber: data.pageNumber

        })

      })

      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        if (parsedRes.status === "failure") {
          dispatch(EventReservationserr(parsedRes.error));
        } else {
          dispatch(EventReservationserr(""));
          dispatch(EventReservations(parsedRes.data));
          dispatch(EventReserNbr(parsedRes.Length));

        }

      });
  };
};
export const EventReserNbr = value => {
  return {
    type: EvRes_Nbr,
    EventReserNbr: value
  }
}

export const getReservationsbyEvent = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/EventReservation/byEvent`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        }

      })

      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        if (parsedRes.status === "failure") {
          dispatch(ByEventReservationserr(parsedRes.error));

        } else {
          dispatch(ByEventReservationserr(""));
          dispatch(ByEventReservations(parsedRes.data));
        }

      });
  };
};
export const getEventRv = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/EventReservation/getEvent`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          event_id: data.event_id,
          pageNumber: data.pageNumber
        })

      })

      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {

        dispatch(uiStopLoading())
        if (parsedRes.status === "failure") {
          dispatch(GetEventReservationserr("That event has not reservations !"));

        } else {
          dispatch(GetEventReservationserr(""));
          dispatch(GetEventReservations(parsedRes.data));
          dispatch(EventReservationsNbr(parsedRes.Length));

        }

      });
  };
};
export const EventReservationsNbr = value => {
  return {
    type: EventR_NB,
    EventReservationsNbr: value
  }
}
export const PlaceReservationsNbr = value => {
  return {
    type: PlaceR_NB,
    PlaceReservationsNbr: value
  }
}
export const EventReservationserr = value => {
  return {
    type: EV_RESERV_ERR,
    EventReservationserr: value
  };
};
export const EventReservations = value => {
  return {
    type: EV_RESERV,
    EventReservations: value
  };
};
export const ByEventReservationserr = value => {
  return {
    type: BY_EV_RESERV_ERR,
    ByEventReservationserr: value
  };
};
export const ByEventReservations = value => {
  return {
    type: BY_EV_RESERV,
    ByEventReservations: value
  };
};

export const GetEventReservationserr = value => {
  return {
    type: GET_EV_RESERV_ERR,
    GetEventReservationserr: value
  };
};
export const GetEventReservations = value => {
  return {
    type: GET_EV_RESERV,
    GetEventReservations: value
  };
};

export const getPlaceReservations = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/PlaceReservation/list`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          pageNumber: data.pageNumber

        })

      })

      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        if (parsedRes.status === "failure") {
          dispatch(PlaceReservationserr(parsedRes.error));

        } else {
          dispatch(PlaceReservationserr(""));
          dispatch(PlaceReservations(parsedRes.data));
          dispatch(PlaceReserNbr(parsedRes.Length));
        }

      });
  };
};
export const PlaceReserNbr = value => {
  return {
    type: PLRes_Nbr,
    PlaceReserNbr: value
  }
}
export const getReservationsbyPlace = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/PlaceReservation/byPlace`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        }

      })

      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        if (parsedRes.status === "failure") {
          dispatch(ByPlaceReservationserr(parsedRes.error));

        } else {
          dispatch(ByPlaceReservationserr(""));
          dispatch(ByPlaceReservations(parsedRes.data));

        }

      });
  };
};

export const getPlaceRv = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/PlaceReservation/getPlace`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          place_id: data.place_id,
          pageNumber: data.pageNumber
        })

      })

      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        if (parsedRes.status === "failure") {
          dispatch(GetPlaceReservationserr("That place has not reservations !"));

        } else {
          dispatch(GetPlaceReservationserr(""));
          dispatch(GetPlaceReservations(parsedRes.data));
          dispatch(PlaceReservationsNbr(parsedRes.Length));

        }

      });
  };
};

export const PlaceReservationserr = value => {
  return {
    type: PL_RESERV_ERR,
    PlaceReservationserr: value
  };
};
export const PlaceReservations = value => {
  return {
    type: PL_RESERV,
    PlaceReservations: value
  };
};

export const ByPlaceReservationserr = value => {
  return {
    type: BY_PL_RESERV_ERR,
    ByPlaceReservationserr: value
  };
};
export const ByPlaceReservations = value => {
  return {
    type: BY_PL_RESERV,
    ByPlaceReservations: value
  };
};

export const GetPlaceReservationserr = value => {
  return {
    type: GET_PL_RESERV_ERR,
    GetPlaceReservationserr: value
  };
};
export const GetPlaceReservations = value => {
  return {
    type: GET_PL_RESERV,
    GetPlaceReservations: value
  };
};