import {
  uiStartLoading,
  uiStopLoading
} from "../index";
import axios from "axios";

import {
  NEWSCAST_ADD_DATA
} from "../actionTypes";
import {
  NEWSCAST_ADD_ERR
} from "../actionTypes";
import {
  NEWSCAST_GET_DATA
} from "../actionTypes";
import {
  NEWSCAST_GET_ERR
} from "../actionTypes";
import {
  NEWSCAST_ADD_STATUS
} from "../actionTypes";

export const getNewscast = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/Newscast/getAdminNewscast`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-access-token": token
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading());
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(uiStopLoading());

        if (parsedRes.status === "success") {
          dispatch(getNewscastData(parsedRes.data));
        } else {
          dispatch(getNewscastErr(parsedRes.data));
        }
      });
  };
};

export const addNewscast = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    const form = new FormData();
    for (let i = 0; i < data.events.length; i++) {
      form.append("eventsList", data.events[i]);
    }
    for (let i = 0; i < data.places.length; i++) {
      form.append("image", data.places[i]);
    }
    for (let i = 0; i < data.gallery.length; i++) {
      form.append("gallery", data.gallery[i]);
    }
    for (let i = 0; i < data.news.length; i++) {
      form.append("newsList", data.news[i]);
    }

    const config = {
      headers: {
        enctype: "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token
      }
    };
    axios
      .post(`${process.env.REACT_APP_DOMAIN}/api/Newscast/add`, form, config, {
        useCredentails: true
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading());
      })
      .then(parsedRes => {
        dispatch(uiStopLoading());
        dispatch(NewscastStatus(""));
        if (parsedRes.status === "fail") {
          dispatch(addNewscastErr(parsedRes.error));
          dispatch(NewscastStatus(parsedRes.status));
        } else {
          dispatch(addNewscastErr(""));
          dispatch(getNewscast());
          dispatch(NewscastData(parsedRes.data));
          dispatch(NewscastStatus(parsedRes.status));
          window.location.reload();
        }
      });
  };
};

export const addNewscastErr = value => {
  return {
    type: NEWSCAST_ADD_ERR,
    addNewscastErr: value
  };
};
export const NewscastStatus = value => {
  return {
    type: NEWSCAST_ADD_STATUS,
    NewscastStatus: value
  };
};

export const NewscastData = value => {
  return {
    type: NEWSCAST_ADD_DATA,
    NewscastData: value
  };
};

export const getNewscastErr = value => {
  return {
    type: NEWSCAST_GET_ERR,
    getNewscastErr: value
  };
};

export const getNewscastData = value => {
  return {
    type: NEWSCAST_GET_DATA,
    getNewscastData: value
  };
};