import axios from "axios";
import {
  PLACES_DATA
} from "../actionTypes";
import {
  PLACE_DATA,
  parking_DATA,
  Bea_DATA
} from "../actionTypes";
import {
  uiStartLoading,
  uiStopLoading,
  uiStartLoader,
  uiStopLoader
} from "../index";
import {
  LIST_PLACES_ERR
} from "../actionTypes";
import {
  PLACES_NBR
} from "../actionTypes";
import {
  PLACE_EDIT
} from "../actionTypes";
import {
  EDIT_PLACE_ERR
} from "../actionTypes";
import {
  STATUS_PLACE_EDIT
} from "../actionTypes";

import {
  PLACE_DELETE
} from "../actionTypes";
import {
  DELETE_PLACE_ERR
} from "../actionTypes";
import {
  STATUS_PLACE_DELETE
} from "../actionTypes";

import {
  PLACE_CREATE
} from "../actionTypes";
import {
  CREATE_PLACE_ERR
} from "../actionTypes";
import {
  STATUS_PLACE_CREATE
} from "../actionTypes";

import {
  ACT_PLACES_DATA
} from "../actionTypes";
import {
  LIST_ACT_PLACES_ERR
} from "../actionTypes";
import {
  ACT_PLACES_FAC_DATA
} from "../actionTypes";
import {
  LIST_ACT_PLACES_FAC_ERR
} from "../actionTypes";
import {
  Clone_PLACE_DATA,
  Clone_PLACE_ERR,
  FIND_PLACE_ERR,
  FIND_PLACE
} from "../actionTypes";
import {
  EDIT_TIMING_SUCCESS
} from "../actionTypes";

export const clonePlace = data => {
  return dispatch => {
    dispatch(uiStopLoading())
    dispatch(clonePlaceErr(""));
    dispatch(clonePlaceData(""));
    var token = localStorage.getItem("adminToken");
    fetch(`${process.env.REACT_APP_DOMAIN}/api/place/clone`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token
      },
      body: JSON.stringify({
        placeID: data.placeId
      })
    })
      .catch(err => {
        console.log(err);

      })
      .then(res => res.json())
      .then(parsedRes => {

        if (parsedRes.status === "fail") {
          dispatch(clonePlaceErr(parsedRes.error));
        } else {
          dispatch(clonePlaceErr(""));
          dispatch(clonePlaceData(parsedRes.status));
        }

      });
  };
};
export const clonePlaceData = value => {
  return {
    type: Clone_PLACE_DATA,
    clonePlaceData: value
  };
};
export const clonePlaceErr = value => {
  return {
    type: Clone_PLACE_ERR,
    clonePlaceErr: value
  };
};
export const getPlaces = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");
    dispatch(uiStartLoader())
    dispatch(placesData(""));
    fetch(`${process.env.REACT_APP_DOMAIN}/api/place/getList`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token
      },
      body: JSON.stringify({
        pageNumber: data.pageNumber
      })
    })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoader())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {

        dispatch(uiStopLoader())
        if (parsedRes.status === "failure") {
          dispatch(listPlacesErr(parsedRes.error));
        } else {
          dispatch(listPlacesErr(""));
          dispatch(PlacesNbr(parsedRes.Length));
          dispatch(placesData(parsedRes.data));
        }

      });
  };
};
export const PlacesNbr = value => {
  return {
    type: PLACES_NBR,
    PlacesNbr: value
  };
};
export const getActivePlacesThings = () => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");
    dispatch(uiStopLoading())
    //dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/place/activePlacesThings`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token
      }
    })
      .catch(err => {
        console.log(err);
        // dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {

        // dispatch(uiStopLoading())
        if (parsedRes.status === "fail") {
          dispatch(listActPlacesErr(parsedRes.error));

        } else {
          dispatch(listActPlacesErr(""));
          dispatch(ActplacesData(parsedRes.data));

        }

      });
  };
};
export const editTimingForActivity = (data) => {
  var token = localStorage.getItem("adminToken");
  return (dispatch) => {
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/place/editTiming`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token
      },
      body: JSON.stringify({
        categoryID: data.categoryID,
        timings: data.timings
      }),
    })
      .catch((err) => {
        console.log(err);
        dispatch(uiStopLoading());
      })
      .then((res) => res.json())
      .then((parsedRes) => {
        dispatch(uiStopLoading());
        dispatch(editTimingSuccess(parsedRes));
      });
  };
};

export const getActivePlacesFacilities = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");
    dispatch(uiStopLoading())
    //dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/place/activePlacesFacilities`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token
      }
    })
      .catch(err => {
        console.log(err);
        //   dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {

        //dispatch(uiStopLoading())
        if (parsedRes.status === "fail") {
          dispatch(listActPlacesFacErr(parsedRes.error));

        } else {
          dispatch(listActPlacesFacErr(""));
          dispatch(ActplacesFacData(parsedRes.data));

        }

      });
  };
};

export const getPlace = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");
    dispatch(uiStopLoading())
    //dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/place/get`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token

      },
      body: JSON.stringify({
        placeID: data.placeID

      })
    })
      .catch(err => {
        console.log(err);
        //dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        // dispatch(uiStopLoading())
        dispatch(placeData(parsedRes.data));
        if (parsedRes.parking !== undefined) {
          dispatch(parkingdata(parsedRes.parking));
        } else {
          dispatch(parkingdata(""));
        }

        if (parsedRes.beacons !== undefined) {
          dispatch(beadata(parsedRes.beacons));
        } else {
          dispatch(beadata(""));
        }


      });
  };
};

export const editPlace = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");
    dispatch(uiStartLoading());
   
    const form = new FormData();
    form.append("placeID", data.placeID);
    form.append("title", data.title);
    form.append("location", data.location);
    form.append("description", data.description);
    form.append("shdescription", data.shdescription);
   
    form.append("arShDescription", data.arShDescription);
    form.append("ClosedMessage", data.ClosedMessage);
    form.append("ARClosedMessage", data.ARClosedMessage);
    
    form.append("active", data.active);

    for (let i = 0; i < data.image.length; i++) {
      form.append("image", data.image[i]);
    }
    for (let i = 0; i < data.gallery.length; i++) {
      form.append("gallery", data.gallery[i]);
    }
    form.append("latitude", data.latitude);
    form.append("longitude", data.longitude);
    form.append("type", data.type);
    form.append("category", data.category);
    form.append("order", data.order);

    if (data.categoryID !== undefined) {
      form.append("categoryID", data.categoryID);
    }
    if (data.facilityID !== undefined) {
      form.append("facilityID", data.facilityID);
    }
    form.append('timings', JSON.stringify(data.timings));

    for (let i = 0; i < data.beaconID.length; i++) {
      form.append("beaconID", data.beaconID[i]);
    }
    form.append("ARtitle", data.ARtitle);
    form.append("ARlocation", data.ARlocation);
    form.append("ARdescription", data.ARdescription);
    form.append("parkingID", data.parkingID);
    form.append("maxNbrPersons", data.maxNbrPersons);
    form.append("personsNbrStatus", data.personsNb);

    const config = {
      headers: {
        enctype: "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token
      }
    };

    axios
      .post(`${process.env.REACT_APP_DOMAIN}/api/place/edit`, form, config, {
        useCredentails: true
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(parsedRes => {
        dispatch(editPlacestatus(""));
        dispatch(uiStopLoading())
        dispatch(EditPlaceerr(""));

        if (parsedRes.data.status === "fail") {

          dispatch(EditPlaceerr(parsedRes.data.error));
        } else if (parsedRes.data.status === "failure") {

          dispatch(EditPlaceerr(parsedRes.data.error));
        } else {
          dispatch(EditPlaceerr(""));
          dispatch(editplace(parsedRes.data.data));
          dispatch(editPlacestatus(parsedRes.data.status));
        }

      });
  };


};
export const FindPlacesByName = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");
    dispatch(findPlace(""));
    dispatch(findPlaceErr(""));
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/FindByName`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token
      },
      body: JSON.stringify({
        searchValue: data.searchValue
      })
    })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(findPlace(""));
        dispatch(uiStopLoading())
        if (parsedRes.status === "error") {
          dispatch(findPlaceErr(parsedRes.message));
        } else {
          dispatch(findPlace(parsedRes.data));
        }
      });
  };
}

export const createPlace = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");
    dispatch(uiStartLoading());
    dispatch(CreatePlacestatus(""));
    const form = new FormData();
    form.append("title", data.title);
    form.append("location", data.location);
    form.append("description", data.description);
    form.append("shdescription", data.shdescription);
    form.append("ClosedMessage", data.shdescription);
    form.append("ARClosedMessage", data.shdescription);
    form.append("arShDescription", data.arShDescription);
    form.append("active", data.active);
    form.append("personsNbrStatus", data.personsNb);
    form.append("maxNbrPersons", data.maxNbrPersons);
    for (let i = 0; i < data.image.length; i++) {
      form.append("image", data.image[i]);
    }
    form.append('timings', JSON.stringify(data.timings));

    form.append("type", data.type);
    form.append("category", data.category);
    if (data.categoryID !== undefined) {
      form.append("categoryID", data.categoryID);
    }
    if (data.facilityID !== undefined) {
      form.append("facilityID", data.facilityID);
    }
    form.append("latitude", data.latitude);
    form.append("longitude", data.longitude);
    for (let i = 0; i < data.beaconID.length; i++) {
      form.append("beaconID", data.beaconID[i]);
    }
    form.append("ARtitle", data.ARtitle);
    form.append("ARlocation", data.ARlocation);
    form.append("ARdescription", data.ARdescription);
    form.append("parkingID", data.parkingID);
    form.append("order", data.order);
    const config = {
      headers: {
        enctype: "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token
      }
    };

    axios
      .post(`${process.env.REACT_APP_DOMAIN}/api/place/add`, form, config, {
        useCredentails: true
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(parsedRes => {
        dispatch(uiStopLoading())
        dispatch(CreatePlacestatus(""));


        if (parsedRes.data.status === "fail") {
          dispatch(CreatePlaceerr(parsedRes.data.error));
        } else {
          dispatch(CreatePlaceerr(""));
          dispatch(CreatePlace(parsedRes.data.data));
          dispatch(CreatePlacestatus(parsedRes.data.status));

        }

      });
  };
};
export const DeletePlace = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");
    dispatch(deletePlacestatus(""));
    dispatch(DeletePlaceerr(""));
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/place/delete`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token
      },
      body: JSON.stringify({
        placeID: data.placeID,

      })
    })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(deletePlacestatus(""));
        dispatch(uiStopLoading())
        if (parsedRes.status === "fail") {
          dispatch(DeletePlaceerr(parsedRes.error));
        } else if (parsedRes.status === "failure") {
          dispatch(DeletePlaceerr(parsedRes.error));
        } else {
          dispatch(DeletePlaceerr(""));
          dispatch(deletePlace(parsedRes.data));
          dispatch(deletePlacestatus(parsedRes.status));
        }

      });
  };
};


export const placesData = value => {
  return {
    type: PLACES_DATA,
    placesData: value
  };
};
export const placeData = value => {
  return {
    type: PLACE_DATA,
    placeData: value
  };
};
export const parkingdata = value => {
  return {
    type: parking_DATA,
    parkingdata: value
  };
};

export const beadata = value => {
  return {
    type: Bea_DATA,
    beadata: value
  };
};

export const editplace = value => {
  return {
    type: PLACE_EDIT,
    editplace: value
  };
};
export const editPlacestatus = value => {
  return {
    type: STATUS_PLACE_EDIT,
    editPlacestatus: value
  };
};
export const listPlacesErr = value => {
  return {
    type: LIST_PLACES_ERR,
    listPlacesErr: value
  };
};

export const listActPlacesErr = value => {
  return {
    type: LIST_ACT_PLACES_ERR,
    listActPlacesErr: value
  };
};
export const ActplacesData = value => {
  return {
    type: ACT_PLACES_DATA,
    ActplacesData: value
  };
};
export const listActPlacesFacErr = value => {
  return {
    type: LIST_ACT_PLACES_FAC_ERR,
    listActPlacesFacErr: value
  };
};
export const ActplacesFacData = value => {
  return {
    type: ACT_PLACES_FAC_DATA,
    ActplacesFacData: value
  };
};
export const EditPlaceerr = value => {
  return {
    type: EDIT_PLACE_ERR,
    EditPlaceerr: value
  };
};

export const deletePlace = value => {
  return {
    type: PLACE_DELETE,
    deletePlace: value
  };
};
export const DeletePlaceerr = value => {
  return {
    type: DELETE_PLACE_ERR,
    DeletePlaceerr: value
  };
};
export const deletePlacestatus = value => {
  return {
    type: STATUS_PLACE_DELETE,
    deletePlacestatus: value
  };
};

export const CreatePlace = value => {
  return {
    type: PLACE_CREATE,
    CreatePlace: value
  };
};
export const CreatePlaceerr = value => {
  return {
    type: CREATE_PLACE_ERR,
    CreatePlaceerr: value
  };
};
export const CreatePlacestatus = value => {
  return {
    type: STATUS_PLACE_CREATE,
    CreatePlacestatus: value
  };
};
export const findPlaceErr = value => {
  return {
    type: FIND_PLACE_ERR,
    findPlaceErr: value
  };
};
export const findPlace = value => {
  return {
    type: FIND_PLACE,
    findPlace: value
  };
};
export const editTimingSuccess = value => {
  return {
    type: EDIT_TIMING_SUCCESS,
    editTimingSuccess: value
  };
};
