import { CREATE_PHOTO_ERR } from "../../actions/actionTypes";
import { STATUS_PHOTO_CREATE } from "../../actions/actionTypes";
import { PHOTO_CREATE } from "../../actions/actionTypes";
import { PHOTOS_DATA } from "../../actions/actionTypes";
import { PHOTOS_NBR } from "../../actions/actionTypes";
import { PHOTOS_LIST_ERR } from "../../actions/actionTypes";
import { PHOTO_DELETE } from "../../actions/actionTypes";
import { PHOTO_DELETE_ERR } from "../../actions/actionTypes";
import { PHOTO_DELETE_STATUS } from "../../actions/actionTypes";
import { PHOTO_DATA } from "../../actions/actionTypes";
import { PHOTO_ERR } from "../../actions/actionTypes";
import { PHOTO_EDIT_ERR } from "../../actions/actionTypes";
import { PHOTO_EDIT } from "../../actions/actionTypes"; 
import { PHOTO_EDIT_STATUS } from "../../actions/actionTypes";

const initialState = {

  CreatePhoto: [],
  CreatePhotoerr: '',
  CreatePhotoStatus: '',
  PhotosData: [],
  listPhotosErr: '',
  PhotosNbr: 0,
  deletePhotostatus: '',
  deletePhoto: [],
  DeletePhotoerr: '',
  photoData: [],
  photoErr: '',
  EditPhotoerr: '', 
  EditPhoto: [],
  EditPhotostatus: []

};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case CREATE_PHOTO_ERR:
      return {
        ...state,
        CreatePhotoerr: action.CreatePhotoerr
      };
    case STATUS_PHOTO_CREATE:
      return {
        ...state,
        CreatePhotoStatus: action.CreatePhotoStatus
      };
    case PHOTO_CREATE:
      return {
        ...state,
        CreatePhoto: action.CreatePhoto
      };
    case PHOTOS_DATA:
      return {
        ...state,
        PhotosData: action.PhotosData
      };
    case PHOTOS_NBR:
      return {
        ...state,
        PhotosNbr: action.PhotosNbr
      };
    case PHOTOS_LIST_ERR:
      return {
        ...state,
        listPhotosErr: action.listPhotosErr
      };
    case PHOTO_DELETE:
      return {
        ...state,
        deletePhoto: action.deletePhoto
      };
    case PHOTO_DELETE_ERR:
      return {
        ...state,
        DeletePhotoerr: action.DeletePhotoerr
      };
    case PHOTO_DELETE_STATUS:
      return {
        ...state,
        deletePhotostatus: action.deletePhotostatus
      };
    case PHOTO_ERR:
      return {
        ...state,
        photoErr: action.photoErr
      };
    case PHOTO_DATA:
      return {
        ...state,
        photoData: action.photoData
      };
    case PHOTO_EDIT_ERR:
      return {
        ...state,
        EditPhotoerr: action.EditPhotoerr
      };
    case PHOTO_EDIT:
      return {
        ...state,
        EditPhoto: action.EditPhoto
      };
    case PHOTO_EDIT_STATUS:
      return {
        ...state,
        EditPhotostatus: action.EditPhotostatus
      };

    default:
      return state;
  }
};

export default reducer;
