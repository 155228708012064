import axios from "axios";
import {
  EVENTS_DATA,
  EVENTS_NBR
} from "../actionTypes";
import {
  EVENT_DATA,
  LIST_EVENTS_ACT_DATA,
  LIST_EVENTS_ACT_ERR
} from "../actionTypes";
import {
  uiStartLoading,
  uiStopLoading,
  uiStartLoader,
  uiStopLoader
} from "../index";
import {
  LIST_EVENTS_ERR
} from "../actionTypes";

import {
  EVENT_EDIT
} from "../actionTypes";
import {
  EDIT_EVENT_ERR
} from "../actionTypes";
import {
  STATUS_EVENT_EDIT
} from "../actionTypes";

import {
  EVENT_DELETE
} from "../actionTypes";
import {
  DELETE_EVENT_ERR
} from "../actionTypes";
import {
  STATUS_EVENT_DELETE
} from "../actionTypes";

import {
  EVENT_CREATE
} from "../actionTypes";
import {
  CREATE_EVENT_ERR
} from "../actionTypes";
import {
  STATUS_EVENT_CREATE
} from "../actionTypes";

export const getEvents = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoader());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/event/getlistEvents`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          pageNumber: data.pageNumber
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoader())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoader())
        if (parsedRes.status === "failure") {
          dispatch(listEventsErr(parsedRes.error));
        } else {
          dispatch(listEventsErr(""));
          dispatch(eventsData(parsedRes.data));

          dispatch(EventsNbr(parsedRes.Length));
        }

      });
  };
};
export const getActiveEvents = () => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/event/Activelist`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        if (parsedRes.status === "failure") {
          dispatch(listActEventsErr(parsedRes.error));

        } else {
          dispatch(listActEventsErr(""));
          dispatch(ActeventsData(parsedRes.data));
        }

      });
  };
};
export const EventsNbr = value => {
  return {
    type: EVENTS_NBR,
    EventsNbr: value
  };
};
export const getEvent = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/event/get`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-access-token": token

        },
        body: JSON.stringify({
          eventID: data.eventID

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        dispatch(eventData(parsedRes.data));

      });
  };
};

export const editEvent = data => {
  return dispatch => {
    dispatch(uiStartLoading());
    var token = localStorage.getItem("adminToken");

    const form = new FormData();
    form.append("eventID", data.eventID);
    for (let i = 0; i < data.image.length; i++) {
      form.append("image", data.image[i]);
    }
    for (let i = 0; i < data.gallery.length; i++) {
      form.append("gallery", data.gallery[i]);
    }
    form.append("title", data.title);
    form.append("description", data.description);
    form.append("place", data.place);
    form.append("date", data.date);
    form.append("Enddate", data.Enddate);
    form.append("type", data.type);
    form.append("active", data.active);
    form.append("price", data.price);
    form.append("ARtitle", data.ARtitle);
    form.append("ARdescription", data.ARdescription);
    form.append("begin", data.begin);
    form.append("eventType", data.eventType);
    form.append("end", data.end);
    form.append("maxNbrPersons", data.maxNbrPersons);

    const config = {
      headers: {
        enctype: "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token
      }
    };

    axios
      .post(`${process.env.REACT_APP_DOMAIN}/api/event/edit`, form, config, {
        useCredentails: true
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(parsedRes => {
        dispatch(editEventstatus(""));
        dispatch(EditEventerr(""));
        dispatch(uiStopLoading())

        if (parsedRes.data.status === "fail") {

          dispatch(EditEventerr(parsedRes.data.error));
        } else if (parsedRes.data.status === "failure") {

          dispatch(EditEventerr(parsedRes.data.error));
        } else {
          dispatch(EditEventerr(""));
          dispatch(editevent(parsedRes.data.data));
          dispatch(editEventstatus(parsedRes.data.status));
        }

      });
  };

};

export const createEvent = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    dispatch(CreateEventstatus(""));
    const form = new FormData();
    for (let i = 0; i < data.image.length; i++) {
      form.append("image", data.image[i]);
    }

    form.append("title", data.title);
    form.append("description", data.description);
    form.append("place", data.place);
    form.append("ARtitle", data.ARtitle);
    form.append("ARdescription", data.ARdescription);
    form.append("date", data.date);
    form.append("Enddate", data.Enddate);
    form.append("type", data.type);
    form.append("price", data.price);
    form.append("active", data.active);
    form.append("adminID", data.adminID);
    form.append("eventType", data.eventType);
    form.append("begin", data.begin);
    form.append("end", data.end);
    form.append("maxNbrPersons", data.maxNbrPersons);

    const config = {
      headers: {
        enctype: "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token
      }
    };

    axios
      .post(`${process.env.REACT_APP_DOMAIN}/api/event/add`, form, config, {
        useCredentails: true
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(parsedRes => {
        dispatch(CreateEventstatus(""));
        dispatch(uiStopLoading())

        if (parsedRes.data.status === "fail") {
          dispatch(CreateEventerr(parsedRes.data.error));
        } else {
          dispatch(CreateEventerr(""));
          dispatch(CreateEvent(parsedRes.data.data));
          dispatch(CreateEventstatus(parsedRes.data.status));

        }

      });
  };
};
export const DeleteEvent = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(deleteEventstatus(""));
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/event/delete/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token

        },
        body: JSON.stringify({
          eventID: data.eventID,

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(deleteEventstatus(""));
        dispatch(DeleteEventerr(""));
        dispatch(uiStopLoading())
        if (parsedRes.status === "fail") {
          dispatch(DeleteEventerr(parsedRes.error));
        } else if (parsedRes.status === "failure") {
          dispatch(DeleteEventerr(parsedRes.error));
        } else {
          dispatch(DeleteEventerr(""));
          dispatch(deleteEvent(parsedRes.data));
          dispatch(deleteEventstatus(parsedRes.status));
        }

      });
  };
};


export const eventsData = value => {
  return {
    type: EVENTS_DATA,
    eventsData: value
  };
};
export const eventData = value => {
  return {
    type: EVENT_DATA,
    eventData: value
  };
};

export const editevent = value => {
  return {
    type: EVENT_EDIT,
    editevent: value
  };
};
export const editEventstatus = value => {
  return {
    type: STATUS_EVENT_EDIT,
    editEventstatus: value
  };
};
export const listEventsErr = value => {
  return {
    type: LIST_EVENTS_ERR,
    listEventsErr: value
  };
};
export const EditEventerr = value => {
  return {
    type: EDIT_EVENT_ERR,
    EditEventerr: value
  };
};

export const deleteEvent = value => {
  return {
    type: EVENT_DELETE,
    deleteEvent: value
  };
};
export const DeleteEventerr = value => {
  return {
    type: DELETE_EVENT_ERR,
    DeleteEventerr: value
  };
};
export const deleteEventstatus = value => {
  return {
    type: STATUS_EVENT_DELETE,
    deleteEventstatus: value
  };
};

export const CreateEvent = value => {
  return {
    type: EVENT_CREATE,
    CreateEvent: value
  };
};
export const CreateEventerr = value => {
  return {
    type: CREATE_EVENT_ERR,
    CreateEventerr: value
  };
};
export const CreateEventstatus = value => {
  return {
    type: STATUS_EVENT_CREATE,
    CreateEventstatus: value
  };
};
export const listActEventsErr = value => {
  return {
    type: LIST_EVENTS_ACT_ERR,
    listActEventsErr: value
  };
};
export const ActeventsData = value => {
  return {
    type: LIST_EVENTS_ACT_DATA,
    ActeventsData: value
  };
};