import {
  NOTIFICATIONS_DATA,
  NOTIFICATIONS_NB
} from "../actionTypes";
import {
  NOTIFICATION_DATA
} from "../actionTypes";
import {
  uiStartLoading,
  uiStopLoading
} from "../index";
import {
  LIST_NOTIFICATIONS_ERR
} from "../actionTypes";

import {
  NOTIFICATION_EDIT
} from "../actionTypes";
import {
  EDIT_NOTIFICATION_ERR
} from "../actionTypes";
import {
  STATUS_NOTIFICATION_EDIT
} from "../actionTypes";

import {
  NOTIFICATION_DELETE
} from "../actionTypes";
import {
  DELETE_NOTIFICATION_ERR
} from "../actionTypes";
import {
  STATUS_NOTIFICATION_DELETE
} from "../actionTypes";

import {
  NOTIFICATION_SEND
} from "../actionTypes";
import {
  SEND_NOTIFICATION_ERR
} from "../actionTypes";
import {
  STATUS_NOTIFICATION_SEND
} from "../actionTypes";

import {
  NOTIFICATION_CREATE
} from "../actionTypes";
import {
  CREATE_NOTIFICATION_ERR
} from "../actionTypes";
import {
  STATUS_NOTIFICATION_CREATE
} from "../actionTypes";



export const getNotifications = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/notification/getList`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          pageNumber: data.pageNumber
        })

      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {

        dispatch(uiStopLoading())
        if (parsedRes.status === "failure") {
          dispatch(listnotificationsErr(parsedRes.error));

        } else {
          dispatch(listnotificationsErr(""));
          dispatch(notificationsData(parsedRes.data));
          dispatch(notificationsNbr(parsedRes.Length));
        }

      });
  };
};

export const getNotification = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/notification/get`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-access-token": token
        },
        body: JSON.stringify({
          notificationID: data.notificationID

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        dispatch(notificationData(parsedRes.data));

      });
  };
};

export const editNotification = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/notification/edit`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-access-token": token
        },
        body: JSON.stringify({
          notificationID: data.notificationID,
          title: data.title,
          body: data.body,
          ARtitle: data.ARtitle,
          ARbody: data.ARbody,
          date: data.date

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(editnotificationStatus(""));
        dispatch(uiStopLoading())
        dispatch(Editnotificationerr(""));

        if (parsedRes.status === "fail") {

          dispatch(Editnotificationerr(parsedRes.error));
        } else {
          dispatch(Editnotificationerr(""));
          dispatch(editnotification(parsedRes.data));
          dispatch(editnotificationStatus(parsedRes.status));
        }

      });
  };


};

export const createNotification = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");


    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/notification/add`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          title: data.title,
          ARtitle: data.ARtitle,
          body: data.body,
          ARbody: data.ARbody,
          date: data.date

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(CreatenotificationStatus(""));
        dispatch(uiStopLoading())

        if (parsedRes.status === "fail") {
          dispatch(Createnotificationerr(parsedRes.error));
        } else {
          dispatch(Createnotificationerr(""));
          dispatch(Createnotification(parsedRes.data));
          dispatch(CreatenotificationStatus(parsedRes.status));

        }

      });
  };
};
export const DeleteNotification = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    dispatch(deletenotificationStatus(""));

    fetch(`${process.env.REACT_APP_DOMAIN}/api/notification/delete`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          notificationID: data.notificationID,

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(deletenotificationStatus(""));
        dispatch(uiStopLoading())
        if (parsedRes.status === "fail") {
          dispatch(Deletenotificationerr(parsedRes.error));
        } else {
          dispatch(Deletenotificationerr(""));
          dispatch(deletenotification(parsedRes.data));
          dispatch(deletenotificationStatus(parsedRes.status));
        }

      });
  };
};
export const SendNotification = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/notification/send`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          notificationID: data.notificationID
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(sendnotificationStatus(""));
        dispatch(uiStopLoading())

        if (parsedRes.status === "fail") {
          dispatch(Sendnotificationerr(parsedRes.error));
        } else {
          dispatch(Sendnotificationerr(""));
          dispatch(sendnotification(parsedRes.data));
          dispatch(sendnotificationStatus(parsedRes.status));
        }

      });
  };
};

export const notificationsData = value => {
  return {
    type: NOTIFICATIONS_DATA,
    notificationsData: value
  };
};
export const notificationsNbr = value => {
  return {
    type: NOTIFICATIONS_NB,
    notificationsNbr: value
  };
};

export const notificationData = value => {
  return {
    type: NOTIFICATION_DATA,
    notificationData: value
  };
};

export const editnotification = value => {
  return {
    type: NOTIFICATION_EDIT,
    editnotification: value
  };
};
export const editnotificationStatus = value => {
  return {
    type: STATUS_NOTIFICATION_EDIT,
    editnotificationStatus: value
  };
};
export const listnotificationsErr = value => {
  return {
    type: LIST_NOTIFICATIONS_ERR,
    listnotificationsErr: value
  };
};

export const Editnotificationerr = value => {
  return {
    type: EDIT_NOTIFICATION_ERR,
    Editnotificationerr: value
  };
};

export const deletenotification = value => {
  return {
    type: NOTIFICATION_DELETE,
    deletenotification: value
  };
};
export const Deletenotificationerr = value => {
  return {
    type: DELETE_NOTIFICATION_ERR,
    Deletenotificationerr: value
  };
};
export const deletenotificationStatus = value => {
  return {
    type: STATUS_NOTIFICATION_DELETE,
    deletenotificationStatus: value
  };
};

export const sendnotification = value => {
  return {
    type: NOTIFICATION_SEND,
    sendnotification: value
  };
};
export const Sendnotificationerr = value => {
  return {
    type: SEND_NOTIFICATION_ERR,
    Sendnotificationerr: value
  };
};
export const sendnotificationStatus = value => {
  return {
    type: STATUS_NOTIFICATION_SEND,
    sendnotificationStatus: value
  };
};

export const Createnotification = value => {
  return {
    type: NOTIFICATION_CREATE,
    Createnotification: value
  };
};
export const Createnotificationerr = value => {
  return {
    type: CREATE_NOTIFICATION_ERR,
    Createnotificationerr: value
  };
};
export const CreatenotificationStatus = value => {
  return {
    type: STATUS_NOTIFICATION_CREATE,
    CreatenotificationStatus: value
  };
};