import React, {
  Component
} from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";
import logoBidaa from "../../../assets/img/brand/ABP-LOGO-SQR-WHI.png";
import notValid from "../../../assets/img/brand/notValid.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  AdminAuthen
} from "../../../store/actions";
import {
  compose
} from "redux";
import {
  connect
} from "react-redux";
import {
  Trans
} from "react-i18next";
import firebase from "firebase";

var config = {
  apiKey: "AIzaSyAjXqxylFikCEXwk0cFm_L32JAu-BTb6P0",
  authDomain: "albiddapark-22cdd.firebaseapp.com",
  databaseURL: "https://albiddapark-22cdd.firebaseio.com",
  projectId: "albiddapark-22cdd",
  storageBucket: "albiddapark-22cdd.appspot.com",
  messagingSenderId: "136639953052",
  appId: "1:136639953052:web:d21acb8e1a5abcf0c71559",
  measurementId: "G-4W9TCS6EJS"
};

firebase.initializeApp(config);

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: null,
      password: null,
      token: "  "
    };
  }

  componentDidMount() {
    firebase
      .messaging()
      .getToken()
      .then(token => {
        if (token) {
          this.setState({
            token: token
          });
        }
      });
  }

  onAuthenticate = () => {
    const data = {
      Username: this.state.Username,
      password: this.state.password,
      token: this.state.token
    };
    if (data.Username !== null && data.password !== null) {
      this.props.AdminAuthen(data);
    }
  };

  render() {
    const {
      isLoading
    } = this.props;

    let btnValidate = ( < Button className = "btn-secondary px-4 login"
      type = "submit"
      onClick = {
        this.onAuthenticate
      } > {
        " "
      } < Trans i18nKey = "Login.Login" / >
      <
      /Button>
    );
    if (isLoading) {
      btnValidate = < CircularProgress size = {
        30
      }
      style = {
        {
          color: "#083328"
        }
      }
      />;
    }
    let err = ( < Container >
      <
      Row className = "justify-content-center" >
      <
      p > < /p> < /Row >
      <
      /Container>
    );

    if (this.props.Autherr !== "") {
      err = ( < Container style = {
          {
            marginTop: "3%"
          }
        } >
        <
        Row className = "justify-content-center" >
        <
        img src = {
          notValid
        }
        alt = "notvalid"
        style = {
          {
            width: "20px",
            marginRight: "10px",
            height: "20px"
          }
        }
        /> <p style = { {
        color: "red"
      }
    } > {
      this.props.Autherr
    } < /p>  < /
    Row > <
      /Container>
  );
}

return ( < div className = "app flex-row align-items-center" >
    <
    Container >
    <
    Row className = "justify-content-center" >
    <
    Col md = "4"
    style = {
      {
        textAlign: "center",
        marginTop: "-60px"
      }
    } >
    <
    img src = {
      logoBidaa
    }
    alt = "bidaa logo"
    style = {
      {
        marginBottom: "20px",
        width: "45%"
      }
    }
    />  < /
    Col > <
    /Row>  <
    Row className = "justify-content-center" >
    <
    Col md = "5" >
    <
    CardGroup >
    <
    Card className = "p-4" >
    <
    CardBody >
    <
    Form >
    <
    h1 > {
      " "
    } <
    Trans i18nKey = "Login.Login" / >
    <
    /h1> <p className = "text-muted" > <
    Trans i18nKey = "Login.SignIn" / >
    <
    /p>  <
    InputGroup className = "mb-3" >
    <
    InputGroupAddon addonType = "prepend" >
    <
    InputGroupText >
    <
    i className = "icon-user" > < /i> < /InputGroupText > < /InputGroupAddon>   <
    Input type = "text"
    placeholder = ""
    onChange = {
      event =>
      this.setState({
        Username: event.target.value
      })
    }
    autoComplete = "username" / >
    <
    /InputGroup>   <
    InputGroup className = "mb-4" >
    <
    InputGroupAddon addonType = "prepend" >
    <
    InputGroupText >
    <
    i className = "icon-lock" > < /i> < /InputGroupText > < /InputGroupAddon>   <
    Input type = "password"
    placeholder = ""
    onChange = {
      event =>
      this.setState({
        password: event.target.value
      })
    }
    autoComplete = "current-password" / >
    <
    /InputGroup>   <
    Row >
    <
    Col xs = "6" > {
      btnValidate
    } < /Col> {
    err
  } < /Row> < /Form >
  <
  /CardBody> < /Card > < /CardGroup> < /Col >
  <
  /Row>  </Container > < /div >
);
}
}

const mapStateToProps = state => {
  return {
    adminAuth: state.adminAuth.adminAuth,
    isLoading: state.ui.isLoading,
    Autherr: state.Autherr.Autherr
  };
};

const mapDispatchToProps = dispatch => {
  return {
    AdminAuthen: data => dispatch(AdminAuthen(data))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Login);