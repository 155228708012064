import React, { Component } from "react";
import { translate } from "react-i18next";
import './App.scss';
import { PrivateRouter } from "./views/Pages/Login/privateRouter.js"
import { Switch, Route } from "react-router-dom";
import Events from "./views/Pages/Events/Events";
import Merchants from "./views/Pages/Merchants/Merchants";
import Users from "./views/Pages/Users/Users";
import PlaceReservations from "./views/Pages/Reservations/PlaceReservations";
import UserDetails from "./views/Pages/Profile/UserDetails";
import EventReservations from "./views/Pages/Reservations/EventReservations";
import ReservationsbyEvent from "./views/Pages/Reservations/ReservationsByEvent";
import ReservationsbyPlace from "./views/Pages/Reservations/ReservationsByPlace";
import UsersList from "./views/Pages/Users/UsersTokens";
import Beacons from "./views/Pages/Beacons/Beacons";
import Places from "./views/Pages/Places/Places";
import Videos from "./views/Pages/Medias/Videos/Videos";
import Photos from "./views/Pages/Medias/Photos/Photos";
import Banners from "./views/Pages/Banners/Banners";
import AddBanner from "./views/Pages/Banners/createBanner";
import FAQs from "./views/Pages/FAQs/FAQs";
import Rules from "./views/Pages/Rules/Rules";
import Timings from "./views/Pages/Timing/Timings";

import HomePage from "./views/Pages/Timing/homePage";
import AboutUs from "./views/Pages/Timing/aboutUs";
import ParkInformation from "./views/Pages/Timing/parkInformation";

import Categories from "./views/Pages/Category/Categories";
import Facilities from "./views/Pages/Facility/Facilities";
import Admins from "./views/Pages/Admin/Admins";
import Notifications from "./views/Pages/Notifications/Notifications";
import Login from "./views/Pages/Login/Login";
import Home from "./containers/DefaultLayout/DefaultLayout";
import CreateEvent from './views/Pages/Events/createEvent';
import CreateMerchant from './views/Pages/Merchants/createMerchant';
import { CreateBeacon } from './store/actions/beacons/beacons';
import { CreatePlace } from './store/actions/places/places';
import { createCategory } from './store/actions/categories/categories';
import { createFacility } from './store/actions/facilities/facilities';
import { CreateBanner } from './store/actions/banners/banners';
import { createFAQ } from './store/actions/FAQs/FAQs';
import { createRegulation } from './store/actions/Rules/Rules';
import { createTiming } from './store/actions/Timings/Timings';
import { createSUBJECT } from './store/actions/Subjects/Subjects';
import { createNotification } from './store/actions/notifications/notifications';
import { createPhotoAlbum } from './store/actions/Medias/photos';
import { initializePush } from "./initialize.js";
class App extends Component {
  render() {
    initializePush()
    if ((localStorage.getItem('i18nextLng') !== "en" && localStorage.getItem('i18nextLng') !== "ar")) {
      localStorage.setItem('i18nextLng', "en")
    }
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
        <PrivateRouter path="/" name="Home" component={Home} />
        <PrivateRouter path="/users" name="users" component={Users} />
        <PrivateRouter path="/users_list" name="userslist" component={UsersList} />
        <PrivateRouter path="/reservations/places" name="PlaceReservations" component={PlaceReservations} />
        <PrivateRouter path="/reservations/events" name="EventReservations" component={EventReservations} />
        <PrivateRouter path="/reservations/by_event" name="Reservations by Event" component={ReservationsbyEvent} />
        <PrivateRouter path="/reservations/by_place" name="Reservations by Place" component={ReservationsbyPlace} />
        <PrivateRouter path="/ibeacons" name="beacons" component={Beacons} />
        <PrivateRouter path="/ibeacon/create" exact name="create beacons" component={CreateBeacon} />
        <PrivateRouter path="/events" name="events" component={Events} />
        <PrivateRouter path="/merchants" name="Merchants" component={Merchants} />
        <PrivateRouter path="/event/create" name="create event" component={CreateEvent} />
        <PrivateRouter path="/merchant/create" name="create merchant" component={CreateMerchant} />
        <PrivateRouter path="/places" name="places" component={Places} />
        <PrivateRouter path="/medias/photos" name="places" component={Photos} />
        <PrivateRouter path="/medias/videos" name="places" component={Videos} />
        <PrivateRouter path="/place/create" exact name="create place" component={CreatePlace} />
        <PrivateRouter path="/categories" name="categories" component={Categories} />
        <PrivateRouter path="/category/create" exact name="create category" component={createCategory} />
        <PrivateRouter path="/facilities" name="facilities" component={Facilities} />
        <PrivateRouter path="/user/:id" name="user details" component={UserDetails} />
        <PrivateRouter path="/admins" name="admins" component={Admins} />
        <PrivateRouter path="/facility/create" exact name="create facility" component={createFacility} />
        <PrivateRouter path="/notifications" name="notifications" component={Notifications} />
        <PrivateRouter path="/notification/create" exact name="create notification" component={createNotification} />
        <PrivateRouter path="/splash" name="banners" component={Banners} />
        <PrivateRouter path="/splash/create" exact name="create banner" component={AddBanner} />
        <PrivateRouter path="/medias/photos/create" exact name="create picture album" component={createPhotoAlbum} />
        <PrivateRouter path="/FAQs" name="FAQs" component={FAQs} />
        <PrivateRouter path="/FAQ/create" exact name="create FAQ" component={createFAQ} />
        <PrivateRouter path="/subject/create" exact name="create Subject" component={createSUBJECT} />
        <PrivateRouter path="/timings" name="timings" component={Timings} />

        <PrivateRouter path="/homePage" name="about us" component={HomePage} />
        <PrivateRouter path="/aboutUs" name="home page" component={AboutUs} />
        <PrivateRouter path="/parkInformation" name="park information" component={ParkInformation} />

        <PrivateRouter path="/timing/create" exact name="create timing" component={createTiming} />
        <PrivateRouter path="/rules" name="Rules" component={Rules} />
        <PrivateRouter path="/rule/create" exact name="create rule" component={createRegulation} />
      </Switch>
    );
  }
}
// extended main view with translate hoc
export default (translate("translations"))(App);