const crypto = require("crypto");
var password = "DkzcSLigxsBRNw95Pb8X";
var iv = "ojisdasjdsjabdjs";


function password_derive_bytes(password, salt, iterations, len) {
    var key = Buffer.from(password + salt);
    for (var i = 0; i < iterations; i++) {
        key = crypto
            .createHash("sha1")
            .update(key)
            .digest();
    }
    if (key.length < len) {
        var hx = password_derive_bytes(password, salt, iterations - 1, 20);
        for (var counter = 1; key.length < len; ++counter) {
            key = Buffer.concat([
                key,
                (key = crypto
                    .createHash("sha1")
                    .update(Buffer.concat([Buffer.from(counter.toString()), hx]))
                    .digest())
            ]);
        }
    }
    return Buffer.alloc(len, key);
}
export async function decode(string) {
    //console.log("decode ", process.env.REACT_APP_DATA_CRYPTE);
    // if (process.env.REACT_APP_DATA_CRYPTE === "true") {
    var key = password_derive_bytes(password, "", 100, 32);
    var decipher = crypto.createDecipheriv("aes-256-cbc", key, Buffer.from(iv));
    var decrypted = decipher.update(string, "base64", "utf8");
    decrypted += decipher.final();
    // } else {
    //     return string;
    // }

    return decrypted;
}