import {
  BEACONS_DATA,
  BEACONS_NB,
  BEACONS_LIST
} from "../actionTypes";
import {
  BEACON_DATA
} from "../actionTypes";
import {
  uiStartLoading,
  uiStopLoading,
  uiStartLoader,
  uiStopLoader
} from "../index";
import {
  LIST_BEACONS_ERR
} from "../actionTypes";

import {
  BEACON_EDIT
} from "../actionTypes";
import {
  EDIT_BEACON_ERR,
  EDIT_BEACON_EXIST
} from "../actionTypes";
import {
  STATUS_BEACON_EDIT
} from "../actionTypes";

import {
  BEACON_DELETE
} from "../actionTypes";
import {
  DELETE_BEACON_ERR
} from "../actionTypes";
import {
  STATUS_BEACON_DELETE
} from "../actionTypes";

import {
  BEACON_CREATE
} from "../actionTypes";
import {
  CREATE_BEACON_ERR,
  CREATE_BEACON_EXIST
} from "../actionTypes";
import {
  STATUS_BEACON_CREATE
} from "../actionTypes";

import {
  FIND_BEACON_ERR,
  FIND_BEACON
} from "../actionTypes";

export const FindBeaconByName = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");
    dispatch(findBeacon(""));
    dispatch(findBeaconErr(""));
     dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/FindByNameBeacon`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          searchValue: data.searchValue
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(findBeacon(""));
         dispatch(uiStopLoading())
        if (parsedRes.status === "error") {
          dispatch(findBeaconErr(parsedRes.message));
        } else {
          dispatch(findBeacon(parsedRes.data));
        }
      });
  };
}
export const getBeacons = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoader());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/beacons/getListwithPagination`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          pageNumber: data.pageNumber
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoader());
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(uiStopLoader());
        if (parsedRes.status === "failure") {
          dispatch(listBeaconsErr(parsedRes.error));
        } else {
          dispatch(listBeaconsErr(""));
          dispatch(beaconsData(parsedRes.data));
          dispatch(beaconsNb(parsedRes.Length));
        }
      });
  };
};
export const getBeaconsList = () => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/beacons/getList`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading());
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(uiStopLoading());
        if (parsedRes.status === "failure") {
          dispatch(listBeaconsErr(parsedRes.error));
        } else {
          dispatch(listBeaconsErr(""));
          dispatch(beaconsDataList(parsedRes.data));
        }
      });
  };
};

export const getBeacon = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/beacons/get`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          beaconID: data.beaconID
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading());
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(uiStopLoading());
        dispatch(beaconData(parsedRes.data));
      });
  };
};

export const editBeacon = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");
    fetch(`${process.env.REACT_APP_DOMAIN}/api/beacons/edit`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          beaconID: data.beaconID,
          Code: data.Code,
          UUID: data.UUID,
          Major: data.Major,
          Minor: data.Minor,
          Message: data.Message,
          MessageAR: data.MessageAR
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading());
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(editBeaconstatus(""));
        dispatch(EditBeaconExist(""));

        dispatch(uiStopLoading());

        if (parsedRes.status === "fail") {
          dispatch(EditBeaconerr(parsedRes.error));
        } else if (parsedRes.status === "failure") {
          dispatch(EditBeaconExist(parsedRes.error));
        } else {
          dispatch(EditBeaconerr(""));
          dispatch(EditBeaconExist(""));
          dispatch(editbeacon(parsedRes.data));
          dispatch(editBeaconstatus(parsedRes.status));
        }
      });
  };
};

export const createBeacon = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");
    dispatch(CreateBeaconstatus(""));
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/beacons/add`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          Code: data.Code,
          UUID: data.UUID,
          Major: data.Major,
          Minor: data.Minor,

          Message: data.Message,
          MessageAR: data.MessageAR
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading());
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(CreateBeaconstatus(""));
        dispatch(CreateBeaconExist(""));
        dispatch(uiStopLoading());

        if (parsedRes.status === "fail") {
          dispatch(CreateBeaconerr(parsedRes.error));
        } else if (parsedRes.status === "failure") {
          dispatch(CreateBeaconExist(parsedRes.error));
        } else {
          dispatch(CreateBeaconerr(""));
          dispatch(CreateBeaconExist(""));
          dispatch(CreateBeacon(parsedRes.data));
          dispatch(CreateBeaconstatus(parsedRes.status));
        }
      });
  };
};
export const DeleteBeacon = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");
    dispatch(deleteBeaconstatus(""));
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/beacons/delete`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          beaconID: data.beaconID
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading());
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(deleteBeaconstatus(""));
        dispatch(DeleteBeaconerr(""));
        dispatch(uiStopLoading());
        if (parsedRes.status === "fail" || parsedRes.status === "failure") {
          dispatch(DeleteBeaconerr(parsedRes.error));
        } else {
          dispatch(DeleteBeaconerr(""));
          dispatch(deleteBeacon(parsedRes.data));
          dispatch(deleteBeaconstatus(parsedRes.status));
        }
      });
  };
};

export const beaconsDataList = value => {
  return {
    type: BEACONS_LIST,
    beaconsDataList: value
  };
};
export const beaconsData = value => {
  return {
    type: BEACONS_DATA,
    beaconsData: value
  };
};
export const beaconData = value => {
  return {
    type: BEACON_DATA,
    beaconData: value
  };
};

export const editbeacon = value => {
  return {
    type: BEACON_EDIT,
    editbeacon: value
  };
};
export const editBeaconstatus = value => {
  return {
    type: STATUS_BEACON_EDIT,
    editBeaconstatus: value
  };
};
export const listBeaconsErr = value => {
  return {
    type: LIST_BEACONS_ERR,
    listBeaconsErr: value
  };
};
export const EditBeaconerr = value => {
  return {
    type: EDIT_BEACON_ERR,
    EditBeaconerr: value
  };
};
export const EditBeaconExist = value => {
  return {
    type: EDIT_BEACON_EXIST,
    EditBeaconExist: value
  };
};

export const deleteBeacon = value => {
  return {
    type: BEACON_DELETE,
    deleteBeacon: value
  };
};
export const DeleteBeaconerr = value => {
  return {
    type: DELETE_BEACON_ERR,
    DeleteBeaconerr: value
  };
};
export const deleteBeaconstatus = value => {
  return {
    type: STATUS_BEACON_DELETE,
    deleteBeaconstatus: value
  };
};

export const CreateBeacon = value => {
  return {
    type: BEACON_CREATE,
    CreateBeacon: value
  };
};
export const CreateBeaconerr = value => {
  return {
    type: CREATE_BEACON_ERR,
    CreateBeaconerr: value
  };
};
export const CreateBeaconExist = value => {
  return {
    type: CREATE_BEACON_EXIST,
    CreateBeaconExist: value
  };
};

export const CreateBeaconstatus = value => {
  return {
    type: STATUS_BEACON_CREATE,
    CreateBeaconstatus: value
  };
};
export const beaconsNb = value => {
  return {
    type: BEACONS_NB,
    beaconsNb: value
  };
};
export const findBeaconErr = value => {
  return {
    type: FIND_BEACON_ERR,
    findBeaconErr: value
  };
};
export const findBeacon = value => {
  return {
    type: FIND_BEACON,
    findBeacon: value
  };
};