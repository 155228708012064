import { EV_RESERV } from "../../actions/actionTypes";
import { EV_RESERV_ERR } from "../../actions/actionTypes";
import { PL_RESERV } from "../../actions/actionTypes";
import { PL_RESERV_ERR } from "../../actions/actionTypes";
import { EventR_NB, EvRes_Nbr } from "../../actions/actionTypes";
import { BY_EV_RESERV } from "../../actions/actionTypes";
import { BY_EV_RESERV_ERR } from "../../actions/actionTypes";
import { BY_PL_RESERV } from "../../actions/actionTypes";
import { BY_PL_RESERV_ERR } from "../../actions/actionTypes";
import { PLRes_Nbr } from "../../actions/actionTypes";
import { GET_EV_RESERV } from "../../actions/actionTypes";
import { GET_EV_RESERV_ERR } from "../../actions/actionTypes";
import { GET_PL_RESERV } from "../../actions/actionTypes";
import { GET_PL_RESERV_ERR } from "../../actions/actionTypes";
import { PlaceR_NB } from "../../actions/actionTypes";

const initialState = {
  EventReservations: [],
  EventReservationserr: '',
  PlaceReservations: [],
  PlaceReservationserr: '',
  EventReservationsNbr: 0,
  ByEventReservations: [],
  ByEventReservationserr: '',
  ByPlaceReservations: [],
  EventReserNbr: 0,
  ByPlaceReservationserr: '',
  PlaceReservationsNbr: 0,
  GetEventReservations: [],
  GetEventReservationserr: '',
  GetPlaceReservations: [],
  GetPlaceReservationserr: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case EvRes_Nbr:
      return {
        ...state,
        EventReserNbr: action.EventReserNbr
      };
    case EventR_NB:
      return {
        ...state,
        EventReservationsNbr: action.EventReservationsNbr
      };
    case EV_RESERV:
      return {
        ...state,
        EventReservations: action.EventReservations
      };
    case EV_RESERV_ERR:
      return {
        ...state,
        EventReservationserr: action.EventReservationserr
      };
    case PL_RESERV:
      return {
        ...state,
        PlaceReservations: action.PlaceReservations
      };
    case PL_RESERV_ERR:
      return {
        ...state,
        PlaceReservationserr: action.PlaceReservationserr
      };
    case BY_EV_RESERV:
      return {
        ...state,
        ByEventReservations: action.ByEventReservations
      };
    case BY_EV_RESERV_ERR:
      return {
        ...state,
        ByEventReservationserr: action.ByEventReservationserr
      };
    case BY_PL_RESERV:
      return {
        ...state,
        ByPlaceReservations: action.ByPlaceReservations
      };
    case BY_PL_RESERV_ERR:
      return {
        ...state,
        ByPlaceReservationserr: action.ByPlaceReservationserr
      };

    case GET_EV_RESERV:
      return {
        ...state,
        GetEventReservations: action.GetEventReservations
      };
    case GET_EV_RESERV_ERR:
      return {
        ...state,
        GetEventReservationserr: action.GetEventReservationserr
      };
    case GET_PL_RESERV:
      return {
        ...state,
        GetPlaceReservations: action.GetPlaceReservations
      };
    case GET_PL_RESERV_ERR:
      return {
        ...state,
        GetPlaceReservationserr: action.GetPlaceReservationserr
      };
    case PLRes_Nbr:
      return {
        ...state,
        PlaceReserNbr: action.PlaceReserNbr
      };
    case PlaceR_NB:
      return {
        ...state,
        PlaceReservationsNbr: action.PlaceReservationsNbr
      };
    default:
      return state;
  }
};

export default reducer;
