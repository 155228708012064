import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardFooter,Col, Row, Table, Tooltip, Badge, Input, Label, Button, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import notValid from '../../../assets/img/brand/notValid.png';

import { getTimings, DeleteTiming, saveAboutus, getAboutus } from "../../../store/actions";
import { compose } from "redux";
import { Trans } from "react-i18next";
import addBtn from '../../../assets/img/brand/plus-button.png';
import history from "../../../history.js";
import { connect } from "react-redux";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import CircularProgress from "@material-ui/core/CircularProgress";
import Dropzone from "react-dropzone";




class Timings extends Component {

    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this);
        this.toggleArabic = this.toggleArabic.bind(this);
        this.state = {
            Aboutdescription: '',
            ARAboutdescription: '',
            ARHomeMessagedescription:"",
            HomeMessagedescription:'',
            current: 1,
            activeTab: new Array(2).fill('1'),
            modal: false,
            TimingId: '',
            active: '',
            total: 0,
            OpeningHours: '',
            ParkMap: '',
            LinkParkMap: '',
            Accessibility: '',
            AROpeningHours: '',
            ARParkMap: '',
            ARLinkParkMap: '',
            ARAccessibility: '',
            empty: '',
            files:[],
            imgAboutUS: [],
            imgAboutUSws: [],
            imgParkinformation:[],
            filesimgParkinformation:[],
            imgParkinformationws:[],
            filesimgHomeMessage:[],
            imgHomeMessagews:[],
            imgHomeMessage:[],
        };
    }
    toggleArabic(tabPane, tab) {
        const newArray = this.state.activeTab.slice()
        newArray[tabPane] = tab
        this.setState({
            activeTab: newArray
        });
    }
    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    onChange = (page) => {
        this.setState({
            current: page
        });
        const data = { pageNumber: page };
        this.props.getTimings(data)
    }
    componentDidMount() {
        var token = localStorage.getItem("adminToken")
        var jwtDecode = require('jwt-decode');
        var decoded = jwtDecode(token);
        var expirationDate = decoded.exp;
        var seconds = 1000;
        var d = new Date();
        var t = d.getTime();

        if (expirationDate < Math.round(t / seconds)) {
            localStorage.setItem("adminToken", "")
            history.push("/login");

        }
        else {
            const data = { pageNumber: 1 };
            this.props.getTimings(data)
            this.props.getAboutus()
        }

    }
    componentDidUpdate(prevProps) {


        if (this.props.aboutDescData !== prevProps.aboutDescData) {
            this.setState({
                Aboutdescription: this.props.aboutDescData.Aboutdescription,
                HomeMessagedescription: this.props.aboutDescData.HomeMessagedescription,

                ARAboutdescription: this.props.aboutDescData.translationAr[0].ARAboutdescription,
                ARHomeMessagedescription: this.props.aboutDescData.translationAr[0].ARHomeMessagedescription,
                OpeningHours:this.props.aboutDescData.OpeningHours,
                ParkMap:this.props.aboutDescData.ParkMap,
                LinkParkMap:this.props.aboutDescData.LinkParkMap,
                Accessibility:this.props.aboutDescData.Accessibility,
    
                AROpeningHours:this.props.aboutDescData.translationAr[0].AROpeningHours,
                ARParkMap:this.props.aboutDescData.translationAr[0].ARParkMap,
                ARLinkParkMap:this.props.aboutDescData.translationAr[0].ARLinkParkMap,
                ARAccessibility:this.props.aboutDescData.translationAr[0].ARAccessibility,

                imgAboutUSws:this.props.aboutDescData.imgAboutUS,
                imgParkinformationws:this.props.aboutDescData.imgParkinformation,
             
                imgHomeMessagews:this.props.aboutDescData.imgHomeMessage,
            })
        }
        if (this.props.timingsNb !== prevProps.timingsNb){
            this.setState({
                total: this.props.timingsNb
            })
        }

    }

    onDelete() {
        const data = { timingID: this.state.TimingId };
        this.props.DeleteTiming(data);
    };

    toggleModal(TimingId) {

        this.setState({
            modal: !this.state.modal,
            TimingId: TimingId,
        });
    }

    componentWillReceiveProps(prevProps) {
        if (prevProps.deleteTimingstatus !== this.props.deleteTimingstatus) {
            if (prevProps.deleteTimingstatus === "success") {
                this.setState({
                    modal: false
                });
                ToastsStore.info(<h6><Trans i18nKey="Timings.del" /></h6>, 3000)
                const data = { pageNumber: 1 };
                this.props.getTimings(data)
            }
        }
        if (prevProps.editAboutDescStatus !== this.props.editAboutDescStatus) {
            if (prevProps.editAboutDescStatus === "success") {
                ToastsStore.info(<h6><Trans i18nKey="Timings.updTim" /></h6>, 3000)
            }

        }

    }
    onHomeMessageDescriptionChange(value) {
        this.setState({
            HomeMessagedescription: value
        });
    }
    onARHomeMessageDescriptionChange(value) {
        this.setState({
            ARHomeMessagedescription: value
        });
    }
    onAboutDescriptionChange(value) {
        this.setState({
            Aboutdescription: value
        });
    }
    onARAboutDescriptionChange(value) {
        this.setState({
            ARAboutdescription: value
        });
    }
    ///debut
    onOpeningHoursChange(value) {
        this.setState({
            OpeningHours: value
        });
    }
    onParkMapChange(value) {
        this.setState({
            ParkMap: value
        });
    }
    onLinkParkMapChange(value) {
        this.setState({
            LinkParkMap: value
        });
    }
    onAccessibilityChange(value) {
        this.setState({
            Accessibility: value
        });
    }

    onAROpeningHoursChange(value) {
        this.setState({
            AROpeningHours: value
        });
    }
    onARParkMapChange(value) {
        this.setState({
            ARParkMap: value
        });
    }
    onARLinkParkMapChange(value) {
        this.setState({
            ARLinkParkMap: value
        });
    }
    onARAccessibilityChange(value) {
        this.setState({
            ARAccessibility: value
        });
    }
    onDropimgAboutUS = files => {

    this.setState({
      files: files
    });
    this.setState({ isCropimgAboutUS: true });

    if (this.state.files.length !== 0) {
      this.setState({ imgAboutUS: URL.createObjectURL(files[0]) });
    }

  };
  onDropimgParkinformation= files => {

    this.setState({
      filesimgParkinformation: files
    });
    this.setState({ isCropimgParkinformation: true });

    if (this.state.filesimgParkinformation.length !== 0) {
      this.setState({ imgParkinformation: URL.createObjectURL(files[0]) });
    }

  };
  onDropimgHomeMessage= files => {

    this.setState({
      filesimgHomeMessage: files
    });
    this.setState({ isCropimgHomeMessage: true });

    if (this.state.filesimgHomeMessage.length !== 0) {
      this.setState({ imgHomeMessage: URL.createObjectURL(files[0]) });
    }

  };
    //fin
    onSubmit = () => {
        this.setState({ empty: "" });

        const data = {
            Aboutdescription: this.state.Aboutdescription,
            ARAboutdescription: this.state.ARAboutdescription,            
            ARHomeMessagedescription:this.state.ARHomeMessagedescription,
            HomeMessagedescription:this.state.HomeMessagedescription,

            OpeningHours:this.state.OpeningHours,
            ParkMap:this.state.ParkMap,
            LinkParkMap:this.state.LinkParkMap,
            Accessibility:this.state.Accessibility,

            AROpeningHours:this.state.AROpeningHours,
            ARParkMap:this.state.ARParkMap,
            ARLinkParkMap:this.state.ARLinkParkMap,
            ARAccessibility:this.state.ARAccessibility,

            imgHomeMessage:this.state.filesimgHomeMessage[0],
            imgParkinformation:this.state.filesimgParkinformation[0],
            imgAboutUS:this.state.files[0]
        }
        
        
     
        
        if (data.Aboutdescription.length === 0 || data.ARAboutdescription.length === 0 ||
            data.ARHomeMessagedescription.length === 0 || data.HomeMessagedescription.length === 0 ||
            data.OpeningHours.length === 0 || data.ParkMap.length === 0 ||
            data.LinkParkMap.length === 0 || data.Accessibility.length === 0 ||
            data.AROpeningHours.length === 0 || data.ARParkMap.length === 0 ||
            data.ARLinkParkMap.length === 0 || data.ARAccessibility.length === 0  
           ||(data.imgHomeMessage === undefined &&this.state.imgHomeMessagews==="")||(data.imgParkinformation === undefined&&this.state.imgParkinformationws==="")||(data.imgAboutUS === undefined&&this.state.imgAboutUSws==="")
            ) {
            this.setState({ empty: <Trans i18nKey="requiredFields" /> });
          }else{
            this.props.saveAboutus(data);
          }
         
    }
    render() {
        const { isLoading, isLoader } = this.props;
        const maxSize = 2097152;

        let btnValidate = (
            <Button onClick={this.onSubmit} type="submit" size="md" className="btn-secondary" ><i className="fa fa-dot-circle-o"></i> <Trans i18nKey="Submit" /></Button>
        )
        if (isLoading) {
            btnValidate = <CircularProgress size={30} style={{ color: "#083328" }} />;
        }
        let btnDelete = (
            <Button onClick={() => this.onDelete()} type="submit" className="btn-secondary" size="md"><i className="icon-check"></i>  <Trans i18nKey="yes" /></Button>
        )
        if (isLoader) {
            btnDelete = <CircularProgress size={30} style={{ color: "#083328" }} />;
        }
        return (
            <div className="animated fadeIn">
                <Row>
                    
                    
                    <Col xl={10}>



                        <Card>
                            <CardHeader>
                                <i className="fa fa-align-justify"></i> <Trans i18nKey="Timings.TimingsList" /> <Link to={`/Timing/create`}><img src={addBtn} alt="" style={{ color: "#235C4E", cursor: "pointer", marginTop: '0.5%', float: (localStorage.getItem('i18nextLng') === "en") ? 'right' : 'left' }} id={'Tooltip-' + this.props.id} /> </Link>

                                <Tooltip isOpen={this.state.tooltipOpen} target={'Tooltip-' + this.props.id} toggle={this.toggle}>
                                    <Trans i18nKey="Timings.AddTiming" />
                                </Tooltip>

                            </CardHeader>
                            <CardBody>

                                <Table responsive hover>
                                    <thead>
                                        <tr>
                                            <th scope="col"> <Trans i18nKey="Title" /> </th>
                                            <th scope="col"><Trans i18nKey="Active" /></th>
                                            <th scope="col"><Trans i18nKey="Reservations.created_at" /></th>
                                            <th scope="col"><Trans i18nKey="Reservations.modified_at" /></th>
                                            <th scope="col"> <Trans i18nKey="Actions" /> </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.listTimingsErr === "" ?
                                            Object.values(this.props.timingsData).map(timingData => {
                                                var cre = timingData.createdAt;
                                                var upd = timingData.updatedAt;
                                                var date = new Date(cre);
                                                var year = date.getFullYear();
                                                var month = date.getMonth() + 1;
                                                var dt = date.getDate();

                                                if (dt < 10) {
                                                    dt = '0' + dt;
                                                }
                                                if (month < 10) {
                                                    month = '0' + month;
                                                }
                                                var dateUp = new Date(upd);
                                                var yearUp = dateUp.getFullYear();
                                                var monthUp = dateUp.getMonth() + 1;
                                                var dtUp = dateUp.getDate();

                                                if (dtUp < 10) {
                                                    dtUp = '0' + dtUp;
                                                }
                                                if (monthUp < 10) {
                                                    monthUp = '0' + monthUp;
                                                }
                                                if (localStorage.getItem('i18nextLng') === "en")
                                                    return <tr key={timingData._id} id={timingData._id}>
                                                        <td>{timingData.title}</td>
                                                        <td><Badge pill color={timingData.active === "no" ? "danger" : "success"} >{timingData.active}</Badge></td>
                                                        <td>{dt + '-' + month + '-' + year}</td>
                                                        <td>{dtUp + '-' + monthUp + '-' + yearUp}</td>
                                                        <td>
                                                            <Link to={`/timings/${timingData._id}`}><i className="icon-pencil" ></i></Link>
                                                            <i onClick={() => this.toggleModal(timingData._id)} className="icon-trash" style={{ marginLeft: '10%', cursor: "pointer" }}></i>
                                                        </td>

                                                    </tr>
                                                return <tr key={timingData._id} id={timingData._id}>
                                                    <td>{timingData.translationAr[0].ARtitle}</td>
                                                    <td><Badge pill color={timingData.active === "no" ? "danger" : "success"} >{timingData.active}</Badge></td>
                                                    <td>{dt + '-' + month + '-' + year}</td>
                                                    <td>{dtUp + '-' + monthUp + '-' + yearUp}</td>
                                                    <td>
                                                        <Link to={`/timings/${timingData._id}`}><i className="icon-pencil" ></i></Link>
                                                        <i onClick={() => this.toggleModal(timingData._id)} className="icon-trash" style={{ marginLeft: '10%', cursor: "pointer" }}></i>
                                                    </td>

                                                </tr>
                                            }

                                            ) : <tr><td><Trans i18nKey="Timings.noTiming" /></td></tr>}
                                        <Modal isOpen={this.state.modal} toggle={() => this.toggleModal()} className={this.props.className}
                                            style={{
                                                position: "absolute",
                                                left: "50%",
                                                top: "50%",
                                                transform: "translate(-50%, -50%)"
                                            }}>
                                            <ModalHeader toggle={() => this.toggleModal()}><Trans i18nKey="Timings.TimingdeltTitle" /></ModalHeader>

                                            <ModalBody>
                                                <br />
                                                {(localStorage.getItem('i18nextLng') === "en") ? "Do you really want to delete this Timing ?" : "هل تريد حقًا حذف هذا التوقيت ؟"}
                                                <br />
                                                <br />
                                            </ModalBody>
                                            <ModalFooter>
                                                {btnDelete}{' '}
                                                <Button style={{
                                                    color: "white",
                                                    backgroundColor: "#C6893F",
                                                    borderColor: "#C6893F",
                                                    marginRight: "10px"
                                                }} onClick={() => this.toggleModal()}><i className="icon-ban"></i> <Trans i18nKey="cancel" /></Button>
                                            </ModalFooter>
                                        </Modal>
                                    </tbody>
                                </Table>
                                <Pagination onChange={this.onChange} showTitle={false} current={this.state.current} total={this.state.total} />
                            </CardBody>
                        </Card>
                    </Col>
                    <ToastsContainer store={ToastsStore} />
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        timingsData: state.timingsData.timingsData,
        timingsNb: state.timingsNb.timingsNb,
        isLoading: state.ui.isLoading,
        isLoader: state.ui.isLoader,
        listTimingsErr: state.listTimingsErr.listTimingsErr,
        deleteTimingstatus: state.deleteTimingstatus.deleteTimingstatus,
        editAboutDescStatus: state.editAboutDescStatus.editAboutDescStatus,
        editaboutDesc: state.editaboutDesc.editaboutDesc,
        EditAboutDescerr: state.EditAboutDescerr.EditAboutDescerr,
        aboutDescData: state.aboutDescData.aboutDescData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getTimings: (data) => dispatch(getTimings(data)),
        DeleteTiming: (data) => dispatch(DeleteTiming(data)),
        saveAboutus: (data) => dispatch(saveAboutus(data)),
        getAboutus: () => dispatch(getAboutus())
    };
};


export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Timings);