import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Table, Badge, Button } from 'reactstrap';
import { getEventReservations } from "../../../store/actions";
import { compose } from "redux";
import { Link } from 'react-router-dom';
import history from "../../../history";
import { connect } from "react-redux";
import { Trans } from "react-i18next";
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

class EventReservations extends Component {
  constructor(props) {
    super(props)

    this.state = {
      current: 1
    };
  }
  componentDidMount() {
    var token = localStorage.getItem("adminToken")
    var jwtDecode = require('jwt-decode');
    var decoded = jwtDecode(token);
    var expirationDate = decoded.exp;
    var seconds = 1000;
    var d = new Date();
    var t = d.getTime();

    if (expirationDate < Math.round(t / seconds)) {
      localStorage.setItem("adminToken", "")
      history.push("/login");

    }
    else {
      const data = { pageNumber: 1 };
      this.props.getEventReservations(data)
    }
  }
  onChange = (page) => {
    this.setState({
      current: page
    });
    const data = { pageNumber: page };
    this.props.getEventReservations(data)
  }

  render() {

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xl={12}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> <Trans i18nKey="EventReservations.EventReservationsList" />
                <Link to={`/reservations/by_event`}>
                  <Button style={{ float: 'right' }} type="submit" size="sm" color="secondary"> <Trans i18nKey="EventReservations.View" /></Button>
                </Link>
              </CardHeader>
              <CardBody>


                <Table responsive hover id="table-to-xls">
                  <thead>
                    <tr>
                      <th scope="col"><Trans i18nKey="Events.Event" /></th>
                      <th scope="col"><Trans i18nKey="Places.codeReservation" /></th>

                      <th scope="col"><Trans i18nKey="Users.UserId" /></th>
                      <th scope="col"><Trans i18nKey="Login.Username" /></th>
                      <th scope="col"><Trans i18nKey="Reservations.paid" /></th>
                      <th scope="col"><Trans i18nKey="Reservations.created_at" /></th>
                      <th scope="col"><Trans i18nKey="Reservations.modified_at" /></th>


                    </tr>
                  </thead>
                  <tbody>


                    {this.props.EventReservationserr === "" ?

                      Object.values(this.props.EventReservations).map((eventData) => {
                        var cre = eventData.createdAt;
                        var upd = eventData.updatedAt;
                        var date = new Date(cre);
                        var year = date.getFullYear();
                        var month = date.getMonth() + 1;
                        var dt = date.getDate();

                        if (dt < 10) {
                          dt = '0' + dt;
                        }
                        if (month < 10) {
                          month = '0' + month;
                        }
                        var dateUp = new Date(upd);
                        var yearUp = dateUp.getFullYear();
                        var monthUp = dateUp.getMonth() + 1;
                        var dtUp = dateUp.getDate();

                        if (dtUp < 10) {
                          dtUp = '0' + dtUp;
                        }
                        if (monthUp < 10) {
                          monthUp = '0' + monthUp;
                        }
                        if (localStorage.getItem('i18nextLng') === "en")

                          return <tr key={eventData._id} id={eventData._id}>
                            <td>{eventData.event_name}</td>
                            <td>{eventData.codeReservation}</td>
                            <td>{eventData.user_id}</td>
                            <td> <Link to={`/user/${eventData.user_id}`}>{eventData.name}</Link></td>
                            {eventData.paid === "free" ?
                              <td><Badge pill color="secondary" >{eventData.paid} </Badge> </td>
                              : <td><Badge pill color={eventData.paid === "no" ? "danger" : "success"} >{eventData.paid} </Badge> </td>
                            }

                            <td>{dt + '-' + month + '-' + year}</td>
                            <td>{dtUp + '-' + monthUp + '-' + yearUp}</td>
                          </tr>
                        return <tr key={eventData._id} id={eventData._id}>
                          <td>{eventData.event_ARname}</td>
                          <td>{eventData.codeReservation}</td>

                          <td>{eventData.user_id}</td>
                          <td> <td> <Link to={`/user/${eventData.user_id}`}>{eventData.name}</Link></td></td>
                          {eventData.paid === "free" ?
                            <td><Badge pill color="secondary" >مجاني</Badge> </td>
                            : <td><Badge pill color={eventData.paid === "no" ? "danger" : "success"} >{eventData.paid === "no" ? "لا" : "نعم"} </Badge> </td>
                          }

                          <td>{dt + '-' + month + '-' + year}</td>
                          <td>{dtUp + '-' + monthUp + '-' + yearUp}</td>

                        </tr>
                      })
                      : <tr><td><Trans i18nKey="Reservations.noReservation" /></td></tr>}


                  </tbody>
                </Table>
                <Pagination onChange={this.onChange} showTitle={false} current={this.state.current} total={this.props.EventReserNbr} />

                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button"
                  table="table-to-xls"
                  filename="Events_Reservations"
                  sheet="Events_Reservations"
                  buttonText={localStorage.getItem('i18nextLng') === "en" ? "Download as XLS" : "تحميل أكسيل"} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

//export default Reservations;
const mapStateToProps = state => {
  return {
    EventReserNbr: state.EventReserNbr.EventReserNbr,
    EventReservations: state.EventReservations.EventReservations,
    isLoading: state.ui.isLoading,
    EventReservationserr: state.EventReservationserr.EventReservationserr
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getEventReservations: (data) => dispatch(getEventReservations(data))
  };
};


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EventReservations);