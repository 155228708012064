import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardFooter, Col, Row, Button, Form, FormGroup, Input, Label, Container } from 'reactstrap';
import { createMerchant } from "../../../store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import notValid from '../../../assets/img/brand/notValid.png';
import history from "../../../history";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Trans } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import jwtDecode from 'jwt-decode'
import Select from 'react-select'
import countryList from 'react-select-country-list';

class CreateMerchant extends Component {

  constructor(props) {
    super(props)
    var arr = countryList().getData()
    var removed = arr.splice(108, 1);
    this.state = {
      email: '',
      name: '',
      gender: "1",
      date: '',
      phone: '',
      active: '',
      role: '',
      match: '',
      country: null,
      removed: removed,
      options: arr,
      password: null,
      passwordRepeat: null
    }
    this.handleChangeActive = this.handleChangeActive.bind(this);
    this.handleChangeRole = this.handleChangeRole.bind(this);
  }

  onCreate = () => {
    var token = localStorage.getItem("adminToken")
    this.setState({ match: "" });
    const data = {
      email: this.state.email,
      name: this.state.name,
      date: this.state.date,
      gender: this.state.gender,
      phone: this.state.phone,
      country: JSON.stringify(this.state.country),
      active: this.state.active,
      password: this.state.password,
      admin_id: jwtDecode(token).data,
      passwordRepeat: this.state.passwordRepeat,
      role: this.state.role
    };
    if ((data.phone === null)) {
      data.phone = ""
    }
    else if (data.email.length === 0 || data.name.length === 0 || data.password === null || data.passwordRepeat === null || data.active === null || data.role === null || data.role.length === 0 || data.active.length === 0) {
      this.setState({ match: <Trans i18nKey="requiredFields" /> });
    }
    else if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(data.email) === false) {
      this.setState({ match: <Trans i18nKey="EmailInvalid" /> });
    }
    else if ((data.phone.length !== 0) && (new RegExp(/^[0-9]+$/g).test(data.phone) === false)) {
      this.setState({ match: <Trans i18nKey="PhoneInvalid" /> });
    }
    else if (data.password.length < 5) {
      this.setState({ match: <Trans i18nKey="PasswordLength" /> });
    }
    else if ((data.phone.length !== 0) && (data.phone.length < 8) && (data.phone !== null)) {
      // if(data.phone.length < 8) {
      this.setState({ match: <Trans i18nKey="PhoneLength" /> });
      //}
    }

    else if (data.password !== data.passwordRepeat) {
      this.setState({ match: <Trans i18nKey="PasswordMatch" /> });
    }
    else {
      this.setState({ match: "" });
      this.props.createMerchant(data)
    }

  };

  componentDidMount() {
    var token = localStorage.getItem("adminToken")

    var decoded = jwtDecode(token);
    var expirationDate = decoded.exp;

    var seconds = 1000;
    var d = new Date();
    var t = d.getTime();

    if (expirationDate < Math.round(t / seconds)) {
      localStorage.setItem("adminToken", "")
      history.push("/login");
    }

  }

  SelectGenderChange(value) {
    this.setState({
      gender: value
    });
  }

  changeHandler = country => {
    this.setState({ country })
  }
  handleChangeActive(event) {
    this.setState({
      active: event.target.value
    });
  }
  handleChangeRole(event) {
    this.setState({
      role: event.target.value
    });
  }
  componentWillReceiveProps(prevProps) {

    if (prevProps.CreateMerchantstatus !== this.props.CreateMerchantstatus) {
      if (prevProps.CreateMerchantstatus === "success") {
        ToastsStore.info(<Trans i18nKey="Merchants.merchantcre" />, 3000)
        setTimeout(
          function () {
            history.push("/merchants");
          }, 1200
        );

      }

    }
  }

  render() {
    const { isLoading } = this.props;

    let btnValidate = (
      <Button onClick={() => this.onCreate()} type="submit" className="btn-secondary" size="md"><i className="fa fa-dot-circle-o"></i>  <Trans i18nKey="Submit" /></Button>
    )
    if (isLoading) {
      btnValidate = <CircularProgress size={30} style={{ color: "#083328" }} />;
    }
    let err = (<Container>
      <Row className="justify-content-center">
        <p></p>
      </Row>
    </Container>
    )

    if (this.props.CreateMerchanterr !== "") {
      err = <Container style={{ paddingTop: '2%' }}>
        <Row className="justify-content-center">
          <img src={notValid} alt="notvalid" style={
            {
              width: "20px",
              marginRight: "10px",
              height: "20px",
              marginLeft: "10px"
            }
          } />
          <p style={{ color: 'red' }}><Trans i18nKey="CreateMerchanterr" /></p>
        </Row>
      </Container>
    }

    return (
      <div className="animated fadeIn">
        <Row>
          <Col lg={10}>
            <Card>
              <CardHeader>
                <strong><i className="icon-info pr-1"></i><Trans i18nKey="Merchants.CreateMerchant" /></strong>
              </CardHeader>
              <CardBody>

                <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
                  <FormGroup row>
                    <Col md="2">
                      <Label htmlFor="text-inputs">* <Trans i18nKey="Users.Name" /></Label>
                    </Col>
                    <Col xs="12" md="4">
                      <Input type="text" id="text-inputs" name="text-input" placeholder={(localStorage.getItem('i18nextLng') === "en") ? "name" : "الاسم و اللقب"}
                        onChange={e => this.setState({
                          name: e.target.value
                        })} />
                    </Col>
                    <br />
                    <br />
                    <Col md="2">
                      <Label htmlFor="text-inputs0">* <Trans i18nKey="Users.Email" /></Label>
                    </Col>
                    <Col xs="12" md="4">
                      <Input type="email" id="text-inputs0" name="text-inputs" placeholder={(localStorage.getItem('i18nextLng') === "en") ? "email" : "البريد الإلكتروني"}
                        onChange={e => this.setState({
                          email: e.target.value
                        })} />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="2">
                      <Label htmlFor="birthday"> Birthday</Label>
                    </Col>
                    <Col xs="12" md="4">
                      <Input type="date" id="birthday" name="birthday" placeholder=""
                        onChange={e => this.setState({
                          date: e.target.value
                        })}
                      />
                    </Col>
                    <br />
                    <br />
                    <Col md="2">
                      <Label htmlFor="gender"> <Trans i18nKey="Users.Gender" /></Label>
                    </Col>
                    <Col xs="12" md="4">

                      <select name="selectSm" ref="SelectGender" id="SelectLm"
                        style={{
                          color: "#5c6873",
                          backgroundColor: "#fff",
                          backgroundClip: "padding-box",
                          border: "1px solid #e4e7ea",
                          padding: "0.25rem 0.5rem",
                          fontSize: "0.76563rem",
                          lineHeight: "1.5",
                          borderRadius: "0.2rem",
                          width: "100%"
                        }}
                        onChange={e => this.SelectGenderChange(e.target.value)}
                      >
                        <option value="1">{(localStorage.getItem('i18nextLng') === "en") ? "male" : "ذكر"}</option>
                        <option value="0">{(localStorage.getItem('i18nextLng') === "en") ? "female" : "أنثى"}</option>
                      </select>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col md="2">
                      <Label htmlFor="text-inputs"> Mobile</Label>
                    </Col>
                    <Col xs="12" md="4">
                      <Input type="text" step="any" id="text-input" name="text-input" placeholder={(localStorage.getItem('i18nextLng') === "en") ? "phone" : "الهاتف"}
                        onChange={e => this.setState({
                          phone: e.target.value
                        })} />
                    </Col>
                    <br />
                    <br />
                    <Col md="2">
                      <Label htmlFor="text-input">  <Trans i18nKey="Users.Country" /></Label>
                    </Col>
                    <Col xs="12" md="4">
                      <Select
                        options={this.state.options}
                        value={this.state.country}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col md="2">
                      <Label htmlFor="text-input">* <Trans i18nKey="Login.Password" /></Label>
                    </Col>
                    <Col xs="12" md="4">
                      <Input type="password" placeholder={(localStorage.getItem('i18nextLng') === "en") ? "password" : "كلمة المرور"} autoComplete="new-password"
                        onChange={event =>
                          this.setState({ password: event.target.value })
                        } />
                    </Col>
                    <br />
                    <br />
                    <Col md="2">
                      <Label htmlFor="text-input">* <Trans i18nKey="Login.RepPassword" /></Label>
                    </Col>
                    <Col xs="12" md="4">
                      <Input type="password" placeholder={(localStorage.getItem('i18nextLng') === "en") ? "Repeat password" : "تكرار كلمة المرور "} autoComplete="new-password"
                        onChange={event =>
                          this.setState({ passwordRepeat: event.target.value })
                        } />
                    </Col>

                  </FormGroup>

                  <FormGroup row>
                    <Col md="2">
                      <Label htmlFor="text-input">* <Trans i18nKey="Active" /></Label>
                    </Col>
                    <Col xs="12" md="4">
                      <FormGroup check inline>
                        <Input className="form-check-input" type="radio" id="inline-radio3" name="inline-radios" value="yes"
                          checked={this.state.active === 'yes'}
                          onChange={this.handleChangeActive} />
                        <Label className="form-check-label" check htmlFor="inline-radio3"> &nbsp;<Trans i18nKey="yes" /> &nbsp; </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Input className="form-check-input" type="radio" id="inline-radio4" name="inline-radios" value="no"
                          checked={this.state.active === 'no'}
                          onChange={this.handleChangeActive} />
                        <Label className="form-check-label" check htmlFor="inline-radio4">&nbsp;<Trans i18nKey="no" />&nbsp;</Label>
                      </FormGroup>

                    </Col>
                    <Col md="2">
                      <Label htmlFor="text-input">*  {localStorage.getItem('i18nextLng') === "en" ? "Role" : "الدور"}</Label>
                    </Col>
                    <Col xs="12" md="4">
                      <FormGroup check inline>
                        <Input className="form-check-input" type="radio" id="inline-radio5" name="inline-role" value="admin"
                          checked={this.state.role === 'admin'}
                          onChange={this.handleChangeRole} />
                        <Label className="form-check-label" check htmlFor="inline-radio5">  &nbsp; {localStorage.getItem('i18nextLng') === "en" ? "Admin" : "مشرف"} &nbsp;</Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Input className="form-check-input" type="radio" id="inline-radio6" name="inline-role" value="merchant"
                          checked={this.state.role === 'merchant'}
                          onChange={this.handleChangeRole} />
                        <Label className="form-check-label" check htmlFor="inline-radio6">&nbsp; {localStorage.getItem('i18nextLng') === "en" ? "Merchant" : "تاجر"} &nbsp;</Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Input className="form-check-input" type="radio" id="inline-radio6" name="inline-role" value="Finance"
                          checked={this.state.role === 'Finance'}
                          onChange={this.handleChangeRole} />
                        <Label className="form-check-label" check htmlFor="inline-radio6">&nbsp; {localStorage.getItem('i18nextLng') === "en" ? "Finance" : "المالية"} &nbsp;</Label>
                      </FormGroup>

                    </Col>
                  </FormGroup>
                </Form>

              </CardBody>
              <CardFooter>
                {btnValidate}

                <ToastsContainer store={ToastsStore} />
                {err}  {this.state.match &&
                  <Container style={{ paddingTop: '2%' }}>
                    <Row className="justify-content-center">
                      <img src={notValid} alt="notvalid" style={
                        {
                          width: "20px",
                          marginRight: "10px",
                          height: "20px",
                          marginLeft: "10px"
                        }
                      } />
                      <p style={{ color: 'red' }}>{this.state.match}</p>
                    </Row>
                  </Container>}
              </CardFooter>
            </Card>
          </Col>

        </Row>


      </div>

    )
  }
}
const mapStateToProps = state => {
  return {
    CreateMerchant: state.CreateMerchant.CreateMerchant,
    CreateMerchanterr: state.CreateMerchanterr.CreateMerchanterr,
    CreateMerchantstatus: state.CreateMerchantstatus.CreateMerchantstatus,
    isLoading: state.ui.isLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createMerchant: (data) => dispatch(createMerchant(data))
  };
};


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CreateMerchant);
