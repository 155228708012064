import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row, Table, Tooltip, FormGroup, Badge, Input, Label, Button, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import notValid from '../../../assets/img/brand/notValid.png';

import { getTimings, DeleteTiming, saveAboutus, getAboutus } from "../../../store/actions";
import { compose } from "redux";
import { Trans } from "react-i18next";
import addBtn from '../../../assets/img/brand/plus-button.png';
import history from "../../../history.js";
import { connect } from "react-redux";
import { ToastsContainer, ToastsStore } from 'react-toasts';
// import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import CircularProgress from "@material-ui/core/CircularProgress";
import Dropzone from "react-dropzone";




class ParkInformation extends Component {

    constructor(props) {
        super(props)
        this.toggleArabic = this.toggleArabic.bind(this);
        this.state = {
            Aboutdescription: '',
            ARAboutdescription: '',
            ARHomeMessagedescription: "",
            HomeMessagedescription: '',
            current: 1,
            activeTab: new Array(2).fill('1'),
            modal: false,
            TimingId: '',
            active: '',
            total: 0,
            OpeningHours: '',
            ParkMap: '',
            LinkParkMap: '',
            Accessibility: '',
            AROpeningHours: '',
            ARParkMap: '',
            ARLinkParkMap: '',
            ARAccessibility: '',
            bookingDisabled: 'no',
            empty: '',
            files: [],
            imgAboutUS: [],
            imgAboutUSws: [],
            imgParkinformation: [],
            filesimgParkinformation: [],
            imgParkinformationws: [],
            filesimgHomeMessage: [],
            imgHomeMessagews: [],
            imgHomeMessage: [],
        };
        this.handleChangeBooking = this.handleChangeBooking.bind(this);
    }
    toggleArabic(tabPane, tab) {
        const newArray = this.state.activeTab.slice()
        newArray[tabPane] = tab
        this.setState({
            activeTab: newArray
        });
    }
    handleChangeBooking(event) {
        this.setState({
            bookingDisabled: event.target.value
        });
    }
    componentDidMount() {
        var token = localStorage.getItem("adminToken")
        var jwtDecode = require('jwt-decode');
        var decoded = jwtDecode(token);
        var expirationDate = decoded.exp;
        var seconds = 1000;
        var d = new Date();
        var t = d.getTime();

        if (expirationDate < Math.round(t / seconds)) {
            localStorage.setItem("adminToken", "")
            history.push("/login");

        }
        else {
            this.props.getAboutus()
        }

    }
    componentDidUpdate(prevProps) {


        if (this.props.aboutDescData !== prevProps.aboutDescData) {
            this.setState({

                OpeningHours: this.props.aboutDescData.OpeningHours,
                ParkMap: this.props.aboutDescData.ParkMap,
                LinkParkMap: this.props.aboutDescData.LinkParkMap,
                Accessibility: this.props.aboutDescData.Accessibility,

                AROpeningHours: this.props.aboutDescData.translationAr[0].AROpeningHours,
                ARParkMap: this.props.aboutDescData.translationAr[0].ARParkMap,
                ARLinkParkMap: this.props.aboutDescData.translationAr[0].ARLinkParkMap,
                ARAccessibility: this.props.aboutDescData.translationAr[0].ARAccessibility,

                bookingDisabled: this.props.aboutDescData.bookingDisabled,
                imgParkinformationws: this.props.aboutDescData.imgParkinformation,


            })
        }

    }



    componentWillReceiveProps(prevProps) {

        if (prevProps.editAboutDescStatus !== this.props.editAboutDescStatus) {
            if (prevProps.editAboutDescStatus === "success") {
                ToastsStore.info(<h6>Park information has been successfully updated!</h6>, 3000)
            }

        }

    }

    ///debut
    onOpeningHoursChange(value) {
        this.setState({
            OpeningHours: value
        });
    }
    onParkMapChange(value) {
        this.setState({
            ParkMap: value
        });
    }
    onLinkParkMapChange(value) {
        this.setState({
            LinkParkMap: value
        });
    }
    onAccessibilityChange(value) {
        this.setState({
            Accessibility: value
        });
    }

    onAROpeningHoursChange(value) {
        this.setState({
            AROpeningHours: value
        });
    }
    onARParkMapChange(value) {
        this.setState({
            ARParkMap: value
        });
    }
    onARLinkParkMapChange(value) {
        this.setState({
            ARLinkParkMap: value
        });
    }
    onARAccessibilityChange(value) {
        this.setState({
            ARAccessibility: value
        });
    }

    onDropimgParkinformation = files => {

        this.setState({
            filesimgParkinformation: files
        });
        this.setState({ isCropimgParkinformation: true });

        if (this.state.filesimgParkinformation.length !== 0) {
            this.setState({ imgParkinformation: URL.createObjectURL(files[0]) });
        }

    };

    //fin
    onSubmit = () => {
        this.setState({ empty: "" });

        const data = {

            OpeningHours: this.state.OpeningHours,
            ParkMap: this.state.ParkMap,
            LinkParkMap: this.state.LinkParkMap,
            Accessibility: this.state.Accessibility,

            AROpeningHours: this.state.AROpeningHours,
            ARParkMap: this.state.ARParkMap,
            ARLinkParkMap: this.state.ARLinkParkMap,
            ARAccessibility: this.state.ARAccessibility,

            imgParkinformation: this.state.filesimgParkinformation[0],
            bookingDisabled: this.state.bookingDisabled

        }



        if (
            data.OpeningHours.length === 0 || data.ParkMap.length === 0 ||
            data.LinkParkMap.length === 0 || data.Accessibility.length === 0 ||
            data.AROpeningHours.length === 0 || data.ARParkMap.length === 0 ||
            data.ARLinkParkMap.length === 0 || data.ARAccessibility.length === 0 || data.bookingDisabled.length === 0
            || (data.imgParkinformation === undefined && this.state.imgParkinformationws === "")
        ) {

            this.setState({ empty: <Trans i18nKey="requiredFields" /> });
        } else {

            this.props.saveAboutus(data);
        }

    }
    render() {
        const { isLoading } = this.props;
        const maxSize = 2097152;

        let btnValidate = (
            <Button onClick={this.onSubmit} type="submit" size="md" className="btn-secondary" ><i className="fa fa-dot-circle-o"></i> <Trans i18nKey="Submit" /></Button>
        )
        if (isLoading) {
            btnValidate = <CircularProgress size={30} style={{ color: "#083328" }} />;
        }

        return (
            <div className="animated fadeIn">
                <Row>
                    <Col lg={10}>

                        <Card>

                            <CardBody>

                                <Nav tabs style={{ backgroundColor: "#f0f3f5" }}>
                                    <NavItem>
                                        <NavLink
                                            active={this.state.activeTab[0] === '1'}
                                            onClick={() => { this.toggleArabic(0, '1'); }}
                                        >
                                            English
                                    </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            active={this.state.activeTab[0] === '2'}
                                            onClick={() => { this.toggleArabic(0, '2'); }}
                                        >
                                            العربية
                                    </NavLink>
                                    </NavItem>

                                </Nav>
                                <TabContent activeTab={this.state.activeTab[0]}>
                                    <TabPane tabId="1">

                                        <br />
                                        <Col md="3">
                                            <Label htmlFor="text-input">* Opening Hours </Label>
                                        </Col>
                                        <Col xs="12" md="12">
                                            <Input type="textarea" name="textarea-input" id="textarea-input" rows="7"
                                                placeholder="" value={this.state.OpeningHours}
                                                onChange={e => this.onOpeningHoursChange(e.target.value)} />
                                        </Col>
                                        <br />
                                        <Col md="3">
                                            <Label htmlFor="text-input">* Park Map </Label>
                                        </Col>
                                        <Col xs="12" md="12">
                                            <Input type="textarea" name="textarea-input" id="textarea-input" rows="7"
                                                placeholder="" value={this.state.ParkMap}
                                                onChange={e => this.onParkMapChange(e.target.value)} />
                                        </Col>
                                        <br />
                                        <Col md="3">
                                            <Label htmlFor="text-input">* Link Park Map </Label>
                                        </Col>
                                        <Col xs="12" md="12">
                                            <Input type="text" name="textarea-input" id="textarea-input" rows="7"
                                                placeholder="" value={this.state.LinkParkMap}
                                                onChange={e => this.onLinkParkMapChange(e.target.value)} />
                                        </Col>
                                        <br />
                                        <Col md="3">
                                            <Label htmlFor="text-input">* Accessibility </Label>
                                        </Col>
                                        <Col xs="12" md="12">
                                            <Input type="textarea" name="textarea-input" id="textarea-input" rows="7"
                                                placeholder="" value={this.state.Accessibility}
                                                onChange={e => this.onAccessibilityChange(e.target.value)} />
                                        </Col>
                                        <br />
                                        <Dropzone
                                            onDrop={this.onDropimgParkinformation.bind(this)}
                                            accept={"image/jpeg, image/png"}
                                            minSize={0}
                                            maxSize={maxSize}
                                        >

                                            {({ getRootProps, getInputProps, isDragReject, rejectedFiles }) => {
                                                const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
                                                return (
                                                    <section
                                                        className="container"
                                                        style={{
                                                            border: "1px solid #eee",
                                                            //   margin: "25px 7%",
                                                            padding: 15,
                                                            textAlign: "center",
                                                            cursor: "pointer"
                                                        }}
                                                    >
                                                        {this.state.isCropimgParkinformation ? (
                                                            <div {...getRootProps({ className: "dropzone" })} >
                                                                <input {...getInputProps()} />
                                                                <b> Choose a file Drag and drop or click to add a main Park image, Recommended  Image Size (540 x 960 pixels)  </b>
                                                                <p>JPEG or PNG, does not exceed 2MB.</p>

                                                                {isDragReject && "File type not accepted, sorry!"}
                                                                {isFileTooLarge && (
                                                                    <div className="text-danger mt-2">
                                                                        File is too large.
                                                                    </div>
                                                                )}
                                                                <img
                                                                    src={this.state.imgParkinformation}
                                                                    alt=""
                                                                    style={{ width: "88%" }}

                                                                />
                                                            </div>
                                                        ) : (
                                                            <div {...getRootProps({ className: "dropzone" })}>
                                                                <input {...getInputProps()} />
                                                                <b> Choose a file Drag and drop or click to add a main Park image, Recommended  Image Size (540 x 960 pixels) </b>
                                                                <p>JPEG or PNG, does not exceed 2MB.</p>

                                                                {isDragReject && "File type not accepted, sorry!"}
                                                                {isFileTooLarge && (
                                                                    <div className="text-danger mt-2">
                                                                        File is too large.

                                                                    </div>
                                                                )}

                                                                <img
                                                                    src={this.state.imgParkinformationws}
                                                                    alt=""
                                                                    style={{ width: "88%" }} />
                                                            </div>
                                                        )}
                                                    </section>
                                                )
                                            }}
                                        </Dropzone>
                                        {/*  */}
                                        <FormGroup row style={{ marginTop: "1rem" }}>
                                            <Col md="2">
                                                <Label>* <Trans i18nKey="DisableBookings" /> :</Label>
                                            </Col>
                                            <Col xs="12" md="10">
                                                <FormGroup check inline>
                                                    <Input className="form-check-input" type="radio" value="yes"
                                                        checked={this.state.bookingDisabled === 'yes'}
                                                        onChange={this.handleChangeBooking} />
                                                    <Label className="form-check-label" check htmlFor="inline-radio3"> &nbsp; <Trans i18nKey="yes" />  &nbsp;</Label>
                                                </FormGroup>
                                                <FormGroup check inline>
                                                    <Input className="form-check-input" type="radio" value="no"
                                                        checked={this.state.bookingDisabled === 'no'}
                                                        onChange={this.handleChangeBooking} />
                                                    <Label className="form-check-label" check >&nbsp;<Trans i18nKey="no" />&nbsp;</Label>
                                                </FormGroup>
                                            </Col>
                                        </FormGroup>
                                    </TabPane>
                                    <TabPane tabId="2" style={{ direction: "rtl" }} >

                                        <br />
                                        <Col md="3">
                                            <Label style={{ float: "right" }} htmlFor="text-input">*  ساعات العمل  </Label>
                                        </Col>
                                        <Col xs="12" md="12">
                                            <Input type="textarea" name="textarea-input" id="textarea-input" rows="7"
                                                placeholder="" value={this.state.AROpeningHours}
                                                onChange={e => this.onAROpeningHoursChange(e.target.value)} />
                                        </Col>
                                        <br />
                                        <Col md="3">
                                            <Label style={{ float: "right" }} htmlFor="text-input">*  خريطة الحديقة </Label>
                                        </Col>
                                        <Col xs="12" md="12">
                                            <Input type="textarea" name="textarea-input" id="textarea-input" rows="7"
                                                placeholder="" value={this.state.ARParkMap}
                                                onChange={e => this.onARParkMapChange(e.target.value)} />
                                        </Col>
                                        <br />
                                        <Col md="3">
                                            <Label style={{ float: "right" }} htmlFor="text-input">* رابط خريطة الحديقة </Label>
                                        </Col>
                                        <Col xs="12" md="12">
                                            <Input type="text" name="textarea-input" id="textarea-input" rows="7"
                                                placeholder="" value={this.state.ARLinkParkMap}
                                                onChange={e => this.onARLinkParkMapChange(e.target.value)} />
                                        </Col>
                                        <br />
                                        <Col md="3">
                                            <Label style={{ float: "right" }} htmlFor="text-input">* إمكانية الوصول </Label>
                                        </Col>
                                        <Col xs="12" md="12">
                                            <Input type="textarea" name="textarea-input" id="textarea-input" rows="7"
                                                placeholder="" value={this.state.ARAccessibility}
                                                onChange={e => this.onARAccessibilityChange(e.target.value)} />
                                        </Col>
                                        <br />
                                        <Dropzone
                                            onDrop={this.onDropimgParkinformation.bind(this)}
                                            accept={"image/jpeg, image/png"}
                                            minSize={0}
                                            maxSize={maxSize}
                                        >

                                            {({ getRootProps, getInputProps, isDragReject, rejectedFiles }) => {
                                                const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
                                                return (
                                                    <section
                                                        className="container"
                                                        style={{
                                                            border: "1px solid #eee",
                                                            //   margin: "25px 6%",
                                                            padding: 15,
                                                            textAlign: "center",
                                                            cursor: "pointer"
                                                        }}
                                                    >
                                                        {this.state.isCropimgParkinformation ? (
                                                            <div {...getRootProps({ className: "dropzone" })} >
                                                                <input {...getInputProps()} />

                                                                <b>إختر ملف وقم بإفلاته أو انقر لإضافة صورة للافتة ، حجم الصورة الموصى به (960  ×  540 بكسل)</b>
                                                                <p>JPEG أو PNG ، لا يتجاوز 1 ميغابايت</p>
                                                                {isDragReject && "File type not accepted, sorry!"}
                                                                {isFileTooLarge && (
                                                                    <div className="text-danger mt-2">
                                                                        الملف كبير جدا
                                                                    </div>
                                                                )}
                                                                <img
                                                                    src={this.state.imgParkinformation}
                                                                    alt=""
                                                                    style={{ width: "88%" }}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div {...getRootProps({ className: "dropzone" })}>
                                                                <input {...getInputProps()} />

                                                                <b>إختر ملف وقم بإفلاته أو انقر لإضافة صورة للافتة ، حجم الصورة الموصى به (960  ×  540 بكسل)</b>
                                                                <p>JPEG أو PNG ، لا يتجاوز 1 ميغابايت</p>
                                                                {isDragReject && "File type not accepted, sorry!"}
                                                                {isFileTooLarge && (
                                                                    <div className="text-danger mt-2">
                                                                        الملف كبير جدا

                                                                    </div>
                                                                )}

                                                                <img
                                                                    src={this.state.imgParkinformationws}
                                                                    alt=""
                                                                    style={{ width: "88%" }}
                                                                />
                                                            </div>
                                                        )}
                                                    </section>
                                                )
                                            }}
                                        </Dropzone>
                                        {/*  */}
                                        <FormGroup row style={{ marginTop: "1rem" }}>
                                            <Col md="2">
                                                <Label style={{ float: "right" }}>*تعطيل الحجز :</Label>
                                            </Col>
                                            <Col xs="12" md="10" style={{ textAlign: "justify" }}>
                                                <FormGroup check inline>
                                                    <Input className="form-check-input" type="radio" value="yes"
                                                        checked={this.state.bookingDisabled === 'yes'}
                                                        onChange={this.handleChangeBooking} />
                                                    <Label className="form-check-label" check htmlFor="inline-radio3"> &nbsp; نعم  &nbsp;</Label>
                                                </FormGroup>
                                                <FormGroup check inline>
                                                    <Input className="form-check-input" type="radio" value="no"
                                                        checked={this.state.bookingDisabled === 'no'}
                                                        onChange={this.handleChangeBooking} />
                                                    <Label className="form-check-label" check >&nbsp;لا &nbsp;</Label>
                                                </FormGroup>
                                            </Col>
                                        </FormGroup>
                                    </TabPane>

                                </TabContent>
                                <br />
                                <ToastsContainer store={ToastsStore} />
                                {btnValidate}
                                {this.state.empty &&
                                    <Row className="justify-content-center">
                                        <img src={notValid} alt="notvalid" style={
                                            {
                                                width: "20px",
                                                marginRight: "10px",
                                                height: "20px"
                                            }
                                        } />
                                        <p style={{ color: 'red' }}>{this.state.empty}</p>
                                    </Row>
                                }




                            </CardBody>

                        </Card>
                    </Col>
                    <br />

                    <ToastsContainer store={ToastsStore} />
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {

        isLoading: state.ui.isLoading,


        editAboutDescStatus: state.editAboutDescStatus.editAboutDescStatus,

        aboutDescData: state.aboutDescData.aboutDescData
    };
};

const mapDispatchToProps = dispatch => {
    return {

        saveAboutus: (data) => dispatch(saveAboutus(data)),
        getAboutus: () => dispatch(getAboutus())
    };
};


export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ParkInformation);