import React, {} from 'react';
import {
  Trans
} from "react-i18next";
export default {
  items: [{
      name: < Trans i18nKey = "Dashboard" / > ,
      url: '/dashboard',
      icon: 'icon-speedometer',

    },
    {
      name: < Trans i18nKey = "Admins.Admins" / > ,
      url: '/admins',
      icon: ' icon-user',
    },
    {
      name: < Trans i18nKey = "Users.Users" / > ,
      url: '/users',
      icon: 'icon-people',
    },
    {
      name: < Trans i18nKey = "Merchants.Merchants" / > ,
      url: '/merchants',
      icon: 'icon-user-follow',
    },
    {
      name: < Trans i18nKey = "Newscast.Newscast" / > ,
      url: '/homeSlider',
      icon: 'icon-grid',
    },
    {
      name: < Trans i18nKey = "Events.Events" / > ,
      url: '/events',
      icon: 'icon-calendar'
    },
    {
      name: < Trans i18nKey = "IBeacons.IBeacons" / > ,
      url: '/ibeacons',
      icon: 'icon-feed',

    },
    {
      name: < Trans i18nKey = "Places.Places" / > ,
      url: '/places',
      icon: 'icon-direction',
    },
    {
      name: < Trans i18nKey = "Parkings.Parkings" / > ,
      url: '/parkings',
      icon: 'icon-paper-plane'
    },
    {
      name: < Trans i18nKey = "Categories.Categories" / > ,
      icon: 'icon-list',
      children: [{
          name: < Trans i18nKey = "ThingsToDo.ThingsToDo" / > ,
          url: '/Activity',
          icon: 'icon-list',
        },
        {
          name: < Trans i18nKey = "Facilities.Facilities" / > ,
          url: '/facilities',
          icon: 'icon-list'
        }
      ]
    },

    {
      name: < Trans i18nKey = "Banners.Banners" / > ,
      url: '/splash',
      icon: 'icon-map',

    },

    {
      name: < Trans i18nKey = "Reservations.Reservations" / > ,
      url: '/reservations',
      icon: 'icon-film',
      children: [{
          name: < Trans i18nKey = "EventReservations.EventReservations" / > ,
          url: '/reservations/events',
          icon: 'icon-calendar',
        },
        {
          name: < Trans i18nKey = "PlaceReservations.PlaceReservations" / > ,
          url: '/reservations/places',
          icon: 'icon-direction'
        }
      ]
    },

    {
      name: < Trans i18nKey = "Medias" / > ,
      url: '/medias',
      icon: 'icon-folder-alt',
      children: [{
          name: < Trans i18nKey = "Photos.Photos" / > ,
          url: '/medias/photos',
          icon: 'icon-picture',
        },
        {
          name: < Trans i18nKey = "Videos.Videos" / > ,
          url: '/medias/videos',
          icon: 'icon-screen-desktop',
        }
      ]
    },
    {
      name: < Trans i18nKey = "Notifications.Notifications" / > ,
      url: '/notifications',
      icon: 'icon-bell'

    },
    {
      name: < Trans i18nKey = "News.News" / > ,
      url: '/news',
      icon: 'icon-docs'

    },
    {
      name: < Trans i18nKey = "FAQs.FAQs" / > ,
      url: '/FAQs',
      icon: 'icon-question'

    },
    {
      name: < Trans i18nKey = "contact" / > ,
      url: '/contact',
      icon: 'icon-envelope'

    },
    {
      name: < Trans i18nKey = "Timings.Timings" / > ,
      url: '/timings',
      icon: 'icon-clock'
    },
    {
      name: < Trans i18nKey = "aboutUs" / > ,
      url: '/aboutUs',
      icon: 'icon-info',

    },
    {
      name: < Trans i18nKey = "homePage" / > ,
      url: '/homePage',
      icon: 'icon-home'
    },
    {
      name: < Trans i18nKey = "parkInformation" / > ,
      url: '/parkInformation',
      icon: 'icon-picture',
    },

    {
      name: < Trans i18nKey = "Rules.Rules" / > ,
      url: '/rules',
      icon: 'icon-pin'
    },

    /*{

      name: 'Mapbox',
      url: 'https://studio.mapbox.com',
      icon: 'icon-location-pin',
      class: 'Mapbox-Nav',
      attributes: { target: '_blank', rel: "noopener" },


    }*/
  ],
};