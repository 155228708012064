import { PLACES_DATA } from "../../actions/actionTypes";
import { PLACE_DATA, parking_DATA } from "../../actions/actionTypes";
import { LIST_PLACES_ERR } from "../../actions/actionTypes";
import { PLACES_NBR } from "../../actions/actionTypes";
import { PLACE_EDIT } from "../../actions/actionTypes";
import { EDIT_PLACE_ERR } from "../../actions/actionTypes";
import { STATUS_PLACE_EDIT } from "../../actions/actionTypes";
import { Bea_DATA } from "../../actions/actionTypes";
import { DELETE_PLACE_ERR } from "../../actions/actionTypes";
import { STATUS_PLACE_DELETE } from "../../actions/actionTypes";
import { PLACE_DELETE } from "../../actions/actionTypes";

import { CREATE_PLACE_ERR } from "../../actions/actionTypes";
import { STATUS_PLACE_CREATE } from "../../actions/actionTypes";
import { PLACE_CREATE } from "../../actions/actionTypes";
import { Clone_PLACE_DATA } from "../../actions/actionTypes";
import { Clone_PLACE_ERR } from "../../actions/actionTypes";
import { ACT_PLACES_DATA } from "../../actions/actionTypes";
import { LIST_ACT_PLACES_ERR } from "../../actions/actionTypes";
import { ACT_PLACES_FAC_DATA } from "../../actions/actionTypes";
import { LIST_ACT_PLACES_FAC_ERR } from "../../actions/actionTypes";
import { FIND_PLACE_ERR } from "../../actions/actionTypes";
import { FIND_PLACE } from "../../actions/actionTypes";
import { EDIT_TIMING_SUCCESS } from "../../actions/actionTypes";

const initialState = {
  placesData: [],
  placeData: [],
  editplace: [],
  EditPlaceerr: '',
  listPlacesErr: '',
  editPlacestatus: '',
  deletePlace: [],
  DeletePlaceerr: '',
  deletePlacestatus: '',
  CreatePlace: [],
  CreatePlaceerr: '',
  CreatePlacestatus: '',
  listActPlacesErr: '',
  PlacesNbr: 0,
  ActplacesData: [],
  listActPlacesFacErr: '',
  ActplacesFacData: [],
  parkingdata: [],
  clonePlaceErr: '',
  clonePlaceData: [],
  findPlace: [],
  findPlaceErr: '',
  beadata:[],
  editTimingSuccess:''
 
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Clone_PLACE_ERR:
      return {
        ...state,
        clonePlaceErr: action.clonePlaceErr
      };
    case Clone_PLACE_DATA:
      return {
        ...state,
        clonePlaceData: action.clonePlaceData
      };
    case PLACES_NBR:
      return {
        ...state,
        PlacesNbr: action.PlacesNbr
      };
    case PLACES_DATA:
      return {
        ...state,
        placesData: action.placesData
      };
    case PLACE_DATA:
      return {
        ...state,
        placeData: action.placeData
      };
    case PLACE_EDIT:
      return {
        ...state,
        editplace: action.editplace
      };
    case LIST_PLACES_ERR:
      return {
        ...state,
        listPlacesErr: action.listPlacesErr
      };

    case EDIT_PLACE_ERR:
      return {
        ...state,
        EditPlaceerr: action.EditPlaceerr
      };
    case STATUS_PLACE_EDIT:
      return {
        ...state,
        editPlacestatus: action.editPlacestatus
      };
    case DELETE_PLACE_ERR:
      return {
        ...state,
        DeletePlaceerr: action.DeletePlaceerr
      };
    case STATUS_PLACE_DELETE:
      return {
        ...state,
        deletePlacestatus: action.deletePlacestatus
      };
    case PLACE_DELETE:
      return {
        ...state,
        deletePlace: action.deletePlace
      };

    case CREATE_PLACE_ERR:
      return {
        ...state,
        CreatePlaceerr: action.CreatePlaceerr
      };
    case STATUS_PLACE_CREATE:
      return {
        ...state,
        CreatePlacestatus: action.CreatePlacestatus
      };
    case PLACE_CREATE:
      return {
        ...state,
        CreatePlace: action.CreatePlace
      };
    case LIST_ACT_PLACES_ERR:
      return {
        ...state,
        listActPlacesErr: action.listActPlacesErr
      };
    case ACT_PLACES_DATA:
      return {
        ...state,
        ActplacesData: action.ActplacesData
      };
    case LIST_ACT_PLACES_FAC_ERR:
      return {
        ...state,
        listActPlacesFacErr: action.listActPlacesFacErr
      };
    case ACT_PLACES_FAC_DATA:
      return {
        ...state,
        ActplacesFacData: action.ActplacesFacData
      };
    case parking_DATA:
      return {
        ...state,
        parkingdata: action.parkingdata
      };
    case Bea_DATA:
      return {
        ...state,
        beadata: action.beadata
      };
    case FIND_PLACE_ERR:
      return {
        ...state,
        findPlaceErr: action.findPlaceErr
      };
    case FIND_PLACE:
      return {
        ...state,
        findPlace: action.findPlace
      };
      case EDIT_TIMING_SUCCESS:
        return {
          ...state,
          editTimingSuccess: action.editTimingSuccess
        };
    default:
      return state;
  }
};

export default reducer;
