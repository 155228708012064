import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Badge,
  Input,
  Col,
  Row,
  Table,
  Tooltip,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button
} from "reactstrap";
import {
  getPlaces,
  DeletePlace,
  clonePlace,
  FindPlacesByName
} from "../../../store/actions";
import { compose } from "redux";
import addBtn from "../../../assets/img/brand/plus-button.png";
import history from "../../../history.js";
import { connect } from "react-redux";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { Trans } from "react-i18next";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import notValid from "../../../assets/img/brand/notValid.png";
import copy from "../../../assets/img/brand/copy.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
class Places extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
      active: "",
      current: 1,
      deleted: "",
      modal: false,
      placeId: "",
      searchValue: "",
      page_size: 10,
      placesData: [],
      total: 0
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  componentDidMount() {
    var token = localStorage.getItem("adminToken");
    var jwtDecode = require("jwt-decode");
    var decoded = jwtDecode(token);
    var expirationDate = decoded.exp;
    var seconds = 1000;
    var d = new Date();
    var t = d.getTime();

    if (expirationDate < Math.round(t / seconds)) {
      localStorage.setItem("adminToken", "");
      history.push("/login");
    } else {
      const data = { pageNumber: 1 };
      this.props.getPlaces(data);
    }
  }

  onChange = page => {
    this.setState({
      current: page
    });
    let page_number = page - 1;

    this.setState({
      placesData: this.props.placesData.slice(
        page_number * this.state.page_size,
        (page_number + 1) * this.state.page_size
      )
    });
  };

  onDelete() {
    const data = { placeID: this.state.placeId };
    this.props.DeletePlace(data);
  }
  toggleModal(placeId) {
    this.setState({
      modal: !this.state.modal,
      placeId: placeId
    });
  }
  copyPaste(placeId) {
    const data = {
      placeId: placeId
    };
    this.props.clonePlace(data);
  }
  componentWillReceiveProps(prevProps) {
    let page_number = 0;
    if (prevProps.placesData !== this.props.placesData) {
      this.setState({
        placesData: prevProps.placesData.slice(
          page_number * this.state.page_size,
          (page_number + 1) * this.state.page_size
        ),
        total: this.props.PlacesNbr
      });
    }
    if (prevProps.deletePlacestatus !== this.props.deletePlacestatus) {
      if (prevProps.deletePlacestatus === "success") {
        this.setState({
          modal: false
        });
        ToastsStore.info(
          <h6>
            <Trans i18nKey="Places.del" />
          </h6>,
          3000
        );
        const data = { pageNumber: 1 };
        this.props.getPlaces(data);
      }
    }
    if (prevProps.DeletePlaceerr.En !== this.props.DeletePlaceerr.En) {
      if (prevProps.DeletePlaceerr !== "") {
        this.setState({
          modal: false,
          deleted:
            localStorage.getItem("i18nextLng") === "en"
              ? prevProps.DeletePlaceerr.En
              : prevProps.DeletePlaceerr.Ar
        });
      }
    }
    if (prevProps.clonePlaceData !== this.props.clonePlaceData) {
      if (prevProps.clonePlaceData === "success") {
        const data = { pageNumber: 1 };
        this.props.getPlaces(data);
      }
    }
    
    if (prevProps.findPlace !== this.props.findPlace) {
      if (this.state.searchValue.length !== 0) {
        this.setState({
          placesData: prevProps.findPlace.slice(
            page_number * this.state.page_size,
            (page_number + 1) * this.state.page_size
          ),
          total: prevProps.findPlace.length
        });
      } else {
        this.setState({
          placesData: this.props.placesData.slice(
            page_number * this.state.page_size,
            (page_number + 1) * this.state.page_size
          ),
          total: this.props.PlacesNbr
        });
      }
    }
  }
  onsearchChange(value) {
    this.setState({ searchValue: value });
    const data = {
      searchValue: value
    };
    this.props.FindPlacesByName(data);
  }

  render() {
    const { isLoading, isLoader } = this.props;
    let load;
    if (isLoader) {
      load = <p style={{ textAlign: "center" }}>Is loading ...</p>;
    }
    let btnValidate = (
      <Button
        onClick={() => this.onDelete()}
        type="submit"
        className="btn-secondary"
        size="md"
      >
        <i className="icon-check"></i> <Trans i18nKey="yes" />
      </Button>
    );
    if (isLoading) {
      btnValidate = <CircularProgress size={30} style={{ color: "#083328" }} />;
    }

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xl={12}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i>{" "}
                <Trans i18nKey="Places.PlacesList" />{" "}
                <Link to={`/place/create`}>
                  <img
                    src={addBtn}
                    alt=""
                    style={{
                      color: "#235C4E",
                      cursor: "pointer",
                      marginTop: "0.5%",
                      float:
                        localStorage.getItem("i18nextLng") === "en"
                          ? "right"
                          : "left"
                    }}
                    id={"Tooltip-" + this.props.id}
                  />{" "}
                </Link>
                <Tooltip
                  isOpen={this.state.tooltipOpen}
                  target={"Tooltip-" + this.props.id}
                  toggle={this.toggle}
                >
                  <Trans i18nKey="Places.AddPlace" />
                </Tooltip>
              </CardHeader>
              <CardBody>
                {load}
                <div className="search">
                  <Input
                    type="text"
                    placeholder="Search By Title"
                    value={this.state.searchValue}
                    onChange={e => this.onsearchChange(e.target.value)}
                  />
                </div>
                <br />
                <br />
                <br />
                <br />
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col">
                        <Trans i18nKey="Title" />
                      </th>
                      <th scope="col">
                        <Trans i18nKey="Places.Location" />
                      </th>
                      <th scope="col">
                        <Trans i18nKey="Categories.Category" />
                      </th>
                      <th scope="col">
                        <Trans i18nKey="Paid" />
                      </th>
                      <th scope="col">
                        <Trans i18nKey="Active" />
                      </th>
                      <th scope="col" style={{ minWidth: "110px" }}>
                        <Trans i18nKey="Actions" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.listPlacesErr === "" ? (
                      Object.values(this.state.placesData).map(placeData => {
                        if (localStorage.getItem("i18nextLng") === "en")
                          return (
                            <tr key={placeData._id} id={placeData._id}>
                              <td>{placeData.title}</td>
                              <td>{placeData.location}</td>
                              <td>
                                {" "}
                                {placeData.category === "Thing to see & do" ? (
                                  <Trans i18nKey="ThingsToDo.ThingsToDo" />
                                ) : (
                                  <Trans i18nKey="Facilities.OneFacility" />
                                )}
                              </td>
                              <td>
                                <Badge
                                  pill
                                  color={
                                    placeData.type === "free"
                                      ? "warning"
                                      : "info"
                                  }
                                >
                                  {placeData.type==="free"?(<Trans i18nKey="no" />):(<Trans i18nKey="yes" />)}
                                </Badge>
                              </td>
                              <td>
                                <Badge
                                  pill
                                  color={
                                    placeData.active === "no"
                                      ? "danger"
                                      : "success"
                                  }
                                >
                                  {placeData.active}
                                </Badge>
                              </td>

                              <td>
                                <Link to={`/places/${placeData._id}`}>
                                  <i className="icon-pencil"></i>
                                </Link>
                                <i
                                  onClick={() =>
                                    this.toggleModal(placeData._id)
                                  }
                                  className="icon-trash"
                                  style={{
                                    marginLeft: "10%",
                                    cursor: "pointer"
                                  }}
                                ></i>
                                <img
                                  alt="copy"
                                  src={copy}
                                  className="copy-paste"
                                  onClick={() => this.copyPaste(placeData._id)}
                                />
                              </td>
                            </tr>
                          );
                        return (
                          <tr key={placeData._id} id={placeData._id}>
                            <td>{placeData.translationAr[0].ARtitle}</td>
                            <td>{placeData.translationAr[0].ARlocation}</td>
                            <td>
                              {placeData.category === "Thing to see & do" ? (
                                <Trans i18nKey="ThingsToDo.ThingsToDo" />
                              ) : (
                                <Trans i18nKey="Facilities.OneFacility" />
                              )}
                            </td>
                            <td>
                              <Badge
                                color={
                                  placeData.type === "free" ? "warning" : "info"
                                }
                              >
                                {placeData.type === "free" ? (
                                  <Trans i18nKey="no" />
                                ) : (
                                  <Trans i18nKey="yes" />
                                )}{" "}
                              </Badge>
                            </td>
                            <td>
                              <Badge
                                pill
                                color={
                                  placeData.active === "no"
                                    ? "danger"
                                    : "success"
                                }
                              >
                                {placeData.active === "no" ? (
                                  <Trans i18nKey="no" />
                                ) : (
                                  <Trans i18nKey="yes" />
                                )}{" "}
                              </Badge>
                            </td>

                            <td>
                              <Link to={`/places/${placeData._id}`}>
                                <i className="icon-pencil"></i>
                              </Link>
                              <i
                                onClick={() => this.toggleModal(placeData._id)}
                                className="icon-trash"
                                style={{ marginLeft: "10%", cursor: "pointer" }}
                              ></i>
                              <img
                                alt="copy"
                                src={copy}
                                className="copy-paste"
                                onClick={() => this.copyPaste(placeData._id)}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>
                          <Trans i18nKey="Places.noPlace" />
                        </td>
                      </tr>
                    )}

                    <Modal
                      isOpen={this.state.modal}
                      toggle={() => this.toggleModal()}
                      className={this.props.className}
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)"
                      }}
                    >
                      <ModalHeader toggle={() => this.toggleModal()}>
                        <Trans i18nKey="Places.placedeltTitle" />
                      </ModalHeader>

                      <ModalBody>
                        <br />
                        {localStorage.getItem("i18nextLng") === "en"
                          ? "Do you really want to delete this place ?"
                          : "هل تريد حقًا حذف هذا المكان ؟"}
                        <br />
                        <br />
                      </ModalBody>
                      <ModalFooter>
                        {btnValidate}{" "}
                        <Button
                          style={{
                            color: "white",
                            backgroundColor: "#C6893F",
                            borderColor: "#C6893F",
                            marginRight: "10px"
                          }}
                          onClick={() => this.toggleModal()}
                        >
                          <i className="icon-ban"></i>{" "}
                          <Trans i18nKey="cancel" />
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </tbody>
                </Table>
                <Pagination
                  onChange={this.onChange}
                  showTitle={false}
                  current={this.state.current}
                  pageSize={this.state.page_size}
                  total={this.state.total}
                />
              </CardBody>
            </Card>
          </Col>
          <ToastsContainer store={ToastsStore} />
          {this.state.deleted !== "" ? (
            <Container>
              <Row className="justify-content-center">
                <img
                  src={notValid}
                  alt="notvalid"
                  style={{
                    width: "20px",
                    marginRight: "10px",
                    height: "20px",
                    marginLeft: "10px"
                  }}
                />
                <p style={{ color: "red" }}> {this.state.deleted} </p>
              </Row>
            </Container>
          ) : (
            <div></div>
          )}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    placesData: state.placesData.placesData,
    PlacesNbr: state.PlacesNbr.PlacesNbr,
    DeletePlaceerr: state.DeletePlaceerr.DeletePlaceerr,
    isLoading: state.ui.isLoading,
    isLoader: state.ui.isLoader,
    listPlacesErr: state.listPlacesErr.listPlacesErr,
    deletePlacestatus: state.deletePlacestatus.deletePlacestatus,
    clonePlaceErr: state.clonePlaceErr.clonePlaceErr,
    clonePlaceData: state.clonePlaceData.clonePlaceData,
    findPlaceErr: state.findPlaceErr.findPlaceErr,
    findPlace: state.findPlace.findPlace
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPlaces: data => dispatch(getPlaces(data)),
    DeletePlace: data => dispatch(DeletePlace(data)),
    clonePlace: data => dispatch(clonePlace(data)),
    FindPlacesByName: data => dispatch(FindPlacesByName(data))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Places);
