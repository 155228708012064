import axios from "axios";
import {
  uiStartLoading,
  uiStopLoading
} from "../index";
import {
  TIMINGS_DATA,
  TIMINGS_NB,
  LIST_TIMINGS_ERR,
  TIMING_DATA
} from "../actionTypes";
import {
  TIMING_DELETE,
  DELETE_TIMING_ERR,
  STATUS_TIMING_DELETE
} from "../actionTypes";
import {
  TIMING_CREATE
} from "../actionTypes";
import {
  CREATE_TIMING_ERR
} from "../actionTypes";
import {
  STATUS_TIMING_CREATE
} from "../actionTypes";
import {
  TIMING_EDIT,
  EDIT_TIMING_ERR,
  STATUS_TIMING_EDIT
} from "../actionTypes";
import {
  ABOUT_EDIT,
  EDIT_ABOUT_ERR,
  STATUS_ABOUT_EDIT,
  ABOUT_Data
} from "../actionTypes";

export const createTiming = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");
    dispatch(CreateTimingstatus(""));
    dispatch(uiStartLoading());
    const form = new FormData();

    form.append("title", data.title);
    form.append("ARtitle", data.ARtitle);
    form.append("startday", data.startday);
    form.append("endday", data.endday);
    form.append("startWEday", data.startWEday);
    form.append("endWEday", data.endWEday);
    form.append("startTime", data.startTime);
    form.append("endTime", data.endTime);
    form.append("startWETime", data.startWETime);
    form.append("endWETime", data.endWETime);
    form.append("startTimingDay", data.startTimingDay);
    form.append("endTimingDay", data.endTimingDay);
    form.append("startTimingMonth", data.startTimingMonth);
    form.append("endTimingMonth", data.endTimingMonth);
    form.append("active", data.active);
    form.append("force", data.force);

    const config = {
      headers: {
        enctype: "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token
      }
    };

    axios
      .post(`${process.env.REACT_APP_DOMAIN}/api/Timing/add`, form, config, {
        useCredentails: true
      })

      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading());
      })
      //.then(res => res.json())
      .then(parsedRes => {
        dispatch(CreateTimingstatus(""));
        dispatch(uiStopLoading());

        if (parsedRes.data.status === "fail") {
          dispatch(CreateTimingerr(parsedRes.error));
        } else {
          dispatch(CreateTimingerr(""));
          dispatch(CreateTiming(parsedRes.data.data));
          dispatch(CreateTimingstatus(parsedRes.data.status));
        }
      });
  };
};

export const getTimings = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/Timing/getList`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          pageNumber: data.pageNumber
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading());
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(uiStopLoading());
        if (parsedRes.status === "failure") {
          dispatch(listTimingsErr(parsedRes.error));
        } else {
          dispatch(listTimingsErr(""));
          dispatch(timingsData(parsedRes.data));
          dispatch(timingsNb(parsedRes.Length));
        }
      });
  };
};
export const editTiming = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");
    dispatch(uiStartLoading());
    const form = new FormData();

    form.append("title", data.title);
    form.append("ARtitle", data.ARtitle);
    form.append("timingID", data.timingID);
    form.append("startday", data.startday);
    form.append("endday", data.endday);
    form.append("startWEday", data.startWEday);
    form.append("endWEday", data.endWEday);
    form.append("startTime", data.startTime);
    form.append("endTime", data.endTime);
    form.append("startWETime", data.startWETime);
    form.append("endWETime", data.endWETime);
    form.append("startTimingDay", data.startTimingDay);
    form.append("endTimingDay", data.endTimingDay);
    form.append("startTimingMonth", data.startTimingMonth);
    form.append("endTimingMonth", data.endTimingMonth);
    form.append("active", data.active);
    form.append("force", data.force);

    const config = {
      headers: {
        enctype: "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token
      }
    };

    axios
      .post(`${process.env.REACT_APP_DOMAIN}/api/Timing/edit`, form, config, {
        useCredentails: true
      })

      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading());
      })

      .then(parsedRes => {
        dispatch(editTimingStatus(""));
        dispatch(uiStopLoading());

        if (parsedRes.data.status === "fail") {
          dispatch(EditTimingerr(parsedRes.data.error));
        } else {
          dispatch(EditTimingerr(""));
          dispatch(edittiming(parsedRes.data.data));
          dispatch(editTimingStatus(parsedRes.data.status));
        }
      });
  };
};
export const saveAboutus = data => {

  return dispatch => {
    var token = localStorage.getItem("adminToken");
    dispatch(uiStartLoading());
    const form = new FormData();
    if (data.Aboutdescription !== undefined) {
      form.append("Aboutdescription", data.Aboutdescription);
    }
    if (data.ARAboutdescription !== undefined) {
      form.append("ARAboutdescription", data.ARAboutdescription);
    }
    if (data.ARHomeMessagedescription !== undefined) {
      form.append("ARHomeMessagedescription", data.ARHomeMessagedescription);
    }
    if (data.HomeMessagedescription !== undefined) {
      form.append("HomeMessagedescription", data.HomeMessagedescription);
    }
    if (data.OpeningHours !== undefined) {
      form.append("OpeningHours", data.OpeningHours);
    }
    if (data.ParkMap !== undefined) {
      form.append("ParkMap", data.ParkMap);
    }
    if (data.LinkParkMap !== undefined) {
      form.append("LinkParkMap", data.LinkParkMap);
    }
    if (data.Accessibility !== undefined) {
      form.append("Accessibility", data.Accessibility);
    }
    if (data.AROpeningHours !== undefined) {
      form.append("AROpeningHours", data.AROpeningHours);
    }
    if (data.ARParkMap !== undefined) {
      form.append("ARParkMap", data.ARParkMap);
    }
    if (data.ARLinkParkMap !== undefined) {
      form.append("ARLinkParkMap", data.ARLinkParkMap);
    }
    if (data.ARAccessibility !== undefined) {
      form.append("ARAccessibility", data.ARAccessibility);
    }
    if (data.imgHomeMessage !== undefined) {
      form.append("imgHomeMessage", data.imgHomeMessage);
    }
    if (data.imgParkinformation !== undefined) {
      form.append("imgParkinformation", data.imgParkinformation);
    }
    if (data.imgAboutUS !== undefined) {
      form.append("imgAboutUS", data.imgAboutUS);
    }
    if (data.bookingDisabled !== undefined) {
      form.append("bookingDisabled", data.bookingDisabled);
    }

    const config = {
      headers: {
        enctype: "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token
      }
    };

    axios
      .post(`${process.env.REACT_APP_DOMAIN}/api/Timing/AddAboutUs`, form, config, {
        useCredentails: true
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading());
      })
      .then(parsedRes => {
        dispatch(editAboutDescStatus(""));
        dispatch(uiStopLoading());

        if (parsedRes.status === "fail") {
          dispatch(EditAboutDescerr(parsedRes.error));
        } else {
          dispatch(editAboutDescStatus(""));
          dispatch(editaboutDesc(parsedRes.data));
          dispatch(editAboutDescStatus(parsedRes.data.status));
        }

      });





  };
};
export const getAboutus = () => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/Timing/getAboutUs`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading());
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(uiStopLoading());
        dispatch(aboutDescData(parsedRes.data));
      });
  };
};
export const aboutDescData = value => {
  return {
    type: ABOUT_Data,
    aboutDescData: value
  };
};
export const EditAboutDescerr = value => {
  return {
    type: EDIT_ABOUT_ERR,
    EditAboutDescerr: value
  };
};

export const editAboutDescStatus = value => {
  return {
    type: STATUS_ABOUT_EDIT,
    editAboutDescStatus: value
  };
};
export const editaboutDesc = value => {
  return {
    type: ABOUT_EDIT,
    editaboutDesc: value
  };
};
//
export const EditTimingerr = value => {
  return {
    type: EDIT_TIMING_ERR,
    EditTimingerr: value
  };
};

export const editTimingStatus = value => {
  return {
    type: STATUS_TIMING_EDIT,
    editTimingStatus: value
  };
};
export const edittiming = value => {
  return {
    type: TIMING_EDIT,
    edittiming: value
  };
};
export const getTiming = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/Timing/get`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          timingID: data.timingID
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading());
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(uiStopLoading());
        dispatch(timingData(parsedRes.data));
      });
  };
};

export const timingData = value => {
  return {
    type: TIMING_DATA,
    timingData: value
  };
};

export const DeleteTiming = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");
    dispatch(deleteTimingstatus(""));
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/Timing/delete`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          timingID: data.timingID
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading());
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(deleteTimingstatus(""));
        dispatch(uiStopLoading());
        if (parsedRes.status === "fail") {
          dispatch(DeleteTimingerr(parsedRes.error));
        } else {
          dispatch(DeleteTimingerr(""));
          dispatch(deleteTiming(parsedRes.data));
          dispatch(deleteTimingstatus(parsedRes.status));
        }
      });
  };
};
export const deleteTiming = value => {
  return {
    type: TIMING_DELETE,
    deleteTiming: value
  };
};
export const DeleteTimingerr = value => {
  return {
    type: DELETE_TIMING_ERR,
    DeleteTimingerr: value
  };
};
export const deleteTimingstatus = value => {
  return {
    type: STATUS_TIMING_DELETE,
    deleteTimingstatus: value
  };
};

export const timingsNb = value => {
  return {
    type: TIMINGS_NB,
    timingsNb: value
  };
};

export const timingsData = value => {
  return {
    type: TIMINGS_DATA,
    timingsData: value
  };
};
export const listTimingsErr = value => {
  return {
    type: LIST_TIMINGS_ERR,
    listTimingsErr: value
  };
};
export const CreateTiming = value => {
  return {
    type: TIMING_CREATE,
    CreateTiming: value
  };
};
export const CreateTimingerr = value => {
  return {
    type: CREATE_TIMING_ERR,
    CreateTimingerr: value
  };
};
export const CreateTimingstatus = value => {
  return {
    type: STATUS_TIMING_CREATE,
    CreateTimingstatus: value
  };
};