import { CREATE_REGULATION_ERR } from "../../actions/actionTypes";
import { STATUS_REGULATION_CREATE } from "../../actions/actionTypes";
import { REGULATION_CREATE } from "../../actions/actionTypes";
import { REGULATIONS_DATA, REGULATIONS_NB, LIST_REGULATIONS_ERR } from "../../actions/actionTypes";
import { REGULATION_DELETE, DELETE_REGULATION_ERR, STATUS_REGULATION_DELETE } from "../../actions/actionTypes";
import { REGULATION_EDIT, EDIT_REGULATION_ERR, STATUS_REGULATION_EDIT, REGULATION_DATA } from "../../actions/actionTypes";
import { Rules_EDIT, EDIT_Rules_ERR, STATUS_Rules_EDIT, Rules_Data } from "../../actions/actionTypes";


const initialState = {
  CreateRegulation: [],
  CreateRegulationerr: '',
  CreateRegulationstatus: '',
  listRegulationsErr: '',
  regulationsData: [],
  regulationsNb: '',
  deleteRegulationstatus: '',
  DeleteRegulationerr: '',
  deleteRegulation: [],
  editRegulationStatus: '',
  editregulation: [],
  EditRegulationerr: '',
  regulationData: [],
  editRulesStatus: '',
  editRules: [],
  EditRuleserr: '',
  RulesData: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_REGULATION_ERR:
      return {
        ...state,
        CreateRegulationerr: action.CreateRegulationerr
      };
    case STATUS_REGULATION_CREATE:
      return {
        ...state,
        CreateRegulationstatus: action.CreateRegulationstatus
      };
    case REGULATION_CREATE:
      return {
        ...state,
        CreateRegulation: action.CreateRegulation
      };
    case REGULATIONS_DATA:
      return {
        ...state,
        regulationsData: action.regulationsData
      };
    case LIST_REGULATIONS_ERR:
      return {
        ...state,
        listRegulationsErr: action.listRegulationsErr
      };
    case REGULATIONS_NB:
      return {
        ...state,
        regulationsNb: action.regulationsNb
      };
    case REGULATION_DELETE:
      return {
        ...state,
        deleteRegulation: action.deleteRegulation
      };
    case DELETE_REGULATION_ERR:
      return {
        ...state,
        DeleteRegulationerr: action.DeleteRegulationerr
      };
    case STATUS_REGULATION_DELETE:
      return {
        ...state,
        deleteRegulationstatus: action.deleteRegulationstatus
      };
    //
    case REGULATION_EDIT:
      return {
        ...state,
        editregulation: action.editregulation
      };
    case EDIT_REGULATION_ERR:
      return {
        ...state,
        EditRegulationerr: action.EditRegulationerr
      };
    case STATUS_REGULATION_EDIT:
      return {
        ...state,
        editRegulationStatus: action.editRegulationStatus
      };
    case REGULATION_DATA:
      return {
        ...state,
        regulationData: action.regulationData
      };
    //
    case Rules_EDIT:
      return {
        ...state,
        editRules: action.editRules
      };
    case EDIT_Rules_ERR:
      return {
        ...state,
        EditRuleserr: action.EditRuleserr
      };
    case STATUS_Rules_EDIT:
      return {
        ...state,
        editRulesStatus: action.editRulesStatus
      };
    case Rules_Data:
      return {
        ...state,
        RulesData: action.RulesData
      };



    default:
      return state;
  }
};

export default reducer;
