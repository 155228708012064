import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row, Table, Tooltip, Badge, Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { getphotosAlbumList, DeletePhoto } from "../../../../store/actions";
import { compose } from "redux";
import addBtn from '../../../../assets/img/brand/plus-button.png';
import history from "../../../../history.js";
import { connect } from "react-redux";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Trans } from "react-i18next";
import Pagination from 'rc-pagination';
import CircularProgress from "@material-ui/core/CircularProgress";
import 'rc-pagination/assets/index.css';

class Photos extends Component {

  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this);

    this.state = {
      tooltipOpen: false,
      current: 1,
      modal: false,
      photoId: ''
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  componentDidMount() {
    var token = localStorage.getItem("adminToken")
    var jwtDecode = require('jwt-decode');
    var decoded = jwtDecode(token);
    var expirationDate = decoded.exp;
    var seconds = 1000;
    var d = new Date();
    var t = d.getTime();

    if (expirationDate < Math.round(t / seconds)) {
      localStorage.setItem("adminToken", "")
      history.push("/login");

    }

    else {
      const data = { pageNumber: 1 };
      this.props.getphotosAlbumList(data)
    }
  }
  onDelete() {
    const data = { photosAlbumID: this.state.photoId };
    this.props.DeletePhoto(data);
  };
  toggleModal(photoId) {

    this.setState({
      modal: !this.state.modal,
      photoId: photoId
    });
  }
  componentWillReceiveProps(prevProps) {

    if (prevProps.deletePhotostatus !== this.props.deletePhotostatus) {

      if (prevProps.deletePhotostatus === "success") {
        this.setState({
          modal: false
        });
        ToastsStore.info(<Trans i18nKey="Photos.del" />, 3000)
        const data = { pageNumber: 1 };
        this.props.getphotosAlbumList(data);
      }

    }

  }
  onChange = (page) => {
    this.setState({
      current: page
    });
    const data = { pageNumber: page };
    this.props.getphotosAlbumList(data)
  }

  render() {
    const { isLoading } = this.props;

    let btnValidate = (
      <Button onClick={() => this.onDelete()} type="submit" className="btn-secondary" size="md"><i className="icon-check"></i>  <Trans i18nKey="yes" /></Button>
    )
    if (isLoading) {
      btnValidate = <CircularProgress size={30} style={{ color: "#083328" }} />;
    }
    return (
      <div className="animated fadeIn">
        <Row>

          <Col xl={12}>

            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i>  <Trans i18nKey="Photos.PhotosList" /> <Link to={`photo/create_album`}><img src={addBtn} alt="" style={{ color: "#235C4E", cursor: "pointer", marginTop: '0.5%', float: (localStorage.getItem('i18nextLng') === "en") ? 'right' : 'left' }} id={'Tooltip-' + this.props.id} /> </Link>

                <Tooltip isOpen={this.state.tooltipOpen} target={'Tooltip-' + this.props.id} toggle={this.toggle}>
                  <Trans i18nKey="Photos.AddPhoto" />
                </Tooltip>

              </CardHeader>
              <CardBody>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col"><Trans i18nKey="Title" /></th>
                      <th scope="col"><Trans i18nKey="Photos.PhotosNbr" /></th>
                      <th scope="col"><Trans i18nKey="Active" /></th>
                      <th scope="col"><Trans i18nKey="Reservations.created_at" /></th>
                      <th scope="col"><Trans i18nKey="Reservations.modified_at" /></th>
                      <th scope="col"><Trans i18nKey="Actions" /></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.listPhotosErr === "" ?
                      Object.values(this.props.PhotosData).map(PhotoData => {
                        var cre = PhotoData.createdAt;
                        var upd = PhotoData.updatedAt;
                        var date = new Date(cre);
                        var year = date.getFullYear();
                        var month = date.getMonth() + 1;
                        var dt = date.getDate();

                        if (dt < 10) {
                          dt = '0' + dt;
                        }
                        if (month < 10) {
                          month = '0' + month;
                        }
                        var dateUp = new Date(upd);
                        var yearUp = dateUp.getFullYear();
                        var monthUp = dateUp.getMonth() + 1;
                        var dtUp = dateUp.getDate();

                        if (dtUp < 10) {
                          dtUp = '0' + dtUp;
                        }
                        if (monthUp < 10) {
                          monthUp = '0' + monthUp;
                        }
                        if (localStorage.getItem('i18nextLng') === "en")
                          return <tr key={PhotoData._id} id={PhotoData._id}>
                            <td>{PhotoData.title}</td>
                            <td>{PhotoData.Picture_number}</td>
                            <td><Badge pill color={PhotoData.active === "no" ? "danger" : "success"} >{PhotoData.active}</Badge></td>
                            <td>{dt + '-' + month + '-' + year}</td>
                            <td>{dtUp + '-' + monthUp + '-' + yearUp}</td>
                            <td>
                              <Link to={`/medias/photos/${PhotoData._id}`}><i className="icon-pencil" style={{ color: "#768828" }}></i></Link>
                              <i onClick={() => this.toggleModal(PhotoData._id)} className="icon-trash" style={{ marginLeft: '10%', cursor: "pointer" }}></i>
                            </td>

                          </tr>
                        return <tr key={PhotoData._id} id={PhotoData._id}>
                          <td>{PhotoData.translationAr[0].ARtitle}</td>
                          <td>{PhotoData.Picture_number}</td>
                          <td><Badge pill color={PhotoData.active === "no" ? "danger" : "success"} >{PhotoData.active === "no" ? <Trans i18nKey="no" /> : <Trans i18nKey="yes" />} </Badge></td>
                          <td>{dt + '-' + month + '-' + year}</td>
                          <td>{dtUp + '-' + monthUp + '-' + yearUp}</td>
                          <td>
                            <Link to={`/medias/photos/${PhotoData._id}`}><i className="icon-pencil" ></i></Link>
                            <i onClick={() => this.toggleModal(PhotoData._id)} className="icon-trash" style={{ marginLeft: '10%', cursor: "pointer" }}></i>
                          </td>

                        </tr>

                      }) : <tr><td><Trans i18nKey="Photos.noPhoto" /></td></tr>}

                    <Modal isOpen={this.state.modal} toggle={() => this.toggleModal()} className={this.props.className}
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)"
                      }}>
                      <ModalHeader toggle={() => this.toggleModal()}><Trans i18nKey="Photos.photodeltTitle" /></ModalHeader>

                      <ModalBody>
                        <br />
                        {(localStorage.getItem('i18nextLng') === "en") ? "Do you really want to delete this album ?" : "هل تريد حقًا حذف هذا الألبوم ؟"}
                        <br />
                        <br />
                      </ModalBody>
                      <ModalFooter>
                        {btnValidate}{' '}
                        <Button style={{
                          color: "white",
                          backgroundColor: "#C6893F",
                          borderColor: "#C6893F",
                          marginRight: "10px"
                        }} onClick={() => this.toggleModal()}><i className="icon-ban"></i> <Trans i18nKey="cancel" /></Button>
                      </ModalFooter>
                    </Modal>
                  </tbody>

                </Table>
                <Pagination onChange={this.onChange} showTitle={false} current={this.state.current} total={this.props.PhotosNbr} />
              </CardBody>
            </Card>
          </Col>
          <ToastsContainer store={ToastsStore} />
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    listPhotosErr: state.listPhotosErr.listPhotosErr,
    PhotosData: state.PhotosData.PhotosData,
    PhotosNbr: state.PhotosNbr.PhotosNbr,
    deletePhoto: state.deletePhoto.deletePhoto,
    deletePhotostatus: state.deletePhotostatus.deletePhotostatus,
    DeletePhotoerr: state.DeletePhotoerr.DeletePhotoerr,
    isLoading: state.ui.isLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getphotosAlbumList: (data) => dispatch(getphotosAlbumList(data)),
    DeletePhoto: (data) => dispatch(DeletePhoto(data))
  };
};


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Photos);