import { NEWS_CREATE } from "../../actions/actionTypes";
import { CREATE_NEWS_ERR } from "../../actions/actionTypes";
import { STATUS_NEWS_CREATE } from "../../actions/actionTypes";

import { NEWSL_DATA } from "../../actions/actionTypes";
import { NEWSL_LIST_ERR } from "../../actions/actionTypes";
import { NEWSL_NBR } from "../../actions/actionTypes";

import { NEWS_DATA } from "../../actions/actionTypes";
import { NEWS_ERR } from "../../actions/actionTypes";

import { NEWS_DELETE } from "../../actions/actionTypes";
import { NEWS_DELETE_ERR } from "../../actions/actionTypes";
import { NEWS_DELETE_STATUS } from "../../actions/actionTypes";

import { NEWS_EDIT_ERR } from "../../actions/actionTypes";
import { NEWS_EDIT } from "../../actions/actionTypes";
import { NEWS_EDIT_STATUS } from "../../actions/actionTypes";
import { LIST_NEWS_ACT_ERR } from "../../actions/actionTypes";
import { LIST_NEWS_ACT_DATA } from "../../actions/actionTypes";

const initialState = {
  getNewsErr: "",
  getNewsData: [],
  deleteNewstatus: '',
  deleteNew: '',
  DeleteNewerr: '',
  NewsNbr: 0,
  NewsData: [],
  listNewsErr: '',
  CreateNewStatus: '',
  CreateNewerr: '',
  CreateNew: [],
  EditNewerr: '',
  EditNew: [],
  EditNewstatus: '',
  ActNewsData : [],
  listActNewsErr: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_NEWS_ACT_DATA:
      return {
        ...state,
        ActNewsData: action.ActNewsData
      };
    case LIST_NEWS_ACT_ERR:
      return {
        ...state,
        listActNewsErr: action.listActNewsErr
      };
    case NEWS_EDIT_STATUS:
      return {
        ...state,
        EditNewstatus: action.EditNewstatus
      };
    case NEWS_EDIT:
      return {
        ...state,
        EditNew: action.EditNew
      };
    case NEWS_EDIT_ERR:
      return {
        ...state,
        EditNewerr: action.EditNewerr
      };
    case NEWS_DELETE_STATUS:
      return {
        ...state,
        deleteNewstatus: action.deleteNewstatus
      };
    case NEWS_DELETE_ERR:
      return {
        ...state,
        DeleteNewerr: action.DeleteNewerr
      };
    case NEWS_DELETE:
      return {
        ...state,
        deleteNew: action.deleteNew
      };
    case NEWS_ERR:
      return {
        ...state,
        getNewsErr: action.getNewsErr
      };
    case NEWS_DATA:
      return {
        ...state,
        getNewsData: action.getNewsData
      };
    case NEWSL_NBR:
      return {
        ...state,
        NewsNbr: action.NewsNbr
      };
    case NEWSL_LIST_ERR:
      return {
        ...state,
        listNewsErr: action.listNewsErr
      };
    case NEWSL_DATA:
      return {
        ...state,
        NewsData: action.NewsData
      };
    case STATUS_NEWS_CREATE:
      return {
        ...state,
        CreateNewStatus: action.CreateNewStatus
      };
    case CREATE_NEWS_ERR:
      return {
        ...state,
        CreateNewerr: action.CreateNewerr
      };
    case NEWS_CREATE:
      return {
        ...state,
        CreateNew: action.CreateNew
      };

    default:
      return state;
  }
};

export default reducer;
