import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row, Table, Badge, Tooltip, Container, Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { getMerchants, DeleteMerchant, getAdmin } from "../../../store/actions";
import { compose } from "redux";
import addBtn from '../../../assets/img/brand/plus-button.png';
import history from "../../../history.js";
import { connect } from "react-redux";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Trans } from "react-i18next";
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import notValid from '../../../assets/img/brand/notValid.png';
import CircularProgress from "@material-ui/core/CircularProgress";


class Merchants extends Component {

  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this);

    this.state = {
      tooltipOpen: false,
      current: 1,
      deleted: '',
      modal: false,
      merchantId: '',
      role: ''
    };
  }
  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }
  onChange = (page) => {
    this.setState({
      current: page
    });
    const data = { pageNumber: page };
    this.props.getMerchants(data)
  }

  componentDidMount() {
    var token = localStorage.getItem("adminToken")
    var jwtDecode = require('jwt-decode');
    var decoded = jwtDecode(token);
    var expirationDate = decoded.exp;
    var seconds = 1000;
    var d = new Date();
    var t = d.getTime();

    if (expirationDate < Math.round(t / seconds)) {
      localStorage.setItem("adminToken", "")
      history.push("/login");

    }
    else {
      const data = { pageNumber: 1 };
      this.props.getMerchants(data);
      const dataAdmin = {
        adminID: decoded.data
      }
      this.props.getAdmin(dataAdmin);
    }

  }
  onDelete() {
    const data = { merchantId: this.state.merchantId };
    this.props.DeleteMerchant(data);
  };
  toggleModal(merchantId) {

    this.setState({
      modal: !this.state.modal,
      merchantId: merchantId
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.getAdminData !== prevProps.getAdminData) {
      if (this.props.getAdminData.role !== undefined) {
        this.setState({
          role: this.props.getAdminData.role,
        })
      }
    }
  }

  componentWillReceiveProps(prevProps) {
    if (prevProps.deleteMerchantstatus !== this.props.deleteMerchantstatus) {
      if (prevProps.deleteMerchantstatus === "success") {
        this.setState({
          modal: false
        });
        ToastsStore.info(<Trans i18nKey="Merchants.merchantdel" />, 3000)
        const data = { pageNumber: 1 };
        this.props.getMerchants(data);
      }

    }
    if (prevProps.DeleteMerchanterr.En !== this.props.DeleteMerchanterr.En) {
      if (prevProps.DeleteMerchanterr !== "") {
        this.setState({
          modal: false,
          deleted: localStorage.getItem('i18nextLng') === "en" ? prevProps.DeleteMerchanterr.En : prevProps.DeleteMerchanterr.Ar
        });
      }

    }

  }
  render() {

    const { isLoading } = this.props;
    let btnValidate = (
      <Button onClick={() => this.onDelete()} type="submit" className="btn-secondary" size="md"><i className="icon-check"></i>  <Trans i18nKey="yes" /></Button>
    )
    if (isLoading) {
      btnValidate = <CircularProgress size={30} style={{ color: "#083328" }} />;
    }
    return (
      <div className="animated fadeIn">
        <Row>

          <Col xl={12}>

            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> <Trans i18nKey="Merchants.MerchantsList" />
                {this.state.role.length !== 0 && this.state.role === "superAdmin" &&
                  <Link to={`/merchant/create`}><img src={addBtn} alt="" style={{ color: "#235C4E", cursor: "pointer", marginTop: '0.5%', float: (localStorage.getItem('i18nextLng') === "en") ? 'right' : 'left' }} id={'Tooltip-' + this.props.id} /> </Link>
                }
                {this.state.role.length !== 0 && this.state.role === "superAdmin" &&
                  <Tooltip isOpen={this.state.tooltipOpen} target={'Tooltip-' + this.props.id} toggle={this.toggle}>
                  <Trans i18nKey="Merchants.AddMerchant" />
                </Tooltip>
                }
              </CardHeader>
              <CardBody>

                <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col"><Trans i18nKey="Users.Name" /></th>
                      <th scope="col"><Trans i18nKey="Users.Email" /></th>

                      <th scope="col"><Trans i18nKey="AddedBy" /></th>
                      <th scope="col">{localStorage.getItem('i18nextLng') === "en" ? "Role" : "الدور"}</th>
                      <th scope="col"><Trans i18nKey="Active" /></th>
                      <th scope="col"><Trans i18nKey="Reservations.created_at" /></th>
                      <th scope="col"><Trans i18nKey="Reservations.modified_at" /></th>
                      {this.state.role === "superAdmin" &&
                        <th scope="col"><Trans i18nKey="Actions" /></th>
                      }

                    </tr>
                  </thead>
                  <tbody>
                    {this.props.listMerchantsErr === "" ?

                      Object.values(this.props.merchantsData).map(merchantData => {
                        var cre = merchantData.createdAt;
                        var upd = merchantData.updatedAt;
                        var date = new Date(cre);
                        var year = date.getFullYear();
                        var month = date.getMonth() + 1;
                        var dt = date.getDate();

                        if (dt < 10) {
                          dt = '0' + dt;
                        }
                        if (month < 10) {
                          month = '0' + month;
                        }
                        var dateUp = new Date(upd);
                        var yearUp = dateUp.getFullYear();
                        var monthUp = dateUp.getMonth() + 1;
                        var dtUp = dateUp.getDate();

                        if (dtUp < 10) {
                          dtUp = '0' + dtUp;
                        }
                        if (monthUp < 10) {
                          monthUp = '0' + monthUp;
                        }
                        return <tr key={merchantData._id} id={merchantData._id}>
                          <td>{merchantData.name}</td>
                          <td>{merchantData.email}</td>
                          <td>{merchantData.admin_id}</td>
                          <td>{merchantData.role }</td>
                          <td><Badge pill color={merchantData.active === "no" ? "danger" : "success"} >{merchantData.active}</Badge></td>
                          <td>{dt + '-' + month + '-' + year}</td>
                          <td>{dtUp + '-' + monthUp + '-' + yearUp}</td>
                          {this.state.role === "superAdmin" &&
                            <td>
                              <Link to={`/merchants/${merchantData._id}`}><i className="icon-pencil" ></i></Link>
                              <i onClick={() => this.toggleModal(merchantData._id)} className="icon-trash" style={{ marginLeft: '10%', cursor: "pointer" }}></i>
                            </td>
                          }

                        </tr>

                      }) : <tr><td><Trans i18nKey="Merchants.noMerchant" /></td></tr>}


                    <Modal isOpen={this.state.modal} toggle={() => this.toggleModal()} className={this.props.className}
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)"
                      }}>
                      <ModalHeader toggle={() => this.toggleModal()}><Trans i18nKey="Merchants.merchantdeltTitle" /></ModalHeader>

                      <ModalBody>
                        <br />
                        {(localStorage.getItem('i18nextLng') === "en") ? "Do you really want to delete this merchant ?" : "هل تريد حقًا حذف هذا الحدث ؟"}
                        <br />
                        <br />
                      </ModalBody>
                      <ModalFooter>
                        {btnValidate}{' '}
                        <Button style={{
                          color: "white",
                          backgroundColor: "#C6893F",
                          borderColor: "#C6893F",
                          marginRight: "10px"
                        }} onClick={() => this.toggleModal()}><i className="icon-ban"></i> <Trans i18nKey="cancel" /></Button>
                      </ModalFooter>
                    </Modal>
                  </tbody>
                </Table>
                <Pagination onChange={this.onChange} showTitle={false} current={this.state.current} total={this.props.MerchantsNbr} />
              </CardBody>
            </Card>
          </Col>
          <ToastsContainer store={ToastsStore} />

          {
            this.state.deleted !== '' ? <Container>
              <Row className="justify-content-center">
                <img src={notValid} alt="notvalid" style={
                  {
                    width: "20px",
                    marginRight: "10px",
                    height: "20px",
                    marginLeft: "10px"
                  }
                } />
                <p style={{ color: 'red' }}> {this.state.deleted} </p>
              </Row>
            </Container>
              : <div></div>
          }
          <br />

        </Row>
      </div >
    )
  }
}

const mapStateToProps = state => {
  return {
    merchantsData: state.merchantsData.merchantsData,
    MerchantsNbr: state.MerchantsNbr.MerchantsNbr,
    isLoading: state.ui.isLoading,
    listMerchantsErr: state.listMerchantsErr.listMerchantsErr,
    deleteMerchantstatus: state.deleteMerchantstatus.deleteMerchantstatus,
    DeleteMerchanterr: state.DeleteMerchanterr.DeleteMerchanterr,
    getAdminData: state.getAdminData.getAdminData,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMerchants: (data) => dispatch(getMerchants(data)),
    DeleteMerchant: (data) => dispatch(DeleteMerchant(data)),
    getAdmin: (data) => dispatch(getAdmin(data))

  };
};


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Merchants);