import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
  Badge,
  Tooltip,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button
} from "reactstrap";
import { getEvents, DeleteEvent } from "../../../store/actions";
import { compose } from "redux";
import addBtn from "../../../assets/img/brand/plus-button.png";
import history from "../../../history.js";
import { connect } from "react-redux";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { Trans } from "react-i18next";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import notValid from "../../../assets/img/brand/notValid.png";
import CircularProgress from "@material-ui/core/CircularProgress";

class Events extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);

    this.state = {
      tooltipOpen: false,
      current: 1,
      deleted: "",
      modal: false,
      eventId: ""
    };
  }
  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }
  onChange = page => {
    this.setState({
      current: page
    });
    const data = { pageNumber: page };
    this.props.getEvents(data);
  };

  componentDidMount() {
    var token = localStorage.getItem("adminToken");
    var jwtDecode = require("jwt-decode");
    var decoded = jwtDecode(token);
    var expirationDate = decoded.exp;
    var seconds = 1000;
    var d = new Date();
    var t = d.getTime();

    if (expirationDate < Math.round(t / seconds)) {
      localStorage.setItem("adminToken", "");
      history.push("/login");
    } else {
      const data = { pageNumber: 1 };
      this.props.getEvents(data);
    }
  }
  onDelete() {
    const data = { eventID: this.state.eventId };
    this.props.DeleteEvent(data);
  }
  toggleModal(eventId) {
    this.setState({
      modal: !this.state.modal,
      eventId: eventId
    });
  }

  componentWillReceiveProps(prevProps) {
    if (prevProps.deleteEventstatus !== this.props.deleteEventstatus) {
      if (prevProps.deleteEventstatus === "success") {
        this.setState({
          modal: false
        });
        ToastsStore.info(<Trans i18nKey="Events.eventdel" />, 3000);
        const data = { pageNumber: 1 };
        this.props.getEvents(data);
      }
    }
    if (prevProps.DeleteEventerr.En !== this.props.DeleteEventerr.En) {
      if (prevProps.DeleteEventerr !== "") {
        this.setState({
          modal: false,
          deleted:
            localStorage.getItem("i18nextLng") === "en"
              ? prevProps.DeleteEventerr.En
              : prevProps.DeleteEventerr.Ar
        });
      }
    }
  }
  render() {
    const { isLoading, isLoader } = this.props;
    let load;
    if (isLoader) {
      load = <p style={{ textAlign: "center" }}>Is loading ...</p>;
    }
    let btnValidate = (
      <Button
        onClick={() => this.onDelete()}
        type="submit"
        className="btn-secondary"
        size="md"
      >
        <i className="icon-check"></i> <Trans i18nKey="yes" />
      </Button>
    );
    if (isLoading) {
      btnValidate = <CircularProgress size={30} style={{ color: "#083328" }} />;
    }

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xl={12}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i>{" "}
                <Trans i18nKey="Events.EventsList" />{" "}
                <Link to={`/event/create`}>
                  <img
                    src={addBtn}
                    alt=""
                    style={{
                      color: "#235C4E",
                      cursor: "pointer",
                      marginTop: "0.5%",
                      float:
                        localStorage.getItem("i18nextLng") === "en"
                          ? "right"
                          : "left"
                    }}
                    id={"Tooltip-" + this.props.id}
                  />{" "}
                </Link>
                <Tooltip
                  isOpen={this.state.tooltipOpen}
                  target={"Tooltip-" + this.props.id}
                  toggle={this.toggle}
                >
                  <Trans i18nKey="Events.AddEvent" />
                </Tooltip>
              </CardHeader>
              <CardBody>
                {load}
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col">
                        <Trans i18nKey="Title" />
                      </th>
                      {/* <th scope="col"><Trans i18nKey="Description" /></th> */}
                      <th scope="col">
                        <Trans i18nKey="Date" />
                      </th>
                      <th scope="col">
                        <Trans i18nKey="Places.Place" />
                      </th>
                      <th scope="col">
                      {localStorage.getItem('i18nextLng') === "en" ? "Event Type " :"نوع الفعالية "}
                      </th>
                      <th scope="col">
                        <Trans i18nKey="Paid" />
                      </th>
                      <th scope="col">
                        <Trans i18nKey="AddedBy" />
                      </th>
                      <th scope="col">
                        <Trans i18nKey="Active" />
                      </th>
                      <th scope="col">
                        <Trans i18nKey="Actions" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.listEventsErr === "" ? (
                      Object.values(this.props.eventsData).map(eventData => {
                        console.log(eventData.eventType)
                        var cre = eventData.date;

                        var date = new Date(cre);
                        var year = date.getFullYear();
                        var month = date.getMonth() + 1;
                        var dt = date.getDate();

                        if (dt < 10) {
                          dt = "0" + dt;
                        }
                        if (month < 10) {
                          month = "0" + month;
                        }
                        if (localStorage.getItem("i18nextLng") === "en")
                          return (
                            <tr key={eventData._id} id={eventData._id}>
                              <td>{eventData.title}</td>
                              {/* <td>{eventData.description}</td> */}
                              <td>{dt + "-" + month + "-" + year}</td>
                              <td>{eventData.place_name}</td>
                              <td>{eventData.eventType !== undefined && eventData.eventType === "current" ? "Related to the park" : eventData.eventType !== undefined && eventData.eventType === "private" ? "Private" : ""}</td>
                              <td>
                                <Badge
                                  color={
                                    eventData.type === "free" ? "warning" : "info"
                                  }
                                >
                                  {eventData.type === "free" ? (
                                    <Trans i18nKey="no" />
                                  ) : (
                                      <Trans i18nKey="yes" />
                                    )}{" "}
                                </Badge>
                              </td>
                              <td>{eventData.admin}</td>
                              <td>
                                <Badge
                                  pill
                                  color={
                                    eventData.active === "no"
                                      ? "danger"
                                      : "success"
                                  }
                                >
                                  {eventData.active}
                                </Badge>
                              </td>

                              <td>
                                <Link to={`/events/${eventData._id}`}>
                                  <i className="icon-pencil"></i>
                                </Link>
                                <i
                                  onClick={() =>
                                    this.toggleModal(eventData._id)
                                  }
                                  className="icon-trash"
                                  style={{
                                    marginLeft: "10%",
                                    cursor: "pointer"
                                  }}
                                ></i>
                              </td>
                            </tr>
                          );
                        return (
                          <tr key={eventData._id} id={eventData._id}>
                            <td>{eventData.translationAr[0].ARtitle}</td>
                            {/* <td>{eventData.translationAr[0].ARdescription}</td> */}
                            <td>{eventData.date}</td>
                            <td>{eventData.place_ARname}</td>
                            <td>
                              <Badge
                                color={
                                  eventData.type === "free" ? "warning" : "info"
                                }
                              >
                                {eventData.type === "free" ? (
                                  <Trans i18nKey="no" />
                                ) : (
                                    <Trans i18nKey="yes" />
                                  )}{" "}
                              </Badge>
                            </td>
                            <td>{eventData.admin}</td>
                            <td>
                              <Badge
                                pill
                                color={
                                  eventData.active === "no"
                                    ? "danger"
                                    : "success"
                                }
                              >
                                {eventData.active === "no" ? (
                                  <Trans i18nKey="no" />
                                ) : (
                                    <Trans i18nKey="yes" />
                                  )}{" "}
                              </Badge>
                            </td>

                            <td>
                              <Link to={`/events/${eventData._id}`}>
                                <i className="icon-pencil"></i>
                              </Link>
                              <i
                                onClick={() => this.toggleModal(eventData._id)}
                                className="icon-trash"
                                style={{ marginLeft: "10%", cursor: "pointer" }}
                              ></i>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                        <tr>
                          <td>
                            <Trans i18nKey="Events.noEvent" />
                          </td>
                        </tr>
                      )}

                    <Modal
                      isOpen={this.state.modal}
                      toggle={() => this.toggleModal()}
                      className={this.props.className}
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)"
                      }}
                    >
                      <ModalHeader toggle={() => this.toggleModal()}>
                        <Trans i18nKey="Events.eventdeltTitle" />
                      </ModalHeader>

                      <ModalBody>
                        <br />
                        {localStorage.getItem("i18nextLng") === "en"
                          ? "Do you really want to delete this event ?"
                          : "هل تريد حقًا حذف هذا الحدث ؟"}
                        <br />
                        <br />
                      </ModalBody>
                      <ModalFooter>
                        {btnValidate}{" "}
                        <Button
                          style={{
                            color: "white",
                            backgroundColor: "#C6893F",
                            borderColor: "#C6893F",
                            marginRight: "10px"
                          }}
                          onClick={() => this.toggleModal()}
                        >
                          <i className="icon-ban"></i>{" "}
                          <Trans i18nKey="cancel" />
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </tbody>
                </Table>
                <Pagination
                  onChange={this.onChange}
                  showTitle={false}
                  current={this.state.current}
                  total={this.props.EventsNbr}
                />
              </CardBody>
            </Card>
          </Col>
          <ToastsContainer store={ToastsStore} />

          {this.state.deleted !== "" ? (
            <Container>
              <Row className="justify-content-center">
                <img
                  src={notValid}
                  alt="notvalid"
                  style={{
                    width: "20px",
                    marginRight: "10px",
                    height: "20px",
                    marginLeft: "10px"
                  }}
                />
                <p style={{ color: "red" }}> {this.state.deleted} </p>
              </Row>
            </Container>
          ) : (
              <div></div>
            )}
          <br />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    eventsData: state.eventsData.eventsData,
    EventsNbr: state.EventsNbr.EventsNbr,
    isLoading: state.ui.isLoading,
    isLoader: state.ui.isLoader,
    listEventsErr: state.listEventsErr.listEventsErr,
    deleteEventstatus: state.deleteEventstatus.deleteEventstatus,
    DeleteEventerr: state.DeleteEventerr.DeleteEventerr
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getEvents: data => dispatch(getEvents(data)),
    DeleteEvent: data => dispatch(DeleteEvent(data))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Events);
