import { CREATE_VIDEO_ERR } from "../../actions/actionTypes";
import { STATUS_VIDEO_CREATE } from "../../actions/actionTypes";
import { VIDEO_CREATE } from "../../actions/actionTypes";
import { VIDEOS_DATA } from "../../actions/actionTypes";
import { VIDEOS_LIST_ERR } from "../../actions/actionTypes";
import { VIDEO_DELETE } from "../../actions/actionTypes";
import { VIDEO_DELETE_ERR } from "../../actions/actionTypes";
import { VIDEO_DELETE_STATUS } from "../../actions/actionTypes";
import { VIDEO_DATA } from "../../actions/actionTypes";
import { VIDEO_ERR } from "../../actions/actionTypes";
import { VIDEO_EDIT_ERR } from "../../actions/actionTypes";
import { VIDEO_EDIT } from "../../actions/actionTypes";
import { VIDEO_EDIT_STATUS } from "../../actions/actionTypes";
import { VIDEOS_NBR } from "../../actions/actionTypes";

const initialState = {

  CreateVideo: [],
  CreateVideoerr: '',
  CreateVideoStatus: '',
  VideosData: [],
  listVideosErr: '',
  deleteVideostatus: '',
  deleteVideo: [],
  DeleteVideoerr: '',
  videoData: [],
  videoErr: '',
  EditVideoerr: '',
  EditVideo: [],
  EditVideostatus: '',
  VideosNbr : 0


};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case CREATE_VIDEO_ERR:
      return {
        ...state,
        CreateVideoerr: action.CreateVideoerr
      };
    case STATUS_VIDEO_CREATE:
      return {
        ...state,
        CreateVideoStatus: action.CreateVideoStatus
      };
    case VIDEO_CREATE:
      return {
        ...state,
        CreateVideo: action.CreateVideo
      };
    case VIDEOS_DATA:
      return {
        ...state,
        VideosData: action.VideosData
      };
    case VIDEOS_NBR:
        return {
          ...state,
          VideosNbr: action.VideosNbr
        };
    case VIDEOS_LIST_ERR:
      return {
        ...state,
        listVideosErr: action.listVideosErr
      };
    case VIDEO_DELETE:
      return {
        ...state,
        deleteVideo: action.deleteVideo
      };
    case VIDEO_DELETE_ERR:
      return {
        ...state,
        DeleteVideoerr: action.DeleteVideoerr
      };
    case VIDEO_DELETE_STATUS:
      return {
        ...state,
        deleteVideostatus: action.deleteVideostatus
      };
    case VIDEO_ERR:
      return {
        ...state,
        videoErr: action.videoErr
      };
    case VIDEO_DATA:
      return {
        ...state,
        videoData: action.videoData
      };
    case VIDEO_EDIT_ERR:
      return {
        ...state,
        EditVideoerr: action.EditVideoerr
      };
    case VIDEO_EDIT:
      return {
        ...state,
        EditVideo: action.EditVideo
      };
    case VIDEO_EDIT_STATUS:
      return {
        ...state,
        EditVideostatus: action.EditVideostatus
      };
    default:
      return state;
  }
};

export default reducer;
