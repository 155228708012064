import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import { getUsers } from "../../../store/actions";
import { compose } from "redux";
import history from "../../../history";
import { connect } from "react-redux";
import { Trans } from "react-i18next";
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
class Users extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current: 1,
    };

  }
  onChange = (page) => {
    this.setState({
      current: page
    });
    const data = { pageNumber: page };
    this.props.getUsers(data)
  }
  componentDidMount() {
    var token = localStorage.getItem("adminToken")
    var jwtDecode = require('jwt-decode');
    var decoded = jwtDecode(token);

    var expirationDate = decoded.exp;
    var seconds = 1000;
    var d = new Date();
    var t = d.getTime();

    if (expirationDate < Math.round(t / seconds)) {
      localStorage.setItem("adminToken", "")
      history.push("/login");

    }
    else {
      const data = { pageNumber: 1 };
      this.props.getUsers(data);
    }
  }
  render() {

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xl={11}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i>  <Trans i18nKey="Users.UsersList" />
              </CardHeader>
              <CardBody>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col"><Trans i18nKey="Users.Name" /></th>
                      <th scope="col"><Trans i18nKey="Users.Email" /></th>
                      <th scope="col"><Trans i18nKey="Users.Gender" /></th>
                      <th scope="col"><Trans i18nKey="Users.Nationality" /></th>
                      <th scope="col"><Trans i18nKey="Users.Phone" /></th>
                      <th scope="col">{localStorage.getItem('i18nextLng') === "en" ? "Address" : " العنوان "}</th>
                      <th scope="col"><Trans i18nKey="Reservations.created_at" /></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.Listerr === "" ?

                      Object.values(this.props.usersData).map((userData) => {
                        var cre = userData.createdAt;
                        var upd = userData.updatedAt;

                        var date = new Date(cre);
                        var year = date.getFullYear();
                        var month = date.getMonth() + 1;
                        var dt = date.getDate();

                        if (dt < 10) {
                          dt = '0' + dt;
                        }
                        if (month < 10) {
                          month = '0' + month;
                        }
                        var dateUp = new Date(upd);
                        var yearUp = dateUp.getFullYear();
                        var monthUp = dateUp.getMonth() + 1;
                        var dtUp = dateUp.getDate();

                        if (dtUp < 10) {
                          dtUp = '0' + dtUp;
                        }
                        if (monthUp < 10) {
                          monthUp = '0' + monthUp;
                        }
                        return <tr key={userData._id} id={userData._id}>
                          <td>{userData.name}</td>
                          <td>{userData.email}</td>
                          <td>{(userData.gender) === "1" ? (localStorage.getItem('i18nextLng') === "en" ? "Male" : "ذكر") : (localStorage.getItem('i18nextLng') === "en" ? "Female" : "أنثى")}</td>
                          <td>{userData.nationality}</td>
                          <td>{userData.phone}</td>
                          <td>{userData.address}</td>
                          <td>{dt + '-' + month + '-' + year}</td>
                        </tr>

                      })
                      : <tr><td><Trans i18nKey="Users.noUser" /></td></tr>}




                  </tbody>
                </Table>
                <Pagination onChange={this.onChange} showTitle={false} current={this.state.current} total={this.props.usersListNbr} />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    usersData: state.usersData.usersData,
    usersListNbr: state.usersListNbr.usersListNbr,
    isLoading: state.ui.isLoading,
    Listerr: state.Listerr.Listerr
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUsers: (data) => dispatch(getUsers(data))
  };
};


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Users);