import {
  CONTACT_DATA
} from "../actionTypes";
import {
  uiStartLoading,
  uiStopLoading
} from "../index";

import {
  CONTACT_EDIT
} from "../actionTypes";
import {
  EDIT_CONTACT_ERR
} from "../actionTypes";
import {
  STATUS_CONTACT_EDIT
} from "../actionTypes";

export const getContact = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/getContact`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        dispatch(contactData(parsedRes.data));

      });
  };
};

export const editContact = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    fetch(`${process.env.REACT_APP_DOMAIN}/api/editContact`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token

        },
        body: JSON.stringify({
          mailto: data.mailto,
          Server: data.Server,
          Port: data.Port,
          encryption: data.encryption,
          user: data.user,
          pass: data.pass
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(editContactStatus(""));
        dispatch(uiStopLoading())

        if (parsedRes.status === "fail") {

          dispatch(EditContacterr(parsedRes.error));
        } else {
          dispatch(EditContacterr(""));
          dispatch(editcontact(parsedRes.data));
          dispatch(editContactStatus(parsedRes.status));
        }

      });
  };

};

export const contactData = value => {
  return {
    type: CONTACT_DATA,
    contactData: value
  };
};

export const editcontact = value => {
  return {
    type: CONTACT_EDIT,
    editcontact: value
  };
};
export const editContactStatus = value => {
  return {
    type: STATUS_CONTACT_EDIT,
    editContactStatus: value
  };
};

export const EditContacterr = value => {
  return {
    type: EDIT_CONTACT_ERR,
    EditContacterr: value
  };
};