import { CONTACT_DATA } from "../../actions/actionTypes";
import { CONTACT_EDIT } from "../../actions/actionTypes";
import { EDIT_CONTACT_ERR } from "../../actions/actionTypes";
import { STATUS_CONTACT_EDIT } from "../../actions/actionTypes";

const initialState = {
  contactData: [],
  editcontact: [],
  EditContacterr: '',
  editContactStatus: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case CONTACT_DATA:
      return {
        ...state,
        contactData: action.contactData
      };
    case CONTACT_EDIT:
      return {
        ...state,
        editcontact: action.editcontact
      };
    case EDIT_CONTACT_ERR:
      return {
        ...state,
        EditContacterr: action.EditContacterr
      };
    case STATUS_CONTACT_EDIT:
      return {
        ...state,
        editContactStatus: action.editContactStatus
      };
    
    default:
      return state;
  }
};

export default reducer;
