import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Table, Badge, Button } from 'reactstrap';
import { getPlaceReservations } from "../../../store/actions";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Link } from 'react-router-dom';
import { compose } from "redux";
import history from "../../../history";
import { connect } from "react-redux";
import { Trans } from "react-i18next";
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
class PlaceReservations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current: 1
    };
  }
  componentDidMount() {
    var token = localStorage.getItem("adminToken")
    var jwtDecode = require('jwt-decode');
    var decoded = jwtDecode(token);
    var expirationDate = decoded.exp;
    var seconds = 1000;
    var d = new Date();
    var t = d.getTime();

    if (expirationDate < Math.round(t / seconds)) {
      localStorage.setItem("adminToken", "")
      history.push("/login");

    }
    else {
      const data = { pageNumber: 1 };
      this.props.getPlaceReservations(data)
    }
  }
  onChange = (page) => {
    this.setState({
      current: page
    });
    const data = { pageNumber: page };
    this.props.getPlaceReservations(data)
  }
  render() {
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xl={12}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> <Trans i18nKey="PlaceReservations.PlaceReservationsList" />
                <Link to={`/reservations/by_place`}>
                  <Button style={{ float: 'right' }} type="submit" size="sm" color="secondary"><i className="icon-info"></i> <Trans i18nKey="PlaceReservations.View" /></Button>
                </Link>
              </CardHeader>
              <CardBody>
                <Table responsive hover id="table-to-xlsplace">
                  <thead>
                    <tr>
                      <th scope="col"><Trans i18nKey="Places.Place" /></th>
                      <th scope="col"><Trans i18nKey="Places.codeReservation" /></th>
                      <th scope="col"><Trans i18nKey="Users.UserId" /></th>
                      <th scope="col"><Trans i18nKey="Login.Username" /></th>
                      <th scope="col"><Trans i18nKey="personsNb" /></th>
                      <th scope="col"><Trans i18nKey="Reservations.date" /></th>
                      <th scope="col"><Trans i18nKey="Reservations.time" /></th>
                      <th scope="col"><Trans i18nKey="Reservations.paid" /></th>

                      <th scope="col"><Trans i18nKey="Reservations.created_at" /></th>
                      <th scope="col"><Trans i18nKey="Reservations.modified_at" /></th>
                    </tr>
                  </thead>
                  <tbody>


                    {this.props.PlaceReservationserr === "" ?

                      Object.values(this.props.PlaceReservations).map((placeData) => {
                        var cre = placeData.createdAt;
                        var upd = placeData.updatedAt;
                      
                        var date = new Date(cre);
                        var year = date.getFullYear();
                        var month = date.getMonth() + 1;
                        var dt = date.getDate();

                        if (dt < 10) {
                          dt = '0' + dt;
                        }
                        if (month < 10) {
                          month = '0' + month;
                        }
                        var dateUp = new Date(upd);
                        var yearUp = dateUp.getFullYear();
                        var monthUp = dateUp.getMonth() + 1;
                        var dtUp = dateUp.getDate();

                        if (dtUp < 10) {
                          dtUp = '0' + dtUp;
                        }
                        if (monthUp < 10) {
                          monthUp = '0' + monthUp;
                        }
                        if (localStorage.getItem('i18nextLng') === "en")

                          return <tr key={placeData._id} id={placeData._id}>
                            <td>{placeData.place_name}</td>
                            <td>{placeData.codeReservation}</td>
                            <td>{placeData.user_id}</td>
                            <td><Link to={`/user/${placeData.user_id}`}>{placeData.name}</Link></td>
                            <td>{placeData.personsNb}</td>
                            <td>{placeData.date}</td>
                            {(placeData.bookingSlot === undefined) ? <td>{placeData.start_time} - {placeData.end_time}</td> : <td> {placeData.bookingSlot} </td>}
                            {placeData.paid === "free" ?
                              <td><Badge pill color="secondary" >{placeData.paid} </Badge> </td>
                              : <td><Badge pill color={placeData.paid === "no" ? "danger" : "success"} >{placeData.paid} </Badge> </td>
                            }

                            <td>{dt + '-' + month + '-' + year}</td>
                            <td>{dtUp + '-' + monthUp + '-' + yearUp}</td>
                          </tr>
                        return <tr key={placeData._id} id={placeData._id}>
                          <td>{placeData.place_ARname}</td>
                          <td>{placeData.codeReservation}</td>
                          <td>{placeData.user_id}</td>
                          <td>{placeData.personsNb}</td>
                          <td>{placeData.date}</td>
                          {(placeData.bookingSlot === undefined) ? <td>{placeData.start_time} - {placeData.end_time}</td> : <td> {placeData.bookingSlot} </td>}
                          {placeData.paid === "free" ?
                            <td><Badge pill color="secondary" >مجاني</Badge> </td>
                            : <td><Badge pill color={placeData.paid === "no" ? "danger" : "success"} >{placeData.paid === "no" ? "لا" : "نعم"} </Badge> </td>
                          }

                          <td>{dt + '-' + month + '-' + year}</td>
                          <td>{dtUp + '-' + monthUp + '-' + yearUp}</td>

                        </tr>
                      })
                      : <tr><td><Trans i18nKey="Reservations.noReservation" /></td></tr>}


                  </tbody>
                </Table>
                <Pagination onChange={this.onChange} showTitle={false} current={this.state.current} total={this.props.PlaceReserNbr} />

                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button"
                  table="table-to-xlsplace"
                  filename="Places_Reservations"
                  sheet="Events_Reservations"
                  buttonText={localStorage.getItem('i18nextLng') === "en" ? "Download as XLS" : "تحميل أكسيل"} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

//export default Reservations;
const mapStateToProps = state => {
  return {
    PlaceReservations: state.PlaceReservations.PlaceReservations,
    PlaceReserNbr: state.PlaceReserNbr.PlaceReserNbr,
    isLoading: state.ui.isLoading,
    PlaceReservationserr: state.PlaceReservationserr.PlaceReservationserr
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPlaceReservations: (data) => dispatch(getPlaceReservations(data))
  };
};


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PlaceReservations);