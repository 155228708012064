import { USERS_DATA } from "../../actions/actionTypes";
import { USER_DATA } from "../../actions/actionTypes";
import { USER_EDIT } from "../../actions/actionTypes";
import { LIST_ERR } from "../../actions/actionTypes";
import { EDIT_ERR } from "../../actions/actionTypes";
import { STATUS_EDIT } from "../../actions/actionTypes";
import { USERS_TOKEN_DATA, USERS_NBR, USERS_LIST_NB, USER_DATA_DETAILS } from "../../actions/actionTypes";
import { LIST_TOKEN_ERR } from "../../actions/actionTypes";

const initialState = {
  usersData: [],
  userData: [],
  usersTokensData: [],
  usersNbr: 0,
  usersListNbr: 0,
  edituser: [],
  Editerr: '',
  Listerr: '',
  editstatus: '',
  ListTokenerr: '',
  userDataDetails: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USERS_DATA:
      return {
        ...state,
        usersData: action.usersData
      };
    case USER_DATA:
      return {
        ...state,
        userData: action.userData
      };
    case USER_EDIT:
      return {
        ...state,
        edituser: action.edituser
      };
    case LIST_ERR:
      return {
        ...state,
        Listerr: action.Listerr
      };
    case EDIT_ERR:
      return {
        ...state,
        Editerr: action.Editerr
      };
    case STATUS_EDIT:
      return {
        ...state,
        editstatus: action.editstatus
      };
    case USERS_LIST_NB:
      return {
        ...state,
        usersListNbr: action.usersListNbr
      };
    case USERS_TOKEN_DATA:
      return {
        ...state,
        usersTokensData: action.usersTokensData
      };
    case USERS_NBR:
      return {
        ...state,
        usersNbr: action.usersNbr
      };
    case USER_DATA_DETAILS:
      return {
        ...state,
        userDataDetails: action.userDataDetails
      };

    case LIST_TOKEN_ERR:
      return {
        ...state,
        ListTokenerr: action.ListTokenerr
      };
    default:
      return state;
  }
};

export default reducer;
