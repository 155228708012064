import { BEACONS_DATA } from "../../actions/actionTypes";
import { BEACON_DATA, BEACONS_NB } from "../../actions/actionTypes";
import { LIST_BEACONS_ERR } from "../../actions/actionTypes";
import { CREATE_BEACON_EXIST , EDIT_BEACON_EXIST } from "../../actions/actionTypes";
import { BEACON_EDIT } from "../../actions/actionTypes";
import { EDIT_BEACON_ERR } from "../../actions/actionTypes";
import { STATUS_BEACON_EDIT } from "../../actions/actionTypes";

import { DELETE_BEACON_ERR } from "../../actions/actionTypes";
import { STATUS_BEACON_DELETE } from "../../actions/actionTypes";
import { BEACON_DELETE } from "../../actions/actionTypes";

import { CREATE_BEACON_ERR } from "../../actions/actionTypes";
import { STATUS_BEACON_CREATE } from "../../actions/actionTypes";
import { BEACON_CREATE } from "../../actions/actionTypes";
import { BEACONS_LIST } from "../../actions/actionTypes";
import { FIND_BEACON_ERR } from "../../actions/actionTypes";
import { FIND_BEACON } from "../../actions/actionTypes";
const initialState = {
    beaconsData: [],
    beaconData: [],
    beaconsNb : 0,
    editbeacon: [],
    EditBeaconerr:'',
    listBeaconsErr:'',
    editBeaconstatus: '',
    deleteBeacon: [],
    DeleteBeaconerr:'',
    deleteBeaconstatus: '',
    CreateBeacon: [],
    CreateBeaconerr:'',
    CreateBeaconstatus: '',
    beaconsDataList:[],
    CreateBeaconExist:'',
    EditBeaconExist:'',
    findBeacon: [],
    findBeaconErr: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) { 
    case CREATE_BEACON_EXIST:
    return {
      ...state,
      CreateBeaconExist: action.CreateBeaconExist
    };
    case EDIT_BEACON_EXIST:
      return {
        ...state,
        EditBeaconExist: action.EditBeaconExist
      };
    case BEACONS_LIST:
    return {
      ...state,
      beaconsDataList: action.beaconsDataList
    };
    case BEACONS_DATA:
      return {
        ...state,
        beaconsData: action.beaconsData
      };
    case BEACON_DATA:
      return {
        ...state,
        beaconData: action.beaconData
      };
    case BEACONS_NB:
      return {
        ...state,
        beaconsNb: action.beaconsNb
      };
    case BEACON_EDIT:
      return {
        ...state,
        editbeacon: action.editbeacon
      };
    case LIST_BEACONS_ERR:
      return {
        ...state,
        listBeaconsErr: action.listBeaconsErr
      };
    case EDIT_BEACON_ERR:
      return {
        ...state,
        EditBeaconerr: action.EditBeaconerr
      };
    case STATUS_BEACON_EDIT:
      return {
        ...state,
        editBeaconstatus: action.editBeaconstatus
      };
    case DELETE_BEACON_ERR:
      return {
        ...state,
        DeleteBeaconerr: action.DeleteBeaconerr
      };
    case STATUS_BEACON_DELETE:
      return {
        ...state,
        deleteBeaconstatus: action.deleteBeaconstatus
      };
    case BEACON_DELETE:
      return {
        ...state,
        deleteBeacon: action.deleteBeacon
      };
    
    case CREATE_BEACON_ERR:
      return {
        ...state,
        CreateBeaconerr: action.CreateBeaconerr
      };
    case STATUS_BEACON_CREATE:
      return {
        ...state, 
        CreateBeaconstatus: action.CreateBeaconstatus
      };
    case BEACON_CREATE:
      return {
        ...state,
        CreateBeacon: action.CreateBeacon
      };
      case FIND_BEACON_ERR:
        return {
          ...state,
          findBeaconErr: action.findBeaconErr
        };
      case FIND_BEACON:
        return {
          ...state,
          findBeacon: action.findBeacon
        };
    default:
      return state;
  }
};

export default reducer;
