import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import uiReducer from "./reducers/ui";

import adminSign from "./reducers/admin/adminReducer";
import adminAuth from "./reducers/admin/adminReducer";
import editAdminStatus from "./reducers/admin/adminReducer";
import editADMIN from "./reducers/admin/adminReducer";
import EditAdminerr from "./reducers/admin/adminReducer";
import adminsListNbr from "./reducers/admin/adminReducer";
import adminsData from "./reducers/admin/adminReducer";
import ListAdminserr from "./reducers/admin/adminReducer";
import Autherr from "./reducers/admin/adminReducer";
import Signerr from "./reducers/admin/adminReducer";
import getAdminData from "./reducers/admin/adminReducer";
import getAdminErr from "./reducers/admin/adminReducer";
import unblockAdminerr from "./reducers/admin/adminReducer";
import blockAdminerr from "./reducers/admin/adminReducer";
import unblockAdminStatus from "./reducers/admin/adminReducer";
import blockAdminStatus from "./reducers/admin/adminReducer";
import usersData from "./reducers/users/usersReducer";
import usersNbr from "./reducers/users/usersReducer";
import userData from "./reducers/users/usersReducer";
import userDataDetails from "./reducers/users/usersReducer";
import edituser from "./reducers/users/usersReducer";
import Listerr from "./reducers/users/usersReducer";
import Editerr from "./reducers/users/usersReducer";
import editstatus from "./reducers/users/usersReducer";
import usersTokensData from "./reducers/users/usersReducer";
import usersListNbr from "./reducers/users/usersReducer";
import ListTokenerr from "./reducers/users/usersReducer";

//events
import ActeventsData from "./reducers/events/eventsReducer";
import listActEventsErr from "./reducers/events/eventsReducer";
import eventsData from "./reducers/events/eventsReducer";
import eventData from "./reducers/events/eventsReducer";
import editevent from "./reducers/events/eventsReducer";
import listEventsErr from "./reducers/events/eventsReducer";
import EditEventerr from "./reducers/events/eventsReducer";
import editEventstatus from "./reducers/events/eventsReducer";
import EventsNbr from "./reducers/events/eventsReducer";
import deleteEvent from "./reducers/events/eventsReducer";
import DeleteEventerr from "./reducers/events/eventsReducer";
import deleteEventstatus from "./reducers/events/eventsReducer";

import CreateEvent from "./reducers/events/eventsReducer";
import CreateEventerr from "./reducers/events/eventsReducer";
import CreateEventstatus from "./reducers/events/eventsReducer";

//Subject
import CreateSubject from "./reducers/Subjects/SubjectReducer";
import CreateSubjecterr from "./reducers/Subjects/SubjectReducer";
import CreateSubjectstatus from "./reducers/Subjects/SubjectReducer";
import subjectsData from "./reducers/Subjects/SubjectReducer";
import listSubjectsErr from "./reducers/Subjects/SubjectReducer";
import subjectsNb from "./reducers/Subjects/SubjectReducer";
import deleteSubjectstatus from "./reducers/Subjects/SubjectReducer";
import DeleteSubjecterr from "./reducers/Subjects/SubjectReducer";
import deleteSubject from "./reducers/Subjects/SubjectReducer";
import editSubjectStatus from "./reducers/Subjects/SubjectReducer";
import editsubject from "./reducers/Subjects/SubjectReducer";
import EditSubjecterr from "./reducers/Subjects/SubjectReducer";
import subjectData from "./reducers/Subjects/SubjectReducer";

//Merchant
import ActmerchantsData from "./reducers/merchants/merchantsReducer";
import listActMerchantsErr from "./reducers/merchants/merchantsReducer";
import merchantsData from "./reducers/merchants/merchantsReducer";
import merchantData from "./reducers/merchants/merchantsReducer";
import editmerchant from "./reducers/merchants/merchantsReducer";
import listMerchantsErr from "./reducers/merchants/merchantsReducer";
import EditMerchanterr from "./reducers/merchants/merchantsReducer";
import editMerchantstatus from "./reducers/merchants/merchantsReducer";
import MerchantsNbr from "./reducers/merchants/merchantsReducer";
import deleteMerchant from "./reducers/merchants/merchantsReducer";
import DeleteMerchanterr from "./reducers/merchants/merchantsReducer";
import deleteMerchantstatus from "./reducers/merchants/merchantsReducer";

import CreateMerchant from "./reducers/merchants/merchantsReducer";
import CreateMerchanterr from "./reducers/merchants/merchantsReducer";
import CreateMerchantstatus from "./reducers/merchants/merchantsReducer";

//beacons
import beaconsData from "./reducers/beacons/beaconsReducer";
import beaconsNb from "./reducers/beacons/beaconsReducer";
import beaconData from "./reducers/beacons/beaconsReducer";
import editbeacon from "./reducers/beacons/beaconsReducer";
import listBeaconsErr from "./reducers/beacons/beaconsReducer";
import EditBeaconerr from "./reducers/beacons/beaconsReducer";
import editBeaconstatus from "./reducers/beacons/beaconsReducer";
import CreateBeaconExist from "./reducers/beacons/beaconsReducer";
import EditBeaconExist from "./reducers/beacons/beaconsReducer";

import deleteBeacon from "./reducers/beacons/beaconsReducer";
import DeleteBeaconerr from "./reducers/beacons/beaconsReducer";
import deleteBeaconstatus from "./reducers/beacons/beaconsReducer";
import beaconsDataList from "./reducers/beacons/beaconsReducer";
import CreateBeacon from "./reducers/beacons/beaconsReducer";
import CreateBeaconerr from "./reducers/beacons/beaconsReducer";
import CreateBeaconstatus from "./reducers/beacons/beaconsReducer";
import findBeaconErr from "./reducers/beacons/beaconsReducer";
import findBeacon from "./reducers/beacons/beaconsReducer";
//FAQ
import CreateFaq from "./reducers/FAQs/FAQReducer";
import CreateFaqerr from "./reducers/FAQs/FAQReducer";
import CreateFaqstatus from "./reducers/FAQs/FAQReducer";
import faqsData from "./reducers/FAQs/FAQReducer";
import listFaqsErr from "./reducers/FAQs/FAQReducer";
import faqsNb from "./reducers/FAQs/FAQReducer";
import deleteFaqstatus from "./reducers/FAQs/FAQReducer";
import DeleteFaqerr from "./reducers/FAQs/FAQReducer";
import deleteFaq from "./reducers/FAQs/FAQReducer";
import editFaqStatus from "./reducers/FAQs/FAQReducer";
import editfaq from "./reducers/FAQs/FAQReducer";
import EditFaqerr from "./reducers/FAQs/FAQReducer";
import faqData from "./reducers/FAQs/FAQReducer";
import editFaqDescStatus from "./reducers/FAQs/FAQReducer";
import editfaqDesc from "./reducers/FAQs/FAQReducer";
import EditFaqDescerr from "./reducers/FAQs/FAQReducer";
import faqDescData from "./reducers/FAQs/FAQReducer";

//places
import placesData from "./reducers/places/placesReducer";
import placeData from "./reducers/places/placesReducer";
import editplace from "./reducers/places/placesReducer";
import listPlacesErr from "./reducers/places/placesReducer";
import EditPlaceerr from "./reducers/places/placesReducer";
import editPlacestatus from "./reducers/places/placesReducer";
import PlacesNbr from "./reducers/places/placesReducer";
import deletePlace from "./reducers/places/placesReducer";
import DeletePlaceerr from "./reducers/places/placesReducer";
import deletePlacestatus from "./reducers/places/placesReducer";
import parkingdata from "./reducers/places/placesReducer";
import CreatePlace from "./reducers/places/placesReducer";
import CreatePlaceerr from "./reducers/places/placesReducer";
import CreatePlacestatus from "./reducers/places/placesReducer";
import clonePlaceErr from "./reducers/places/placesReducer";
import clonePlaceData from "./reducers/places/placesReducer";
import listActPlacesErr from "./reducers/places/placesReducer";
import ActplacesData from "./reducers/places/placesReducer";
import listActPlacesFacErr from "./reducers/places/placesReducer";
import ActplacesFacData from "./reducers/places/placesReducer";
import findPlaceErr from "./reducers/places/placesReducer";
import findPlace from "./reducers/places/placesReducer";
import beadata from "./reducers/places/placesReducer";
import editTimingSuccess from "./reducers/places/placesReducer";
//categories
import CategoriesData from "./reducers/categories/categoriesReducer";
import categoryData from "./reducers/categories/categoriesReducer";
import editcategory from "./reducers/categories/categoriesReducer";
import EditCategoryerr from "./reducers/categories/categoriesReducer";
import listCategoriesErr from "./reducers/categories/categoriesReducer";
import editCategoryStatus from "./reducers/categories/categoriesReducer";
import deleteCategory from "./reducers/categories/categoriesReducer";
import DeleteCategoryerr from "./reducers/categories/categoriesReducer";
import deleteCategoryStatus from "./reducers/categories/categoriesReducer";
import CreateCategory from "./reducers/categories/categoriesReducer";
import CreateCategoryerr from "./reducers/categories/categoriesReducer";
import CreateCategoryStatus from "./reducers/categories/categoriesReducer";
import CategoriesNbr from "./reducers/categories/categoriesReducer";
import ActCategoriesData from "./reducers/categories/categoriesReducer";
import listActCategoriesErr from "./reducers/categories/categoriesReducer";

//facilities
import FacilitiesData from "./reducers/facilities/facilitiesReducer";
import facilityData from "./reducers/facilities/facilitiesReducer";
import editfacility from "./reducers/facilities/facilitiesReducer";
import EditFacilityerr from "./reducers/facilities/facilitiesReducer";
import listFacilitiesErr from "./reducers/facilities/facilitiesReducer";
import editFacilityStatus from "./reducers/facilities/facilitiesReducer";
import deleteFacility from "./reducers/facilities/facilitiesReducer";
import DeleteFacilityerr from "./reducers/facilities/facilitiesReducer";
import deleteFacilityStatus from "./reducers/facilities/facilitiesReducer";
import CreateFacility from "./reducers/facilities/facilitiesReducer";
import CreateFacilityerr from "./reducers/facilities/facilitiesReducer";
import CreateFacilityStatus from "./reducers/facilities/facilitiesReducer";
import FacilitiesNbr from "./reducers/facilities/facilitiesReducer";
import listActFacilitiesErr from "./reducers/facilities/facilitiesReducer";
import ActFacilitiesData from "./reducers/facilities/facilitiesReducer";


//banners
import bannersData from "./reducers/banners/bannerReducer";
import bannerData from "./reducers/banners/bannerReducer";
import editbanner from "./reducers/banners/bannerReducer";
import listBannersErr from "./reducers/banners/bannerReducer";
import EditBannererr from "./reducers/banners/bannerReducer";
import editBannerStatus from "./reducers/banners/bannerReducer";
import bannersNbr from "./reducers/banners/bannerReducer";
import deleteBanner from "./reducers/banners/bannerReducer";
import DeleteBannererr from "./reducers/banners/bannerReducer";
import deleteBannerStatus from "./reducers/banners/bannerReducer";

import CreateBanner from "./reducers/banners/bannerReducer";
import CreateBannererr from "./reducers/banners/bannerReducer";
import CreateBannerStatus from "./reducers/banners/bannerReducer";

import EditBannerAvatarerr from "./reducers/banners/bannerReducer";
import editbannerAvatar from "./reducers/banners/bannerReducer";
import editBannerAvatarStatus from "./reducers/banners/bannerReducer";

//photos
import CreatePhoto from "./reducers/Medias/photosReducer";
import CreatePhotoerr from "./reducers/Medias/photosReducer";
import CreatePhotoStatus from "./reducers/Medias/photosReducer";
import PhotosData from "./reducers/Medias/photosReducer";
import listPhotosErr from "./reducers/Medias/photosReducer";
import deletePhoto from "./reducers/Medias/photosReducer";
import DeletePhotoerr from "./reducers/Medias/photosReducer";
import deletePhotostatus from "./reducers/Medias/photosReducer";
import photoErr from "./reducers/Medias/photosReducer";
import photoData from "./reducers/Medias/photosReducer";
import PhotosNbr from "./reducers/Medias/photosReducer";
import EditPhotostatus from "./reducers/Medias/photosReducer";
import EditPhoto from "./reducers/Medias/photosReducer";
import EditPhotoerr from "./reducers/Medias/photosReducer";

//VIDEOS
import CreateVideo from "./reducers/Medias/videosReducer";
import CreateVideoerr from "./reducers/Medias/videosReducer";
import CreateVideoStatus from "./reducers/Medias/videosReducer";
import VideosNbr from "./reducers/Medias/videosReducer";
import VideosData from "./reducers/Medias/videosReducer";
import listVideosErr from "./reducers/Medias/videosReducer";
import deleteVideo from "./reducers/Medias/videosReducer";
import DeleteVideoerr from "./reducers/Medias/videosReducer";
import deleteVideostatus from "./reducers/Medias/videosReducer";
import videoErr from "./reducers/Medias/videosReducer";
import videoData from "./reducers/Medias/videosReducer";
import EditVideostatus from "./reducers/Medias/videosReducer";
import EditVideo from "./reducers/Medias/videosReducer";
import EditVideoerr from "./reducers/Medias/videosReducer";

//notifications
import notificationsData from "./reducers/notifications/notificationsReducer";
import notificationData from "./reducers/notifications/notificationsReducer";
import editnotification from "./reducers/notifications/notificationsReducer";
import listnotificationsErr from "./reducers/notifications/notificationsReducer";
import Editnotificationerr from "./reducers/notifications/notificationsReducer";
import editnotificationStatus from "./reducers/notifications/notificationsReducer";

import deletenotification from "./reducers/notifications/notificationsReducer";
import Deletenotificationerr from "./reducers/notifications/notificationsReducer";
import deletenotificationStatus from "./reducers/notifications/notificationsReducer";

import Createnotification from "./reducers/notifications/notificationsReducer";
import Createnotificationerr from "./reducers/notifications/notificationsReducer";
import CreatenotificationStatus from "./reducers/notifications/notificationsReducer";
import notificationsNbr from "./reducers/notifications/notificationsReducer";
import sendnotification from "./reducers/notifications/notificationsReducer";
import Sendnotificationerr from "./reducers/notifications/notificationsReducer";
import sendnotificationStatus from "./reducers/notifications/notificationsReducer";

//reservations
import EventReservations from "./reducers/reservations/reservationsReducer";
import EventReservationsNbr from "./reducers/reservations/reservationsReducer";
import EventReservationserr from "./reducers/reservations/reservationsReducer";
import PlaceReservations from "./reducers/reservations/reservationsReducer";
import PlaceReservationserr from "./reducers/reservations/reservationsReducer";
import PlaceReserNbr from "./reducers/reservations/reservationsReducer";
import ByEventReservations from "./reducers/reservations/reservationsReducer";
import ByEventReservationserr from "./reducers/reservations/reservationsReducer";
import ByPlaceReservations from "./reducers/reservations/reservationsReducer";
import ByPlaceReservationserr from "./reducers/reservations/reservationsReducer";
import EventReserNbr from "./reducers/reservations/reservationsReducer";
import PlaceReservationsNbr from "./reducers/reservations/reservationsReducer";
import GetEventReservations from "./reducers/reservations/reservationsReducer";
import GetEventReservationserr from "./reducers/reservations/reservationsReducer";
import GetPlaceReservations from "./reducers/reservations/reservationsReducer";
import GetPlaceReservationserr from "./reducers/reservations/reservationsReducer";
//News
import getNewsErr from "./reducers/News/NewsReducer";
import getNewsData from "./reducers/News/NewsReducer";
import deleteNewstatus from "./reducers/News/NewsReducer";
import deleteNew from "./reducers/News/NewsReducer";
import DeleteNewerr from "./reducers/News/NewsReducer";
import NewsNbr from "./reducers/News/NewsReducer";
import NewsData from "./reducers/News/NewsReducer";
import listNewsErr from "./reducers/News/NewsReducer";
import CreateNewStatus from "./reducers/News/NewsReducer";
import CreateNewerr from "./reducers/News/NewsReducer";
import CreateNew from "./reducers/News/NewsReducer";
import EditNewerr from "./reducers/News/NewsReducer";
import EditNew from "./reducers/News/NewsReducer";
import EditNewstatus from "./reducers/News/NewsReducer";
import ActNewsData from "./reducers/News/NewsReducer";
import listActNewsErr from "./reducers/News/NewsReducer";
//Newscast
import addNewscastErr from "./reducers/Newscast/NewscastReducer";
import NewscastData from "./reducers/Newscast/NewscastReducer";
import getNewscastData from "./reducers/Newscast/NewscastReducer";
import getNewscastErr from "./reducers/Newscast/NewscastReducer";
import NewscastStatus from "./reducers/Newscast/NewscastReducer";


//parking
import parkingsData from "./reducers/parkings/parkingsReducer";
import parkingData from "./reducers/parkings/parkingsReducer";
import editparking from "./reducers/parkings/parkingsReducer";
import listParkingsErr from "./reducers/parkings/parkingsReducer";
import EditParkingerr from "./reducers/parkings/parkingsReducer";
import editParkingstatus from "./reducers/parkings/parkingsReducer";
import ParkingsNbr from "./reducers/parkings/parkingsReducer";
import deleteparking from "./reducers/parkings/parkingsReducer";
import DeleteParkingerr from "./reducers/parkings/parkingsReducer";
import deleteParkingstatus from "./reducers/parkings/parkingsReducer";

import CreateParking from "./reducers/parkings/parkingsReducer";
import CreateParkingerr from "./reducers/parkings/parkingsReducer";
import CreateParkingstatus from "./reducers/parkings/parkingsReducer";

import listActParkingsErr from "./reducers/parkings/parkingsReducer";
import ActparkingsData from "./reducers/parkings/parkingsReducer";
import ActParkingsDetailsErr from "./reducers/parkings/parkingsReducer";
import ActParkingsDetailsData from "./reducers/parkings/parkingsReducer";

import contactData from "./reducers/contacts/contactReducer";
import editcontact from "./reducers/contacts/contactReducer";
import EditContacterr from "./reducers/contacts/contactReducer";
import editContactStatus from "./reducers/contacts/contactReducer";
//TIMING
import CreateTiming from "./reducers/Timings/TimingReducer";
import CreateTimingerr from "./reducers/Timings/TimingReducer";
import CreateTimingstatus from "./reducers/Timings/TimingReducer";
import listTimingsErr from "./reducers/Timings/TimingReducer";
import timingsData from "./reducers/Timings/TimingReducer";
import timingsNb from "./reducers/Timings/TimingReducer";
import deleteTimingstatus from "./reducers/Timings/TimingReducer";
import DeleteTimingerr from "./reducers/Timings/TimingReducer";
import deleteTiming from "./reducers/Timings/TimingReducer";
import editTimingStatus from "./reducers/Timings/TimingReducer";
import edittiming from "./reducers/Timings/TimingReducer";
import EditTimingerr from "./reducers/Timings/TimingReducer";
import timingData from "./reducers/Timings/TimingReducer";
import editAboutDescStatus from "./reducers/Timings/TimingReducer";
import editaboutDesc from "./reducers/Timings/TimingReducer";
import EditAboutDescerr from "./reducers/Timings/TimingReducer";
import aboutDescData from "./reducers/Timings/TimingReducer";

//REGULATION
import CreateRegulation from "./reducers/Rules/RuleReducer";
import CreateRegulationerr from "./reducers/Rules/RuleReducer";
import CreateRegulationstatus from "./reducers/Rules/RuleReducer";
import regulationsData from "./reducers/Rules/RuleReducer";
import listRegulationsErr from "./reducers/Rules/RuleReducer";
import regulationsNb from "./reducers/Rules/RuleReducer";
import deleteRegulationstatus from "./reducers/Rules/RuleReducer";
import DeleteRegulationerr from "./reducers/Rules/RuleReducer";
import deleteRegulation from "./reducers/Rules/RuleReducer";
import editRegulationStatus from "./reducers/Rules/RuleReducer";
import editregulation from "./reducers/Rules/RuleReducer";
import EditRegulationerr from "./reducers/Rules/RuleReducer";
import regulationData from "./reducers/Rules/RuleReducer";
import editRulesStatus from "./reducers/Rules/RuleReducer";
import editRules from "./reducers/Rules/RuleReducer";
import EditRuleserr from "./reducers/Rules/RuleReducer";
import RulesData from "./reducers/Rules/RuleReducer";

//DASHBOARD
import thingsData from "./reducers/dashboard/dashboardReducer";
//import listThingsErr from  "./reducers/dashboard/dashboardReducer";

const rootReducer = combineReducers({
  ui: uiReducer,
  userData: userData,
  usersData: usersData,
  userDataDetails: userDataDetails,
  edituser: edituser,
  usersTokensData: usersTokensData,
  ListTokenerr: ListTokenerr,
  editstatus: editstatus,
  Editerr: Editerr,
  Listerr: Listerr,
  usersNbr: usersNbr,
  usersListNbr: usersListNbr,
  adminSign: adminSign,
  adminAuth: adminAuth,
  editAdminStatus: editAdminStatus,
  editADMIN: editADMIN,
  EditAdminerr: EditAdminerr,
  adminsListNbr: adminsListNbr,
  adminsData: adminsData,
  ListAdminserr: ListAdminserr,
  getAdminData: getAdminData,
  getAdminErr: getAdminErr,
  unblockAdminerr: unblockAdminerr,
  blockAdminerr: blockAdminerr,
  unblockAdminStatus: unblockAdminStatus,
  blockAdminStatus: blockAdminStatus,
  Autherr: Autherr,
  Signerr: Signerr,
  ActeventsData: ActeventsData,
  listActEventsErr: listActEventsErr,
  EventsNbr: EventsNbr,
  eventsData: eventsData,
  eventData: eventData,
  editevent: editevent,
  EditEventerr: EditEventerr,
  listEventsErr: listEventsErr,
  editEventstatus: editEventstatus,
  deleteEvent: deleteEvent,
  DeleteEventerr: DeleteEventerr,
  deleteEventstatus: deleteEventstatus,
  CreateEvent: CreateEvent,
  CreateEventerr: CreateEventerr,
  CreateEventstatus: CreateEventstatus,

  ActmerchantsData: ActmerchantsData,
  listActMerchantsErr: listActMerchantsErr,
  MerchantsNbr: MerchantsNbr,
  merchantsData: merchantsData,
  merchantData: merchantData,
  editmerchant: editmerchant,
  EditMerchanterr: EditMerchanterr,
  listMerchantsErr: listMerchantsErr,
  editMerchantstatus: editMerchantstatus,
  deleteMerchant: deleteMerchant,
  DeleteMerchanterr: DeleteMerchanterr,
  deleteMerchantstatus: deleteMerchantstatus,
  CreateMerchant: CreateMerchant,
  CreateMerchanterr: CreateMerchanterr,
  CreateMerchantstatus: CreateMerchantstatus,
  beaconsData: beaconsData,
  beaconsDataList: beaconsDataList,
  beaconData: beaconData,
  beaconsNb: beaconsNb,
  findBeaconErr: findBeaconErr,
  findBeacon: findBeacon,
  editbeacon: editbeacon,
  EditBeaconerr: EditBeaconerr,
  listBeaconsErr: listBeaconsErr,
  editBeaconstatus: editBeaconstatus,
  deleteBeacon: deleteBeacon,
  DeleteBeaconerr: DeleteBeaconerr,
  deleteBeaconstatus: deleteBeaconstatus,
  CreateBeacon: CreateBeacon,
  CreateBeaconerr: CreateBeaconerr,
  CreateBeaconExist: CreateBeaconExist,
  EditBeaconExist: EditBeaconExist,
  CreateBeaconstatus: CreateBeaconstatus,
  placesData: placesData,
  placeData: placeData,
  editplace: editplace,
  findPlaceErr: findPlaceErr,
  findPlace: findPlace,
  beadata: beadata,
  parkingdata: parkingdata,
  EditPlaceerr: EditPlaceerr,
  listPlacesErr: listPlacesErr,
  editPlacestatus: editPlacestatus,
  deletePlace: deletePlace,
  DeletePlaceerr: DeletePlaceerr,
  deletePlacestatus: deletePlacestatus,
  CreatePlace: CreatePlace,
  CreatePlaceerr: CreatePlaceerr,
  CreatePlacestatus: CreatePlacestatus,
  PlacesNbr: PlacesNbr,
  clonePlaceErr: clonePlaceErr,
  clonePlaceData: clonePlaceData,
  CategoriesNbr: CategoriesNbr,
  CategoriesData: CategoriesData,
  categoryData: categoryData,
  listActCategoriesErr: listActCategoriesErr,
  ActCategoriesData: ActCategoriesData,
  editcategory: editcategory,
  listActFacilitiesErr: listActFacilitiesErr,
  ActFacilitiesData: ActFacilitiesData,
  EditCategoryerr: EditCategoryerr,
  listCategoriesErr: listCategoriesErr,
  editCategoryStatus: editCategoryStatus,
  deleteCategory: deleteCategory,
  DeleteCategoryerr: DeleteCategoryerr,
  deleteCategoryStatus: deleteCategoryStatus,
  CreateCategory: CreateCategory,
  CreateCategoryerr: CreateCategoryerr,
  CreateCategoryStatus: CreateCategoryStatus,
  FacilitiesNbr: FacilitiesNbr,
  FacilitiesData: FacilitiesData,
  facilityData: facilityData,
  editfacility: editfacility,
  EditFacilityerr: EditFacilityerr,
  listFacilitiesErr: listFacilitiesErr,
  editFacilityStatus: editFacilityStatus,
  deleteFacility: deleteFacility,
  DeleteFacilityerr: DeleteFacilityerr,
  deleteFacilityStatus: deleteFacilityStatus,
  CreateFacility: CreateFacility,
  CreateFacilityerr: CreateFacilityerr,
  CreateFacilityStatus: CreateFacilityStatus,
  listActPlacesErr: listActPlacesErr,
  ActplacesData: ActplacesData,
  listActPlacesFacErr: listActPlacesFacErr,
  ActplacesFacData: ActplacesFacData,
  bannersNbr: bannersNbr,
  EventReservationsNbr: EventReservationsNbr,
  bannersData: bannersData,
  bannerData: bannerData,
  editbanner: editbanner,
  EditBannererr: EditBannererr,
  listBannersErr: listBannersErr,
  editBannerStatus: editBannerStatus,
  deleteBanner: deleteBanner,
  DeleteBannererr: DeleteBannererr,
  deleteBannerStatus: deleteBannerStatus,
  CreateBanner: CreateBanner,
  CreateBannererr: CreateBannererr,
  CreateBannerStatus: CreateBannerStatus,
  EditBannerAvatarerr: EditBannerAvatarerr,
  editbannerAvatar: editbannerAvatar,
  editBannerAvatarStatus: editBannerAvatarStatus,

  CreatePhoto: CreatePhoto,
  CreatePhotoerr: CreatePhotoerr,
  CreatePhotoStatus: CreatePhotoStatus,
  PhotosData: PhotosData,
  PhotosNbr: PhotosNbr,
  listPhotosErr: listPhotosErr,
  deletePhoto: deletePhoto,
  DeletePhotoerr: DeletePhotoerr,
  deletePhotostatus: deletePhotostatus,
  photoErr: photoErr,
  photoData: photoData,
  EditPhotoerr: EditPhotoerr,
  EditPhoto: EditPhoto,
  EditPhotostatus: EditPhotostatus,

  CreateVideo: CreateVideo,
  CreateVideoerr: CreateVideoerr,
  CreateVideoStatus: CreateVideoStatus,
  VideosData: VideosData,
  listVideosErr: listVideosErr,
  deleteVideo: deleteVideo,
  DeleteVideoerr: DeleteVideoerr,
  deleteVideostatus: deleteVideostatus,
  videoErr: videoErr,
  videoData: videoData,
  VideosNbr: VideosNbr,
  EditVideoerr: EditVideoerr,
  EditVideo: EditVideo,
  EditVideostatus: EditVideostatus,

  notificationsData: notificationsData,
  notificationData: notificationData,
  editnotification: editnotification,
  listnotificationsErr: listnotificationsErr,
  Editnotificationerr: Editnotificationerr,
  editnotificationStatus: editnotificationStatus,
  deletenotification: deletenotification,
  Deletenotificationerr: Deletenotificationerr,
  deletenotificationStatus: deletenotificationStatus,
  Createnotification: Createnotification,
  Createnotificationerr: Createnotificationerr,
  CreatenotificationStatus: CreatenotificationStatus,
  sendnotification: sendnotification,
  Sendnotificationerr: Sendnotificationerr,
  notificationsNbr: notificationsNbr,
  sendnotificationStatus: sendnotificationStatus,
  PlaceReserNbr: PlaceReserNbr,
  EventReservations: EventReservations,
  EventReservationserr: EventReservationserr,
  PlaceReservations: PlaceReservations,
  PlaceReservationserr: PlaceReservationserr,
  ByEventReservations: ByEventReservations,
  ByEventReservationserr: ByEventReservationserr,
  ByPlaceReservations: ByPlaceReservations,
  ByPlaceReservationserr: ByPlaceReservationserr,
  GetEventReservations: GetEventReservations,
  GetEventReservationserr: GetEventReservationserr,
  GetPlaceReservations: GetPlaceReservations,
  GetPlaceReservationserr: GetPlaceReservationserr,
  EventReserNbr: EventReserNbr,
  PlaceReservationsNbr: PlaceReservationsNbr,
  editTimingSuccess: editTimingSuccess,
  getNewsErr: getNewsErr,
  getNewsData: getNewsData,
  deleteNewstatus: deleteNewstatus,
  deleteNew: deleteNew,
  DeleteNewerr: DeleteNewerr,
  NewsNbr: NewsNbr,
  NewsData: NewsData,
  listNewsErr: listNewsErr,
  CreateNewStatus: CreateNewStatus,
  CreateNewerr: CreateNewerr,
  CreateNew: CreateNew,
  EditNewerr: EditNewerr,
  EditNew: EditNew,
  EditNewstatus: EditNewstatus,
  ActNewsData: ActNewsData,
  listActNewsErr: listActNewsErr,
  addNewscastErr: addNewscastErr,
  NewscastData: NewscastData,
  getNewscastData: getNewscastData,
  getNewscastErr: getNewscastErr,
  NewscastStatus: NewscastStatus,
  parkingsData: parkingsData,
  parkingData: parkingData,
  editparking: editparking,
  listParkingsErr: listParkingsErr,
  EditParkingerr: EditParkingerr,
  editParkingstatus: editParkingstatus,
  ParkingsNbr: ParkingsNbr,
  deleteparking: deleteparking,
  DeleteParkingerr: DeleteParkingerr,
  deleteParkingstatus: deleteParkingstatus,

  CreateParking: CreateParking,
  CreateParkingerr: CreateParkingerr,
  CreateParkingstatus: CreateParkingstatus,

  listActParkingsErr: listActParkingsErr,
  ActparkingsData: ActparkingsData,
  ActParkingsDetailsErr: ActParkingsDetailsErr,
  ActParkingsDetailsData: ActParkingsDetailsData,

  CreateFaqerr: CreateFaqerr,
  CreateFaq: CreateFaq,
  CreateFaqstatus: CreateFaqstatus,
  faqsNb: faqsNb,
  listFaqsErr: listFaqsErr,
  faqsData: faqsData,
  deleteFaqstatus: deleteFaqstatus,
  DeleteFaqerr: DeleteFaqerr,
  deleteFaq: deleteFaq,
  editFaqStatus: editFaqStatus,
  editfaq: editfaq,
  EditFaqerr: EditFaqerr,
  faqData: faqData,
  editFaqDescStatus: editFaqDescStatus,
  editfaqDesc: editfaqDesc,
  EditFaqDescerr: EditFaqDescerr,
  faqDescData: faqDescData,

  CreateRegulation: CreateRegulation,
  CreateRegulationerr: CreateRegulationerr,
  CreateRegulationstatus: CreateRegulationstatus,
  listRegulationsErr: listRegulationsErr,
  regulationsData: regulationsData,
  regulationsNb: regulationsNb,
  deleteRegulationstatus: deleteRegulationstatus,
  DeleteRegulationerr: DeleteRegulationerr,
  deleteRegulation: deleteRegulation,
  editRegulationStatus: editRegulationStatus,
  editregulation: editregulation,
  EditRegulationerr: EditRegulationerr,
  regulationData: regulationData,
  editRulesStatus: editRulesStatus,
  editRules: editRules,
  EditRuleserr: EditRuleserr,
  RulesData: RulesData,

  contactData: contactData,
  editcontact: editcontact,
  EditContacterr: EditContacterr,
  editContactStatus: editContactStatus,
  CreateSubjecterr: CreateSubjecterr,
  CreateSubject: CreateSubject,
  CreateSubjectstatus: CreateSubjectstatus,
  subjectsNb: subjectsNb,
  listSubjectsErr: listSubjectsErr,
  subjectsData: subjectsData,
  deleteSubjectstatus: deleteSubjectstatus,
  DeleteSubjecterr: DeleteSubjecterr,
  deleteSubject: deleteSubject,
  editSubjectStatus: editSubjectStatus,
  editsubject: editsubject,
  EditSubjecterr: EditSubjecterr,
  subjectData: subjectData,
  CreateTiming: CreateTiming,
  CreateTimingerr: CreateTimingerr,
  CreateTimingstatus: CreateTimingstatus,
  listTimingsErr: listTimingsErr,
  timingsData: timingsData,
  timingsNb: timingsNb,
  deleteTimingstatus: deleteTimingstatus,
  DeleteTimingerr: DeleteTimingerr,
  deleteTiming: deleteTiming,
  editTimingStatus: editTimingStatus,
  edittiming: edittiming,
  EditTimingerr: EditTimingerr,
  timingData: timingData,
  editAboutDescStatus: editAboutDescStatus,
  editaboutDesc: editaboutDesc,
  EditAboutDescerr: EditAboutDescerr,
  aboutDescData: aboutDescData,
  thingsData: thingsData,
  // listThingsErr:listThingsErr
});

let composeEnhancers = compose;
composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const Store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default Store;
