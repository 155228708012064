import { CREATE_SUBJECT_ERR } from "../../actions/actionTypes";
import { STATUS_SUBJECT_CREATE } from "../../actions/actionTypes";
import { SUBJECT_CREATE } from "../../actions/actionTypes";
import { SUBJECTS_DATA, SUBJECTS_NB, LIST_SUBJECTS_ERR } from "../../actions/actionTypes";
import { SUBJECT_DELETE, DELETE_SUBJECT_ERR, STATUS_SUBJECT_DELETE } from "../../actions/actionTypes";
import { SUBJECT_EDIT, EDIT_SUBJECT_ERR, STATUS_SUBJECT_EDIT, SUBJECT_DATA } from "../../actions/actionTypes";

const initialState = {
  CreateSubject: [],
  CreateSubjecterr: '',
  CreateSubjectstatus: '',
  listSubjectsErr: '',
  subjectsData: [],
  subjectsNb: '',
  deleteSubjectstatus: '',
  DeleteSubjecterr: '',
  deleteSubject: [],
  editSubjectStatus: '',
  editsubject: [],
  EditSubjecterr: '',
  subjectData: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SUBJECT_ERR:
      return {
        ...state,
        CreateSubjecterr: action.CreateSubjecterr
      };
    case STATUS_SUBJECT_CREATE:
      return {
        ...state,
        CreateSubjectstatus: action.CreateSubjectstatus
      };
    case SUBJECT_CREATE:
      return {
        ...state,
        CreateSubject: action.CreateSubject
      };
    case SUBJECTS_DATA:
      return {
        ...state,
        subjectsData: action.subjectsData
      };
    case LIST_SUBJECTS_ERR:
      return {
        ...state,
        listSubjectsErr: action.listSubjectsErr
      };
    case SUBJECTS_NB:
      return {
        ...state,
        subjectsNb: action.subjectsNb
      };
    case SUBJECT_DELETE:
      return {
        ...state,
        deleteSubject: action.deleteSubject
      };
    case DELETE_SUBJECT_ERR:
      return {
        ...state,
        DeleteSubjecterr: action.DeleteSubjecterr
      };
    case STATUS_SUBJECT_DELETE:
      return {
        ...state,
        deleteSubjectstatus: action.deleteSubjectstatus
      };
    case SUBJECT_EDIT:
      return {
        ...state,
        editsubject: action.editsubject
      };
    case EDIT_SUBJECT_ERR:
      return {
        ...state,
        EditSubjecterr: action.EditSubjecterr
      };
    case STATUS_SUBJECT_EDIT:
      return {
        ...state,
        editSubjectStatus: action.editSubjectStatus
      };
    case SUBJECT_DATA:
      return {
        ...state,
        subjectData: action.subjectData
      };

    default:
      return state;
  }
};

export default reducer;
