import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardFooter, Col, Row, Button, Form, FormGroup, Input, Label, Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { createBanner } from "../../../store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import notValid from '../../../assets/img/brand/notValid.png';
import history from "../../../history";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import Dropzone from "react-dropzone";
import { Trans } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";

class CreateBanner extends Component {

  constructor(props) {
    super(props)
    this.state = {
      description: '',
      title: '',
      ARdescription: '',
      ARtitle: '',
      active: '',
      last: '',
      img: null,
      files: true,
      emptyImg: '',
      imgAR: null,
      filesAR: true,
      activeTab: new Array(2).fill('1'),
      order: 0

    }
    this.toggle = this.toggle.bind(this);
    this.handleChangeActive = this.handleChangeActive.bind(this);
    this.handleChangelast = this.handleChangelast.bind(this);
  }
  toggle(tabPane, tab) {
    const newArray = this.state.activeTab.slice()
    newArray[tabPane] = tab
    this.setState({
      activeTab: newArray,
    });
  }

  onDrop = files => {
    this.setState({
      files: files
    });
    this.setState({ isCrop: true });
    if (this.state.files.length !== 0) {
      this.setState({ img: URL.createObjectURL(files[0]) });
    }
  };
  onDropAR = filesAR => {
    this.setState({
      filesAR: filesAR
    });
    this.setState({ isCropAR: true });
    if (this.state.filesAR.length !== 0) {
      this.setState({ imgAR: URL.createObjectURL(filesAR[0]) });
    }


  };

  handelOrder(value) {
    this.setState({
      order: value
    });
  }
  onCreate = () => {
    this.setState({ emptyImg: "" });
    var order
    if (this.state.order === "no") {
      order = 0
    }
    else {
      order = this.state.order
    }
    const data = {
      description: this.state.description,
      title: this.state.title,
      active: this.state.active,
      last: this.state.last,
      image: this.state.files[0],
      imageAR: this.state.filesAR[0],
      ARdescription: this.state.ARdescription,
      ARtitle: this.state.ARtitle,
      order: order,

    };
    if (data.title.length === 0 || data.ARtitle.length === 0
      || data.active.length === 0 || data.last.length === 0) {
      this.setState({ emptyImg: <Trans i18nKey="requiredFields" /> });
    }

    else if (data.image === undefined || data.imageAR === undefined) {
      this.setState({ emptyImg: <Trans i18nKey="Banners.imgRequired" /> });
    }

    else {
      this.setState({ emptyImg: "" });
      this.props.createBanner(data)
    }

  };

  onDescriptionChange(value) {
    this.setState({
      description: value
    });
  }
  onTitleChange(value) {
    this.setState({
      title: value
    });
  }

  onARDescriptionChange(value) {
    this.setState({
      ARdescription: value
    });
  }
  onARTitleChange(value) {
    this.setState({
      ARtitle: value
    });
  }

  handleChangeActive(event) {
    this.setState({
      active: event.target.value
    });
  }
  handleChangelast(event) {
    this.setState({
      last: event.target.value
    });
  }

  componentDidMount() {
    var token = localStorage.getItem("adminToken")
    var jwtDecode = require('jwt-decode');
    var decoded = jwtDecode(token);
    var expirationDate = decoded.exp;
    var seconds = 1000;
    var d = new Date();
    var t = d.getTime();

    if (expirationDate < Math.round(t / seconds)) {

      localStorage.setItem("adminToken", "")
      history.push("/login");

    }

  }


  componentWillReceiveProps(prevProps) {

    if (prevProps.CreateBannerStatus !== this.props.CreateBannerStatus) {
      if (prevProps.CreateBannerStatus === "success") {
        ToastsStore.info(<h6><Trans i18nKey="Banners.cre" /></h6>, 3000)
        setTimeout(
          function () {
            history.push("/splash");
          }, 1200
        );
      }
    }
  }
  render() {
    const { isLoading } = this.props;

    let btnValidate = (
      <Button onClick={() => this.onCreate()} type="submit" size="md" className="btn-secondary" ><i className="fa fa-dot-circle-o"></i>  <Trans i18nKey="Submit" /> </Button>
    )
    if (isLoading) {
      btnValidate = <CircularProgress size={30} style={{ color: "#083328" }} />;
    }
    const maxSize = 1048576;
    let err = (<Container>
      <Row className="justify-content-center">
        <p></p>
      </Row>
    </Container>
    )

    if (this.props.CreateBannererr !== "") {
      err = <Container style={{ paddingTop: '2%' }}>
        <Row className="justify-content-center">
          <img src={notValid} alt="notvalid" style={
            {
              width: "20px",
              marginRight: "10px",
              height: "20px",
              marginLeft: "10px"
            }
          } />
          <p style={{ color: 'red' }}>{this.props.CreateBannererr}</p>
        </Row>
      </Container>
    }

    return (
      <div className="animated fadeIn">
        <Row>
          <Col lg={10}>
            <Card>
              <CardHeader>
                <strong><i className="icon-info pr-1"></i><Trans i18nKey="Banners.CreateBanner" /> </strong>
              </CardHeader>


              <CardBody>
                <Nav tabs style={{ backgroundColor: "#f0f3f5" }}>
                  <NavItem>
                    <NavLink
                      active={this.state.activeTab[0] === '1'}
                      onClick={() => { this.toggle(0, '1'); }}
                    >
                      English
                </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={this.state.activeTab[0] === '2'}
                      onClick={() => { this.toggle(0, '2'); }}
                    >
                      العربية
                </NavLink>
                  </NavItem>

                </Nav>
                <TabContent activeTab={this.state.activeTab[0]}>
                  <TabPane tabId="2">
                    <Dropzone
                      onDrop={this.onDropAR.bind(this)}
                      accept={"image/jpeg, image/png"}
                      minSize={0}
                      maxSize={maxSize}
                    >

                      {({ getRootProps, getInputProps, isDragReject, rejectedFiles }) => {
                        const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
                        return (
                          <section
                            className="container"
                            style={{
                              border: "1px solid #eee",
                              margin: "25px 0",
                              padding: 15,
                              textAlign: "center",
                              cursor: "pointer"
                            }}
                          >
                            {this.state.isCrop ? (
                              <div {...getRootProps({ className: "dropzone" })}>
                                <input {...getInputProps()} />
                                <b>(إختر ملف وقم بإفلاته أو انقر لإضافة صورة للافتة ، حجم الصورة الموصى به (960 × 540بكسل</b>
                                <p>JPEG أو PNG ، لا يتجاوز 1 ميغابايت</p>
                                {isDragReject && " نوع الملف غير مقبول ، آسف"}
                                {isFileTooLarge && (
                                  <div className="text-danger mt-2">
                                    الملف كبير جدا
                                  </div>
                                )}
                                <img
                                  src={this.state.imgAR}
                                  alt=""

                                />
                              </div>
                            ) : (
                                <div {...getRootProps({ className: "dropzone" })}>
                                  <input {...getInputProps()} />
                                  <b>(إختر ملف وقم بإفلاته أو انقر لإضافة صورة للافتة ، حجم الصورة الموصى به (960 × 540بكسل</b>
                                  <p>JPEG أو PNG ، لا يتجاوز 1 ميغابايت</p>
                                  {isDragReject && " نوع الملف غير مقبول ، آسف"}
                                  {isFileTooLarge && (
                                    <div className="text-danger mt-2">
                                      الملف كبير جدا
                                    </div>
                                  )}
                                  <img
                                    src={this.state.imgAR}
                                    alt=""

                                  />
                                </div>
                              )}
                          </section>
                        )
                      }}
                    </Dropzone>
                    <Form action="" style={{ direction: "rtl" }} method="post" encType="multipart/form-data" className="form-horizontal">
                      <FormGroup row>
                        <Col md="2">
                          <Label htmlFor="text-input">* العنوان :</Label>
                        </Col>
                        <Col xs="12" md="9">
                          <Input type="text" id="text-input" name="text-input" placeholder="" value={this.state.ARtitle}
                            onChange={e => this.onARTitleChange(e.target.value)} />
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Col md="2">
                          <Label htmlFor="text-input"> الوصف :</Label>
                        </Col>
                        <Col xs="12" md="9">

                          <Input type="textarea" rows="7"
                            placeholder="" value={this.state.ARdescription}
                            onChange={e => this.onARDescriptionChange(e.target.value)} />
                        </Col>
                      </FormGroup>

                      <br />
                    </Form>
                  </TabPane>
                  <TabPane tabId="1">
                    <Dropzone
                      onDrop={this.onDrop.bind(this)}
                      accept={"image/jpeg, image/png"}
                      minSize={0}
                      maxSize={maxSize}
                    >

                      {({ getRootProps, getInputProps, isDragReject, rejectedFiles }) => {
                        const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
                        return (
                          <section
                            className="container"
                            style={{
                              border: "1px solid #eee",
                              margin: "25px 0",
                              padding: 15,
                              textAlign: "center",
                              cursor: "pointer"
                            }}
                          >
                            {this.state.isCrop ? (
                              <div {...getRootProps({ className: "dropzone" })}>
                                <input {...getInputProps()} />
                                <b> Choose a file Drag and drop or click to add a main banner image, Recommended  Image Size (540 x 960 pixels)  </b>
                                <p>JPEG or PNG, does not exceed 1MB.</p>
                                {isDragReject && "File type not accepted, sorry!"}
                                {isFileTooLarge && (
                                  <div className="text-danger mt-2">
                                    File is too large.
                                  </div>
                                )}
                                <img
                                  src={this.state.img}
                                  alt=""

                                />
                              </div>
                            ) : (
                                <div {...getRootProps({ className: "dropzone" })}>
                                  <input {...getInputProps()} />
                                  <b> Choose a file Drag and drop or click to add a main banner image, Recommended  Image Size (540 x 960 pixels)  </b>
                                  <p>JPEG or PNG, does not exceed 1MB.</p>
                                  {isDragReject && "File type not accepted, sorry!"}
                                  {isFileTooLarge && (
                                    <div className="text-danger mt-2">
                                      File is too large.
                                    </div>
                                  )}
                                  <img
                                    src={this.state.imgs}
                                    alt=""

                                  />
                                </div>
                              )}
                          </section>
                        )
                      }}
                    </Dropzone>
                    <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">

                      <FormGroup row>
                        <Col md="2">
                          <Label htmlFor="text-input0">* Title :</Label>
                        </Col>
                        <Col xs="12" md="9">
                          <Input type="text" id="text-input0" name="text-input" placeholder="" value={this.state.title}
                            onChange={e => this.onTitleChange(e.target.value)} />
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Col md="2">
                          <Label htmlFor="text-input">Description :</Label>
                        </Col>
                        <Col xs="12" md="9">

                          <Input type="textarea" rows="7"
                            placeholder="" value={this.state.description}
                            onChange={e => this.onDescriptionChange(e.target.value)} />
                        </Col>
                      </FormGroup>


                    </Form>

                  </TabPane>

                </TabContent>
                <br />

                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">* <Trans i18nKey="Active" /> :</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <FormGroup check inline>
                      <Input className="form-check-input" type="radio" id="inline-radio3" name="inline-radios" value="yes"
                        checked={this.state.active === 'yes'}
                        onChange={this.handleChangeActive} />
                      <Label className="form-check-label" check htmlFor="inline-radio3"> &nbsp; <Trans i18nKey="yes" />  &nbsp; </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input className="form-check-input" type="radio" id="inline-radio3" name="inline-radios" value="no"
                        checked={this.state.active === 'no'}
                        onChange={this.handleChangeActive} />
                      <Label className="form-check-label" check htmlFor="inline-radio3">&nbsp;<Trans i18nKey="no" />&nbsp; </Label>
                    </FormGroup>

                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">* <Trans i18nKey="Last" /> :</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <FormGroup check inline>
                      <Input className="form-check-input" type="radio" id="Last" name="Last" value="yes"
                        checked={this.state.last === 'yes'}
                        onChange={this.handleChangelast} />
                      <Label className="form-check-label" check htmlFor="Last"> &nbsp; <Trans i18nKey="yes" />  &nbsp; </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input className="form-check-input" type="radio" id="Last" name="Last" value="no"
                        checked={this.state.last === 'no'}
                        onChange={this.handleChangelast} />
                      <Label className="form-check-label" check htmlFor="Last">&nbsp;<Trans i18nKey="no" />&nbsp; </Label>
                    </FormGroup>

                  </Col>
                </FormGroup>
                <FormGroup row>

                  <Col md="3">
                    <Label >* <Trans i18nKey="Places.order" /> :</Label>
                  </Col>
                  <Col xs="12" md="4">
                    <Input type="number" value={this.state.order}
                      onChange={e => this.handelOrder(e.target.value)} min="0" />
                  </Col>

                </FormGroup>
              </CardBody>


              <CardFooter>
                {btnValidate}

                <ToastsContainer store={ToastsStore} />
                {err}  {this.state.emptyImg &&
                  <Container style={{ paddingTop: '2%' }}>
                    <Row className="justify-content-center">
                      <img src={notValid} alt="notvalid" style={
                        {
                          width: "20px",
                          marginRight: "10px",
                          height: "20px",
                          marginLeft: "10px"
                        }
                      } />
                      <p style={{ color: 'red' }}>{this.state.emptyImg}</p>
                    </Row>
                  </Container>}
              </CardFooter>
            </Card>
          </Col>

        </Row>


      </div>

    )
  }
}
const mapStateToProps = state => {
  return {
    CreateBanner: state.CreateBanner.CreateBanner,
    CreateBannererr: state.CreateBannererr.CreateBannererr,
    CreateBannerStatus: state.CreateBannerStatus.CreateBannerStatus,
    isLoading: state.ui.isLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createBanner: (data) => dispatch(createBanner(data))

  };
};


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CreateBanner);
