import axios from "axios";
import {
  PARKINGS_DATA
} from "../actionTypes";
import {
  PARKING_DATA
} from "../actionTypes";
import {
  uiStartLoading,
  uiStopLoading
} from "../index";
import {
  LIST_PARKINGS_ERR
} from "../actionTypes";
import {
  PARKINGS_NBR
} from "../actionTypes";
import {
  PARKING_EDIT
} from "../actionTypes";
import {
  EDIT_PARKING_ERR
} from "../actionTypes";
import {
  STATUS_PARKING_EDIT
} from "../actionTypes";

import {
  PARKING_DELETE
} from "../actionTypes";
import {
  DELETE_PARKING_ERR
} from "../actionTypes";
import {
  STATUS_PARKING_DELETE
} from "../actionTypes";

import {
  PARKING_CREATE
} from "../actionTypes";
import {
  CREATE_PARKING_ERR
} from "../actionTypes";
import {
  STATUS_PARKING_CREATE
} from "../actionTypes";

import {
  ACT_PARKINGS_DATA
} from "../actionTypes";
import {
  LIST_ACT_PARKINGS_ERR
} from "../actionTypes";
import {
  ACT_PARKINGS_DET_DATA
} from "../actionTypes";
import {
  ACT_PARKINGS_DET_ERR
} from "../actionTypes";

export const getParkings = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/parking/getList`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          pageNumber: data.pageNumber
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {

        dispatch(uiStopLoading())
        if (parsedRes.status === "failure") {
          dispatch(ParkingsNbr(""));
          dispatch(listParkingsErr(parsedRes.error));

        } else {
          dispatch(listParkingsErr(""));
          dispatch(ParkingsNbr(parsedRes.Length));
          dispatch(parkingsData(parsedRes.data));

        }

      });
  };
};
export const ParkingsNbr = value => {
  return {
    type: PARKINGS_NBR,
    ParkingsNbr: value
  };
};
export const getActiveParkings = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/parking/getActiveParkings`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {

        dispatch(uiStopLoading())
        if (parsedRes.status === "fail") {
          dispatch(listActParkingsErr(parsedRes.error));

        } else {
          dispatch(listActParkingsErr(""));
          dispatch(ActparkingsData(parsedRes.data));

        }

      });
  };
};

export const getActiveParkingDetails = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/parking/getActiveParkingDetails`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {

        dispatch(uiStopLoading())
        if (parsedRes.status === "fail") {
          dispatch(ActParkingsDetailsErr(parsedRes.error));

        } else {
          dispatch(ActParkingsDetailsErr(""));
          dispatch(ActparkingsDetailsData(parsedRes.data));

        }

      });
  };
};

export const getParking = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/parking/get`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-access-token": token

        },
        body: JSON.stringify({
          parkingID: data.parkingID

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        dispatch(parkingData(parsedRes.data));

      });
  };
};

export const editParking = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    const form = new FormData();
    form.append("parkingID", data.parkingID);
    form.append("title", data.title);
    form.append("description", data.description);
    form.append("active", data.active);
    for (let i = 0; i < data.image.length; i++) {
      form.append("image", data.image[i]);
    }
    for (let i = 0; i < data.gallery.length; i++) {
      form.append("gallery", data.gallery[i]);
    }
    form.append("icon", data.icon);
    form.append("latitude", data.latitude);
    form.append("longitude", data.longitude);
    form.append("type", data.type);
    form.append("category", data.category);
    form.append("beaconID", data.beaconID);
    form.append("ARtitle", data.ARtitle);
    form.append("ARdescription", data.ARdescription);

    const config = {
      headers: {
        enctype: "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token
      }
    };

    axios
      .post(`${process.env.REACT_APP_DOMAIN}/api/parking/edit`, form, config, {
        useCredentails: true
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(parsedRes => {
        dispatch(editParkingstatus(""));
        dispatch(uiStopLoading())
        dispatch(EditParkingerr(""));

        if (parsedRes.data.status === "fail") {

          dispatch(EditParkingerr(parsedRes.data.error));
        } else if (parsedRes.data.status === "failure") {

          dispatch(EditParkingerr(parsedRes.data.error));
        } else {
          dispatch(EditParkingerr(""));
          dispatch(editparking(parsedRes.data.data));
          dispatch(editParkingstatus(parsedRes.data.status));
        }

      });
  };


};

export const createParking = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    dispatch(CreateParkingstatus(""));

    const form = new FormData();

    form.append("title", data.title);
    form.append("description", data.description);
    form.append("active", data.active);
    for (let i = 0; i < data.image.length; i++) {
      form.append("image", data.image[i]);
    }
    form.append("type", data.type);
    form.append("category", data.category);
    form.append("latitude", data.latitude);
    form.append("longitude", data.longitude);
    form.append("beaconID", data.beaconID);
    form.append("ARtitle", data.ARtitle);
    form.append("ARdescription", data.ARdescription);
    form.append("parkingID", data.parkingID);
    form.append("icon", data.icon);
    const config = {
      headers: {
        enctype: "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token
      }
    };

    axios
      .post(`${process.env.REACT_APP_DOMAIN}/api/parking/add`, form, config, {
        useCredentails: true
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(parsedRes => {
        dispatch(CreateParkingstatus(""));
        dispatch(uiStopLoading())

        if (parsedRes.data.status === "fail") {
          dispatch(CreateParkingerr(parsedRes.data.error));
        } else {
          dispatch(CreateParkingerr(""));
          dispatch(CreateParking(parsedRes.data.data));
          dispatch(CreateParkingstatus(parsedRes.data.status));

        }

      });
  };
};
export const DeleteParking = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    dispatch(deleteParkingstatus(""));

    fetch(`${process.env.REACT_APP_DOMAIN}/api/parking/delete`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token

        },
        body: JSON.stringify({
          parkingID: data.parkingID,
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(deleteParkingstatus(""));
        dispatch(DeleteParkingerr(""));
        dispatch(uiStopLoading())
        if (parsedRes.status === "fail" || parsedRes.status === "failure") {
          dispatch(DeleteParkingerr(parsedRes.error));

        } else {
          dispatch(DeleteParkingerr(""));
          dispatch(deleteParking(parsedRes.data));
          dispatch(deleteParkingstatus(parsedRes.status));
        }

      });
  };
};


export const parkingsData = value => {
  return {
    type: PARKINGS_DATA,
    parkingsData: value
  };
};
export const parkingData = value => {
  return {
    type: PARKING_DATA,
    parkingData: value
  };
};

export const editparking = value => {
  return {
    type: PARKING_EDIT,
    editparking: value
  };
};
export const editParkingstatus = value => {
  return {
    type: STATUS_PARKING_EDIT,
    editParkingstatus: value
  };
};
export const listParkingsErr = value => {
  return {
    type: LIST_PARKINGS_ERR,
    listParkingsErr: value
  };
};

export const listActParkingsErr = value => {
  return {
    type: LIST_ACT_PARKINGS_ERR,
    listActParkingsErr: value
  };
};
export const ActparkingsData = value => {
  return {
    type: ACT_PARKINGS_DATA,
    ActparkingsData: value
  };
};
export const ActParkingsDetailsErr = value => {
  return {
    type: ACT_PARKINGS_DET_ERR,
    ActParkingsDetailsErr: value
  };
};
export const ActparkingsDetailsData = value => {
  return {
    type: ACT_PARKINGS_DET_DATA,
    ActparkingsDetailsData: value
  };
};
export const EditParkingerr = value => {
  return {
    type: EDIT_PARKING_ERR,
    EditParkingerr: value
  };
};

export const deleteParking = value => {
  return {
    type: PARKING_DELETE,
    deleteParking: value
  };
};
export const DeleteParkingerr = value => {
  return {
    type: DELETE_PARKING_ERR,
    DeleteParkingerr: value
  };
};
export const deleteParkingstatus = value => {
  return {
    type: STATUS_PARKING_DELETE,
    deleteParkingstatus: value
  };
};

export const CreateParking = value => {
  return {
    type: PARKING_CREATE,
    CreateParking: value
  };
};
export const CreateParkingerr = value => {
  return {
    type: CREATE_PARKING_ERR,
    CreateParkingerr: value
  };
};
export const CreateParkingstatus = value => {
  return {
    type: STATUS_PARKING_CREATE,
    CreateParkingstatus: value
  };
};