import React, {  } from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import store from "./store/configureStore";
import { Router } from "react-router";

import i18n from "./i18n";
import "./index.css";
import App from "./App";
import history from "./history";

// append app to dom
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router> 
    </Provider>
  </I18nextProvider>,
  document.getElementById("root")
 
);
