import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row, Table, Tooltip, Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { getNotifications, DeleteNotification, SendNotification } from "../../../store/actions";
import { compose } from "redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import addBtn from '../../../assets/img/brand/plus-button.png';
import history from "../../../history.js";
import { connect } from "react-redux";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Trans } from "react-i18next";
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
class Notifications extends Component {

  constructor(props) {
    super(props)
    this.state = {
      tooltipOpen: false,
      description: '',
      title: '',
      body: '',
      current: 1,
      active: '',
      modal: false,
      notificationId: ''
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }
  onChange = (page) => {
    this.setState({
      current: page
    });
    const data = { pageNumber: page };
    this.props.getNotifications(data)
  }
  onSend(notificationid) {
    const data = { notificationID: notificationid };
    this.props.SendNotification(data);
  };

  componentDidMount() {
    var token = localStorage.getItem("adminToken")
    var jwtDecode = require('jwt-decode');
    var decoded = jwtDecode(token);
    var expirationDate = decoded.exp;
    var seconds = 1000;
    var d = new Date();
    var t = d.getTime();

    if (expirationDate < Math.round(t / seconds)) {
      localStorage.setItem("adminToken", "")
      history.push("/login");

    }
    else {
      const data = { pageNumber: 1 };
      this.props.getNotifications(data)
    }

  }
  onDelete() {
    const data = { notificationID: this.state.notificationId };
    this.props.DeleteNotification(data);
  };
  toggleModal(notificationId) {

    this.setState({
      modal: !this.state.modal,
      notificationId: notificationId
    });
  }
  componentWillReceiveProps(prevProps) {
    if (prevProps.deletenotificationStatus !== this.props.deletenotificationStatus) {
      if (prevProps.deletenotificationStatus === "success") {
        this.setState({
          modal: false
        });
        ToastsStore.info(<h6><Trans i18nKey="Notifications.del" /></h6>, 3000)
        const data = { pageNumber: 1 };
        this.props.getNotifications(data)
      }

    }
    if (prevProps.sendnotificationStatus !== this.props.sendnotificationStatus) {
      if (prevProps.sendnotificationStatus === "success") {
        ToastsStore.info(<h6><Trans i18nKey="Notifications.push" /></h6>, 3000)
      }

    }


  }
  render() {
    const { isLoading } = this.props;

    let btnValidate = (
      <Button onClick={() => this.onDelete()} type="submit" className="btn-secondary" size="md"><i className="icon-check"></i>  <Trans i18nKey="yes" /></Button>
    )
    if (isLoading) {
      btnValidate = <CircularProgress size={30} style={{ color: "#083328" }} />;
    }
    return (
      <div className="animated fadeIn">
        <Row>

          <Col xl={12}>

            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i><Trans i18nKey="Notifications.NotifsList" /> <Link to={`/notification/create`}><img src={addBtn} alt="" style={{ color: "#235C4E", cursor: "pointer", marginTop: '0.5%', float: (localStorage.getItem('i18nextLng') === "en") ? 'right' : 'left' }} id={'Tooltip-' + this.props.id} /> </Link>

                <Tooltip isOpen={this.state.tooltipOpen} target={'Tooltip-' + this.props.id} toggle={this.toggle}>
                  <Trans i18nKey="Notifications.AddNotif" />
                </Tooltip>

              </CardHeader>
              <CardBody>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col"><Trans i18nKey="Title" /></th>
                      <th scope="col"><Trans i18nKey="Body" /></th>
                      <th scope="col"><Trans i18nKey="send" /></th>
                      <th scope="col"><Trans i18nKey="Actions" /></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.listnotificationsErr === "" ?
                      Object.values(this.props.notificationsData).map(notificationData => {
                        if (localStorage.getItem('i18nextLng') === "en")
                          return <tr key={notificationData._id} id={notificationData._id}>
                            <td>{notificationData.title}</td>
                            <td>{notificationData.body}</td>
                            <td>
                              <Button outline className="btn-secondary" size="sm" onClick={() => this.onSend(notificationData._id)} type="submit" ><i className="icon-paper-plane"></i>  <Trans i18nKey="sendNow" /></Button>
                            </td>

                            <td>
                              <Link to={`/notifications/${notificationData._id}`}><i className="icon-pencil" ></i></Link>
                              <i onClick={() => this.toggleModal(notificationData._id)} className="icon-trash" style={{ marginLeft: '10%', cursor: "pointer" }}></i>
                            </td>

                          </tr>
                        return <tr key={notificationData._id} id={notificationData._id}>
                          <td>{notificationData.translationAr[0].ARtitle}</td>
                          <td>{notificationData.translationAr[0].ARbody}</td>
                          <td>

                            <Button outline className="btn-secondary" size="sm" onClick={() => this.onSend(notificationData._id)} type="submit" ><i className="icon-paper-plane"></i>  <Trans i18nKey="sendNow" /></Button>
                          </td>

                          <td>
                            <Link to={`/notifications/${notificationData._id}`}><i className="icon-pencil" ></i></Link>
                            <i onClick={() => this.toggleModal(notificationData._id)} className="icon-trash" style={{ marginLeft: '10%', cursor: "pointer" }}></i>
                          </td>

                        </tr>
                      }

                      ) : <tr><td><Trans i18nKey="Notifications.noNotif" /></td></tr>}
                    <Modal isOpen={this.state.modal} toggle={() => this.toggleModal()} className={this.props.className}
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)"
                      }}>
                      <ModalHeader toggle={() => this.toggleModal()}><Trans i18nKey="Notifications.notifdeltTitle" /></ModalHeader>

                      <ModalBody>
                        <br />
                        {(localStorage.getItem('i18nextLng') === "en") ? "Do you really want to delete this notifications ?" : "هل تريد حقًا حذف هذا الاشعار ؟"}
                        <br />
                        <br />
                      </ModalBody>
                      <ModalFooter>
                        {btnValidate}{' '}
                        <Button style={{
                          color: "white",
                          backgroundColor: "#C6893F",
                          borderColor: "#C6893F",
                          marginRight: "10px"
                        }} onClick={() => this.toggleModal()}><i className="icon-ban"></i> <Trans i18nKey="cancel" /></Button>
                      </ModalFooter>
                    </Modal>

                  </tbody>
                </Table>
                <Pagination onChange={this.onChange} showTitle={false} current={this.state.current} total={this.props.notificationsNbr} />
              </CardBody>
            </Card>
          </Col>
          <ToastsContainer store={ToastsStore} />
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    notificationsData: state.notificationsData.notificationsData,
    isLoading: state.ui.isLoading,
    listnotificationsErr: state.listnotificationsErr.listnotificationsErr,
    deletenotificationStatus: state.deletenotificationStatus.deletenotificationStatus,
    sendnotificationStatus: state.sendnotificationStatus.sendnotificationStatus,
    notificationsNbr: state.notificationsNbr.notificationsNbr

  };
};

const mapDispatchToProps = dispatch => {
  return {
    getNotifications: (data) => dispatch(getNotifications(data)),
    DeleteNotification: (data) => dispatch(DeleteNotification(data)),
    SendNotification: (data) => dispatch(SendNotification(data))
  };
};


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Notifications);