import { PARKINGS_DATA } from "../../actions/actionTypes";
import { PARKING_DATA } from "../../actions/actionTypes";
import { LIST_PARKINGS_ERR } from "../../actions/actionTypes";
import { PARKINGS_NBR } from "../../actions/actionTypes";
import { PARKING_EDIT } from "../../actions/actionTypes";
import { EDIT_PARKING_ERR } from "../../actions/actionTypes";
import { STATUS_PARKING_EDIT } from "../../actions/actionTypes";

import { DELETE_PARKING_ERR } from "../../actions/actionTypes";
import { STATUS_PARKING_DELETE } from "../../actions/actionTypes";
import { PARKING_DELETE } from "../../actions/actionTypes";

import { CREATE_PARKING_ERR } from "../../actions/actionTypes";
import { STATUS_PARKING_CREATE } from "../../actions/actionTypes";
import { PARKING_CREATE } from "../../actions/actionTypes";

import { ACT_PARKINGS_DATA } from "../../actions/actionTypes";
import { LIST_ACT_PARKINGS_ERR } from "../../actions/actionTypes";
import { ACT_PARKINGS_DET_ERR } from "../../actions/actionTypes";
import { ACT_PARKINGS_DET_DATA } from "../../actions/actionTypes";

const initialState = {
  parkingsData: [],
  parkingData: [],
  editparking: [],
  EditParkingerr: '',
  listParkingsErr: '',
  editParkingstatus: '',
  deleteParking: [],
  DeleteParkingerr: '',
  deleteParkingstatus: '',
  CreateParking: [],
  CreateParkingerr: '',
  CreateParkingstatus: '', 
  ParkingsNbr: 0,
  ActparkingsData: [],
  ActParkingsDetailsErr: '',
  ActparkingsDetailsData: [],
  listActParkingsErr: '',

};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PARKINGS_NBR:
      return {
        ...state,
        ParkingsNbr: action.ParkingsNbr
      };
    case PARKINGS_DATA:
      return {
        ...state,
        parkingsData: action.parkingsData
      };
    case PARKING_DATA:
      return {
        ...state,
        parkingData: action.parkingData
      };
    case PARKING_EDIT:
      return {
        ...state,
        editparking: action.editparking
      };
    case LIST_PARKINGS_ERR:
      return {
        ...state,
        listParkingsErr: action.listParkingsErr
      };

    case EDIT_PARKING_ERR:
      return {
        ...state,
        EditParkingerr: action.EditParkingerr
      };
    case STATUS_PARKING_EDIT:
      return {
        ...state,
        editParkingstatus: action.editParkingstatus
      };
    case DELETE_PARKING_ERR:
      return {
        ...state,
        DeleteParkingerr: action.DeleteParkingerr
      };
    case STATUS_PARKING_DELETE:
      return {
        ...state,
        deleteParkingstatus: action.deleteParkingstatus
      };
    case PARKING_DELETE:
      return {
        ...state,
        deleteParking: action.deleteParking
      };

    case CREATE_PARKING_ERR:
      return {
        ...state,
        CreateParkingerr: action.CreateParkingerr
      };
    case STATUS_PARKING_CREATE:
      return {
        ...state,
        CreateParkingstatus: action.CreateParkingstatus
      };
    case PARKING_CREATE:
      return {
        ...state,
        CreateParking: action.CreateParking
      };
    case LIST_ACT_PARKINGS_ERR:
      return {
        ...state,
        listActParkingsErr: action.listActParkingsErr
      };
    case ACT_PARKINGS_DATA:
      return {
        ...state,
        ActparkingsData: action.ActparkingsData
      };
    case ACT_PARKINGS_DET_ERR:
      return {
        ...state,
        ActParkingsDetailsErr: action.ActParkingsDetailsErr
      };
    case ACT_PARKINGS_DET_DATA:
      return {
        ...state,
        ActparkingsDetailsData: action.ActparkingsDetailsData
      };
    default:
      return state;
  }
};

export default reducer;
