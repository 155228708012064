import axios from "axios";
import {
  CATEGORIES_DATA
} from "../actionTypes";
import {
  CATEGORY_DATA,
  CATEGORY_NB
} from "../actionTypes";
import {
  uiStartLoading,
  uiStopLoading
} from "../index";
import {
  LIST_CATEGORIES_ERR
} from "../actionTypes";

import {
  CATEGORY_EDIT
} from "../actionTypes";
import {
  EDIT_CATEGORY_ERR
} from "../actionTypes";
import {
  STATUS_CATEGORY_EDIT
} from "../actionTypes";

import {
  CATEGORY_DELETE
} from "../actionTypes";
import {
  DELETE_CATEGORY_ERR
} from "../actionTypes";
import {
  STATUS_CATEGORY_DELETE
} from "../actionTypes";

import {
  CATEGORY_CREATE
} from "../actionTypes";
import {
  CREATE_CATEGORY_ERR
} from "../actionTypes";
import {
  STATUS_CATEGORY_CREATE
} from "../actionTypes";
import {
  LIST_ACT_CATEGORIES_ERR,
  ACT_CATEGORIES_DATA
} from "../actionTypes";

export const getCategories = data => {
  return dispatch => {

    dispatch(uiStartLoading());
    var token = localStorage.getItem("adminToken");
    fetch(`${process.env.REACT_APP_DOMAIN}/api/category/getList`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          pageNumber: data.pageNumber

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {

        dispatch(uiStopLoading())

        if (parsedRes.status === "failure") {
          dispatch(listCategoriesErr(parsedRes.error));

        } else {
          dispatch(listCategoriesErr(""));
          dispatch(CategoriesData(parsedRes.data));
          dispatch(CategoriesNbr(parsedRes.Length));

        }

      });
  };
};

export const getActiveCategories = () => {
  return dispatch => {

    dispatch(uiStartLoading());
    var token = localStorage.getItem("adminToken");
    fetch(`${process.env.REACT_APP_DOMAIN}/api/categories/active`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {

        dispatch(uiStopLoading())

        if (parsedRes.status === "failure") {
          dispatch(listActCategoriesErr(parsedRes.error));
        } else {
          dispatch(listActCategoriesErr(""));
          dispatch(ActCategoriesData(parsedRes.data));


        }

      });
  };
};
export const CategoriesNbr = value => {
  return {
    type: CATEGORY_NB,
    CategoriesNbr: value
  }
}

export const getCategory = data => {
  return dispatch => {

    dispatch(uiStartLoading());
    var token = localStorage.getItem("adminToken");
    fetch(`${process.env.REACT_APP_DOMAIN}/api/category/get`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-access-token": token

        },
        body: JSON.stringify({
          categoryID: data.categoryID

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        dispatch(categoryData(parsedRes.data));

      });
  };
};


export const editCategory = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");
    dispatch(uiStartLoading());
    const form = new FormData();
    form.append("categoryID", data.categoryID);
    form.append("title", data.title);
    form.append("ARtitle", data.ARtitle);
    form.append("active", data.active);
    form.append("image", data.image);
    form.append("MerchantIcon", data.MerchantIcon);
    form.append("order", data.order);
    const config = {
      headers: {
        enctype: "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token
      }
    };

    axios
      .post(`${process.env.REACT_APP_DOMAIN}/api/category/edit`, form, config, {
        useCredentails: true
      })

      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(parsedRes => {
        dispatch(editCategoryStatus(""));
        dispatch(uiStopLoading())
        dispatch(EditCategoryerr(""));

        if (parsedRes.data.status === "fail") {

          dispatch(EditCategoryerr(parsedRes.data.error));
        }
        if (parsedRes.data.status === "failure") {

          dispatch(EditCategoryerr(parsedRes.data.error));
        } else {
          dispatch(EditCategoryerr(""));
          dispatch(editcategory(parsedRes.data.data));
          dispatch(editCategoryStatus(parsedRes.data.status));
        }

      });
  };


};


export const createCategory = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");
    dispatch(CreateCategoryStatus(""));
    dispatch(uiStartLoading());
    const form = new FormData();

    form.append("title", data.title);
    form.append("ARtitle", data.ARtitle);
    form.append("active", data.active);
    form.append("image", data.image);
    form.append("MerchantIcon", data.MerchantIcon);
    form.append("order", data.order);

    const config = {
      headers: {
        enctype: "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token
      }
    };

    axios
      .post(`${process.env.REACT_APP_DOMAIN}/api/category/add`, form, config, {
        useCredentails: true
      })

      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })

      .then(parsedRes => {
        dispatch(CreateCategoryStatus(""));
        dispatch(uiStopLoading())

        if (parsedRes.data.status === "fail") {
          dispatch(CreateCategoryerr(parsedRes.data.error));

        } else {
          dispatch(CreateCategoryerr(""));
          dispatch(CreateCategory(parsedRes.data.data));
          dispatch(CreateCategoryStatus(parsedRes.data.status));
        }

      });
  };
};
export const DeleteCategory = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");
    dispatch(deleteCategoryStatus(""));
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/category/delete`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          categoryID: data.categoryID,

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(DeleteCategoryerr(""));
        dispatch(deleteCategoryStatus(""));
        dispatch(uiStopLoading())
        if (parsedRes.status === "fail") {
          dispatch(DeleteCategoryerr(parsedRes.error));
        }
        if (parsedRes.status === "failure") {
          dispatch(DeleteCategoryerr(parsedRes.error));
        } else {
          dispatch(DeleteCategoryerr(""));
          dispatch(deleteCategory(parsedRes.data));
          dispatch(deleteCategoryStatus(parsedRes.status));
        }

      });
  };
};


export const CategoriesData = value => {
  return {
    type: CATEGORIES_DATA,
    CategoriesData: value
  };
};
export const ActCategoriesData = value => {
  return {
    type: ACT_CATEGORIES_DATA,
    ActCategoriesData: value
  };
};
export const categoryData = value => {
  return {
    type: CATEGORY_DATA,
    categoryData: value
  };
};

export const editcategory = value => {
  return {
    type: CATEGORY_EDIT,
    editcategory: value
  };
};
export const editCategoryStatus = value => {
  return {
    type: STATUS_CATEGORY_EDIT,
    editCategoryStatus: value
  };
};
export const listCategoriesErr = value => {
  return {
    type: LIST_CATEGORIES_ERR,
    listCategoriesErr: value
  };
};
export const listActCategoriesErr = value => {
  return {
    type: LIST_ACT_CATEGORIES_ERR,
    listActCategoriesErr: value
  };
};
export const EditCategoryerr = value => {
  return {
    type: EDIT_CATEGORY_ERR,
    EditCategoryerr: value
  };
};

export const deleteCategory = value => {
  return {
    type: CATEGORY_DELETE,
    deleteCategory: value
  };
};
export const DeleteCategoryerr = value => {
  return {
    type: DELETE_CATEGORY_ERR,
    DeleteCategoryerr: value
  };
};
export const deleteCategoryStatus = value => {
  return {
    type: STATUS_CATEGORY_DELETE,
    deleteCategoryStatus: value
  };
};

export const CreateCategory = value => {
  return {
    type: CATEGORY_CREATE,
    CreateCategory: value
  };
};
export const CreateCategoryerr = value => {
  return {
    type: CREATE_CATEGORY_ERR,
    CreateCategoryerr: value
  };
};
export const CreateCategoryStatus = value => {
  return {
    type: STATUS_CATEGORY_CREATE,
    CreateCategoryStatus: value
  };
};