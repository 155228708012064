import React, {
  Component
} from 'react';
import {
  Link
} from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  Badge,
  Col,
  Row,
  Table,
  Container,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button
} from 'reactstrap';
import {
  getCategories,
  DeleteCategory
} from "../../../store/actions";
import {
  compose
} from "redux";
import addBtn from '../../../assets/img/brand/plus-button.png';
import history from "../../../history.js";
import {
  connect
} from "react-redux";
import {
  ToastsContainer,
  ToastsStore
} from 'react-toasts';
import {
  Trans
} from "react-i18next";
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import notValid from '../../../assets/img/brand/notValid.png';
import CircularProgress from "@material-ui/core/CircularProgress";

class Categories extends Component {

  constructor(props) {
    super(props)
    this.state = {
      tooltipOpen: false,
      description: '',
      title: '',
      active: '',
      current: 1,
      deleted: '',
      modal: false,
      thingId: ''
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }
  onChange = (page) => {
    this.setState({
      current: page
    });
    const data = {
      pageNumber: page
    };
    this.props.getCategories(data)
  }
  componentDidMount() {
    var token = localStorage.getItem("adminToken")
    var jwtDecode = require('jwt-decode');
    var decoded = jwtDecode(token);
    var expirationDate = decoded.exp;
    var seconds = 1000;
    var d = new Date();
    var t = d.getTime();

    if (expirationDate < Math.round(t / seconds)) {
      localStorage.setItem("adminToken", "")
      history.push("/login");
    } else {
      const data = {
        pageNumber: 1
      };
      this.props.getCategories(data)
    }

  }
  onDelete() {
    const data = {
      categoryID: this.state.thingId
    };
    this.props.DeleteCategory(data);
  };
  toggleModal(thingId) {

    this.setState({
      modal: !this.state.modal,
      thingId: thingId,
    });
  }

  componentWillReceiveProps(prevProps) {
    if (prevProps.deleteCategoryStatus !== this.props.deleteCategoryStatus) {
      if (prevProps.deleteCategoryStatus === "success") {
        this.setState({
          modal: false
        });
        ToastsStore.info( < Trans i18nKey = "ThingsToDo.del" / > , 3000)
        const data = {
          pageNumber: 1
        };
        this.props.getCategories(data)
      }

    }
    if (prevProps.DeleteCategoryerr.En !== this.props.DeleteCategoryerr.En) {
      if (prevProps.DeleteCategoryerr !== "") {
        this.setState({
          modal: false,
          deleted: localStorage.getItem('i18nextLng') === "en" ? prevProps.DeleteCategoryerr.En : prevProps.DeleteCategoryerr.Ar
        });
      }

    }

  }
  render() {
    const {
      isLoading
    } = this.props;

    let btnValidate = ( <
      Button onClick = {
        () => this.onDelete()
      }
      type = "submit"
      className = "btn-secondary"
      size = "md" > < i className = "icon-check" > < /i>  <Trans i18nKey="yes" / > < /Button>
    )
    if (isLoading) {
      btnValidate = < CircularProgress size = {
        30
      }
      style = {
        {
          color: "#083328"
        }
      }
      />;
    }
    return ( <
      div className = "animated fadeIn" >
      <
      Row >

      <
      Col xl = {
        12
      } >

      <
      Card >
      <
      CardHeader >
      <
      i className = "fa fa-align-justify" > < /i> <Trans i18nKey="ThingsToDo.ThingsToDoList" / > < Link to = {
        `/thingtodo/create`
      } > < img src = {
        addBtn
      }
      alt = ""
      style = {
        {
          color: "#235C4E",
          cursor: "pointer",
          marginTop: '0.5%',
          float: (localStorage.getItem('i18nextLng') === "en") ? 'right' : 'left'
        }
      }
      id = {
        'Tooltip-' + this.props.id
      }
      /> </Link >

      <
      Tooltip isOpen = {
        this.state.tooltipOpen
      }
      target = {
        'Tooltip-' + this.props.id
      }
      toggle = {
        this.toggle
      } >
      <
      Trans i18nKey = "ThingsToDo.AddThingToDo" / >
      <
      /Tooltip>

      <
      /CardHeader> <
      CardBody >
      <
      Table responsive hover >
      <
      thead >
      <
      tr >
      <
      th scope = "col" > < Trans i18nKey = "Title" / > < /th> <
      th scope = "col" > < Trans i18nKey = "Active" / > < /th> <
      th scope = "col" > < Trans i18nKey = "Reservations.created_at" / > < /th> <
      th scope = "col" > < Trans i18nKey = "Reservations.modified_at" / > < /th> <
      th scope = "col" > < Trans i18nKey = "Actions" / > < /th> <
      /tr> <
      /thead> <
      tbody > {
        this.props.listCategoriesErr === "" ?
        Object.values(this.props.CategoriesData).map(categoryData => {

          var cre = categoryData.createdAt;
          var upd = categoryData.updatedAt;
          var date = new Date(cre);
          var year = date.getFullYear();
          var month = date.getMonth() + 1;
          var dt = date.getDate();

          if (dt < 10) {
            dt = '0' + dt;
          }
          if (month < 10) {
            month = '0' + month;
          }
          var dateUp = new Date(upd);
          var yearUp = dateUp.getFullYear();
          var monthUp = dateUp.getMonth() + 1;
          var dtUp = dateUp.getDate();

          if (dtUp < 10) {
            dtUp = '0' + dtUp;
          }
          if (monthUp < 10) {
            monthUp = '0' + monthUp;
          }
          if (localStorage.getItem('i18nextLng') === "en")
            return <tr key = {
              categoryData._id
            }
          id = {
              categoryData._id
            } >
            <
            td > {
              categoryData.title
            } < /td>

            <
            td > < Badge pill color = {
              categoryData.active === "no" ? "danger" : "success"
            } > {
              categoryData.active
            } < /Badge></td >
            <
            td > {
              dt + '-' + month + '-' + year
            } < /td> <
            td > {
              dtUp + '-' + monthUp + '-' + yearUp
            } < /td> <
            td >
            <
            Link to = {
              `/Activity/${categoryData._id}`
            } > < i className = "icon-pencil" > < /i></Link >
            <
            i onClick = {
              () => this.toggleModal(categoryData._id)
            }
          className = "icon-trash"
          style = {
              {
                marginLeft: '10%',
                cursor: "pointer"
              }
            } > < /i> <
            /td> <
            /tr>
          return <tr key = {
            categoryData._id
          }
          id = {
              categoryData._id
            } >
            <
            td > {
              categoryData.translationAr[0].ARtitle
            } < /td> <
            td > < Badge pill color = {
              categoryData.active === "no" ? "danger" : "success"
            } > {
              categoryData.active === "no" ? < Trans i18nKey = "no" / > : < Trans i18nKey = "yes" / >
            } < /Badge></td >
            <
            td > {
              dt + '-' + month + '-' + year
            } < /td> <
            td > {
              dtUp + '-' + monthUp + '-' + yearUp
            } < /td> <
            td >
            <
            Link to = {
              `/Activity/${categoryData._id}`
            } > < i className = "icon-pencil" > < /i></Link >
            <
            i onClick = {
              () => this.toggleModal(categoryData._id)
            }
          className = "icon-trash"
          style = {
              {
                marginLeft: '10%',
                cursor: "pointer"
              }
            } > < /i> <
            /td>

            <
            /tr>

        }) : < tr > < td > < Trans i18nKey = "ThingsToDo.noThingToDo" / > < /td></tr >
      }

      <
      Modal isOpen = {
        this.state.modal
      }
      toggle = {
        () => this.toggleModal()
      }
      className = {
        this.props.className
      }
      style = {
        {
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)"
        }
      } >
      <
      ModalHeader toggle = {
        () => this.toggleModal()
      } > < Trans i18nKey = "ThingsToDo.thingdeltTitle" / > < /ModalHeader>

      <
      ModalBody >
      <
      br / > {
        (localStorage.getItem('i18nextLng') === "en") ? "Do you really want to delete this Activity ?" : "هل تريد حقًا حذف هذا النشاط ؟"
      } <
      br / >
      <
      br / >
      <
      /ModalBody> <
      ModalFooter > {
        btnValidate
      } {
        ' '
      } <
      Button style = {
        {
          color: "white",
          backgroundColor: "#C6893F",
          borderColor: "#C6893F",
          marginRight: "10px"
        }
      }
      onClick = {
        () => this.toggleModal()
      } > < i className = "icon-ban" > < /i> <Trans i18nKey="cancel" / > < /Button> <
      /ModalFooter> <
      /Modal> <
      /tbody> <
      /Table> <
      Pagination onChange = {
        this.onChange
      }
      showTitle = {
        false
      }
      current = {
        this.state.current
      }
      total = {
        this.props.CategoriesNbr
      }
      /> <
      /CardBody> <
      /Card> <
      /Col> <
      ToastsContainer store = {
        ToastsStore
      }
      /> {
        this.state.deleted !== '' ? < Container >
          <
          Row className = "justify-content-center" >
          <
          img src = {
            notValid
          }
        alt = "notvalid"
        style = {
          {
            width: "20px",
            marginRight: "10px",
            height: "20px",
            marginLeft: "10px"
          }
        }
        /> <
        p style = {
            {
              color: 'red'
            }
          } > {
            this.state.deleted
          } < /p> <
          /Row> <
          /Container>: < div > < /div>
      } <
      /Row> <
      /div>
    )
  }
}

const mapStateToProps = state => {
  return {
    CategoriesData: state.CategoriesData.CategoriesData,
    isLoading: state.ui.isLoading,
    listCategoriesErr: state.listCategoriesErr.listCategoriesErr,
    deleteCategoryStatus: state.deleteCategoryStatus.deleteCategoryStatus,
    DeleteCategoryerr: state.DeleteCategoryerr.DeleteCategoryerr,
    CreateCategory: state.CreateCategory.CreateCategory,
    CreateCategoryerr: state.CreateCategoryerr.CreateCategoryerr,
    CreateCategoryStatus: state.CreateCategoryStatus.CreateCategoryStatus,
    CategoriesNbr: state.CategoriesNbr.CategoriesNbr

  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCategories: (data) => dispatch(getCategories(data)),
    DeleteCategory: (data) => dispatch(DeleteCategory(data)),

  };
};


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Categories);