import { UI_START_LOADING, UI_STOP_LOADING } from "../actions/actionTypes";
import { UI_START_LOADER, UI_STOP_LOADER } from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isLoader: false
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UI_START_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case UI_STOP_LOADING:
      return {
        ...state,
        isLoading: false
      };
    case UI_START_LOADER:
      return {
        ...state,
        isLoader: true
      };
    case UI_STOP_LOADER:
      return {
        ...state,
        isLoader: false
      };
    default:
      return state;
  }
};
export default reducer;