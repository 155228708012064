import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Badge, CardHeader, Col, Row, Table, Container, Tooltip, Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { getAdmins, unblockAdmin, blockAdmin, getAdmin } from "../../../store/actions";
import { compose } from "redux";
import addBtn from '../../../assets/img/brand/plus-button.png';
import history from "../../../history.js";
import { connect } from "react-redux";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Trans } from "react-i18next";
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import notValid from '../../../assets/img/brand/notValid.png';
import CircularProgress from "@material-ui/core/CircularProgress";

class Admins extends Component {

  constructor(props) {
    super(props)
    this.state = {
      tooltipOpen: false,
      description: '',
      title: '',
      current: 1,
      blocked: '',
      modal: false,
      unblock: false,
      adminId: '',
      role: ''
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }
  onChange = (page) => {
    this.setState({
      current: page
    });
    const data = { pageNumber: page };
    this.props.getAdmins(data)
  }
  componentDidMount() {
    var token = localStorage.getItem("adminToken")
    var jwtDecode = require('jwt-decode');
    var decoded = jwtDecode(token);
    var expirationDate = decoded.exp;
    var seconds = 1000;
    var d = new Date();
    var t = d.getTime();

    if (expirationDate < Math.round(t / seconds)) {
      localStorage.setItem("adminToken", "")
      history.push("/login");
    }
    else {
      const dataAdmin = {
        adminID: decoded.data
      }
      this.props.getAdmin(dataAdmin);
      const data = { pageNumber: 1 };
      this.props.getAdmins(data)
    }

  }
  componentDidUpdate(prevProps) {
    if (this.props.getAdminData !== prevProps.getAdminData) {
      if (this.props.getAdminData.role !== undefined) {
        this.setState({
          role: this.props.getAdminData.role,
        })
      }
    }
  }
  onBlock() {
    const data = { adminID: this.state.adminId };
    this.props.blockAdmin(data);
  };
  toggleModal(adminId) {
    this.setState({
      modal: !this.state.modal,
      adminId: adminId,
    });
  }
  onUnblock() {
    const data = { adminID: this.state.adminId };
    this.props.unblockAdmin(data);
  };
  toggleUnblock(adminId) {
    this.setState({
      unblock: !this.state.unblock,
      adminId: adminId,
    });
  }

  componentWillReceiveProps(prevProps) {
    if (prevProps.blockAdminStatus !== this.props.blockAdminStatus) {
      if (prevProps.blockAdminStatus === "success") {
        this.setState({
          modal: false
        });
        ToastsStore.info(<Trans i18nKey="Admins.IsBlocked" />, 3000)
        const data = { pageNumber: 1 };
        this.props.getAdmins(data)
      }

    }
    if (prevProps.unblockAdminStatus !== this.props.unblockAdminStatus) {
      if (prevProps.unblockAdminStatus === "success") {
        this.setState({
          unblock: false
        });
        ToastsStore.info(<Trans i18nKey="Admins.NotBlocked" />, 3000)
        const data = { pageNumber: 1 };
        this.props.getAdmins(data)
      }

    }

  }
  render() {
    const { isLoading } = this.props;

    let btnValidate = (
      <Button onClick={() => this.onBlock()} type="submit" className="btn-secondary" size="md"><i className="icon-check"></i>  <Trans i18nKey="yes" /></Button>
    )
    if (isLoading) {
      btnValidate = <CircularProgress size={30} style={{ color: "#083328" }} />;
    }

    let btnUnblock = (
      <Button onClick={() => this.onUnblock()} type="submit" className="btn-secondary" size="md"><i className="icon-check"></i>  <Trans i18nKey="yes" /></Button>
    )
    if (isLoading) {
      btnUnblock = <CircularProgress size={30} style={{ color: "#083328" }} />;
    }
    return (
      <div className="animated fadeIn">
        <Row>

          <Col xl={12}>

            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> <Trans i18nKey="Admins.AdminsList" />
                {this.state.role.length !== 0 && this.state.role === "superAdmin" &&
                  <Link to={`/admin/create`}><img src={addBtn} alt="" style={{ color: "#235C4E", cursor: "pointer", marginTop: '0.5%', float: (localStorage.getItem('i18nextLng') === "en") ? 'right' : 'left' }} id={'Tooltip-' + this.props.id} />
                  </Link>

                 }
                {this.state.role.length !== 0 && this.state.role === "superAdmin" &&
                  <Tooltip isOpen={this.state.tooltipOpen} target={'Tooltip-' + this.props.id} toggle={this.toggle}>
                    <Trans i18nKey="Admins.AddAdmin" />
                  </Tooltip>
                }
              </CardHeader>
              <CardBody>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col"><Trans i18nKey="Users.pseudonym" /></th>
                      <th scope="col"><Trans i18nKey="Users.Email" /></th>
                      <th scope="col"><Trans i18nKey="Reservations.created_at" /></th>
                      <th scope="col"><Trans i18nKey="Reservations.modified_at" /></th>
                      {this.state.role.length !== 0 && this.state.role === "superAdmin" &&
                        <th scope="col"><Trans i18nKey="Admins.block" /></th>
                      }
                      {this.state.role.length !== 0 && this.state.role === "superAdmin" &&
                        <th scope="col"><Trans i18nKey="Admins.EditAdmin" /></th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.ListAdminserr === "" ?
                      Object.values(this.props.adminsData).map(adminData => {
                        var cre = adminData.createdAt;
                        var upd = adminData.updatedAt;
                        var date = new Date(cre);
                        var year = date.getFullYear();
                        var month = date.getMonth() + 1;
                        var dt = date.getDate();

                        if (dt < 10) {
                          dt = '0' + dt;
                        }
                        if (month < 10) {
                          month = '0' + month;
                        }
                        var dateUp = new Date(upd);
                        var yearUp = dateUp.getFullYear();
                        var monthUp = dateUp.getMonth() + 1;
                        var dtUp = dateUp.getDate();

                        if (dtUp < 10) {
                          dtUp = '0' + dtUp;
                        }
                        if (monthUp < 10) {
                          monthUp = '0' + monthUp;
                        }
                        return <tr key={adminData._id} id={adminData._id}>
                          <td>{adminData.Username}</td>
                          <td>{adminData.email}</td>
                          <td>{dt + '-' + month + '-' + year}</td>
                          <td>{dtUp + '-' + monthUp + '-' + yearUp}</td>
                          {this.state.role === "superAdmin" &&
                             <td>
                             {adminData.blocked === "no" ?
                               <i onClick={() => this.toggleModal(adminData._id)} className="icon-ban" style={{ color: '#A72B2A', marginLeft: '10%', cursor: "pointer" }}></i>
                               :
                               <div>
                                 <Badge pill color="danger"><Trans i18nKey="Admins.blocked" /></Badge>
                                 <i onClick={() => this.toggleUnblock(adminData._id)} className="icon-check" style={{ color: '#A72B2A', marginLeft: '10%', cursor: "pointer" }}></i>
                               </div>}
 
 
                           </td>
                          }
                       
                          {this.state.role.length !== 0 && this.state.role === "superAdmin" &&
                            <td>
                              <Link to={`/admin/${adminData._id}`}><i className="icon-pencil" style={{ color: '#A72B2A', marginLeft: '15%', cursor: "pointer" }} ></i></Link>
                            </td>
                          }
                        </tr>

                      }

                      ) : <tr><td><Trans i18nKey="Admins.noAdmin" /></td></tr>}

                    <Modal isOpen={this.state.modal} toggle={() => this.toggleModal()} className={this.props.className}
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)"
                      }}>
                      <ModalHeader toggle={() => this.toggleModal()}><Trans i18nKey="Admins.block" /></ModalHeader>

                      <ModalBody>
                        <br />
                        {(localStorage.getItem('i18nextLng') === "en") ? "Do you really want to block this admin ?" : "هل تريد حقًا حظر هذا المشرف ؟"}
                        <br />
                        <br />
                      </ModalBody>
                      <ModalFooter>
                        {btnValidate}{' '}
                        <Button style={{
                          color: "white",
                          backgroundColor: "#C6893F",
                          borderColor: "#C6893F",
                          marginRight: "10px"
                        }} onClick={() => this.toggleModal()}><i className="icon-ban"></i> <Trans i18nKey="cancel" /></Button>
                      </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.unblock} toggle={() => this.toggleUnblock()} className={this.props.className}
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)"
                      }}>
                      <ModalHeader toggle={() => this.toggleUnblock()}><Trans i18nKey="Admins.unblock" /></ModalHeader>

                      <ModalBody>
                        <br />
                        {(localStorage.getItem('i18nextLng') === "en") ? "Do you really want to unblock this admin ?" : "هل تريد حقًا إلغاء حظر هذا المشرف ؟"}
                        <br />
                        <br />
                      </ModalBody>
                      <ModalFooter>
                        {btnUnblock}{' '}
                        <Button style={{
                          color: "white",
                          backgroundColor: "#C6893F",
                          borderColor: "#C6893F",
                          marginRight: "10px"
                        }} onClick={() => this.toggleUnblock()}><i className="icon-ban"></i> <Trans i18nKey="cancel" /></Button>
                      </ModalFooter>
                    </Modal>

                  </tbody>
                </Table>
                <Pagination onChange={this.onChange} showTitle={false} current={this.state.current} total={this.props.adminsListNbr} />
              </CardBody>
            </Card>
          </Col>
          <ToastsContainer store={ToastsStore} />
          {
            this.state.blocked !== '' ? <Container>
              <Row className="justify-content-center">
                <img src={notValid} alt="notvalid" style={
                  {
                    width: "20px",
                    marginRight: "10px",
                    height: "20px",
                    marginLeft: "10px"
                  }
                } />
                <p style={{ color: 'red' }}> {this.state.blocked} </p>
              </Row>
            </Container>
              : <div></div>
          }
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    adminsData: state.adminsData.adminsData,
    adminsListNbr: state.adminsListNbr.adminsListNbr,
    isLoading: state.ui.isLoading,
    ListAdminserr: state.ListAdminserr.ListAdminserr,
    unblockAdminerr: state.unblockAdminerr.blockAdminerr,
    blockAdminerr: state.blockAdminerr.blockAdminerr,
    unblockAdminStatus: state.unblockAdminStatus.unblockAdminStatus,
    blockAdminStatus: state.blockAdminStatus.blockAdminStatus,
    getAdminData: state.getAdminData.getAdminData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAdmins: (data) => dispatch(getAdmins(data)),
    blockAdmin: (data) => dispatch(blockAdmin(data)),
    unblockAdmin: (data) => dispatch(unblockAdmin(data)),
    getAdmin: (data) => dispatch(getAdmin(data))
  };
};


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Admins);