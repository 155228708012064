import axios from "axios";

import {
  uiStartLoading,
  uiStopLoading
} from "../index";
import {
  PHOTO_CREATE
} from "../actionTypes";
import {
  CREATE_PHOTO_ERR
} from "../actionTypes";
import {
  STATUS_PHOTO_CREATE
} from "../actionTypes";

import {
  PHOTOS_DATA
} from "../actionTypes";
import {
  PHOTOS_LIST_ERR
} from "../actionTypes";
import {
  PHOTOS_NBR
} from "../actionTypes";


import {
  PHOTO_DATA
} from "../actionTypes";
import {
  PHOTO_ERR
} from "../actionTypes";

import {
  PHOTO_DELETE
} from "../actionTypes";
import {
  PHOTO_DELETE_ERR
} from "../actionTypes";
import {
  PHOTO_DELETE_STATUS
} from "../actionTypes";

import {
  PHOTO_EDIT_ERR
} from "../actionTypes";
import {
  PHOTO_EDIT
} from "../actionTypes";
import {
  PHOTO_EDIT_STATUS
} from "../actionTypes";

export const createPhotoAlbum = data => {
  return dispatch => {

    dispatch(uiStartLoading());
    dispatch(CreatePhotoStatus(""));
    var token = localStorage.getItem("adminToken");

    const form = new FormData();

    for (let i = 0; i < data.pictures.length; i++) {
      form.append("photosList", data.pictures[i]);
    }

    form.append("title", data.title);
    form.append("active", data.active);
    form.append("ARtitle", data.ARtitle);
    form.append("cover", data.cover);

    const config = {
      headers: {
        enctype: "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token
      }
    };

    axios
      .post(`${process.env.REACT_APP_DOMAIN}/api/photosAlbum/add`, form, config, {
        useCredentails: true
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(parsedRes => {
        dispatch(CreatePhotoStatus(""));
        dispatch(uiStopLoading())

        if (parsedRes.data.status === "fail") {
          dispatch(CreatePhotoerr(parsedRes.data.error));
        } else {
          dispatch(CreatePhotoerr(""));
          dispatch(CreatePhoto(parsedRes.data.data));
          dispatch(CreatePhotoStatus(parsedRes.data.status));

        }

      });
  };
};


export const editPhotoAlbum = data => {
  return dispatch => {
    dispatch(uiStartLoading());
    var token = localStorage.getItem("adminToken");

    const form = new FormData();
    for (let i = 0; i < data.pictures.length; i++) {
      form.append("photosList", data.pictures[i]);
    }
    for (let i = 0; i < data.gallery.length; i++) {
      form.append("gallery", data.gallery[i]);
    }
    form.append("photosAlbumID", data.photosAlbumID);
    form.append("title", data.title);
    form.append("ARtitle", data.ARtitle);
    form.append("active", data.active);
    form.append("cover", data.cover);


    const config = {
      headers: {
        enctype: "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token
      }
    };

    axios
      .post(`${process.env.REACT_APP_DOMAIN}/api/photosAlbum/edit`, form, config, {
        useCredentails: true
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(parsedRes => {
        dispatch(EditPhotostatus(""));
        dispatch(uiStopLoading())

        if (parsedRes.data.status === "fail") {

          dispatch(EditPhotoerr(parsedRes.data.error));
        } else if (parsedRes.data.status === "failure") {

          dispatch(EditPhotoerr(parsedRes.data.error));
        } else {
          dispatch(EditPhotoerr(""));
          dispatch(EditPhoto(parsedRes.data.data));
          dispatch(EditPhotostatus(parsedRes.data.status));
        }

      });
  };


};
export const EditPhotoerr = value => {
  return {
    type: PHOTO_EDIT_ERR,
    EditPhotoerr: value
  };
};
export const EditPhoto = value => {
  return {
    type: PHOTO_EDIT,
    EditPhoto: value
  };
};
export const EditPhotostatus = value => {
  return {
    type: PHOTO_EDIT_STATUS,
    EditPhotostatus: value
  };
};

export const getPhotoAlbum = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/photosAlbum/get`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-access-token": token

        },
        body: JSON.stringify({
          photosAlbumID: data.photosAlbumID,
          pageNumber: data.pageNumber

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())

        if (parsedRes.status === "success") {
          dispatch(photoData(parsedRes.data));

        } else {
          dispatch(photoErr(parsedRes.data));
        }


      });
  };
};

export const DeletePhoto = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(deletePhotostatus(""));
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/photosAlbum/delete/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token

        },
        body: JSON.stringify({
          photosAlbumID: data.photosAlbumID,

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(deletePhotostatus(""));
        dispatch(uiStopLoading())
        if (parsedRes.status === "fail") {
          dispatch(DeletePhotoerr(parsedRes.error));
        } else {
          dispatch(DeletePhotoerr(""));
          dispatch(deletePhoto(parsedRes.data));
          dispatch(deletePhotostatus(parsedRes.status));
        }

      });
  };
};

export const getphotosAlbumList = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/photosAlbum/getAdminList`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token

        },
        body: JSON.stringify({
          pageNumber: data.pageNumber

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        if (parsedRes.status === "failure") {
          dispatch(listPhotosErr(parsedRes.error));

        } else if (parsedRes.status === "fail") {
          dispatch(listPhotosErr(parsedRes.error));
        } else {
          dispatch(listPhotosErr(""));
          dispatch(PhotosData(parsedRes.data));
          dispatch(PhotosNbr(parsedRes.Length));
        }

      });
  };
};

export const CreatePhoto = value => {
  return {
    type: PHOTO_CREATE,
    CreatePhoto: value
  };
};
export const CreatePhotoerr = value => {
  return {
    type: CREATE_PHOTO_ERR,
    CreatePhotoerr: value
  };
};
export const CreatePhotoStatus = value => {
  return {
    type: STATUS_PHOTO_CREATE,
    CreatePhotoStatus: value
  };
};
export const listPhotosErr = value => {
  return {
    type: PHOTOS_LIST_ERR,
    listPhotosErr: value
  };
};
export const PhotosData = value => {
  return {
    type: PHOTOS_DATA,
    PhotosData: value
  };
};
export const PhotosNbr = value => {
  return {
    type: PHOTOS_NBR,
    PhotosNbr: value
  };
};
export const DeletePhotoerr = value => {
  return {
    type: PHOTO_DELETE_ERR,
    DeletePhotoerr: value
  };
};

export const deletePhoto = value => {
  return {
    type: PHOTO_DELETE,
    deletePhoto: value
  };
};

export const deletePhotostatus = value => {
  return {
    type: PHOTO_DELETE_STATUS,
    deletePhotostatus: value
  };
};

export const photoData = value => {
  return {
    type: PHOTO_DATA,
    photoData: value
  };
};

export const photoErr = value => {
  return {
    type: PHOTO_ERR,
    photoErr: value
  };
};