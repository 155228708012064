import axios from "axios";
import {
  BANNERS_DATA
} from "../actionTypes";
import {
  BANNER_DATA
} from "../actionTypes";
import {
  uiStartLoading,
  uiStopLoading
} from "../index";
import {
  LIST_BANNERS_ERR
} from "../actionTypes";

import {
  BANNER_EDIT
} from "../actionTypes";
import {
  EDIT_BANNER_ERR,
  BANNERS_NB
} from "../actionTypes";
import {
  STATUS_BANNER_EDIT
} from "../actionTypes";

import {
  BANNER_DELETE
} from "../actionTypes";
import {
  DELETE_BANNER_ERR
} from "../actionTypes";
import {
  STATUS_BANNER_DELETE
} from "../actionTypes";

import {
  BANNER_CREATE
} from "../actionTypes";
import {
  CREATE_BANNER_ERR
} from "../actionTypes";
import {
  STATUS_BANNER_CREATE
} from "../actionTypes";

import {
  BANNER_EDIT_AVATAR
} from "../actionTypes";
import {
  EDIT_BANNER_AVATAR_ERR
} from "../actionTypes";
import {
  STATUS_BANNER_AVATAR_EDIT
} from "../actionTypes";

export const getBanners = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/banners/getList`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          pageNumber: data.pageNumber

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {

        dispatch(uiStopLoading())
        if (parsedRes.status === "failure") {
          dispatch(listBannersErr(parsedRes.error));

        } else {
          dispatch(listBannersErr(""));
          dispatch(bannersData(parsedRes.data));
          dispatch(bannersNbr(parsedRes.Length));

        }

      });
  };
};
export const bannersNbr = value => {
  return {
    type: BANNERS_NB,
    bannersNbr: value
  }
}
export const getBanner = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/banner/get`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-access-token": token
        },
        body: JSON.stringify({
          bannerID: data.bannerID

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        dispatch(bannerData(parsedRes.data));

      });
  };
};

export const editBanner = dataImg => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    const form = new FormData();
    form.append("bannerID", dataImg.bannerID);
    form.append("title", dataImg.title);
    form.append("description", dataImg.description);
    form.append("active", dataImg.active);
    form.append("last", dataImg.last);
    form.append("image", dataImg.image);
    form.append("imageAR", dataImg.imageAR);
    form.append("ARtitle", dataImg.ARtitle);
    form.append("ARdescription", dataImg.ARdescription);
    form.append("order", dataImg.order);


    const config = {
      headers: {
        enctype: "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token
      }
    };

    axios
      .post(`${process.env.REACT_APP_DOMAIN}/api/banner/edit`, form, config, {
        useCredentails: true
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(parsedRes => {
        dispatch(editBannerStatus(""));
        dispatch(uiStopLoading())
        dispatch(EditBannererr(""));

        if (parsedRes.data.status === "fail") {

          dispatch(EditBannererr(parsedRes.data.error));
        } else {
          dispatch(EditBannererr(""));
          dispatch(editbanner(parsedRes.data.data));
          dispatch(editBannerStatus(parsedRes.data.status));
        }

      });
  };


};
export const editBannerAndAvatar = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    const form = new FormData();
    form.append("bannerID", data.bannerID);
    form.append("title", data.title);
    form.append("description", data.description);
    form.append("active", data.active);
    form.append("last", data.last);
    form.append("image", data.image);
    form.append("imageAR", data.imageAR);
    form.append("ARtitle", data.ARtitle);
    form.append("ARdescription", data.ARdescription);
    form.append("order", data.order);

    const config = {
      headers: {
        enctype: "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token

      }
    };

    axios
      .post(`${process.env.REACT_APP_DOMAIN}/api/banner/editBannerAndAvatar`, form, config, {
        useCredentails: true
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(parsedRes => {
        dispatch(editBannerAvatarStatus(""));
        dispatch(uiStopLoading())

        if (parsedRes.data.status === "fail") {
          dispatch(EditBannerAvatarerr(parsedRes.data.error));
        } else {
          dispatch(EditBannerAvatarerr(""));
          dispatch(editbannerAvatar(parsedRes.data.data));
          dispatch(editBannerAvatarStatus(parsedRes.data.status));
        }

      });
  };


};

export const createBanner = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(CreateBannerStatus(""));
    dispatch(uiStartLoading());

    const form = new FormData();
    form.append("title", data.title);
    form.append("description", data.description);
    form.append("active", data.active);
    form.append("last", data.last);
    form.append("image", data.image);
    form.append("imageAR", data.imageAR);
    form.append("ARtitle", data.ARtitle);
    form.append("ARdescription", data.ARdescription);
    form.append("order", data.order);

    const config = {
      headers: {
        enctype: "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token
      }
    };

    axios
      .post(`${process.env.REACT_APP_DOMAIN}/api/banner/add`, form, config, {
        useCredentails: true
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(parsedRes => {
        dispatch(CreateBannerStatus(""));
        dispatch(uiStopLoading())

        if (parsedRes.data.status === "fail") {
          dispatch(CreateBannererr(parsedRes.data.error));
        } else {
          dispatch(CreateBannererr(""));
          dispatch(CreateBanner(parsedRes.data.data));
          dispatch(CreateBannerStatus(parsedRes.data.status));

        }

      });
  };
};
export const DeleteBanner = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(deleteBannerStatus(""));
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/banner/delete`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          bannerID: data.bannerID,

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(deleteBannerStatus(""));
        dispatch(uiStopLoading())
        if (parsedRes.status === "fail") {
          dispatch(DeleteBannererr(parsedRes.error));
        } else {
          dispatch(DeleteBannererr(""));
          dispatch(deleteBanner(parsedRes.data));
          dispatch(deleteBannerStatus(parsedRes.status));
        }

      });
  };
};


export const bannersData = value => {
  return {
    type: BANNERS_DATA,
    bannersData: value
  };
};
export const bannerData = value => {
  return {
    type: BANNER_DATA,
    bannerData: value
  };
};

export const editbanner = value => {
  return {
    type: BANNER_EDIT,
    editbanner: value
  };
};
export const editBannerStatus = value => {
  return {
    type: STATUS_BANNER_EDIT,
    editBannerStatus: value
  };
};
export const listBannersErr = value => {
  return {
    type: LIST_BANNERS_ERR,
    listBannersErr: value
  };
};

export const EditBannererr = value => {
  return {
    type: EDIT_BANNER_ERR,
    EditBannererr: value
  };
};

export const deleteBanner = value => {
  return {
    type: BANNER_DELETE,
    deleteBanner: value
  };
};
export const DeleteBannererr = value => {
  return {
    type: DELETE_BANNER_ERR,
    DeleteBannererr: value
  };
};
export const deleteBannerStatus = value => {
  return {
    type: STATUS_BANNER_DELETE,
    deleteBannerStatus: value
  };
};

export const CreateBanner = value => {
  return {
    type: BANNER_CREATE,
    CreateBanner: value
  };
};
export const CreateBannererr = value => {
  return {
    type: CREATE_BANNER_ERR,
    CreateBannererr: value
  };
};
export const CreateBannerStatus = value => {
  return {
    type: STATUS_BANNER_CREATE,
    CreateBannerStatus: value
  };
};

export const editbannerAvatar = value => {
  return {
    type: BANNER_EDIT_AVATAR,
    editbannerAvatar: value
  };
};
export const editBannerAvatarStatus = value => {
  return {
    type: STATUS_BANNER_AVATAR_EDIT,
    editBannerAvatarStatus: value
  };
};

export const EditBannerAvatarerr = value => {
  return {
    type: EDIT_BANNER_AVATAR_ERR,
    EditBannerAvatarerr: value
  };
};