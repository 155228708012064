import { NOTIFICATIONS_DATA, NOTIFICATIONS_NB } from "../../actions/actionTypes";
import { NOTIFICATION_DATA } from "../../actions/actionTypes";
import { LIST_NOTIFICATIONS_ERR } from "../../actions/actionTypes";

import { NOTIFICATION_EDIT } from "../../actions/actionTypes";
import { EDIT_NOTIFICATION_ERR } from "../../actions/actionTypes";
import { STATUS_NOTIFICATION_EDIT } from "../../actions/actionTypes";

import { DELETE_NOTIFICATION_ERR } from "../../actions/actionTypes";
import { STATUS_NOTIFICATION_DELETE } from "../../actions/actionTypes";
import { NOTIFICATION_DELETE } from "../../actions/actionTypes";

import { CREATE_NOTIFICATION_ERR } from "../../actions/actionTypes";
import { STATUS_NOTIFICATION_CREATE } from "../../actions/actionTypes";
import { NOTIFICATION_CREATE } from "../../actions/actionTypes";

import { SEND_NOTIFICATION_ERR } from "../../actions/actionTypes";
import { STATUS_NOTIFICATION_SEND } from "../../actions/actionTypes";
import { NOTIFICATION_SEND } from "../../actions/actionTypes";

const initialState = {
  notificationsData: [],
  notificationData: [],
  editnotification: [],
  Editnotificationerr: '',
  listnotificationsErr: '',
  editnotificationStatus: '',
  deleteNotification: [],
  DeleteNotificationerr: '',
  deletenotificationStatus: '',
  CreateNotification: [],
  Createnotificationerr: '',
  CreatenotificationStatus: '',
  listActNotificationsErr: '',
  sendnotification: [],
  SendNotificationerr: '',
  sendnotificationStatus: '',
  notificationsNbr: 0

};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATIONS_NB:
      return {
        ...state,
        notificationsNbr: action.notificationsNbr
      };
    case NOTIFICATIONS_DATA:
      return {
        ...state,
        notificationsData: action.notificationsData
      };
    case NOTIFICATION_DATA:
      return {
        ...state,
        notificationData: action.notificationData
      };
    case NOTIFICATION_EDIT:
      return {
        ...state,
        editnotification: action.editnotification
      };
    case LIST_NOTIFICATIONS_ERR:
      return {
        ...state,
        listnotificationsErr: action.listnotificationsErr
      };
    case EDIT_NOTIFICATION_ERR:
      return {
        ...state,
        Editnotificationerr: action.Editnotificationerr
      };
    case STATUS_NOTIFICATION_EDIT:
      return {
        ...state,
        editnotificationStatus: action.editnotificationStatus
      };
    case DELETE_NOTIFICATION_ERR:
      return {
        ...state,
        DeleteNotificationerr: action.DeleteNotificationerr
      };
    case STATUS_NOTIFICATION_DELETE:
      return {
        ...state,
        deletenotificationStatus: action.deletenotificationStatus
      };
    case NOTIFICATION_DELETE:
      return {
        ...state,
        deleteNotification: action.deleteNotification
      };

    case CREATE_NOTIFICATION_ERR:
      return {
        ...state,
        Createnotificationerr: action.Createnotificationerr
      };
    case STATUS_NOTIFICATION_CREATE:
      return {
        ...state,
        CreatenotificationStatus: action.CreatenotificationStatus
      };
    case NOTIFICATION_CREATE:
      return {
        ...state,
        CreateNotification: action.CreateNotification
      };

    case SEND_NOTIFICATION_ERR:
      return {
        ...state,
        SendNotificationerr: action.SendNotificationerr
      };
    case STATUS_NOTIFICATION_SEND:
      return {
        ...state,
        sendnotificationStatus: action.sendnotificationStatus
      };
    case NOTIFICATION_SEND:
      return {
        ...state,
        sendnotification: action.sendnotification
      };
    default:
      return state;
  }
};

export default reducer;
