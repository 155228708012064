import {
  USER_EDIT
} from "../actionTypes";
import {
  USERS_DATA
} from "../actionTypes";
import {
  USER_DATA
} from "../actionTypes";
import {
  USER_DATA_DETAILS
} from "../actionTypes";
import {
  USERS_TOKEN_DATA,
  USERS_NBR,
  USERS_LIST_NB
} from "../actionTypes";
import {
  uiStartLoading,
  uiStopLoading
} from "../index";
import {
  LIST_ERR
} from "../actionTypes";
import {
  LIST_TOKEN_ERR
} from "../actionTypes";
import {
  EDIT_ERR
} from "../actionTypes";
import {
  STATUS_EDIT
} from "../actionTypes";
import {
  decode
} from "../../lib/cryp";
export const getUsers = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/usersList`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token

        },
        body: JSON.stringify({
          token: localStorage.getItem("adminToken"),
          pageNumber: data.pageNumber
        })
      })

      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(async parsedRes => {
        dispatch(uiStopLoading())
        if (parsedRes.status === "failure") {
          dispatch(Listerr(parsedRes.error));

        } else {
          dispatch(Listerr(""));
          let data = await decode(JSON.stringify(parsedRes.data));

          dispatch(usersData(JSON.parse(data)));
          dispatch(usersListNbr(parsedRes.Length));
        }

      });
  };
};
export const usersListNbr = value => {
  return {
    type: USERS_LIST_NB,
    usersListNbr: value
  }
}
export const getUsersTokens = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/registration/list`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          pageNumber: data.pageNumber

        })

      })

      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        if (parsedRes.status === "failure") {
          dispatch(ListTokenerr(parsedRes.error));

        } else {
          dispatch(ListTokenerr(""));
          dispatch(usersTokensData(parsedRes.data));
          dispatch(usersNbr(parsedRes.Length));
        }

      });
  };
};
export const getUser = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/
    *ser`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token

        },
        body: JSON.stringify({
          token: localStorage.getItem("adminToken"),
          clientID: data.clientID
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        dispatch(userData(parsedRes.data));

      });
  };
};

export const getUserDetails = data => {
console.log(data)
  return dispatch => {
    dispatch(userDataDetails());
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/getUserDetails`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"

        },
        body: JSON.stringify({
          user_id: data
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        dispatch(userDataDetails(parsedRes.data));

      });
  };
};
export const editUser = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(editstatus(""));
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/updateUser`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token

        },
        body: JSON.stringify({
          token: localStorage.getItem("adminToken"),
          clientID: data.clientID,
          firstname: data.firstname,
          lastname: data.lastname,
          country: data.country,
          phone: data.phone
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(editstatus(""));
        dispatch(uiStopLoading())
        if (parsedRes.status === "fail") {
          dispatch(Editerr(parsedRes.error));

        } else {
          dispatch(Editerr(""));
          dispatch(edituser(parsedRes.data));
          dispatch(editstatus(parsedRes.status));
        }

      });
  };
};

export const userDataDetails = value => {
  return {
    type: USER_DATA_DETAILS,
    userDataDetails: value
  };
};
export const usersNbr = value => {
  return {
    type: USERS_NBR,
    usersNbr: value
  };
};

export const usersData = value => {
  return {
    type: USERS_DATA,
    usersData: value
  };
};
export const userData = value => {
  return {
    type: USER_DATA,
    userData: value
  };
};

export const edituser = value => {
  return {
    type: USER_EDIT,
    edituser: value
  };
};
export const editstatus = value => {
  return {
    type: STATUS_EDIT,
    editstatus: value
  };
};
export const Listerr = value => {
  return {
    type: LIST_ERR,
    Listerr: value
  };
};
export const Editerr = value => {
  return {
    type: EDIT_ERR,
    Editerr: value
  };
};
export const ListTokenerr = value => {
  return {
    type: LIST_TOKEN_ERR,
    ListTokenerr: value
  };
};
export const usersTokensData = value => {
  return {
    type: USERS_TOKEN_DATA,
    usersTokensData: value
  };
};