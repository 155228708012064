import { ADMIN_AUTH } from "../../actions/actionTypes";
import { ADMIN_SIGN } from "../../actions/actionTypes";
import { AUTH_ERR } from "../../actions/actionTypes";
import { SIGN_ERR } from "../../actions/actionTypes";
import { ADMINS_LIST_NB, ADMINS_ERR, ADMINS_DATA } from "../../actions/actionTypes";
import { ADMIN_ERR, ADMIN_DATA } from "../../actions/actionTypes";
import { EDIT_ADMIN_ERR, ADMIN_EDIT, STATUS_ADMIN_EDIT } from "../../actions/actionTypes";
import { UNBLOCK_ADMIN_STATUS, BLOCK_ADMIN_STATUS, UNBLOCK_ADMIN_ERR, BLOCK_ADMIN_ERR } from "../../actions/actionTypes";

const initialState = {
  adminSign: [],
  adminAuth: [],
  Autherr: '',
  Signerr: '',
  adminsListNbr: 0,
  adminsData: [],
  ListAdminserr: '',
  getAdminData: [],
  getAdminErr: '',
  editAdminStatus: '',
  editADMIN: [],
  EditAdminerr: '',
  unblockAdminerr:'',
  blockAdminerr: '',
  unblockAdminStatus:'',
  blockAdminStatus:''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_SIGN:
      return {
        ...state,
        adminSign: action.adminSign
      };
    case ADMIN_AUTH:
      return {
        ...state,
        adminAuth: action.adminAuth
      };
    case AUTH_ERR:
      return {
        ...state,
        Autherr: action.Autherr
      };
    case SIGN_ERR:
      return {
        ...state,
        Signerr: action.Signerr
      };
    case ADMINS_LIST_NB:
      return {
        ...state,
        adminsListNbr: action.adminsListNbr
      };
    case ADMINS_DATA:
      return {
        ...state,
        adminsData: action.adminsData
      };
    case ADMINS_ERR:
      return {
        ...state,
        ListAdminserr: action.ListAdminserr
      };
    case ADMIN_DATA:
      return {
        ...state,
        getAdminData: action.getAdminData
      };
    case ADMIN_ERR:
      return {
        ...state,
        getAdminErr: action.getAdminErr
      };

    case EDIT_ADMIN_ERR:
      return {
        ...state,
        EditAdminerr: action.EditAdminerr
      };
    case ADMIN_EDIT:
      return {
        ...state,
        editADMIN: action.editADMIN
      };
    case STATUS_ADMIN_EDIT:
      return {
        ...state,
        editAdminStatus: action.editAdminStatus
      };

    case BLOCK_ADMIN_STATUS:
      return {
        ...state,
        blockAdminStatus: action.blockAdminStatus
      };

    case UNBLOCK_ADMIN_STATUS:
      return {
        ...state,
        unblockAdminStatus: action.unblockAdminStatus
      };
    case UNBLOCK_ADMIN_ERR:
      return {
        ...state,
        unblockAdminerr: action.unblockAdminerr
      };
    case BLOCK_ADMIN_ERR:
      return {
        ...state,
        blockAdminerr: action.blockAdminerr
      };
    default:
      return state;
  }
};

export default reducer;
