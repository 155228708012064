import { TIMINGS_DATA, TIMINGS_NB, LIST_TIMINGS_ERR, TIMING_DATA } from "../../actions/actionTypes";
import { TIMING_DELETE, DELETE_TIMING_ERR, STATUS_TIMING_DELETE } from "../../actions/actionTypes";
import { TIMING_CREATE } from "../../actions/actionTypes";
import { CREATE_TIMING_ERR } from "../../actions/actionTypes";
import { STATUS_TIMING_CREATE } from "../../actions/actionTypes";
import { TIMING_EDIT, EDIT_TIMING_ERR, STATUS_TIMING_EDIT } from "../../actions/actionTypes";
import { ABOUT_EDIT, EDIT_ABOUT_ERR, STATUS_ABOUT_EDIT, ABOUT_Data } from "../../actions/actionTypes";


const initialState = {
  CreateTiming: [],
  CreateTimingerr: '',
  CreateTimingstatus: '',
  listTimingsErr: '',
  timingsData: [],
  timingsNb: '',
  deleteTimingstatus: '',
  DeleteTimingerr: '',
  deleteTiming: [],
  editTimingStatus: '',
  edittiming: [],
  EditTimingerr: '',
  timingData: [],
  editAboutDescStatus: '',
  editaboutDesc: [],
  EditAboutDescerr: '',
  aboutDescData: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_TIMING_ERR:
      return {
        ...state,
        CreateTimingerr: action.CreateTimingerr
      };
    case STATUS_TIMING_CREATE:
      return {
        ...state,
        CreateTimingstatus: action.CreateTimingstatus
      };
    case TIMING_CREATE:
      return {
        ...state,
        CreateTiming: action.CreateTiming
      };
    case TIMINGS_DATA:
      return {
        ...state,
        timingsData: action.timingsData
      };
    case LIST_TIMINGS_ERR:
      return {
        ...state,
        listTimingsErr: action.listTimingsErr
      };
    case TIMINGS_NB:
      return {
        ...state,
        timingsNb: action.timingsNb
      };
    case TIMING_DELETE:
      return {
        ...state,
        deleteTiming: action.deleteTiming
      };
    case DELETE_TIMING_ERR:
      return {
        ...state,
        DeleteTimingerr: action.DeleteTimingerr
      };
    case STATUS_TIMING_DELETE:
      return {
        ...state,
        deleteTimingstatus: action.deleteTimingstatus
      };
    //
    case TIMING_EDIT:
      return {
        ...state,
        edittiming: action.edittiming
      };
    case EDIT_TIMING_ERR:
      return {
        ...state,
        EditTimingerr: action.EditTimingerr
      };
    case STATUS_TIMING_EDIT:
      return {
        ...state,
        editTimingStatus: action.editTimingStatus
      };
    case TIMING_DATA:
      return {
        ...state,
        timingData: action.timingData
      };
    //
    case ABOUT_EDIT:
      return {
        ...state,
        editaboutDesc: action.editaboutDesc
      };
    case EDIT_ABOUT_ERR:
      return {
        ...state,
        EditAboutDescerr: action.EditAboutDescerr
      };
    case STATUS_ABOUT_EDIT:
      return {
        ...state,
        editAboutDescStatus: action.editAboutDescStatus
      };
    case ABOUT_Data:
      return {
        ...state,
        aboutDescData: action.aboutDescData
      };



    default:
      return state;
  }
};

export default reducer;
