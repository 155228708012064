import { EVENTS_DATA } from "../../actions/actionTypes";
import { EVENT_DATA } from "../../actions/actionTypes";
import { LIST_EVENTS_ERR } from "../../actions/actionTypes";
import { EVENTS_NBR } from "../../actions/actionTypes";
import { EVENT_EDIT } from "../../actions/actionTypes";
import { EDIT_EVENT_ERR } from "../../actions/actionTypes";
import { STATUS_EVENT_EDIT } from "../../actions/actionTypes";
import { LIST_EVENTS_ACT_DATA } from "../../actions/actionTypes";
import { LIST_EVENTS_ACT_ERR } from "../../actions/actionTypes";

import { DELETE_EVENT_ERR } from "../../actions/actionTypes";
import { STATUS_EVENT_DELETE } from "../../actions/actionTypes";
import { EVENT_DELETE } from "../../actions/actionTypes";

import { CREATE_EVENT_ERR } from "../../actions/actionTypes";
import { STATUS_EVENT_CREATE } from "../../actions/actionTypes";
import { EVENT_CREATE } from "../../actions/actionTypes";

const initialState = {
  eventsData: [],
  eventData: [],
  ActeventsData: [],
  listActEventsErr: '',
  EventsNbr: 0,
  editevent: [],
  EditEventerr: '',
  listEventsErr: '',
  editEventstatus: '',
  deleteEvent: [],
  DeleteEventerr: '',
  deleteEventstatus: '',
  CreateEvent: [],
  CreateEventerr: '',
  CreateEventstatus: ''  
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_EVENTS_ACT_DATA:
      return {
        ...state,
        ActeventsData: action.ActeventsData
      };
    case LIST_EVENTS_ACT_ERR:
      return {
        ...state,
        listActEventsErr: action.listActEventsErr
      };
    case EVENTS_DATA:
      return {
        ...state,
        eventsData: action.eventsData
      };
    case EVENT_DATA:
      return {
        ...state,
        eventData: action.eventData
      };
    case EVENT_EDIT:
      return {
        ...state,
        editevent: action.editevent
      };
    case LIST_EVENTS_ERR:
      return {
        ...state,
        listEventsErr: action.listEventsErr
      };
    case EDIT_EVENT_ERR:
      return {
        ...state,
        EditEventerr: action.EditEventerr
      };
    case STATUS_EVENT_EDIT:
      return {
        ...state,
        editEventstatus: action.editEventstatus
      };
    case DELETE_EVENT_ERR:
      return {
        ...state,
        DeleteEventerr: action.DeleteEventerr
      };
    case STATUS_EVENT_DELETE:
      return {
        ...state,
        deleteEventstatus: action.deleteEventstatus
      };
    case EVENT_DELETE:
      return {
        ...state,
        deleteEvent: action.deleteEvent
      };

    case CREATE_EVENT_ERR:
      return {
        ...state,
        CreateEventerr: action.CreateEventerr
      };
    case STATUS_EVENT_CREATE:
      return {
        ...state,
        CreateEventstatus: action.CreateEventstatus
      };
    case EVENT_CREATE:
      return {
        ...state,
        CreateEvent: action.CreateEvent
      };
    case EVENTS_NBR:
      return {
        ...state,
        EventsNbr: action.EventsNbr
      };

    default:
      return state;
  }
};

export default reducer;
