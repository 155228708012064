import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardFooter, Col, Row, Button, Form, FormGroup, Input, Label, Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { createEvent, getActivePlacesThings } from "../../../store/actions";
import { DropzoneArea } from 'material-ui-dropzone'
import { compose } from "redux";
import { connect } from "react-redux";
import notValid from '../../../assets/img/brand/notValid.png';
import history from "../../../history";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Trans } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import jwtDecode from 'jwt-decode'
import TextField from '@material-ui/core/TextField';

class CreateEvent extends Component {

  constructor(props) {
    super(props)
    this.state = {
      description: '',
      title: '',
      maxPersons: 1,
      ARdescription: '',
      ARtitle: '',
      place: '',
      price: 0,
      date: '',
      Enddate: '',
      type: '',
      active: '',
      files: [],
      typeEvent:'current',
      empty: '',
      places: [],
      activeTab: new Array(2).fill('1'),
      begin: "",
      end: ""
    }
    this.toggle = this.toggle.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
    this.handleChangeActive = this.handleChangeActive.bind(this);
    this.onPriceChange = this.onPriceChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleBegin = this.handleBegin.bind(this);
    this.handleEnd = this.handleEnd.bind(this);
    this.handleChangeTypeEvent=this.handleChangeTypeEvent.bind(this)
  }
  handleBegin(event) {
    this.setState({ begin: event });
  };
  handleEnd(event) {
    this.setState({ end: event });
  };
  handleChange(event) {
    var options = event.target.options;
    var value
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value = options[i].value;
      }
    }
    this.setState({
      place: value
    });

  }
  toggle(tabPane, tab) {
    const newArray = this.state.activeTab.slice()
    newArray[tabPane] = tab
    this.setState({
      activeTab: newArray,
    });
  }

  handleChangeImage(files) {
    let copy = [];
    for (var i = 0, l = files.length; i < l; i++) {
      copy.push(files[i])
    }
    this.setState({
      files: files
    });
  }

  onCreate = () => {
    var token = localStorage.getItem("adminToken")
    this.setState({ empty: "" });
    var price
    if (this.state.type === "free") {
      price = 0
    }
    else {
      price = this.state.price
    }
    const data = {
      description: this.state.description,
      title: this.state.title,
      ARdescription: this.state.ARdescription,
      ARtitle: this.state.ARtitle,
      place: this.state.place,
      date: this.state.date,
      Enddate: this.state.Enddate,
      begin: this.state.begin,
      end: this.state.end,
      type: this.state.type,
      price: price,
      active: this.state.active,
      image: this.state.files,
      adminID: jwtDecode(token).data,
      maxNbrPersons: this.state.maxPersons,
      eventType:this.state.typeEvent
    };
    if (data.type === "Payment" && data.price === 0) {
      this.setState({ empty: <Trans i18nKey="priceErr" /> });
    }
    else if (data.title.length === 0 || data.description.length === 0 || data.place.length === 0 || data.ARdescription.length === 0 ||
      data.ARtitle.length === 0 || data.date.length === 0  || data.Enddate.length === 0 || data.type.length === 0 || data.active.length === 0  || data.eventType.length === 0) {

      this.setState({ empty: <Trans i18nKey="requiredFields" /> });

    }
    else if ((data.begin.length === 0 && data.end.length !== 0) || (data.end.length === 0 && data.begin.length !== 0)) {

      this.setState({ empty: <Trans i18nKey="timingErr" /> });
    }
    else if (data.maxNbrPersons < 1) {
      this.setState({ empty: "The maximum number of persons cannot be less than 1" });
    }
    else if (data.image === undefined) {
      this.setState({ empty: <Trans i18nKey="Events.imgRequired" /> });
    }

    else {
      this.setState({ empty: "" });
      this.props.createEvent(data)

    }

  };
  onmaxPersonsChange(value) {
    this.setState({
      maxPersons: value
    });
  }
  componentDidUpdate(prevProps) {
    var Places = []
    if (this.props.ActplacesData !== prevProps.ActplacesData) {
      if (this.props.ActplacesData !== undefined) {
        if (this.props.ActplacesData.length !== 0) {
          for (let i = 0; i < this.props.ActplacesData.length; i++) {
            Places.push({ id: this.props.ActplacesData[i]._id, value: this.props.ActplacesData[i].translationAr[0].ARtitle, label: this.props.ActplacesData[i].title })
          }

        }
      }

      this.setState({
        places: Places
      })
    }
  }

  componentDidMount() {
    var token = localStorage.getItem("adminToken")

    var decoded = jwtDecode(token);
    var expirationDate = decoded.exp;

    var seconds = 1000;
    var d = new Date();
    var t = d.getTime();

    if (expirationDate < Math.round(t / seconds)) {

      localStorage.setItem("adminToken", "")
      history.push("/login");

    }
    else {
      this.props.getActivePlacesThings();
    }
  }

  onDescriptionChange(value) {
    this.setState({
      description: value
    });
  }
  onTitleChange(value) {
    this.setState({
      title: value
    });
  }

  onARDescriptionChange(value) {
    this.setState({
      ARdescription: value
    });
  }
  onARTitleChange(value) {
    this.setState({
      ARtitle: value
    });
  }

  onDateChange(value) {
    this.setState({
      date: value
    });
  }
  onEndDateChange(value){
    this.setState({
      Enddate: value
    });
  }
  handleChangeType(event) {
    this.setState({
      type: event.target.value
    });
  }

  handleChangeTypeEvent(event) {
    this.setState({
      typeEvent: event.target.value
    });
  }
  onPriceChange(event) {
    this.setState({
      price: event
    });
  }
  handleChangeActive(event) {
    this.setState({
      active: event.target.value
    });
  }
  componentWillReceiveProps(prevProps) {

    if (prevProps.CreateEventstatus !== this.props.CreateEventstatus) {
      if (prevProps.CreateEventstatus === "success") {
        ToastsStore.info(<Trans i18nKey="Events.eventcre" />, 3000)
        setTimeout(
          function () {
            history.push("/events");
          }, 1200
        );

      }

    }
  }

  render() {
    const { isLoading } = this.props;

    let btnValidate = (
      <Button onClick={() => this.onCreate()} type="submit" className="btn-secondary" size="md"><i className="fa fa-dot-circle-o"></i>  <Trans i18nKey="Submit" /></Button>
    )
    if (isLoading) {
      btnValidate = <CircularProgress size={30} style={{ color: "#083328" }} />;
    }
    let err = (<Container>
      <Row className="justify-content-center">
        <p></p>
      </Row>
    </Container>
    )

    if (this.props.CreateEventerr !== "") {
      err = <Container style={{ paddingTop: '2%' }}>
        <Row className="justify-content-center">
          <img src={notValid} alt="notvalid" style={
            {
              width: "20px",
              marginRight: "10px",
              height: "20px",
              marginLeft: "10px"
            }
          } />
          <p style={{ color: 'red' }}>{this.props.CreateEventerr}</p>
        </Row>
      </Container>
    }

    return (
      <div className="animated fadeIn">
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader>
                <strong><i className="icon-info pr-1"></i><Trans i18nKey="Events.CreateEvent" /></strong>
              </CardHeader>
              <CardBody>
                <Nav tabs style={{ backgroundColor: "#f0f3f5" }}>
                  <NavItem>
                    <NavLink
                      active={this.state.activeTab[0] === '1'}
                      onClick={() => { this.toggle(0, '1'); }}
                    >
                      English
                </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={this.state.activeTab[0] === '2'}
                      onClick={() => { this.toggle(0, '2'); }}
                    >
                      العربية
                </NavLink>
                  </NavItem>

                </Nav>
                <TabContent activeTab={this.state.activeTab[0]}>
                  <TabPane tabId="1">
                    <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">

                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="text-input">* Title </Label>
                        </Col>
                        <Col xs="12" md="9">
                          <Input type="text" id="text-input" name="text-input" placeholder="" value={this.state.title}
                            onChange={e => this.onTitleChange(e.target.value)} />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="text-input">* Description </Label>
                        </Col>
                        <Col xs="12" md="9">

                          <Input type="textarea" rows="7"
                            placeholder="" value={this.state.description}
                            onChange={e => this.onDescriptionChange(e.target.value)} />
                        </Col>
                      </FormGroup>

                    </Form>
                  </TabPane>
                  <TabPane tabId="2">
                    <Form style={{ direction: "rtl" }} action="" method="post" encType="multipart/form-data" className="form-horizontal">

                      <FormGroup row >
                        <Col md="3">
                          <Label htmlFor="text-input0">* العنوان </Label>
                        </Col>
                        <Col xs="12" md="9">
                          <Input type="text" id="text-input0" name="text-input" placeholder="" value={this.state.ARtitle}
                            onChange={e => this.onARTitleChange(e.target.value)} />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col md="3">
                          <Label >* الوصف </Label>
                        </Col>
                        <Col xs="12" md="9">

                          <Input type="textarea" rows="7"
                            placeholder="" value={this.state.ARdescription}
                            onChange={e => this.onARDescriptionChange(e.target.value)} />
                        </Col>
                      </FormGroup>


                    </Form>
                  </TabPane>
                </TabContent>
                <br />
                <Label htmlFor="text-input">* <Trans i18nKey="Photos.Photoss" /> </Label>
                <DropzoneArea
                  onChange={this.handleChangeImage.bind(this)}
                  filesLimit={100}
                  dropzoneParagraphClass={"dropzone-Paragraph"}
                  acceptedFiles={['image/*']}
                  dropzoneText={(localStorage.getItem('i18nextLng') === "en") ? "Drag and drop images here or click, Recommended  Image Size (960x 540 pixels) " : "إختر ملف وقم بإفلاته أو انقر لإضافة صور، حجم الصورة الموصى به 540 × 960بكسل"}
                  showAlerts={false}
                />

                <br />
                <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
                  <FormGroup row>
                    <Col md="6">
                      <Label htmlFor="text-input2">* <Trans i18nKey="SDate" />  </Label>
                    </Col>
                    <Col xs="12" md="6">
                      <Input type="date" id="text-input2" name="text-input" placeholder="" value={this.state.date}
                        onChange={e => this.onDateChange(e.target.value)} />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="6">
                      <Label htmlFor="text-input2D">* <Trans i18nKey="EndDate" />  </Label>
                    </Col>
                    <Col xs="12" md="6">
                      <Input type="date" id="text-input2D" name="text-input" placeholder="" value={this.state.Enddate}
                        onChange={e => this.onEndDateChange(e.target.value)} />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="6">
                      <Label htmlFor="multiple-select">* <Trans i18nKey="Places.PlacesList" /></Label>
                    </Col>
                    <Col md="6">
                      <Input onChange={this.handleChange} type="select" ref="Select" name="multiple-select" id="multiple-select" >
                        <option></option>
                        {this.state.places.length !== 0 ?
                          Object.values(this.state.places).map((placeData) => {

                            if (localStorage.getItem('i18nextLng') === "en")

                              return <option key={placeData.id} value={placeData.id}>{placeData.label}</option>

                            return <option key={placeData._id} value={placeData._id}>{placeData.value}</option>

                          }) : <option >{(localStorage.getItem('i18nextLng') === "en") ? "No Place Exists" : "لا يوجد مكان"} </option>}
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="6">
                      <Label >* <Trans i18nKey="Places.maxNbrPersons" /> :</Label>
                    </Col>
                    <Col xs="12" md="6">
                      <Input type="number" value={this.state.maxPersons}
                        onChange={e => this.onmaxPersonsChange(e.target.value)} />
                    </Col>
                  </FormGroup>
                  <br />
                  <FormGroup row>
                    <Col md="6" xs="6">
                      <TextField
                        id="from"
                        label={<Trans i18nKey="from" />}
                        type="time"
                        size="medium"
                        onChange={e => this.handleBegin(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      />
                    </Col>
                    <Col md="6" xs="6">
                      <TextField
                        id="to"
                        type="time"
                        label={<Trans i18nKey="to" />}
                        onChange={e => this.handleEnd(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      />

                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="2">
                      <Label htmlFor="text-input">* <Trans i18nKey="Paid" /> </Label>
                    </Col>
                    <Col xs="12" md="5">
                       <FormGroup check className="radio">

                        <Input className="form-check-input" type="radio" id="ForPayment" name="radios" value="Payment"
                          checked={this.state.type === 'Payment'}
                          onChange={this.handleChangeType} />
                        <Label check className="form-check-label" htmlFor="ForPayment"> &nbsp; &nbsp; <Trans i18nKey="yes" /> &nbsp; &nbsp;</Label>
                      </FormGroup>
                      <FormGroup check className="radio">
                        <Input className="form-check-input" type="radio" id="radio2" name="radios" value="free"
                          checked={this.state.type === 'free'}
                          onChange={this.handleChangeType} />

                        <Label check className="form-check-label" htmlFor="radio2"> &nbsp; &nbsp; <Trans i18nKey="no" /> &nbsp; &nbsp;</Label>
                      </FormGroup>
                     
                    </Col>
                    <Col xs="12" md="5">
                      {this.state.type === 'Payment' && <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}><Input type="number" step="0.01" id="text-input4" name="text-input" placeholder={localStorage.getItem('i18nextLng') === "en" ? "Add Price" : "أضف سعر"} value={this.state.price} style={{ width: '80%' }}
                        onChange={e => this.onPriceChange(e.target.value)} />&nbsp; QAR </div>}
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="2">
                      <Label htmlFor="text-input">* <Trans i18nKey="Active" /> </Label>
                    </Col>
                    <Col xs="12" md="10">
                      <FormGroup check inline>
                        <Input className="form-check-input" type="radio" name="inline-radios" value="yes"
                          checked={this.state.active === 'yes'}
                          onChange={this.handleChangeActive} />
                        <Label className="form-check-label" check > &nbsp;<Trans i18nKey="yes" /> &nbsp; </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Input className="form-check-input" type="radio" name="inline-radios" value="no"
                          checked={this.state.active === 'no'}
                          onChange={this.handleChangeActive} />
                        <Label className="form-check-label" check >&nbsp;<Trans i18nKey="no" />&nbsp;</Label>
                      </FormGroup>

                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="2">
                      <Label htmlFor="text-input">* <Trans i18nKey="Type" /> </Label>
                    </Col>
                    <Col xs="12" md="10">
                      <FormGroup check inline>
                        <Input className="form-check-input" type="radio" name="inline" value="current"
                          checked={this.state.typeEvent === 'current'}
                          onChange={this.handleChangeTypeEvent} />
                        <Label className="form-check-label" check >&nbsp; {localStorage.getItem('i18nextLng') === "en" ? "Related to the park" :"متعلق بالحديقة "} &nbsp;</Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Input className="form-check-input" type="radio" name="inline" value="private"
                          checked={this.state.typeEvent === 'private'}
                          onChange={this.handleChangeTypeEvent} />
                        <Label className="form-check-label" check >&nbsp;{localStorage.getItem('i18nextLng') === "en" ? "Private" :" خاص "} &nbsp;</Label>
                      </FormGroup>

                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                {btnValidate}

                <ToastsContainer store={ToastsStore} />
                {err}  {this.state.empty &&
                  <Container style={{ paddingTop: '2%' }}>
                    <Row className="justify-content-center">
                      <img src={notValid} alt="notvalid" style={
                        {
                          width: "20px",
                          marginRight: "10px",
                          height: "20px",
                          marginLeft: "10px"
                        }
                      } />
                      <p style={{ color: 'red' }}>{this.state.empty}</p>
                    </Row>
                  </Container>}
              </CardFooter>
            </Card>
          </Col>

        </Row>


      </div>

    )
  }
}
const mapStateToProps = state => {
  return {
    CreateEvent: state.CreateEvent.CreateEvent,
    CreateEventerr: state.CreateEventerr.CreateEventerr,
    CreateEventstatus: state.CreateEventstatus.CreateEventstatus,
    ActplacesData: state.ActplacesData.ActplacesData,
    listActPlacesErr: state.listActPlacesErr.listActPlacesErr,
    isLoading: state.ui.isLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createEvent: (data) => dispatch(createEvent(data)),

    getActivePlacesThings: () => dispatch(getActivePlacesThings())
  };
};


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CreateEvent);
