import {
  ADMIN_SIGN
} from "../actionTypes";
import {
  ADMIN_AUTH
} from "../actionTypes";
import {
  AUTH_ERR
} from "../actionTypes";
import {
  SIGN_ERR
} from "../actionTypes";
import {
  ADMINS_LIST_NB,
  ADMINS_ERR,
  ADMINS_DATA
} from "../actionTypes";
import {
  uiStartLoading,
  uiStopLoading
} from "../index";
import {
  ADMIN_ERR,
  ADMIN_DATA
} from "../actionTypes";
import {
  UNBLOCK_ADMIN_STATUS,
  BLOCK_ADMIN_STATUS,
  UNBLOCK_ADMIN_ERR,
  BLOCK_ADMIN_ERR
} from "../actionTypes";
import {
  EDIT_ADMIN_ERR,
  ADMIN_EDIT,
  STATUS_ADMIN_EDIT
} from "../actionTypes";
import history from "../../../history";
import {
  decode
} from "../../lib/cryp";


export const Adminsignup = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/Adminsignup/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          Username: data.Username,
          email: data.email,
          password: data.password,
          blocked: "no"
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(adminSign(""));
        dispatch(Signerr(""));
        dispatch(uiStopLoading())
        if (parsedRes.status === "fail") {
          dispatch(Signerr(parsedRes.error));
        } else if (parsedRes.status === "failure") {
          dispatch(Signerr(parsedRes.status));
        } else {
          dispatch(adminSign(parsedRes.status));
          dispatch(Signerr(""));

        }
      });
  };
};


export const blockAdmin = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    fetch(`${process.env.REACT_APP_DOMAIN}/api/blockAdmin`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          adminID: data.adminID
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(blockAdminStatus(""));
        dispatch(uiStopLoading())
        dispatch(blockAdminerr(""));

        if (parsedRes.status === "fail") {
          dispatch(blockAdminerr(parsedRes.status));
        } else {
          dispatch(blockAdminStatus(parsedRes.status));
        }
      });
  };

};

export const unblockAdmin = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    fetch(`${process.env.REACT_APP_DOMAIN}/api/unblockAdmin`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          adminID: data.adminID
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(unblockAdminStatus(""));
        dispatch(uiStopLoading())
        dispatch(unblockAdminerr(""));

        if (parsedRes.status === "fail") {
          dispatch(unblockAdminerr(parsedRes.status));
        } else {
          dispatch(unblockAdminStatus(parsedRes.status));
        }
      });
  };

};

export const unblockAdminStatus = value => {
  return {
    type: UNBLOCK_ADMIN_STATUS,
    unblockAdminStatus: value
  };
};
export const unblockAdminerr = value => {
  return {
    type: UNBLOCK_ADMIN_ERR,
    unblockAdminerr: value
  };
};

export const blockAdminStatus = value => {
  return {
    type: BLOCK_ADMIN_STATUS,
    blockAdminStatus: value
  };
};
export const blockAdminerr = value => {
  return {
    type: BLOCK_ADMIN_ERR,
    blockAdminerr: value
  };
};

export const editAdmin = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    fetch(`${process.env.REACT_APP_DOMAIN}/api/updateAdmin`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          adminID: data.adminID,
          Username: data.Username,
          email: data.email,
          newPassword: data.newPassword,
          blocked: 'no'
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(editAdminStatus(""));
        dispatch(uiStopLoading())
        dispatch(EditAdminerr(""));

        if (parsedRes.status === "fail" || parsedRes.status === "failure") {
          dispatch(EditAdminerr(parsedRes.status));

        } else {
          dispatch(editADMIN(parsedRes.data));
          dispatch(editAdminStatus(parsedRes.status));
        }
      });
  };

};
export const EditAdminerr = value => {
  return {
    type: EDIT_ADMIN_ERR,
    EditAdminerr: value
  };
};
export const editADMIN = value => {
  return {
    type: ADMIN_EDIT,
    editADMIN: value
  };
};
export const editAdminStatus = value => {
  return {
    type: STATUS_ADMIN_EDIT,
    editAdminStatus: value
  };
};

export const getAdmin = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/getAdmin`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-access-token": token
        },
        body: JSON.stringify({
          adminID: data.adminID
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())

        if (parsedRes.status === "success") {
          dispatch(getAdminData(parsedRes.data));
        } else {
          dispatch(getAdminErr(parsedRes.data));
        }

      });
  };
};
export const getAdminData = value => {
  return {
    type: ADMIN_DATA,
    getAdminData: value
  };
};
export const getAdminErr = value => {
  return {
    type: ADMIN_ERR,
    getAdminErr: value
  };
};

export const AdminAuthen = data => {

  return dispatch => {

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/adminAuth`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          Username: data.Username,
          password: data.password,
          fireBToken: data.token
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(uiStopLoading())
        if (parsedRes.status === "fail") {
          dispatch(Autherr(parsedRes.error));
        } else {
          localStorage.setItem('adminToken', parsedRes.token);
          dispatch(adminAuth(parsedRes.data));
          dispatch(Autherr(""));
          history.push("/dashboard");
        }

      });
  };
};
export const getAdmins = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/adminsList`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          token: localStorage.getItem("adminToken"),
          pageNumber: data.pageNumber
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(async parsedRes => {
        dispatch(uiStopLoading())
        if (parsedRes.status === "failure") {
          dispatch(ListAdminserr(parsedRes.error));
        } else {
          dispatch(ListAdminserr(""));
          let data = await decode(JSON.stringify(parsedRes.data));

          dispatch(adminsData(JSON.parse(data)));
          dispatch(adminsListNbr(parsedRes.Length));
        }

      });
  };
};
export const adminsListNbr = value => {
  return {
    type: ADMINS_LIST_NB,
    adminsListNbr: value
  }
}
export const ListAdminserr = value => {
  return {
    type: ADMINS_ERR,
    ListAdminserr: value
  }
}
export const adminsData = value => {
  return {
    type: ADMINS_DATA,
    adminsData: value
  }
}
export const adminSign = value => {
  return {
    type: ADMIN_SIGN,
    adminSign: value
  };
};
export const adminAuth = value => {
  return {
    type: ADMIN_AUTH,
    adminAuth: value
  };
};
export const Autherr = value => {
  return {
    type: AUTH_ERR,
    Autherr: value
  };
};
export const Signerr = value => {
  return {
    type: SIGN_ERR,
    Signerr: value
  };
};