import { BANNERS_DATA } from "../../actions/actionTypes";
import { BANNER_DATA } from "../../actions/actionTypes";
import { LIST_BANNERS_ERR, BANNERS_NB } from "../../actions/actionTypes";

import { BANNER_EDIT } from "../../actions/actionTypes";
import { EDIT_BANNER_ERR } from "../../actions/actionTypes";
import { STATUS_BANNER_EDIT } from "../../actions/actionTypes";

import { DELETE_BANNER_ERR } from "../../actions/actionTypes";
import { STATUS_BANNER_DELETE } from "../../actions/actionTypes";
import { BANNER_DELETE } from "../../actions/actionTypes";

import { CREATE_BANNER_ERR } from "../../actions/actionTypes";
import { STATUS_BANNER_CREATE } from "../../actions/actionTypes";
import { BANNER_CREATE } from "../../actions/actionTypes";

import { BANNER_EDIT_AVATAR } from "../../actions/actionTypes";
import { EDIT_BANNER_AVATAR_ERR } from "../../actions/actionTypes";
import { STATUS_BANNER_AVATAR_EDIT } from "../../actions/actionTypes";

const initialState = {
  bannersData: [],
  bannerData: [],
  editbanner: [],
  EditBannererr: '',
  listBannersErr: '',
  editBannerStatus: '',
  deleteBanner: [],
  DeleteBannererr: '',
  deleteBannerStatus: '',
  CreateBanner: [],
  CreateBannererr: '',
  CreateBannerStatus: '',
  EditBannerAvatarerr: '',
  editBannerAvatar: [],
  editBannerAvatarStatus: '',
  listActBannersErr: '',
  ActbannersData: [],
  bannersNbr: 0

};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case BANNERS_NB:
      return {
        ...state,
        bannersNbr: action.bannersNbr
      };
    case BANNERS_DATA:
      return {
        ...state,
        bannersData: action.bannersData
      };
    case BANNER_DATA:
      return {
        ...state,
        bannerData: action.bannerData
      };
    case BANNER_EDIT:
      return {
        ...state,
        editbanner: action.editbanner
      };
    case LIST_BANNERS_ERR:
      return {
        ...state,
        listBannersErr: action.listBannersErr
      };
    case EDIT_BANNER_ERR:
      return {
        ...state,
        EditBannererr: action.EditBannererr
      };
    case STATUS_BANNER_EDIT:
      return {
        ...state,
        editBannerStatus: action.editBannerStatus
      };
    case DELETE_BANNER_ERR:
      return {
        ...state,
        DeleteBannererr: action.DeleteBannererr
      };
    case STATUS_BANNER_DELETE:
      return {
        ...state,
        deleteBannerStatus: action.deleteBannerStatus
      };
    case BANNER_DELETE:
      return {
        ...state,
        deleteBanner: action.deleteBanner
      };

    case CREATE_BANNER_ERR:
      return {
        ...state,
        CreateBannererr: action.CreateBannererr
      };
    case STATUS_BANNER_CREATE:
      return {
        ...state,
        CreateBannerStatus: action.CreateBannerStatus
      };
    case BANNER_CREATE:
      return {
        ...state,
        CreateBanner: action.CreateBanner
      };
    case BANNER_EDIT_AVATAR:
      return {
        ...state,
        editBannerAvatar: action.editBannerAvatar
      };
    case EDIT_BANNER_AVATAR_ERR:
      return {
        ...state,
        EditBannerAvatarerr: action.EditBannerAvatarerr
      };
    case STATUS_BANNER_AVATAR_EDIT:
      return {
        ...state,
        editBannerAvatarStatus: action.editBannerAvatarStatus
      };

    default:
      return state;
  }
};

export default reducer;
