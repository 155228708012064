import { UI_START_LOADING, UI_STOP_LOADING } from './actionTypes';
import { UI_START_LOADER, UI_STOP_LOADER } from './actionTypes';

export const uiStartLoading = () => {
    return {
        type: UI_START_LOADING
    };
};
export const uiStopLoading = () => {
    return {
        type: UI_STOP_LOADING
    };
};
export const uiStartLoader = () => {
    return {
        type: UI_START_LOADER
    };
};
export const uiStopLoader = () => { 
    return {
        type: UI_STOP_LOADER
    };
};