import axios from "axios";

import {
  FACILITIES_DATA
} from "../actionTypes";
import {
  FACILITY_DATA,
  FACILITY_NB
} from "../actionTypes";
import {
  uiStartLoading,
  uiStopLoading
} from "../index";
import {
  LIST_FACILITIES_ERR
} from "../actionTypes";

import {
  FACILITY_EDIT
} from "../actionTypes";
import {
  EDIT_FACILITY_ERR
} from "../actionTypes";
import {
  STATUS_FACILITY_EDIT
} from "../actionTypes";

import {
  FACILITY_DELETE
} from "../actionTypes";
import {
  DELETE_FACILITY_ERR
} from "../actionTypes";
import {
  STATUS_FACILITY_DELETE
} from "../actionTypes";

import {
  FACILITY_CREATE
} from "../actionTypes";
import {
  CREATE_FACILITY_ERR
} from "../actionTypes";
import {
  STATUS_FACILITY_CREATE
} from "../actionTypes";
import {
  LIST_ACT_Facilities_ERR,
  ACT_Facilities_DATA
} from "../actionTypes";
//import { da } from "date-fns/locale";

export const getActiveFacilities = () => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/facilities/active`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {

        dispatch(uiStopLoading())

        if (parsedRes.status === "failure") {
          dispatch(listActFacilitiesErr(parsedRes.error));
        } else {
          dispatch(listActFacilitiesErr(""));
          dispatch(ActFacilitiesData(parsedRes.data));


        }

      });
  };
};
export const listActFacilitiesErr = value => {
  return {
    type: LIST_ACT_Facilities_ERR,
    listActFacilitiesErr: value
  };
};
export const ActFacilitiesData = value => {
  return {
    type: ACT_Facilities_DATA,
    ActFacilitiesData: value
  };
};
export const getFacilities = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/facility/getList`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          pageNumber: data.pageNumber

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {

        dispatch(uiStopLoading())

        if (parsedRes.status === "failure") {
          dispatch(listFacilitiesErr(parsedRes.error));

        } else {
          dispatch(listFacilitiesErr(""));
          dispatch(FacilitiesData(parsedRes.data));
          dispatch(FacilitiesNbr(parsedRes.Length));

        }

      });
  };
};
export const FacilitiesNbr = value => {
  return {
    type: FACILITY_NB,
    FacilitiesNbr: value
  }
}

export const getFacility = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/facility/get`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-access-token": token

        },
        body: JSON.stringify({
          facilityID: data.facilityID

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        dispatch(facilityData(parsedRes.data));

      });
  };
};


export const editFacility = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");
    const form = new FormData();
    form.append("facilityID", data.facilityID);
    form.append("title", data.title);
    form.append("ARtitle", data.ARtitle);
    form.append("active", data.active);
    form.append("image", data.image);
    form.append("order", data.order);

    const config = {
      headers: {
        enctype: "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token
      }
    };

    axios
      .post(`${process.env.REACT_APP_DOMAIN}/api/facility/edit`, form, config, {
        useCredentails: true
      })

      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(parsedRes => {
        dispatch(editFacilityStatus(""));
        dispatch(uiStopLoading())
        dispatch(EditFacilityerr(""));

        if (parsedRes.data.status === "fail") {
          dispatch(EditFacilityerr(parsedRes.data.error));
        }
        if (parsedRes.data.status === "failure") {
          dispatch(EditFacilityerr(parsedRes.data.error));
        } else {
          dispatch(EditFacilityerr(""));
          dispatch(editfacility(parsedRes.data.data));
          dispatch(editFacilityStatus(parsedRes.data.status));
        }

      });
  };


};


export const createFacility = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(CreateFacilityStatus(""));
    dispatch(uiStartLoading());
    const form = new FormData();

    form.append("title", data.title);
    form.append("ARtitle", data.ARtitle);
    form.append("active", data.active);
    form.append("image", data.image);
    form.append("order", data.order);


    const config = {
      headers: {
        enctype: "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token
      }
    };

    axios
      .post(`${process.env.REACT_APP_DOMAIN}/api/facility/add`, form, config, {
        useCredentails: true
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })

      .then(parsedRes => {
        dispatch(CreateFacilityStatus(""));
        dispatch(uiStopLoading())

        if (parsedRes.data.status === "fail") {
          dispatch(CreateFacilityerr(parsedRes.data.error));
        } else {
          dispatch(CreateFacilityerr(""));
          dispatch(CreateFacility(parsedRes.data.data));
          dispatch(CreateFacilityStatus(parsedRes.data.status));

        }

      });
  };
};
export const DeleteFacility = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(deleteFacilityStatus(""));
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/facility/delete`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token

        },
        body: JSON.stringify({
          facilityID: data.facilityID,

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(deleteFacilityStatus(""));
        dispatch(uiStopLoading())
        if (parsedRes.status === "fail") {
          dispatch(DeleteFacilityerr(parsedRes.error));
        } else if (parsedRes.status === "failure") {
          dispatch(DeleteFacilityerr(parsedRes.error));
        } else {
          dispatch(DeleteFacilityerr(""));
          dispatch(deleteFacility(parsedRes.data));
          dispatch(deleteFacilityStatus(parsedRes.status));
        }

      });
  };
};

export const FacilitiesData = value => {
  return {
    type: FACILITIES_DATA,
    FacilitiesData: value
  };
};
export const facilityData = value => {
  return {
    type: FACILITY_DATA,
    facilityData: value
  };
};

export const editfacility = value => {
  return {
    type: FACILITY_EDIT,
    editfacility: value
  };
};
export const editFacilityStatus = value => {
  return {
    type: STATUS_FACILITY_EDIT,
    editFacilityStatus: value
  };
};
export const listFacilitiesErr = value => {
  return {
    type: LIST_FACILITIES_ERR,
    listFacilitiesErr: value
  };
};
export const EditFacilityerr = value => {
  return {
    type: EDIT_FACILITY_ERR,
    EditFacilityerr: value
  };
};

export const deleteFacility = value => {
  return {
    type: FACILITY_DELETE,
    deleteFacility: value
  };
};
export const DeleteFacilityerr = value => {
  return {
    type: DELETE_FACILITY_ERR,
    DeleteFacilityerr: value
  };
};
export const deleteFacilityStatus = value => {
  return {
    type: STATUS_FACILITY_DELETE,
    deleteFacilityStatus: value
  };
};

export const CreateFacility = value => {
  return {
    type: FACILITY_CREATE,
    CreateFacility: value
  };
};
export const CreateFacilityerr = value => {
  return {
    type: CREATE_FACILITY_ERR,
    CreateFacilityerr: value
  };
};
export const CreateFacilityStatus = value => {
  return {
    type: STATUS_FACILITY_CREATE,
    CreateFacilityStatus: value
  };
};