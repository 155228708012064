import { MERCHANTS_DATA } from "../../actions/actionTypes";
import { MERCHANT_DATA } from "../../actions/actionTypes";
import { LIST_MERCHANTS_ERR } from "../../actions/actionTypes";
import { MERCHANTS_NBR } from "../../actions/actionTypes";
import { MERCHANT_EDIT } from "../../actions/actionTypes";
import { EDIT_MERCHANT_ERR } from "../../actions/actionTypes";
import { STATUS_MERCHANT_EDIT } from "../../actions/actionTypes";
import { LIST_MERCHANTS_ACT_DATA } from "../../actions/actionTypes";
import { LIST_MERCHANTS_ACT_ERR } from "../../actions/actionTypes";

import { DELETE_MERCHANT_ERR } from "../../actions/actionTypes";
import { STATUS_MERCHANT_DELETE } from "../../actions/actionTypes";
import { MERCHANT_DELETE } from "../../actions/actionTypes";

import { CREATE_MERCHANT_ERR } from "../../actions/actionTypes";
import { STATUS_MERCHANT_CREATE } from "../../actions/actionTypes";
import { MERCHANT_CREATE } from "../../actions/actionTypes";

const initialState = {
  merchantsData: [],
  merchantData: [],
  ActmerchantsData: [],
  listActMerchantsErr: '',
  MerchantsNbr: 0,
  editmerchant: [],
  EditMerchanterr: '',
  listMerchantsErr: '',
  editMerchantstatus: '',
  deleteMerchant: [],
  DeleteMerchanterr: '',
  deleteMerchantstatus: '',
  CreateMerchant: [],
  CreateMerchanterr: '',
  CreateMerchantstatus: ''  
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_MERCHANTS_ACT_DATA:
      return {
        ...state,
        ActmerchantsData: action.ActmerchantsData
      };
    case LIST_MERCHANTS_ACT_ERR:
      return {
        ...state,
        listActMerchantsErr: action.listActMerchantsErr
      };
    case MERCHANTS_DATA:
      return {
        ...state,
        merchantsData: action.merchantsData
      };
    case MERCHANT_DATA:
      return {
        ...state,
        merchantData: action.merchantData
      };
    case MERCHANT_EDIT:
      return {
        ...state,
        editmerchant: action.editmerchant
      };
    case LIST_MERCHANTS_ERR:
      return {
        ...state,
        listMerchantsErr: action.listMerchantsErr
      };
    case EDIT_MERCHANT_ERR:
      return {
        ...state,
        EditMerchanterr: action.EditMerchanterr
      };
    case STATUS_MERCHANT_EDIT:
      return {
        ...state,
        editMerchantstatus: action.editMerchantstatus
      };
    case DELETE_MERCHANT_ERR:
      return {
        ...state,
        DeleteMerchanterr: action.DeleteMerchanterr
      };
    case STATUS_MERCHANT_DELETE:
      return {
        ...state,
        deleteMerchantstatus: action.deleteMerchantstatus
      };
    case MERCHANT_DELETE:
      return {
        ...state,
        deleteMerchant: action.deleteMerchant
      };

    case CREATE_MERCHANT_ERR:
      return {
        ...state,
        CreateMerchanterr: action.CreateMerchanterr
      };
    case STATUS_MERCHANT_CREATE:
      return {
        ...state,
        CreateMerchantstatus: action.CreateMerchantstatus
      };
    case MERCHANT_CREATE:
      return {
        ...state,
        CreateMerchant: action.CreateMerchant
      };
    case MERCHANTS_NBR:
      return {
        ...state,
        MerchantsNbr: action.MerchantsNbr
      };

    default:
      return state;
  }
};

export default reducer;
