import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardFooter,Col, Row, Table, Tooltip, Badge, Input, Label, Button, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import notValid from '../../../assets/img/brand/notValid.png';

import { getTimings, DeleteTiming, saveAboutus, getAboutus } from "../../../store/actions";
import { compose } from "redux";
import { Trans } from "react-i18next";
import addBtn from '../../../assets/img/brand/plus-button.png';
import history from "../../../history.js";
import { connect } from "react-redux";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import CircularProgress from "@material-ui/core/CircularProgress";
import Dropzone from "react-dropzone";




class HomePage extends Component {

    constructor(props) {
        super(props)
        this.toggleArabic = this.toggleArabic.bind(this);
        this.state = {
        
            ARHomeMessagedescription:"",
            HomeMessagedescription:'',
            activeTab: new Array(2).fill('1'),
            active: '',
            empty: '',
            files:[],
            filesimgHomeMessage:[],
            imgHomeMessagews:[],
            imgHomeMessage:[],
        };
    }
    toggleArabic(tabPane, tab) {
        const newArray = this.state.activeTab.slice()
        newArray[tabPane] = tab
        this.setState({
            activeTab: newArray
        });
    }
 

 
    componentDidMount() {
        var token = localStorage.getItem("adminToken")
        var jwtDecode = require('jwt-decode');
        var decoded = jwtDecode(token);
        var expirationDate = decoded.exp;
        var seconds = 1000;
        var d = new Date();
        var t = d.getTime();

        if (expirationDate < Math.round(t / seconds)) {
            localStorage.setItem("adminToken", "")
            history.push("/login");

        }
        else {
            const data = { pageNumber: 1 };
            this.props.getAboutus()
        }

    }
    componentDidUpdate(prevProps) {


        if (this.props.aboutDescData !== prevProps.aboutDescData) {
            this.setState({
              
                HomeMessagedescription: this.props.aboutDescData.HomeMessagedescription,
                ARHomeMessagedescription: this.props.aboutDescData.translationAr[0].ARHomeMessagedescription,
                imgHomeMessagews:this.props.aboutDescData.imgHomeMessage,
            })
        }
     
    }


    componentWillReceiveProps(prevProps) {
      
        if (prevProps.editAboutDescStatus !== this.props.editAboutDescStatus) {
            if (prevProps.editAboutDescStatus === "success") {
                ToastsStore.info(<h6>Home page has been successfully updated!</h6>, 3000)
            }

        }

    }
    onHomeMessageDescriptionChange(value) {
        this.setState({
            HomeMessagedescription: value
        });
    }
    onARHomeMessageDescriptionChange(value) {
        this.setState({
            ARHomeMessagedescription: value
        });
    }

   
  onDropimgHomeMessage= files => {

    this.setState({
      filesimgHomeMessage: files
    });
    this.setState({ isCropimgHomeMessage: true });

    if (this.state.filesimgHomeMessage.length !== 0) {
      this.setState({ imgHomeMessage: URL.createObjectURL(files[0]) });
    }

  };
    
    onSubmit = () => {
        this.setState({ empty: "" });

        const data = {
                  
            ARHomeMessagedescription:this.state.ARHomeMessagedescription,
            HomeMessagedescription:this.state.HomeMessagedescription,
            imgHomeMessage:this.state.filesimgHomeMessage[0],
           
        }
        
        
     
        
        if (
            data.ARHomeMessagedescription.length === 0 || data.HomeMessagedescription.length === 0 ||
           
           (data.imgHomeMessage === undefined &&this.state.imgHomeMessagews==="")
            ) {
            this.setState({ empty: <Trans i18nKey="requiredFields" /> });
          }else{
            this.props.saveAboutus(data);
          }
         
    }
    render() {
        const { isLoading } = this.props;
        const maxSize = 2097152;

        let btnValidate = (
            <Button onClick={this.onSubmit} type="submit" size="md" className="btn-secondary" ><i className="fa fa-dot-circle-o"></i> <Trans i18nKey="Submit" /></Button>
        )
        if (isLoading) {
            btnValidate = <CircularProgress size={30} style={{ color: "#083328" }} />;
        }
       
        return (
            <div className="animated fadeIn">
                <Row>
                    <Col lg={10}>
                    
                        <Card>
                        
                            <CardBody>
                            
                      
                             
                                <Nav tabs style={{ backgroundColor: "#f0f3f5" }}>
                                    <NavItem>
                                        <NavLink
                                            active={this.state.activeTab[0] === '1'}
                                            onClick={() => { this.toggleArabic(0, '1'); }}
                                        >
                                            English
                  </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            active={this.state.activeTab[0] === '2'}
                                            onClick={() => { this.toggleArabic(0, '2'); }}
                                        >
                                            العربية
                  </NavLink>
                                    </NavItem>

                                </Nav>
                                <TabContent activeTab={this.state.activeTab[0]}>
                                    <TabPane tabId="1">
                                     
                                        <br/>
                                        <Col md="3">
                                            <Label htmlFor="text-input">* Description </Label>
                                        </Col>
                                        <Col xs="12" md="12">
                                            <Input type="textarea" name="textarea-input" id="textarea-input" rows="7"
                                                placeholder="" value={this.state.HomeMessagedescription}
                                                onChange={e => this.onHomeMessageDescriptionChange(e.target.value)} />
                                        </Col>
                                        <br/>
                                        <Dropzone
                      onDrop={this.onDropimgHomeMessage.bind(this)}
                      accept={"image/jpeg, image/png"}
                      minSize={0}
                      maxSize={maxSize}
                    >

                      {({ getRootProps, getInputProps, isDragReject, rejectedFiles }) => {
                        const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
                        return (
                          <section
                            className="container"
                            style={{
                              border: "1px solid #eee",
                         //     margin: "25px 7%",
                              padding: 15,
                              textAlign: "center",
                              cursor: "pointer"
                            }}
                          >
                            {this.state.isCropimgHomeMessage ? (
                              <div {...getRootProps({ className: "dropzone" })} >
                                <input {...getInputProps()} />
                                <b> Choose a file Drag and drop or click to add a main Home image, Recommended  Image Size (540 x 960 pixels)  </b>
                                <p>JPEG or PNG, does not exceed 2MB.</p>
                              
                                {isDragReject && "File type not accepted, sorry!"}
                                {isFileTooLarge && (
                                  <div className="text-danger mt-2">
                                      File is too large.                                 
                                  </div>
                                )}
                                <img
                                  src={this.state.imgHomeMessage}
                                  alt=""
                                  style={{width: "88%"}}  

                                />
                              </div>
                            ) : (
                                <div {...getRootProps({ className: "dropzone" })}>
                                  <input {...getInputProps()} />
                                  <b> Choose a file Drag and drop or click to add a main Home image, Recommended  Image Size (540 x 960 pixels) </b>
                                  <p>JPEG or PNG, does not exceed 2MB.</p>
                                 
                                  {isDragReject && "File type not accepted, sorry!"}
                                  {isFileTooLarge && (
                                    <div className="text-danger mt-2">
                                      File is too large.

                                    </div>
                                  )}

                                  <img
                                    src={this.state.imgHomeMessagews}
                                    alt=""
                                    style={{width: "88%"}}  
                                  />
                                </div>
                              )}
                          </section>
                        )
                      }}
                    </Dropzone>
                                    </TabPane>
                                    <TabPane tabId="2"  style={{ direction: "rtl" }} >
                                      
                                        <br/>
                                        <Col md="3">
                                            <Label style={{float : "right"}} htmlFor="text-input">*  استقبال  </Label>
                                        </Col>
                                        <Col xs="12" md="12">
                                            <Input type="textarea" name="textarea-input" id="textarea-input" rows="7"
                                                placeholder="" value={this.state.ARHomeMessagedescription}
                                                onChange={e => this.onARHomeMessageDescriptionChange(e.target.value)} />
                                        </Col>
                                        <br/>
                                        <Dropzone
                      onDrop={this.onDropimgHomeMessage.bind(this)}
                      accept={"image/jpeg, image/png"}
                      minSize={0}
                      maxSize={maxSize}
                    >

                      {({ getRootProps, getInputProps, isDragReject, rejectedFiles }) => {
                        const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
                        return (
                          <section
                            className="container"
                            style={{
                              border: "1px solid #eee",
                             // margin: "25px 6%",
                              padding: 15,
                              textAlign: "center",
                              cursor: "pointer"
                            }}
                          >
                            {this.state.isCropimgHomeMessage ? (
                              <div {...getRootProps({ className: "dropzone" })} >
                                <input {...getInputProps()} />
                             
                                <b>إختر ملف وقم بإفلاته أو انقر لإضافة صورة للافتة ، حجم الصورة الموصى به (960  ×  540 بكسل)</b>
                                <p>JPEG أو PNG ، لا يتجاوز 1 ميغابايت</p>
                                {isDragReject && "File type not accepted, sorry!"}
                                {isFileTooLarge && (
                                  <div className="text-danger mt-2">
                                                                    الملف كبير جدا
                                  </div>
                                )}
                                <img
                                  src={this.state.imgHomeMessage}
                                  alt=""
                                  style={{width: "88%"}}  
                                />
                              </div>
                            ) : (
                                <div {...getRootProps({ className: "dropzone" })}>
                                  <input {...getInputProps()} />
                         
                                  <b>إختر ملف وقم بإفلاته أو انقر لإضافة صورة للافتة ، حجم الصورة الموصى به (960  ×  540 بكسل)</b>
                                  <p>JPEG أو PNG ، لا يتجاوز 1 ميغابايت</p>
                                  {isDragReject && "File type not accepted, sorry!"}
                                  {isFileTooLarge && (
                                    <div className="text-danger mt-2">
                                                                          الملف كبير جدا

                                    </div>
                                  )}

                                  <img
                                    src={this.state.imgHomeMessagews}
                                    alt=""
                                    style={{width: "88%"}}                                  />
                                </div>
                              )}
                          </section>
                        )
                      }}
                    </Dropzone>
                                    </TabPane>
                                </TabContent>
                                
                                <br/>
                                
                              
                              
                                <br/>
                            <ToastsContainer store={ToastsStore} />
                            {btnValidate}
                                {this.state.empty &&
                    <Row className="justify-content-center">
                      <img src={notValid} alt="notvalid" style={
                        {
                          width: "20px",
                          marginRight: "10px",
                          height: "20px"
                        }
                      } />
                      <p style={{ color: 'red' }}>{this.state.empty}</p>
                    </Row>
                   }
                                
                                
                                
                
                            </CardBody>
       
                        </Card>
                    </Col>
                    <br />
                    
                  <ToastsContainer store={ToastsStore} />
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
      
        isLoading: state.ui.isLoading,
        isLoader: state.ui.isLoader,
        editAboutDescStatus: state.editAboutDescStatus.editAboutDescStatus,
        aboutDescData: state.aboutDescData.aboutDescData
    };
};

const mapDispatchToProps = dispatch => {
    return {

        saveAboutus: (data) => dispatch(saveAboutus(data)),
        getAboutus: () => dispatch(getAboutus())
    };
};


export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(HomePage);