import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row, Table, Container,Input, Tooltip, Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { getBeacons, DeleteBeacon,FindBeaconByName } from "../../../store/actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { compose } from "redux";
import { Trans } from "react-i18next";
import notValid from '../../../assets/img/brand/notValid.png';
import addBtn from '../../../assets/img/brand/plus-button.png';
import history from "../../../history.js";
import { connect } from "react-redux";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import ReactFileReader from 'react-file-reader';
import axios from "axios";
class Beacons extends Component {

  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this);
    this.state = {
      Code: '',
      UUID: '',
      Major: '',
      Minor: '',
      current: 1,
      modal: false,
      beaconId: '',
      deleted: '',
      searchValue: "",
      Loading: false,
      notvalid: '',
      beaconsData: [],
      total: 0,
      page_size: 10,
    };
  }
  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  handleFiles = files => {
    this.setState({ notvalid: "" });
    var token = localStorage.getItem("adminToken");

    const form = new FormData();
    form.append("file", files[0]);
    const config = {
      headers: {
        enctype: "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token
      }
    };
    this.setState({ Loading: true })
    axios
      .post(`${process.env.REACT_APP_DOMAIN}/api/beacons/uploadcsv`, form, config, { useCredentails: true })
      .catch(err => {
        this.setState({ Loading: false })
        console.log(err);
      })
      .then(parsedRes => {
        this.setState({ Loading: false })

        if (parsedRes.data.status === "error") {
          this.setState({ notvalid: < Trans i18nKey="IBeacons.invalidfile" /> });
        }
        else {
          this.setState({ notvalid: "" });
          const data = { pageNumber: 1 };
          this.props.getBeacons(data)
        }

      });
  }
  onChange = (page) => {
    this.setState({
      current: page
    });
    const data = { pageNumber: page };
    this.props.getBeacons(data)
  }
  componentDidMount() {
    var token = localStorage.getItem("adminToken")
    var jwtDecode = require('jwt-decode');
    var decoded = jwtDecode(token);
    var expirationDate = decoded.exp;
    var seconds = 1000;
    var d = new Date();
    var t = d.getTime();

    if (expirationDate < Math.round(t / seconds)) {
      localStorage.setItem("adminToken", "")
      history.push("/login");

    }
    else {
      const data = { pageNumber: 1 };
      this.props.getBeacons(data)
    }

  }
  onDelete() {
    const data = { beaconID: this.state.beaconId };
    this.props.DeleteBeacon(data);
  };
  toggleModal(beaconId) {

    this.setState({
      modal: !this.state.modal,
      beaconId: beaconId,
    });
  }

  componentWillReceiveProps(prevProps) {
  

    if (prevProps.deleteBeaconstatus !== this.props.deleteBeaconstatus) {
      if (prevProps.deleteBeaconstatus === "success") {
        this.setState({
          modal: false
        });
        ToastsStore.info(<h6><Trans i18nKey="IBeacons.del" /></h6>, 3000)
        const data = { pageNumber: 1 };
        this.props.getBeacons(data)
      }

    }
    if (prevProps.DeleteBeaconerr.En !== this.props.DeleteBeaconerr.En) {
      if (prevProps.DeleteBeaconerr !== "") {
        this.setState({
          modal: false,
          deleted: localStorage.getItem('i18nextLng') === "en" ? prevProps.DeleteBeaconerr.En : prevProps.DeleteBeaconerr.Ar
        });
      }

    }

   

  }
  componentDidUpdate(prevProps){
 
  
    if (prevProps.beaconsData !== this.props.beaconsData) {
      this.setState({
        beaconsData: this.props.beaconsData
      });
    }
    if(prevProps.beaconsNb !== this.props.beaconsNb){

      this.setState({
        total: this.props.beaconsNb
      });
    }

      if (prevProps.findBeacon !== this.props.findBeacon) {
       if (this.state.searchValue.length !== 0) {
        this.setState({
          beaconsData: this.props.findBeacon,
          total: this.props.findBeacon.length
        });
       } else {
        this.setState({
          beaconsData: this.props.beaconsData,
          total: this.props.beaconsNb
        });
       }
     }
  }
  onsearchChange(value) {
    this.setState({ searchValue: value });
    const data = {
      searchValue: value
    };
    this.props.FindBeaconByName(data);
  }
  render() {
    const { isLoading, isLoader } = this.props;
    let load
    if (isLoader) {
      load = <p style={{ textAlign: "center" }}>Is loading ...</p>;
    }
    let btnValidate = (
      <Button onClick={() => this.onDelete()} type="submit" className="btn-secondary" size="md"><i className="icon-check"></i>  <Trans i18nKey="yes" /></Button>
    )
    if (isLoading) {
      btnValidate = <CircularProgress size={30} style={{ color: "#083328" }} />;
    }

  
    return (
      <div className="animated fadeIn">
        <Row>

          <Col xl={10}>

            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> <Trans i18nKey="IBeacons.IBeaconsList" /> <Link to={`/ibeacon/create`}><img src={addBtn} alt="" style={{ color: "#235C4E", cursor: "pointer", marginTop: '0.5%', float: (localStorage.getItem('i18nextLng') === "en") ? 'right' : 'left' }} id={'Tooltip-' + this.props.id} /> </Link>

                <Tooltip isOpen={this.state.tooltipOpen} target={'Tooltip-' + this.props.id} toggle={this.toggle}>
                  <Trans i18nKey="IBeacons.AddIBeacon" />
                </Tooltip>

              </CardHeader>
              <CardBody>
              {load}
              <div className="search">
                  <Input
                    type="text"
                    placeholder="Search By Code"
                    value={this.state.searchValue}
                    onChange={e => this.onsearchChange(e.target.value)}
                  />
                </div>
                <br />
                <br />
                <br />
                <br />
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col"> <Trans i18nKey="IBeacons.Code" /> </th>
                      <th scope="col"> <Trans i18nKey="IBeacons.Major" /> </th>
                      <th scope="col"> <Trans i18nKey="IBeacons.Minor" /> </th>
                      <th scope="col"> <Trans i18nKey="IBeacons.UUID" /> </th>
                      <th scope="col"><Trans i18nKey="Reservations.created_at" /></th>
                      <th scope="col"><Trans i18nKey="Reservations.modified_at" /></th>
                      <th scope="col"> <Trans i18nKey="Actions" /> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.listBeaconsErr === "" ?

                      Object.values(this.state.beaconsData).map((beaconData) => {
                        var cre = beaconData.createdAt;
                        var upd = beaconData.updatedAt;
                        var date = new Date(cre);
                        var year = date.getFullYear();
                        var month = date.getMonth() + 1;
                        var dt = date.getDate();

                        if (dt < 10) {
                          dt = '0' + dt;
                        }
                        if (month < 10) {
                          month = '0' + month;
                        }
                        var dateUp = new Date(upd);
                        var yearUp = dateUp.getFullYear();
                        var monthUp = dateUp.getMonth() + 1;
                        var dtUp = dateUp.getDate();

                        if (dtUp < 10) {
                          dtUp = '0' + dtUp;
                        }
                        if (monthUp < 10) {
                          monthUp = '0' + monthUp;
                        }

                        return <tr key={beaconData._id} id={beaconData._id}>
                          <td>{beaconData.Code}</td>
                          <td>{beaconData.Major === null ? 0 : beaconData.Major} </td>
                          <td>{beaconData.Minor === null ? 0 : beaconData.Minor}</td>
                          <td>{beaconData.UUID === null ? 0 : beaconData.UUID}</td>
                          <td>{dt + '-' + month + '-' + year}</td>
                          <td>{dtUp + '-' + monthUp + '-' + yearUp}</td>
                          <td>
                            <Link to={`/ibeacons/${beaconData._id}`}><i className="icon-pencil" ></i></Link>
                            <i onClick={() => this.toggleModal(beaconData._id)} className="icon-trash" style={{ marginLeft: '10%', cursor: "pointer" }}></i>
                          </td>

                        </tr>
                      }) : <tr><td><Trans i18nKey="IBeacons.noIBeacons" /></td></tr>}
                    <Modal isOpen={this.state.modal} toggle={() => this.toggleModal()} className={this.props.className}
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)"
                      }}>
                      <ModalHeader toggle={() => this.toggleModal()}><Trans i18nKey="IBeacons.IBeacondeltTitle" /></ModalHeader>

                      <ModalBody>
                        <br />
                        {(localStorage.getItem('i18nextLng') === "en") ? "Do you really want to delete this IBeacon?" : "هل تريد حقًا حذف هذا الابيكون؟"}
                        <br />
                        <br />
                      </ModalBody>
                      <ModalFooter>
                        {btnValidate}{' '}
                        <Button style={{
                          color: "white",
                          backgroundColor: "#C6893F",
                          borderColor: "#C6893F",
                          marginRight: "10px"
                        }} onClick={() => this.toggleModal()}><i className="icon-ban"></i> <Trans i18nKey="cancel" /></Button>
                      </ModalFooter>
                    </Modal>
                  </tbody>
                </Table>
                <Pagination onChange={this.onChange} showTitle={false} current={this.state.current} total={this.state.total} />
                <br />
                <br />
                <ReactFileReader fileTypes={[".csv"]} base64={false} multipleFiles={false} handleFiles={this.handleFiles}>
                  <Button className='btn-secondary'><Trans i18nKey="upload" /></Button>
                </ReactFileReader>
              </CardBody>
            </Card>
          </Col>
          <ToastsContainer store={ToastsStore} />
          {this.state.notvalid &&
            <Container style={{ paddingTop: '2%' }}>
              <Row className="justify-content-center">
                <img src={notValid} alt="notvalid" style={
                  {
                    width: "20px",
                    marginRight: "10px",
                    height: "20px",
                    marginLeft: "10px"
                  }
                } />
                <p style={{ color: 'red' }}>{this.state.notvalid}</p>
              </Row>
            </Container>}
          {
            this.state.deleted !== '' ? <Container>
              <Row className="justify-content-center">
                <img src={notValid} alt="notvalid" style={
                  {
                    width: "20px",
                    marginRight: "10px",
                    height: "20px",
                    marginLeft: "10px"
                  }
                } />
                <p style={{ color: 'red' }}> {this.state.deleted} </p>
              </Row>
            </Container>
              : <div></div>
          }
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    beaconsData: state.beaconsData.beaconsData,
    beaconsNb: state.beaconsNb.beaconsNb,
    isLoading: state.ui.isLoading,
    isLoader: state.ui.isLoader,
    listBeaconsErr: state.listBeaconsErr.listBeaconsErr,
    deleteBeaconstatus: state.deleteBeaconstatus.deleteBeaconstatus,
    DeleteBeaconerr: state.DeleteBeaconerr.DeleteBeaconerr,
    findBeaconErr: state.findBeaconErr.findBeaconErr,
    findBeacon: state.findBeacon.findBeacon

  };
};

const mapDispatchToProps = dispatch => {
  return {
    getBeacons: (data) => dispatch(getBeacons(data)),
    DeleteBeacon: (data) => dispatch(DeleteBeacon(data)),
    FindBeaconByName: data => dispatch(FindBeaconByName(data))
  };
};


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Beacons);