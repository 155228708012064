import axios from "axios";

import {
  uiStartLoading,
  uiStopLoading
} from "../index";
import {
  NEWS_CREATE
} from "../actionTypes";
import {
  CREATE_NEWS_ERR
} from "../actionTypes";
import {
  STATUS_NEWS_CREATE
} from "../actionTypes";

import {
  NEWSL_DATA
} from "../actionTypes";
import {
  NEWSL_LIST_ERR
} from "../actionTypes";
import {
  NEWSL_NBR
} from "../actionTypes";


import {
  NEWS_DATA,
  LIST_NEWS_ACT_DATA,
  LIST_NEWS_ACT_ERR
} from "../actionTypes";
import {
  NEWS_ERR
} from "../actionTypes";

import {
  NEWS_DELETE
} from "../actionTypes";
import {
  NEWS_DELETE_ERR
} from "../actionTypes";
import {
  NEWS_DELETE_STATUS
} from "../actionTypes";

import {
  NEWS_EDIT_ERR
} from "../actionTypes";
import {
  NEWS_EDIT
} from "../actionTypes";
import {
  NEWS_EDIT_STATUS
} from "../actionTypes";

export const createNews = data => {
  return dispatch => {
    dispatch(uiStartLoading());
    dispatch(CreateNewStatus(""));
    var token = localStorage.getItem("adminToken");

    const form = new FormData();

    for (let i = 0; i < data.CoversList.length; i++) {
      form.append("CoversList", data.CoversList[i]);
    }

    if (data.videosList.length === 0) {
      form.append("videosList", "no videos");
    } else {
      for (let i = 0; i < data.videosList.length; i++) {
        form.append("videosList", data.videosList[i]);
      }

    }
    form.append("title", data.title);
    form.append("date", data.date);
    form.append("active", data.active);
    form.append("ARtitle", data.ARtitle);
    form.append("description", data.description);
    form.append("ARdescription", data.ARdescription);


    const config = {
      headers: {
        enctype: "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token
      }
    };

    axios
      .post(`${process.env.REACT_APP_DOMAIN}/api/News/add`, form, config, {
        useCredentails: true
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(parsedRes => {
        dispatch(CreateNewStatus(""));
        dispatch(uiStopLoading())

        if (parsedRes.data.status === "fail") {
          dispatch(CreateNewerr(parsedRes.data.error));
        } else {
          dispatch(CreateNewerr(""));
          dispatch(CreateNew(parsedRes.data.data));
          dispatch(CreateNewStatus(parsedRes.data.status));

        }

      });
  };
};

export const editNews = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    const form = new FormData();
    for (let i = 0; i < data.gallery.length; i++) {
      form.append("gallery", data.gallery[i]);
    }
    for (let i = 0; i < data.pictures.length; i++) {
      form.append("CoversList", data.pictures[i]);
    }

    if (data.videosList.length === 0) {
      form.append("videosList", "no videos");
    } else {
      for (let i = 0; i < data.videosList.length; i++) {
        form.append("videosList", data.videosList[i]);
      }

    }
    form.append("NewsID", data.NewsID);
    form.append("title", data.title);
    form.append("ARtitle", data.ARtitle);
    form.append("active", data.active);
    form.append("date", data.date);
    form.append("description", data.description);
    form.append("ARdescription", data.ARdescription);

    const config = {
      headers: {
        enctype: "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token
      }
    };

    axios
      .post(`${process.env.REACT_APP_DOMAIN}/api/News/edit`, form, config, {
        useCredentails: true
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(parsedRes => {
        dispatch(EditNewstatus(""));
        dispatch(uiStopLoading())

        if (parsedRes.data.status === "fail") {

          dispatch(EditNewerr(parsedRes.data.error));
        } else if (parsedRes.data.status === "failure") {

          dispatch(EditNewerr(parsedRes.data.error));
        } else {
          dispatch(EditNewerr(""));
          dispatch(EditNew(parsedRes.data.data));
          dispatch(EditNewstatus(parsedRes.data.status));
        }

      });
  };


};
export const getActiveNews = data => {

  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/News/getActiveAdminNewsList`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        if (parsedRes.status === "failure") {
          dispatch(listActNewsErr(parsedRes.error));

        } else {
          dispatch(listActNewsErr(""));
          dispatch(ActNewsData(parsedRes.data));

        }

      });
  };
};
export const EditNewerr = value => {
  return {
    type: NEWS_EDIT_ERR,
    EditNewerr: value
  };
};
export const EditNew = value => {
  return {
    type: NEWS_EDIT,
    EditNew: value
  };
};
export const EditNewstatus = value => {
  return {
    type: NEWS_EDIT_STATUS,
    EditNewstatus: value
  };
};

export const getNews = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/News/get`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-access-token": token

        },
        body: JSON.stringify({
          NewsID: data.NewsID
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())

        if (parsedRes.status === "success") {
          dispatch(getNewsData(parsedRes.data));

        } else {
          dispatch(getNewsErr(parsedRes.data));
        }


      });
  };
};

export const DeleteNew = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(deleteNewstatus(""));
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/News/delete/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token

        },
        body: JSON.stringify({
          NewsID: data.NewsID,

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(deleteNewstatus(""));
        dispatch(uiStopLoading())
        if (parsedRes.status === "fail") {
          dispatch(DeleteNewerr(parsedRes.error));
        } else {
          dispatch(DeleteNewerr(""));
          dispatch(deleteNew(parsedRes.data));
          dispatch(deleteNewstatus(parsedRes.status));
        }

      });
  };
};

export const getNewsList = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/News/getAdminList`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token

        },
        body: JSON.stringify({
          pageNumber: data.pageNumber

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        if (parsedRes.status === "failure") {
          dispatch(listNewsErr(parsedRes.error));

        } else if (parsedRes.status === "fail") {
          dispatch(listNewsErr(parsedRes.error));
        } else {
          dispatch(listNewsErr(""));
          dispatch(NewsData(parsedRes.data));
          dispatch(NewsNbr(parsedRes.Length));
        }

      });
  };
};

export const CreateNew = value => {
  return {
    type: NEWS_CREATE,
    CreateNew: value
  };
};
export const CreateNewerr = value => {
  return {
    type: CREATE_NEWS_ERR,
    CreateNewerr: value
  };
};
export const CreateNewStatus = value => {
  return {
    type: STATUS_NEWS_CREATE,
    CreateNewStatus: value
  };
};
export const listNewsErr = value => {
  return {
    type: NEWSL_LIST_ERR,
    listNewsErr: value
  };
};
export const NewsData = value => {
  return {
    type: NEWSL_DATA,
    NewsData: value
  };
};
export const NewsNbr = value => {
  return {
    type: NEWSL_NBR,
    NewsNbr: value
  };
};
export const DeleteNewerr = value => {
  return {
    type: NEWS_DELETE_ERR,
    DeleteNewerr: value
  };
};

export const deleteNew = value => {
  return {
    type: NEWS_DELETE,
    deleteNew: value
  };
};

export const deleteNewstatus = value => {
  return {
    type: NEWS_DELETE_STATUS,
    deleteNewstatus: value
  };
};

export const getNewsData = value => {
  return {
    type: NEWS_DATA,
    getNewsData: value
  };
};

export const getNewsErr = value => {
  return {
    type: NEWS_ERR,
    getNewsErr: value
  };
};
export const listActNewsErr = value => {
  return {
    type: LIST_NEWS_ACT_ERR,
    listActNewsErr: value
  };
};
export const ActNewsData = value => {
  return {
    type: LIST_NEWS_ACT_DATA,
    ActNewsData: value
  };
};