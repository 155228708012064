import { NEWSCAST_GET_DATA } from "../../actions/actionTypes";
import { NEWSCAST_GET_ERR } from "../../actions/actionTypes";
import { NEWSCAST_ADD_DATA } from "../../actions/actionTypes";
import { NEWSCAST_ADD_ERR } from "../../actions/actionTypes";
import { NEWSCAST_ADD_STATUS } from "../../actions/actionTypes";

const initialState = {
  addNewscastErr: "",
  NewscastData: [],
  getNewscastData: [],
  getNewscastErr: '',
  NewscastStatus: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case NEWSCAST_GET_DATA:
      return {
        ...state,
        getNewscastData: action.getNewscastData
      };
    case NEWSCAST_GET_ERR:
      return {
        ...state,
        getNewscastErr: action.getNewscastErr
      };
    case NEWSCAST_ADD_DATA:
      return {
        ...state,
        NewscastData: action.NewscastData
      };
    case NEWSCAST_ADD_ERR:
      return {
        ...state,
        addNewscastErr: action.addNewscastErr
      };
    case NEWSCAST_ADD_STATUS:
      return {
        ...state,
        NewscastStatus: action.NewscastStatus
      };

    default:
      return state;
  }
};

export default reducer;
