import { THINGS_DATA } from "../../actions/actionTypes";
import { LIST_THINGS_ERR } from "../../actions/actionTypes";

const initialState = {
  thingsData: [],
  listThingsErr: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case THINGS_DATA:
      return {
        ...state,
        thingsData: action.thingsData
      };
    case LIST_THINGS_ERR:
      return {
        ...state,
        listThingsErr: action.listThingsErr
      };
    default:
      return state;
  }
};
export default reducer;