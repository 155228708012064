import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Table, Button } from 'reactstrap';
import { getReservationsbyEvent } from "../../../store/actions";
import { compose } from "redux";
import history from "../../../history";
import { connect } from "react-redux";
import { Trans } from "react-i18next";
import { Link } from 'react-router-dom';

class ReservationsByEvent extends Component {

  componentDidMount() {
    var token = localStorage.getItem("adminToken")
    var jwtDecode = require('jwt-decode');
    var decoded = jwtDecode(token);
    var expirationDate = decoded.exp;
    var seconds = 1000;
    var d = new Date();
    var t = d.getTime();

    if (expirationDate < Math.round(t / seconds)) {
      localStorage.setItem("adminToken", "")
      history.push("/login");

    }
    else { this.props.getReservationsbyEvent(); }
  }
  render() {
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xl={8}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> <Trans i18nKey="EventReservations.ReservationsByEvent" />

              </CardHeader>
              <CardBody>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col"><Trans i18nKey="Events.Event" /></th>

                      <th scope="col"><Trans i18nKey="Reservations.reservationsNb" /></th>
                      <th scope="col"><Trans i18nKey="moreDetails" /></th>
                    </tr>
                  </thead>
                  <tbody>


                    {this.props.ByEventReservationserr === "" ?

                      Object.values(this.props.ByEventReservations).map((eventData) => {

                        if (eventData._id !== undefined) {
                          if (localStorage.getItem('i18nextLng') === "en")

                            return <tr key={eventData._id} id={eventData._id}>
                              <td>{eventData.title}</td>

                              <td>{eventData.reservationsNbr}</td>
                              <td><Link to={`/reservations/by_event/${eventData._id}`}><Button outline className="btn-secondary" size="sm" type="submit" ><i className="icon-paper-plane"></i>  <Trans i18nKey="moreDetails" /></Button></Link></td>

                            </tr>
                          return <tr key={eventData._id} id={eventData._id}>
                            <td>{eventData.translationAr[0].ARtitle}</td>

                            <td>{eventData.reservationsNbr}</td>
                            <td><Link to={`/reservations/by_event/${eventData._id}`}><Button outline className="btn-secondary" size="sm" type="submit" ><i className="icon-paper-plane"></i>  <Trans i18nKey="moreDetails" /></Button></Link></td>
                          </tr>
                        }

                      })
                      : <tr><td><Trans i18nKey="Reservations.noReservation" /></td></tr>}


                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

//export default Reservations;
const mapStateToProps = state => {
  return {
    ByEventReservations: state.ByEventReservations.ByEventReservations,
    isLoading: state.ui.isLoading,
    ByEventReservationserr: state.ByEventReservationserr.ByEventReservationserr
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getReservationsbyEvent: () => dispatch(getReservationsbyEvent())
  };
};


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ReservationsByEvent);