import React, { Component } from 'react';
import {  Card, CardBody, CardHeader, Col, Row, Table, Button} from 'reactstrap';
import { getReservationsbyPlace } from "../../../store/actions";
import { compose } from "redux";
import history from "../../../history";
import { connect } from "react-redux";
import { Trans } from "react-i18next";
import { Link } from 'react-router-dom';

class ReservationsByPlace extends Component {
  
    componentDidMount() {
      var token = localStorage.getItem("adminToken") 
      var jwtDecode = require('jwt-decode');
      var decoded = jwtDecode(token);
      var expirationDate = decoded.exp;
      var seconds = 1000;
      var d = new Date(); 
      var t= d.getTime();
     
      if (expirationDate < Math.round(t / seconds)) {
        localStorage.setItem("adminToken","")
        history.push("/login");
       
      }
      else {this.props.getReservationsbyPlace();}
  }
  render() {
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xl={8}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> <Trans i18nKey="PlaceReservations.ReservationsByPlace" />
               
              </CardHeader>
              <CardBody>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col"><Trans i18nKey="Places.Place" /></th>
                      <th scope="col"><Trans i18nKey="Reservations.reservationsNb" /></th>
                      <th scope="col"><Trans i18nKey="moreDetails" /></th>
                    </tr>
                  </thead>
                  <tbody>
                   
                  
                  { this.props.ByPlaceReservationserr === "" ? 
              
                  Object.values(this.props.ByPlaceReservations).map((placeData) => {
                                   
                  
                    if(localStorage.getItem('i18nextLng')=== "en")
                  
                    return <tr key={placeData._id} id={placeData._id}>
                              <td>{placeData.title}</td>                            
                              <td>{placeData.reservationsNbr}</td>   
                              
                              <td><Link to={`/reservations/by_place/${placeData._id}`}><Button  outline  className="btn-secondary"  size="sm" type="submit" ><i className="icon-paper-plane"></i>  <Trans i18nKey="moreDetails" /></Button></Link></td>                                           
                               
                          </tr>
                    return <tr key={placeData._id} id={placeData._id}> 
                              <td>{placeData.translationAr[0].ARtitle}</td>
                              <td>{placeData.reservationsNbr}</td>                            
                              <td><Link to={`/reservations/by_place/${placeData._id}`}><Button  outline  className="btn-secondary"  size="sm" type="submit" ><i className="icon-paper-plane"></i>  <Trans i18nKey="moreDetails" /></Button></Link></td>                                           
                          </tr> 
                  }) 
                   :  <tr><td><Trans i18nKey="Reservations.noReservation" /></td></tr>}
                
                   
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

//export default Reservations;
const mapStateToProps = state => {
  return {
    ByPlaceReservations: state.ByPlaceReservations.ByPlaceReservations,
    isLoading: state.ui.isLoading,
    ByPlaceReservationserr: state.ByPlaceReservationserr.ByPlaceReservationserr
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getReservationsbyPlace:()=>dispatch(getReservationsbyPlace())
  };
};


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ReservationsByPlace);