import {
  MERCHANTS_DATA,
  MERCHANTS_NBR
} from "../actionTypes";
import {
  MERCHANT_DATA,
  LIST_MERCHANTS_ACT_DATA,
  LIST_MERCHANTS_ACT_ERR
} from "../actionTypes";
import {
  uiStartLoading,
  uiStopLoading
} from "../index";
import {
  LIST_MERCHANTS_ERR
} from "../actionTypes";

import {
  MERCHANT_EDIT
} from "../actionTypes";
import {
  EDIT_MERCHANT_ERR
} from "../actionTypes";
import {
  STATUS_MERCHANT_EDIT
} from "../actionTypes";

import {
  MERCHANT_DELETE
} from "../actionTypes";
import {
  DELETE_MERCHANT_ERR
} from "../actionTypes";
import {
  STATUS_MERCHANT_DELETE
} from "../actionTypes";

import {
  MERCHANT_CREATE
} from "../actionTypes";
import {
  CREATE_MERCHANT_ERR
} from "../actionTypes";
import {
  STATUS_MERCHANT_CREATE
} from "../actionTypes";
import {
  decode
} from "../../lib/cryp";
export const getMerchants = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/merchant/list`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          pageNumber: data.pageNumber
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(async parsedRes => {
        dispatch(uiStopLoading())
        if (parsedRes.status === "failure") {
          dispatch(listMerchantsErr(parsedRes.error));

        } else {
          dispatch(listMerchantsErr(""));
          let data = await decode(JSON.stringify(parsedRes.data));
          dispatch(merchantsData(JSON.parse(data)));
          dispatch(MerchantsNbr(parsedRes.Length));
        }

      });
  };
};

export const MerchantsNbr = value => {
  return {
    type: MERCHANTS_NBR,
    MerchantsNbr: value
  };
};
export const getMerchant = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());

    fetch(`${process.env.REACT_APP_DOMAIN}/api/merchant/get`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-access-token": token

        },
        body: JSON.stringify({
          merchantId: data.merchantId
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())

        dispatch(merchantData(parsedRes.data));
      });
  };
};

export const editMerchant = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    dispatch(editMerchantstatus());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/merchant/edit`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token

        },
        body: JSON.stringify({
          email: data.email,
          name: data.name,
          birthDate: data.date,
          gender: data.gender,
          phone: data.phone,
          country: data.country,
          active: data.active,
          merchantId: data.merchantId,
          role: data.role,
          oldPassword: data.oldPassword,
          newPassword: data.newPassword
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {

        dispatch(uiStopLoading())

        if (parsedRes.status === "fail" || parsedRes.status === "failure") {

          dispatch(EditMerchanterr(parsedRes.status));
        } else {
          dispatch(editmerchant(parsedRes.data));
          dispatch(editMerchantstatus(parsedRes.status));
        }

      });
  };

};

export const createMerchant = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    dispatch(CreateMerchantstatus(""));

    fetch(`${process.env.REACT_APP_DOMAIN}/api/merchant/add`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          email: data.email,
          name: data.name,
          birthDate: data.date,
          gender: data.gender,
          phone: data.phone,
          country: data.country,
          active: data.active,
          password: data.password,
          admin_id: data.admin_id,
          role: data.role
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(CreateMerchantstatus(""));
        dispatch(uiStopLoading())

        if (parsedRes.status === "fail") {
          dispatch(CreateMerchanterr(parsedRes.error));
        } else {
          dispatch(CreateMerchanterr(""));
          dispatch(CreateMerchant(parsedRes.data));
          dispatch(CreateMerchantstatus(parsedRes.status));
        }

      });
  };
};
export const DeleteMerchant = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(deleteMerchantstatus(""));
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/merchant/delete`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token

        },
        body: JSON.stringify({
          merchantId: data.merchantId,

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(deleteMerchantstatus(""));
        dispatch(DeleteMerchanterr(""));
        dispatch(uiStopLoading())
        if (parsedRes.status === "fail") {
          dispatch(DeleteMerchanterr(parsedRes.error));
        } else if (parsedRes.status === "failure") {
          dispatch(DeleteMerchanterr(parsedRes.error));
        } else {
          dispatch(DeleteMerchanterr(""));
          dispatch(deleteMerchant(parsedRes.data));
          dispatch(deleteMerchantstatus(parsedRes.status));
        }

      });
  };
};


export const merchantsData = value => {
  return {
    type: MERCHANTS_DATA,
    merchantsData: value
  };
};
export const merchantData = value => {
  return {
    type: MERCHANT_DATA,
    merchantData: value
  };
};

export const editmerchant = value => {
  return {
    type: MERCHANT_EDIT,
    editmerchant: value
  };
};
export const editMerchantstatus = value => {
  return {
    type: STATUS_MERCHANT_EDIT,
    editMerchantstatus: value
  };
};
export const listMerchantsErr = value => {
  return {
    type: LIST_MERCHANTS_ERR,
    listMerchantsErr: value
  };
};
export const EditMerchanterr = value => {
  return {
    type: EDIT_MERCHANT_ERR,
    EditMerchanterr: value
  };
};

export const deleteMerchant = value => {
  return {
    type: MERCHANT_DELETE,
    deleteMerchant: value
  };
};
export const DeleteMerchanterr = value => {
  return {
    type: DELETE_MERCHANT_ERR,
    DeleteMerchanterr: value
  };
};
export const deleteMerchantstatus = value => {
  return {
    type: STATUS_MERCHANT_DELETE,
    deleteMerchantstatus: value
  };
};

export const CreateMerchant = value => {
  return {
    type: MERCHANT_CREATE,
    CreateMerchant: value
  };
};
export const CreateMerchanterr = value => {
  return {
    type: CREATE_MERCHANT_ERR,
    CreateMerchanterr: value
  };
};
export const CreateMerchantstatus = value => {
  return {
    type: STATUS_MERCHANT_CREATE,
    CreateMerchantstatus: value
  };
};
export const listActMerchantsErr = value => {
  return {
    type: LIST_MERCHANTS_ACT_ERR,
    listActMerchantsErr: value
  };
};
export const ActmerchantsData = value => {
  return {
    type: LIST_MERCHANTS_ACT_DATA,
    ActmerchantsData: value
  };
};