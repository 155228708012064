import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Badge, Col, Row, Table, Tooltip, Modal, ModalBody, Container, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { getFacilities, DeleteFacility } from "../../../store/actions";
import { compose } from "redux";
import addBtn from '../../../assets/img/brand/plus-button.png';
import history from "../../../history.js";
import { connect } from "react-redux";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Trans } from "react-i18next";
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import notValid from '../../../assets/img/brand/notValid.png';
import CircularProgress from "@material-ui/core/CircularProgress";

class Facilities extends Component {

  constructor(props) {
    super(props)
    this.state = {
      tooltipOpen: false,
      modal: false,
      description: '',
      title: '',
      active: '',
      deleted: '',
      current: 1,
      facilityId: ''
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }
  onChange = (page) => {
    this.setState({
      current: page
    });
    const data = { pageNumber: page };
    this.props.getFacilities(data)
  }
  componentDidMount() {
    var token = localStorage.getItem("adminToken")
    var jwtDecode = require('jwt-decode');
    var decoded = jwtDecode(token);
    var expirationDate = decoded.exp;
    var seconds = 1000;
    var d = new Date();
    var t = d.getTime();

    if (expirationDate < Math.round(t / seconds)) {
      localStorage.setItem("adminToken", "")
      history.push("/login");
    }
    else {
      const data = { pageNumber: 1 };
      this.props.getFacilities(data)
    }

  }
  onDelete() {
    const data = { facilityID: this.state.facilityId };
    this.props.DeleteFacility(data);
  };
  toggleModal(facilityId) {

    this.setState({
      modal: !this.state.modal,
      facilityId: facilityId,
    });
  }
  componentWillReceiveProps(prevProps) {
    if (prevProps.deleteFacilityStatus !== this.props.deleteFacilityStatus) {
      if (prevProps.deleteFacilityStatus === "success") {
        this.setState({
          modal: false
        });
        ToastsStore.info(<Trans i18nKey="Facilities.del" />, 3000)
        const data = { pageNumber: 1 };
        this.props.getFacilities(data)
      }

    }
    if (prevProps.DeleteFacilityerr.En !== this.props.DeleteFacilityerr.En) {
      if (prevProps.DeleteFacilityerr !== "") {
        this.setState({
          modal: false,
          deleted: localStorage.getItem('i18nextLng') === "en" ? prevProps.DeleteFacilityerr.En : prevProps.DeleteFacilityerr.Ar
        });
      }

    }

  }
  render() {
    const { isLoading } = this.props;

    let btnValidate = (
      <Button onClick={() => this.onDelete()} type="submit" className="btn-secondary" size="md"><i className="icon-check"></i>  <Trans i18nKey="yes" /></Button>
    )
    if (isLoading) {
      btnValidate = <CircularProgress size={30} style={{ color: "#083328" }} />;
    }
    return (
      <div className="animated fadeIn">
        <Row>

          <Col xl={12}>

            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> <Trans i18nKey="Facilities.FacilitiesList" />  <Link to={`/facility/create`}><img src={addBtn} alt="" style={{ color: "#235C4E", cursor: "pointer", marginTop: '0.5%', float: (localStorage.getItem('i18nextLng') === "en") ? 'right' : 'left' }} id={'Tooltip-' + this.props.id} /> </Link>

                <Tooltip isOpen={this.state.tooltipOpen} target={'Tooltip-' + this.props.id} toggle={this.toggle}>
                  <Trans i18nKey="Facilities.AddFacility" />
                </Tooltip>

              </CardHeader>
              <CardBody>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col"><Trans i18nKey="Title" /></th>
                      <th scope="col"><Trans i18nKey="Active" /></th>
                      <th scope="col"><Trans i18nKey="Reservations.created_at" /></th>
                      <th scope="col"><Trans i18nKey="Reservations.modified_at" /></th>
                      <th scope="col"><Trans i18nKey="Actions" /></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.listFacilitiesErr === "" ?
                      Object.values(this.props.FacilitiesData).map(facilityData => {

                        var cre = facilityData.createdAt;
                        var upd = facilityData.updatedAt;
                        var date = new Date(cre);
                        var year = date.getFullYear();
                        var month = date.getMonth() + 1;
                        var dt = date.getDate();

                        if (dt < 10) {
                          dt = '0' + dt;
                        }
                        if (month < 10) {
                          month = '0' + month;
                        }
                        var dateUp = new Date(upd);
                        var yearUp = dateUp.getFullYear();
                        var monthUp = dateUp.getMonth() + 1;
                        var dtUp = dateUp.getDate();

                        if (dtUp < 10) {
                          dtUp = '0' + dtUp;
                        }
                        if (monthUp < 10) {
                          monthUp = '0' + monthUp;
                        }
                        if (localStorage.getItem('i18nextLng') === "en")
                          return <tr key={facilityData._id} id={facilityData._id}>
                            <td>{facilityData.title}</td>

                            <td><Badge pill color={facilityData.active === "no" ? "danger" : "success"} >{facilityData.active}</Badge></td>
                            <td>{dt + '-' + month + '-' + year}</td>
                            <td>{dtUp + '-' + monthUp + '-' + yearUp}</td>
                            <td>
                              <Link to={`/facilities/${facilityData._id}`}><i className="icon-pencil" ></i></Link>
                              <i onClick={() => this.toggleModal(facilityData._id)} className="icon-trash" style={{ marginLeft: '10%', cursor: "pointer" }}></i>
                            </td>
                          </tr>
                        return <tr key={facilityData._id} id={facilityData._id}>
                          <td>{facilityData.translationAr[0].ARtitle}</td>
                          <td><Badge pill color={facilityData.active === "no" ? "danger" : "success"} >{facilityData.active === "no" ? <Trans i18nKey="no" /> : <Trans i18nKey="yes" />} </Badge></td>
                          <td>{dt + '-' + month + '-' + year}</td>
                          <td>{dtUp + '-' + monthUp + '-' + yearUp}</td>
                          <td>
                            <Link to={`/facilities/${facilityData._id}`}><i className="icon-pencil" ></i></Link>
                            <i onClick={() => this.toggleModal(facilityData._id)} className="icon-trash" style={{ marginLeft: '10%', cursor: "pointer" }}></i>
                          </td>

                        </tr>

                      }) : <tr><td><Trans i18nKey="Facilities.noFacility" /></td></tr>}

                    <Modal isOpen={this.state.modal} toggle={() => this.toggleModal()} className={this.props.className}
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)"
                      }}>
                      <ModalHeader toggle={() => this.toggleModal()}><Trans i18nKey="Facilities.facilitydeltTitle" /></ModalHeader>

                      <ModalBody>
                        <br />
                        {(localStorage.getItem('i18nextLng') === "en") ? "Do you really want to delete this facility ?" : "هل تريد حقًا حذف هذا المرفق ؟"}
                        <br />
                        <br />
                      </ModalBody>
                      <ModalFooter>
                        {btnValidate}{' '}
                        <Button style={{
                          color: "white",
                          backgroundColor: "#C6893F",
                          borderColor: "#C6893F",
                          marginRight: "10px"
                        }} onClick={() => this.toggleModal()}><i className="icon-ban"></i> <Trans i18nKey="cancel" /></Button>
                      </ModalFooter>
                    </Modal>

                  </tbody>
                </Table>
                <Pagination onChange={this.onChange} showTitle={false} current={this.state.current} total={this.props.FacilitiesNbr} />
              </CardBody>
            </Card>
          </Col>
          <ToastsContainer store={ToastsStore} />
          {
            this.state.deleted !== '' ? <Container>
              <Row className="justify-content-center">
                <img src={notValid} alt="notvalid" style={
                  {
                    width: "20px",
                    marginRight: "10px",
                    height: "20px",
                    marginLeft: "10px"
                  }
                } />
                <p style={{ color: 'red' }}> {this.state.deleted} </p>
              </Row>
            </Container>
              : <div></div>
          }
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    FacilitiesData: state.FacilitiesData.FacilitiesData,
    isLoading: state.ui.isLoading,
    listFacilitiesErr: state.listFacilitiesErr.listFacilitiesErr,
    deleteFacilityStatus: state.deleteFacilityStatus.deleteFacilityStatus,
    DeleteFacilityerr: state.DeleteFacilityerr.DeleteFacilityerr,
    CreateFacility: state.CreateFacility.CreateFacility,
    CreateFacilityerr: state.CreateFacilityerr.CreateFacilityerr,
    CreateFacilityStatus: state.CreateFacilityStatus.CreateFacilityStatus,
    FacilitiesNbr: state.FacilitiesNbr.FacilitiesNbr

  };
};

const mapDispatchToProps = dispatch => {
  return {
    getFacilities: (data) => dispatch(getFacilities(data)),
    DeleteFacility: (data) => dispatch(DeleteFacility(data)),

  };
};


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Facilities);