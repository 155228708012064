import { CATEGORIES_DATA, CATEGORY_NB } from "../../actions/actionTypes";
import { CATEGORY_DATA } from "../../actions/actionTypes";
import { LIST_CATEGORIES_ERR } from "../../actions/actionTypes";

import { CATEGORY_EDIT } from "../../actions/actionTypes";
import { EDIT_CATEGORY_ERR } from "../../actions/actionTypes";
import { STATUS_CATEGORY_EDIT } from "../../actions/actionTypes";

import { DELETE_CATEGORY_ERR } from "../../actions/actionTypes";
import { STATUS_CATEGORY_DELETE } from "../../actions/actionTypes";
import { CATEGORY_DELETE } from "../../actions/actionTypes";

import { CREATE_CATEGORY_ERR } from "../../actions/actionTypes";
import { STATUS_CATEGORY_CREATE } from "../../actions/actionTypes";
import { CATEGORY_CREATE } from "../../actions/actionTypes";
import { LIST_ACT_CATEGORIES_ERR, ACT_CATEGORIES_DATA } from "../../actions/actionTypes";


const initialState = {
  CategoriesData: [],
  CategoriesNbr: 0,
  categoryData: [],
  editcategory: [],
  EditCategoryerr: '',
  listCategoriesErr: '',
  editCategoryStatus: '',
  deleteCategory: [],
  DeleteCategoryerr: '',
  deleteCategoryStatus: '',
  CreateCategory: [],
  CreateCategoryerr: '',
  CreateCategoryStatus: '',
  listActCategoriesErr: '',
  ActCategoriesData: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY_NB:
      return {
        ...state,
        CategoriesNbr: action.CategoriesNbr
      };
    case CATEGORIES_DATA:
      return {
        ...state,
        CategoriesData: action.CategoriesData
      };

    case CATEGORY_DATA:
      return {
        ...state,
        categoryData: action.categoryData
      };
    case CATEGORY_EDIT:
      return {
        ...state,
        editcategory: action.editcategory
      };
    case LIST_CATEGORIES_ERR:
      return {
        ...state,
        listCategoriesErr: action.listCategoriesErr
      };
    case EDIT_CATEGORY_ERR:
      return {
        ...state,
        EditCategoryerr: action.EditCategoryerr
      };
    case STATUS_CATEGORY_EDIT:
      return {
        ...state,
        editCategoryStatus: action.editCategoryStatus
      };
    case DELETE_CATEGORY_ERR:
      return {
        ...state,
        DeleteCategoryerr: action.DeleteCategoryerr
      };
    case STATUS_CATEGORY_DELETE:
      return {
        ...state,
        deleteCategoryStatus: action.deleteCategoryStatus
      };
    case CATEGORY_DELETE:
      return {
        ...state,
        deleteCategory: action.deleteCategory
      };

    case CREATE_CATEGORY_ERR:
      return {
        ...state,
        CreateCategoryerr: action.CreateCategoryerr
      };
    case STATUS_CATEGORY_CREATE:
      return {
        ...state,
        CreateCategoryStatus: action.CreateCategoryStatus
      };
    case CATEGORY_CREATE:
      return {
        ...state,
        CreateCategory: action.CreateCategory
      };
    case LIST_ACT_CATEGORIES_ERR:
      return {
        ...state,
        listActCategoriesErr: action.listActCategoriesErr
      };
    case ACT_CATEGORIES_DATA:
      return {
        ...state,
        ActCategoriesData: action.ActCategoriesData
      };

    default:
      return state;
  }
};

export default reducer;
