import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody,CardFooter, CardHeader, Col, Row, Table, Tooltip, Badge, Input,Container, Label, Button, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import { getFaqs, DeleteFaq, saveDescription, getDescription } from "../../../store/actions";
import { compose } from "redux";
import { Trans } from "react-i18next";
import addBtn from '../../../assets/img/brand/plus-button.png';
import history from "../../../history.js";
import { connect } from "react-redux";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import CircularProgress from "@material-ui/core/CircularProgress";
import notValid from '../../../assets/img/brand/notValid.png';
class Faqs extends Component {

    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this);
        this.toggleArabic = this.toggleArabic.bind(this);
        this.state = {
            FAQdescription: '',
            ARFAQdescription: '',
            current: 1,
            activeTab: new Array(2).fill('1'),
            modal: false,
            FAQId: '',
            empty:'',

        };
    }
    toggleArabic(tabPane, tab) {
        const newArray = this.state.activeTab.slice()
        newArray[tabPane] = tab
        this.setState({
            activeTab: newArray,
        });
    }
    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }
    onChange = (page) => {
        this.setState({
            current: page
        });
        const data = { pageNumber: page };
        this.props.getFaqs(data)
    }
    componentDidMount() {
        var token = localStorage.getItem("adminToken")
        var jwtDecode = require('jwt-decode');
        var decoded = jwtDecode(token);
        var expirationDate = decoded.exp;
        var seconds = 1000;
        var d = new Date();
        var t = d.getTime();

        if (expirationDate < Math.round(t / seconds)) {
            localStorage.setItem("adminToken", "")
            history.push("/login");

        }
        else {
            const data = { pageNumber: 1 };
            this.props.getFaqs(data)
            this.props.getDescription()
        }

    }
    componentDidUpdate(prevProps) {

        if (this.props.faqDescData !== prevProps.faqDescData) {
            this.setState({
                FAQdescription: this.props.faqDescData.FAQdescription,
                ARFAQdescription: this.props.faqDescData.translationAr[0].ARFAQdescription
            })

        }

    }
    onDelete() {
        const data = { faqID: this.state.FAQId };
        this.props.DeleteFaq(data);
    };

    toggleModal(faqID) {

        this.setState({
            modal: !this.state.modal,
            FAQId: faqID,
        });
    }
    componentWillReceiveProps(prevProps) {
        if (prevProps.deleteFaqstatus !== this.props.deleteFaqstatus) {
            if (prevProps.deleteFaqstatus === "success") {
                this.setState({
                    modal: false
                });
                ToastsStore.info(<h6><Trans i18nKey="FAQs.del" /></h6>, 3000)
                const data = { pageNumber: 1 };
                this.props.getFaqs(data)
            }
        }
        if (prevProps.editFaqDescStatus !== this.props.editFaqDescStatus) {
            if (prevProps.editFaqDescStatus === "success") {
                ToastsStore.info(<h6><Trans i18nKey="FAQs.updated" /></h6>, 3000)
            }

        }

    }

    onFAQDescriptionChange(value) {
        this.setState({
            FAQdescription: value
        });
    }
    onARFAQDescriptionChange(value) {
        this.setState({
            ARFAQdescription: value
        });
    }
    onSubmit = () => {
        const data = {
            FAQdescription: this.state.FAQdescription,
            ARFAQdescription: this.state.ARFAQdescription
        }
        if (data.FAQdescription.length === 0 || data.ARFAQdescription.length === 0 ) {
              this.setState({ empty: < Trans i18nKey="requiredFields" /> });
  
          } else{
            this.setState({ empty: "" });
            this.props.saveDescription(data);
          }
       
    }
    render() {
        const { isLoading, isLoader } = this.props;

        let btnValidate = (
            <Button onClick={this.onSubmit} type="submit" size="md" className="btn-secondary" ><i className="fa fa-dot-circle-o"></i> <Trans i18nKey="Submit" /></Button>
        )
        if (isLoading) {
            btnValidate = <CircularProgress size={30} style={{ color: "#083328" }} />;
        }
        let btnDelete = (
            <Button onClick={() => this.onDelete()} type="submit" className="btn-secondary" size="md"><i className="icon-check"></i>  <Trans i18nKey="yes" /></Button>
        )
        if (isLoader) {
            btnDelete = <CircularProgress size={30} style={{ color: "#083328" }} />;
        }
        return (
            <div className="animated fadeIn">
                <Row>
                    <Col lg={10}>
                        <Card>
                            <CardBody>
                                <Nav tabs style={{ backgroundColor: "#f0f3f5" }}>
                                    <NavItem>
                                        <NavLink
                                            active={this.state.activeTab[0] === '1'}
                                            onClick={() => { this.toggleArabic(0, '1'); }}
                                        >
                                            English
                  </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            active={this.state.activeTab[0] === '2'}
                                            onClick={() => { this.toggleArabic(0, '2'); }}
                                        >
                                            العربية
                  </NavLink>
                                    </NavItem>

                                </Nav>
                                <TabContent activeTab={this.state.activeTab[0]}>
                                    <TabPane tabId="1">
                                        <Col md="3">
                                            <Label htmlFor="text-input">* FAQ Description </Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="textarea" name="textarea-input" id="textarea-input" rows="7"
                                                placeholder="" value={this.state.FAQdescription}
                                                onChange={e => this.onFAQDescriptionChange(e.target.value)} />
                                        </Col>

                                    </TabPane>
                                    <TabPane tabId="2" style={{ direction: "rtl" }}>
                                        <Col md="3" >
                                            <Label style={{float: "right"}} htmlFor="text-input">* شرح الأسئلة الشائعة  </Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="textarea" name="textarea-input" id="textarea-input" rows="7"
                                                placeholder="" value={this.state.ARFAQdescription}
                                                onChange={e => this.onARFAQDescriptionChange(e.target.value)} />
                                        </Col>

                                    </TabPane>
                                </TabContent>
                                <br />
                  
                            </CardBody>
                            <CardFooter>
              {btnValidate}
               
                {this.state.empty &&  
                  <Container>
                   <Row className="justify-content-center">
                      <img src={notValid}  alt="notvalid" style={
                      {
                        width: "20px",
                        marginRight: "10px",
                        height: "20px",
                        marginLeft: "10px"
                      }
                      } />
                      <p style={{color: 'red'}}>{this.state.empty}</p> 
                    </Row>
                  </Container>} 
              </CardFooter>
                        </Card>
                    </Col>
                    <br />
                    <Col xl={10}>



                        <Card>
                            <CardHeader>
                                <i className="fa fa-align-justify"></i> <Trans i18nKey="FAQs.FAQsList" /> <Link to={`/FAQ/create`}><img src={addBtn} alt="" style={{ color: "#235C4E", cursor: "pointer", marginTop: '0.5%', float: (localStorage.getItem('i18nextLng') === "en") ? 'right' : 'left' }} id={'Tooltip-' + this.props.id} /> </Link>

                                <Tooltip isOpen={this.state.tooltipOpen} target={'Tooltip-' + this.props.id} toggle={this.toggle}>
                                    <Trans i18nKey="FAQs.AddFAQ" />
                                </Tooltip>

                            </CardHeader>
                            <CardBody>

                                <Table responsive hover>
                                    <thead>
                                        <tr>
                                            <th scope="col"> <Trans i18nKey="Question" /> </th>
                                            <th scope="col"> <Trans i18nKey="Active" /> </th>
                                            <th scope="col"><Trans i18nKey="Reservations.created_at" /></th>
                                            <th scope="col"><Trans i18nKey="Reservations.modified_at" /></th>
                                            <th scope="col"> <Trans i18nKey="Actions" /> </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.listFaqsErr === "" ?
                                            Object.values(this.props.faqsData).map(faqData => {
                                                var cre = faqData.createdAt;
                                                var upd = faqData.updatedAt;
                                                var date = new Date(cre);
                                                var year = date.getFullYear();
                                                var month = date.getMonth() + 1;
                                                var dt = date.getDate();

                                                if (dt < 10) {
                                                    dt = '0' + dt;
                                                }
                                                if (month < 10) {
                                                    month = '0' + month;
                                                }
                                                var dateUp = new Date(upd);
                                                var yearUp = dateUp.getFullYear();
                                                var monthUp = dateUp.getMonth() + 1;
                                                var dtUp = dateUp.getDate();

                                                if (dtUp < 10) {
                                                    dtUp = '0' + dtUp;
                                                }
                                                if (monthUp < 10) {
                                                    monthUp = '0' + monthUp;
                                                }
                                                if (localStorage.getItem('i18nextLng') === "en")
                                                    return <tr key={faqData._id} id={faqData._id}>
                                                        <td>{faqData.Question}</td>
                                                        <td><Badge pill color={faqData.active === "no" ? "danger" : "success"} >{faqData.active}</Badge></td>
                                                        <td>{dt + '-' + month + '-' + year}</td>
                                                        <td>{dtUp + '-' + monthUp + '-' + yearUp}</td>
                                                        <td>
                                                            <Link to={`/faqs/${faqData._id}`}><i className="icon-pencil" ></i></Link>
                                                            <i onClick={() => this.toggleModal(faqData._id)} className="icon-trash" style={{ marginLeft: '10%', cursor: "pointer" }}></i>
                                                        </td>

                                                    </tr>
                                                return <tr key={faqData._id} id={faqData._id}>
                                                    <td>{faqData.translationAr[0].ARQuestion}</td>
                                                    <td><Badge pill color={faqData.active === "no" ? "danger" : "success"} >{faqData.active === "no" ? <Trans i18nKey="no" /> : <Trans i18nKey="yes" />} </Badge></td>
                                                    <td>{dt + '-' + month + '-' + year}</td>
                                                    <td>{dtUp + '-' + monthUp + '-' + yearUp}</td>
                                                    <td>
                                                        <Link to={`/faqs/${faqData._id}`}><i className="icon-pencil" ></i></Link>
                                                        <i onClick={() => this.toggleModal(faqData._id)} className="icon-trash" style={{ marginLeft: '10%', cursor: "pointer" }}></i>
                                                    </td>

                                                </tr>
                                            }

                                            ) : <tr><td><Trans i18nKey="FAQs.noFAQ" /></td></tr>}
                                        <Modal isOpen={this.state.modal} toggle={() => this.toggleModal()} className={this.props.className}
                                            style={{
                                                position: "absolute",
                                                left: "50%",
                                                top: "50%",
                                                transform: "translate(-50%, -50%)"
                                            }}>
                                            <ModalHeader toggle={() => this.toggleModal()}><Trans i18nKey="FAQs.FAQdeltTitle" /></ModalHeader>

                                            <ModalBody>
                                                <br />
                                                {(localStorage.getItem('i18nextLng') === "en") ? "Do you really want to delete this FAQ ?" : "هل تريد حقًا حذف هذا السؤال ؟"}
                                                <br />
                                                <br />
                                            </ModalBody>
                                            <ModalFooter>
                                                {btnDelete}{' '}
                                                <Button style={{
                                                    color: "white",
                                                    backgroundColor: "#C6893F",
                                                    borderColor: "#C6893F",
                                                    marginRight: "10px"
                                                }} onClick={() => this.toggleModal()}><i className="icon-ban"></i> <Trans i18nKey="cancel" /></Button>
                                            </ModalFooter>
                                        </Modal>
                                    </tbody>
                                </Table>
                                <Pagination onChange={this.onChange} showTitle={false} current={this.state.current} total={this.props.faqsNb} />
                            </CardBody>
                        </Card>
                    </Col>
                    <ToastsContainer store={ToastsStore} />
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        faqsData: state.faqsData.faqsData,
        faqsNb: state.faqsNb.faqsNb,
        isLoading: state.ui.isLoading,
        isLoader: state.ui.isLoader,
        listFaqsErr: state.listFaqsErr.listFaqsErr,
        deleteFaqstatus: state.deleteFaqstatus.deleteFaqstatus,
        editFaqDescStatus: state.editFaqDescStatus.editFaqDescStatus,
        editfaqDesc: state.editfaqDesc.editfaqDesc,
        EditFaqDescerr: state.EditFaqDescerr.EditFaqDescerr,
        faqDescData: state.faqDescData.faqDescData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getFaqs: (data) => dispatch(getFaqs(data)),
        DeleteFaq: (data) => dispatch(DeleteFaq(data)),
        saveDescription: (data) => dispatch(saveDescription(data)),
        getDescription: () => dispatch(getDescription())
    };
};


export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Faqs);