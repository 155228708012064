import axios from "axios";

import {
  uiStartLoading,
  uiStopLoading
} from "../index";
import {
  VIDEO_CREATE
} from "../actionTypes";
import {
  CREATE_VIDEO_ERR
} from "../actionTypes";
import {
  STATUS_VIDEO_CREATE
} from "../actionTypes";
import {
  VIDEOS_DATA
} from "../actionTypes";
import {
  VIDEOS_LIST_ERR
} from "../actionTypes";

import {
  VIDEO_DATA
} from "../actionTypes";
import {
  VIDEOS_NBR
} from "../actionTypes";
import {
  VIDEO_ERR
} from "../actionTypes";

import {
  VIDEO_DELETE
} from "../actionTypes";
import {
  VIDEO_DELETE_ERR
} from "../actionTypes";
import {
  VIDEO_DELETE_STATUS
} from "../actionTypes";

import {
  VIDEO_EDIT_ERR
} from "../actionTypes";
import {
  VIDEO_EDIT
} from "../actionTypes";
import {
  VIDEO_EDIT_STATUS
} from "../actionTypes";

export const editVideoAlbum = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    const form = new FormData();
    for (let i = 0; i < data.videosList.length; i++) {
      form.append("videosList", data.videosList[i]);
    }

    form.append("videosAlbumID", data.videosAlbumID);
    form.append("title", data.title);
    form.append("ARtitle", data.ARtitle);
    form.append("active", data.active);
    form.append("cover", data.cover);


    const config = {
      headers: {
        enctype: "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token
      }
    };

    axios
      .post(`${process.env.REACT_APP_DOMAIN}/api/videosAlbum/edit`, form, config, {
        useCredentails: true
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(parsedRes => {
        dispatch(EditVideostatus(""));
        dispatch(uiStopLoading())

        if (parsedRes.data.status === "fail") {

          dispatch(EditVideoerr(parsedRes.data.error));
        } else if (parsedRes.data.status === "failure") {

          dispatch(EditVideoerr(parsedRes.data.error));
        } else {
          dispatch(EditVideoerr(""));
          dispatch(EditVideo(parsedRes.data.data));
          dispatch(EditVideostatus(parsedRes.data.status));

        }

      });
  };


};
export const EditVideoerr = value => {
  return {
    type: VIDEO_EDIT_ERR,
    EditVideoerr: value
  };
};
export const EditVideo = value => {
  return {
    type: VIDEO_EDIT,
    EditVideo: value
  };
};
export const EditVideostatus = value => {
  return {
    type: VIDEO_EDIT_STATUS,
    EditVideostatus: value
  };
};
export const createVideoAlbum = data => {
  return dispatch => {
    dispatch(uiStartLoading());
    dispatch(CreateVideoStatus(""));
    var token = localStorage.getItem("adminToken");

    const form = new FormData();
    for (let i = 0; i < data.videosList.length; i++) {
      form.append("videosList", data.videosList[i]);
    }
    form.append("title", data.title);
    form.append("active", data.active);
    form.append("ARtitle", data.ARtitle);
    form.append("cover", data.cover);


    const config = {
      headers: {
        enctype: "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token
      }
    };

    axios
      .post(`${process.env.REACT_APP_DOMAIN}/api/videosAlbum/add`, form, config, {
        useCredentails: true
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(parsedRes => {
        dispatch(CreateVideoStatus(""));
        dispatch(uiStopLoading())

        if (parsedRes.data.status === "fail") {
          dispatch(CreateVideoerr(parsedRes.data.error));
        } else {
          dispatch(CreateVideoerr(""));
          dispatch(CreateVideo(parsedRes.data.data));
          dispatch(CreateVideoStatus(parsedRes.data.status));

        }

      });
  };
};
export const getVideoAlbum = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/videosAlbum/get`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-access-token": token
        },
        body: JSON.stringify({
          videosAlbumID: data.videosAlbumID,
          pageNumber: data.pageNumber

        })

      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())

        if (parsedRes.status === "success") {
          dispatch(videoData(parsedRes.data));

        } else {
          dispatch(videoErr(parsedRes.data));
        }


      });
  };
};

export const DeleteVideo = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(deleteVideostatus(""));
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/videosAlbum/delete/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token

        },
        body: JSON.stringify({
          videosAlbumID: data.videosAlbumID,

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(deleteVideostatus(""));
        dispatch(uiStopLoading())
        if (parsedRes.status === "fail") {
          dispatch(DeleteVideoerr(parsedRes.error));
        } else {
          dispatch(DeleteVideoerr(""));
          dispatch(deleteVideo(parsedRes.data));
          dispatch(deleteVideostatus(parsedRes.status));
        }

      });
  };
};

export const getvideosAlbumList = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/videosAlbum/getAdminList`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token

        },
        body: JSON.stringify({
          pageNumber: data.pageNumber
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        if (parsedRes.status === "failure") {
          dispatch(listVideosErr(parsedRes.error));

        } else if (parsedRes.status === "fail") {
          dispatch(listVideosErr(parsedRes.error));
        } else {
          dispatch(listVideosErr(""));
          dispatch(VideosData(parsedRes.data));
          dispatch(VideosNbr(parsedRes.Length));
        }

      });
  };
};


export const CreateVideo = value => {
  return {
    type: VIDEO_CREATE,
    CreateVideo: value
  };
};
export const CreateVideoerr = value => {
  return {
    type: CREATE_VIDEO_ERR,
    CreateVideoerr: value
  };
};
export const CreateVideoStatus = value => {
  return {
    type: STATUS_VIDEO_CREATE,
    CreateVideoStatus: value
  };
};


export const listVideosErr = value => {
  return {
    type: VIDEOS_LIST_ERR,
    listVideosErr: value
  };
};
export const VideosData = value => {
  return {
    type: VIDEOS_DATA,
    VideosData: value
  };
};
export const VideosNbr = value => {
  return {
    type: VIDEOS_NBR,
    VideosNbr: value
  };
};

export const DeleteVideoerr = value => {
  return {
    type: VIDEO_DELETE_ERR,
    DeleteVideoerr: value
  };
};

export const deleteVideo = value => {
  return {
    type: VIDEO_DELETE,
    deleteVideo: value
  };
};

export const deleteVideostatus = value => {
  return {
    type: VIDEO_DELETE_STATUS,
    deleteVideostatus: value
  };
};

export const videoData = value => {
  return {
    type: VIDEO_DATA,
    videoData: value
  };
};

export const videoErr = value => {
  return {
    type: VIDEO_ERR,
    videoErr: value
  };
};