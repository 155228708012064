import firebase from "firebase";
export function initializePush() {
  
   const messaging = firebase.messaging();
   messaging.requestPermission()
      .then(function () {

         return messaging.getToken()
            .then(function (currentToken) {
             //  console.log(currentToken);
            })
            .catch(function (err) {
               console.log('An error occurred while retrieving token. ', err);

            });

      }).catch(function (err) {
         console.log('Error');
      })
   messaging.onMessage(function (data) {

      // const notificationTitle = data.notification.title;
      // const notificationOptions = {
      //    body: data.notification.body,
      //    icon: data.notification.icon,
      // };


   });
   
}

