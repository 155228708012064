import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody,Container,CardFooter, CardHeader, Col, Row, Table, Tooltip, Badge, Input, Label, Button, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import { getRegulations, DeleteRegulation, saveRule, getRule } from "../../../store/actions";
import { compose } from "redux";
import { Trans } from "react-i18next";
import addBtn from '../../../assets/img/brand/plus-button.png';
import history from "../../../history.js";
import { connect } from "react-redux";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import CircularProgress from "@material-ui/core/CircularProgress";
import notValid from '../../../assets/img/brand/notValid.png';

class Rules extends Component {

    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this);
        this.toggleArabic = this.toggleArabic.bind(this);
        this.state = {
            Rules: '',
            ARRules: '',
            current: 1,
            activeTab: new Array(2).fill('1'),
            modal: false,
            RegulationId: '',
            empty:'',

        };
    }
    toggleArabic(tabPane, tab) {
        const newArray = this.state.activeTab.slice()
        newArray[tabPane] = tab
        this.setState({
            activeTab: newArray,
        });
    }
    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }
    onChange = (page) => {
        this.setState({
            current: page
        });
        const data = { pageNumber: page };
        this.props.getRegulations(data)
    }
    componentDidMount() {
        var token = localStorage.getItem("adminToken")
        var jwtDecode = require('jwt-decode');
        var decoded = jwtDecode(token);
        var expirationDate = decoded.exp;
        var seconds = 1000;
        var d = new Date();
        var t = d.getTime();

        if (expirationDate < Math.round(t / seconds)) {
            localStorage.setItem("adminToken", "")
            history.push("/login");

        }
        else {
            const data = { pageNumber: 1 };
            this.props.getRegulations(data)
            this.props.getRule()
        }

    }
    componentDidUpdate(prevProps) {

        if (this.props.RulesData !== prevProps.RulesData) {
            this.setState({
                Rules: this.props.RulesData.Rules,
                ARRules: this.props.RulesData.translationAr[0].ARRules
            })

        }

    }
    onDelete() {
        const data = { regulationID: this.state.RegulationId };
        this.props.DeleteRegulation(data);
    };

    toggleModal(regulationID) {

        this.setState({
            modal: !this.state.modal,
            RegulationId: regulationID,
        });
    }
    componentWillReceiveProps(prevProps) {
        if (prevProps.deleteRegulationstatus !== this.props.deleteRegulationstatus) {
            if (prevProps.deleteRegulationstatus === "success") {
                this.setState({
                    modal: false
                });
                ToastsStore.info(<h6><Trans i18nKey="Rules.del" /></h6>, 3000)
                const data = { pageNumber: 1 };
                this.props.getRegulations(data)
            }
        }
        if (prevProps.editRulesStatus !== this.props.editRulesStatus) {
            if (prevProps.editRulesStatus === "success") {
                ToastsStore.info(<h6><Trans i18nKey="Rules.updRule" /></h6>, 3000)
            }

        }

    }

    onRulesChange(value) {
        this.setState({
            Rules: value
        });
    }
    onARRulesChange(value) {
        this.setState({
            ARRules: value
        });
    }
    onSubmit = () => {
        const data = {
            Rules: this.state.Rules,
            ARRules: this.state.ARRules
        }
        if (data.Rules.length === 0 || data.ARRules.length === 0 ) {
            this.setState({ empty: < Trans i18nKey="requiredFields" /> });

        } else{
          this.setState({ empty: "" });
        this.props.saveRule(data);
        }
    }
    render() {
        const { isLoading, isLoader } = this.props;

        let btnValidate = (
            <Button onClick={this.onSubmit} type="submit" size="md" className="btn-secondary" ><i className="fa fa-dot-circle-o"></i> <Trans i18nKey="Submit" /></Button>
        )
        if (isLoading) {
            btnValidate = <CircularProgress size={30} style={{ color: "#083328" }} />;
        }
        let btnDelete = (
            <Button onClick={() => this.onDelete()} type="submit" className="btn-secondary" size="md"><i className="icon-check"></i>  <Trans i18nKey="yes" /></Button>
        )
        if (isLoader) {
            btnDelete = <CircularProgress size={30} style={{ color: "#083328" }} />;
        }
        return (
            <div className="animated fadeIn">
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <Nav tabs style={{ backgroundColor: "#f0f3f5" }}>
                                    <NavItem>
                                        <NavLink
                                            active={this.state.activeTab[0] === '1'}
                                            onClick={() => { this.toggleArabic(0, '1'); }}
                                        >
                                            English
                  </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            active={this.state.activeTab[0] === '2'}
                                            onClick={() => { this.toggleArabic(0, '2'); }}
                                        >
                                            العربية
                  </NavLink>
                                    </NavItem>

                                </Nav>
                                <TabContent activeTab={this.state.activeTab[0]}>
                                    <TabPane tabId="1">
                                        <Col md="3">
                                            <Label htmlFor="text-input">* Rules </Label>
                                        </Col>
                                        <Col xs="12" md="12">
                                            <Input type="textarea" name="textarea-input" id="textarea-input" rows="12"
                                                placeholder="" value={this.state.Rules}
                                                onChange={e => this.onRulesChange(e.target.value)} />
                                        </Col>

                                    </TabPane>
                                    <TabPane style={{ direction: "rtl" }} tabId="2">
                                        <Col md="3">
                                            <Label style={{float : "right"}} htmlFor="text-input">* القواعد </Label>
                                        </Col>
                                        <Col xs="12" md="12">
                                            <Input type="textarea" name="textarea-input" id="textarea-input" rows="12"
                                                placeholder="" value={this.state.ARRules}
                                                onChange={e => this.onARRulesChange(e.target.value)} />
                                        </Col>

                                    </TabPane>
                                </TabContent>
                                <br />
                             
                            </CardBody>
                            <CardFooter>
              {btnValidate}
               
                {this.state.empty &&  
                  <Container>
                   <Row className="justify-content-center">
                      <img src={notValid}  alt="notvalid" style={
                      {
                        width: "20px",
                        marginRight: "10px",
                        height: "20px",
                        marginLeft: "10px"
                      }
                      } />
                      <p style={{color: 'red'}}>{this.state.empty}</p> 
                    </Row>
                  </Container>} 
              </CardFooter>
                        </Card>
                    </Col>
                    <br />
                    <Col xl={12}>
                        <Card>
                            <CardHeader>
                                <i className="fa fa-align-justify"></i> <Trans i18nKey="Rules.RulesList" /> <Link to={`/rule/create`}><img src={addBtn} alt="" style={{ color: "#235C4E", cursor: "pointer", marginTop: '0.5%', float: (localStorage.getItem('i18nextLng') === "en") ? 'right' : 'left' }} id={'Tooltip-' + this.props.id} /> </Link>

                                <Tooltip isOpen={this.state.tooltipOpen} target={'Tooltip-' + this.props.id} toggle={this.toggle}>
                                    <Trans i18nKey="Rules.AddRule" />
                                </Tooltip>

                            </CardHeader>
                            <CardBody>

                                <Table responsive hover>
                                    <thead>
                                        <tr>
                                            <th scope="col"> <Trans i18nKey="Title" /> </th>
                                            <th scope="col"> <Trans i18nKey="Active" /> </th>
                                            <th scope="col"><Trans i18nKey="Reservations.created_at" /></th>
                                            <th scope="col"><Trans i18nKey="Reservations.modified_at" /></th>
                                            <th scope="col"> <Trans i18nKey="Actions" /> </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.listRegulationsErr === "" ?
                                            Object.values(this.props.regulationsData).map(regulationData => {
                                                var cre = regulationData.createdAt;
                                                var upd = regulationData.updatedAt;
                                                var date = new Date(cre);
                                                var year = date.getFullYear();
                                                var month = date.getMonth() + 1;
                                                var dt = date.getDate();

                                                if (dt < 10) {
                                                    dt = '0' + dt;
                                                }
                                                if (month < 10) {
                                                    month = '0' + month;
                                                }
                                                var dateUp = new Date(upd);
                                                var yearUp = dateUp.getFullYear();
                                                var monthUp = dateUp.getMonth() + 1;
                                                var dtUp = dateUp.getDate();

                                                if (dtUp < 10) {
                                                    dtUp = '0' + dtUp;
                                                }
                                                if (monthUp < 10) {
                                                    monthUp = '0' + monthUp;
                                                }
                                                if (localStorage.getItem('i18nextLng') === "en")
                                                    return <tr key={regulationData._id} id={regulationData._id}>
                                                        <td>{regulationData.Title}</td>
                                                        <td><Badge pill color={regulationData.active === "no" ? "danger" : "success"} >{regulationData.active}</Badge></td>
                                                        <td>{dt + '-' + month + '-' + year}</td>
                                                        <td>{dtUp + '-' + monthUp + '-' + yearUp}</td>
                                                        <td>
                                                            <Link to={`/rules/${regulationData._id}`}><i className="icon-pencil" ></i></Link>
                                                            <i onClick={() => this.toggleModal(regulationData._id)} className="icon-trash" style={{ marginLeft: '10%', cursor: "pointer" }}></i>
                                                        </td>

                                                    </tr>
                                                return <tr key={regulationData._id} id={regulationData._id}>
                                                    <td>{regulationData.translationAr[0].ARTitle}</td>
                                                    <td><Badge pill color={regulationData.active === "no" ? "danger" : "success"} >{regulationData.active === "no" ? <Trans i18nKey="no" /> : <Trans i18nKey="yes" />} </Badge></td>
                                                    <td>{dt + '-' + month + '-' + year}</td>
                                                    <td>{dtUp + '-' + monthUp + '-' + yearUp}</td>
                                                    <td>
                                                        <Link to={`/rules/${regulationData._id}`}><i className="icon-pencil" ></i></Link>
                                                        <i onClick={() => this.toggleModal(regulationData._id)} className="icon-trash" style={{ marginLeft: '10%', cursor: "pointer" }}></i>
                                                    </td>

                                                </tr>
                                            }

                                            ) : <tr><td><Trans i18nKey="Rules.noRule" /></td></tr>}
                                        <Modal isOpen={this.state.modal} toggle={() => this.toggleModal()} className={this.props.className}
                                            style={{
                                                position: "absolute",
                                                left: "50%",
                                                top: "50%",
                                                transform: "translate(-50%, -50%)"
                                            }}>
                                            <ModalHeader toggle={() => this.toggleModal()}><Trans i18nKey="Rules.RuledeltTitle" /></ModalHeader>

                                            <ModalBody>
                                                <br />
                                                {(localStorage.getItem('i18nextLng') === "en") ? "Do you really want to delete this Regulation ?" : "هل تريد حقًا حذف هذا النظام  ؟"}
                                                <br />
                                                <br />
                                            </ModalBody>
                                            <ModalFooter>
                                                {btnDelete}{' '}
                                                <Button style={{
                                                    color: "white",
                                                    backgroundColor: "#C6893F",
                                                    borderColor: "#C6893F",
                                                    marginRight: "10px"
                                                }} onClick={() => this.toggleModal()}><i className="icon-ban"></i> <Trans i18nKey="cancel" /></Button>
                                            </ModalFooter>
                                        </Modal>
                                    </tbody>
                                </Table>
                                <Pagination onChange={this.onChange} showTitle={false} current={this.state.current} total={this.props.regulationsNb} />
                            </CardBody>
                        </Card>
                    </Col>
                    <ToastsContainer store={ToastsStore} />
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        regulationsData: state.regulationsData.regulationsData,
        regulationsNb: state.regulationsNb.regulationsNb,
        isLoading: state.ui.isLoading,
        isLoader: state.ui.isLoader,
        listRegulationsErr: state.listRegulationsErr.listRegulationsErr,
        deleteRegulationstatus: state.deleteRegulationstatus.deleteRegulationstatus,
        editRulesStatus: state.editRulesStatus.editRulesStatus,
        editRules: state.editRules.editRules,
        EditRuleserr: state.EditRuleserr.EditRuleserr,
        RulesData: state.RulesData.RulesData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getRegulations: (data) => dispatch(getRegulations(data)),
        DeleteRegulation: (data) => dispatch(DeleteRegulation(data)),
        saveRule: (data) => dispatch(saveRule(data)),
        getRule: () => dispatch(getRule())
    };
};


export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Rules);