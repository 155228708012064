export const UI_START_LOADING = "UI_START_LOADING";
export const UI_STOP_LOADING = "UI_STOP_LOADING";
export const UI_START_LOADER = "UI_START_LOADER";
export const UI_STOP_LOADER = "UI_STOP_LOADER"
//users
export const USERS_DATA = "USERS_DATA";
export const USER_DATA_DETAILS = "USER_DATA_DETAILS";
export const USER_DATA = "USER_DATA";
export const USER_EDIT = "USER_EDIT";
export const USERS_NBR = "USERS_NBR";
export const LIST_TOKEN_ERR = "LIST_TOKEN_ERR";
export const USERS_TOKEN_DATA = "USERS_TOKEN_DATA";
export const LIST_ERR = "LIST_ERR";
export const EDIT_ERR = "EDIT_ERR";
export const STATUS_EDIT = "STATUS_EDIT";
export const USERS_LIST_NB = "USERS_LIST_NB";

//login + signup
export const ADMIN_SIGN = "ADMIN_SIGN";
export const ADMIN_AUTH = "ADMIN_AUTH";
export const AUTH_ERR = "AUTH_ERR";
export const SIGN_ERR = "SIGN_ERR";
export const ADMINS_LIST_NB = "ADMINS_LIST_NB";
export const ADMINS_ERR = "ADMINS_ERR";
export const ADMINS_DATA = "ADMINS_DATA";
export const ADMIN_ERR = "ADMIN_ERR";
export const ADMIN_DATA = "ADMIN_DATA";
export const EDIT_ADMIN_ERR = "EDIT_ADMIN_ERR"
export const ADMIN_EDIT = "ADMIN_EDIT"
export const STATUS_ADMIN_EDIT = "STATUS_ADMIN_EDIT";
export const UNBLOCK_ADMIN_STATUS = "UNBLOCK_ADMIN_STATUS"
export const BLOCK_ADMIN_STATUS = "BLOCK_ADMIN_STATUS"
export const UNBLOCK_ADMIN_ERR = "UNBLOCK_ADMIN_ERR"
export const BLOCK_ADMIN_ERR = "BLOCK_ADMIN_ERR"

//events
export const EVENTS_DATA = "EVENTS_DATA";
export const EVENT_DATA = "EVENT_DATA";
export const EVENT_EDIT = "EVENT_EDIT";
export const EVENTS_NBR = "EVENTS_NBR";
export const LIST_EVENTS_ERR = "LIST_EVENTS_ERR";
export const EDIT_EVENT_ERR = "EDIT_EVENT_ERR";
export const STATUS_EVENT_EDIT = "STATUS_EVENT_EDIT";

export const EVENT_DELETE = "EVENT_DELETE";
export const DELETE_EVENT_ERR = "DELETE_EVENT_ERR";
export const STATUS_EVENT_DELETE = "STATUS_EVENT_DELETE";

export const EVENT_CREATE = "EVENT_CREATE";
export const CREATE_EVENT_ERR = "CREATE_EVENT_ERR";
export const STATUS_EVENT_CREATE = "STATUS_EVENT_CREATE";
export const LIST_EVENTS_ACT_DATA = "LIST_EVENTS_ACT_DATA";
export const LIST_EVENTS_ACT_ERR = "LIST_EVENTS_ACT_ERR";

//MERCHANT
export const MERCHANTS_DATA = "MERCHANTS_DATA";
export const MERCHANT_DATA = "MERCHANT_DATA";
export const MERCHANT_EDIT = "MERCHANT_EDIT";
export const MERCHANTS_NBR = "MERCHANTS_NBR";
export const LIST_MERCHANTS_ERR = "LIST_MERCHANTS_ERR";
export const EDIT_MERCHANT_ERR = "EDIT_MERCHANT_ERR";
export const STATUS_MERCHANT_EDIT = "STATUS_MERCHANT_EDIT";

export const MERCHANT_DELETE = "MERCHANT_DELETE";
export const DELETE_MERCHANT_ERR = "DELETE_MERCHANT_ERR";
export const STATUS_MERCHANT_DELETE = "STATUS_MERCHANT_DELETE";

export const MERCHANT_CREATE = "MERCHANT_CREATE";
export const CREATE_MERCHANT_ERR = "CREATE_MERCHANT_ERR";
export const STATUS_MERCHANT_CREATE = "STATUS_MERCHANT_CREATE";
export const LIST_MERCHANTS_ACT_DATA = "LIST_MERCHANTS_ACT_DATA";
export const LIST_MERCHANTS_ACT_ERR = "LIST_MERCHANTS_ACT_ERR";

//BEACONS
export const BEACONS_DATA = "BEACONS_DATA";
export const BEACON_DATA = "BEACON_DATA";
export const BEACON_EDIT = "BEACON_EDIT";
export const BEACONS_NB = "BEACONS_NB";
export const BEACONS_LIST = "BEACONS_LIST";
export const EDIT_BEACON_EXIST = "EDIT_BEACON_EXIST";
export const CREATE_BEACON_EXIST = "CREATE_BEACON_EXIST";

export const LIST_BEACONS_ERR = "LIST_BEACONS_ERR";
export const EDIT_BEACON_ERR = "EDIT_BEACON_ERR";
export const STATUS_BEACON_EDIT = "STATUS_BEACON_EDIT";

export const BEACON_DELETE = "BEACON_DELETE";
export const DELETE_BEACON_ERR = "DELETE_BEACON_ERR";
export const STATUS_BEACON_DELETE = "STATUS_BEACON_DELETE";

export const BEACON_CREATE = "BEACON_CREATE";
export const CREATE_BEACON_ERR = "CREATE_BEACON_ERR";
export const STATUS_BEACON_CREATE = "STATUS_BEACON_CREATE";
export const FIND_BEACON_ERR = "FIND_BEACON_ERR"
export const FIND_BEACON = "FIND_BEACON"
//PLACES
export const PLACES_DATA = "PLACES_DATA";
export const PLACE_DATA = "PLACE_DATA";
export const PLACE_EDIT = "PLACE_EDIT";
export const PLACES_NBR = "PLACES_NBR";
export const LIST_PLACES_ERR = "LIST_PLACES_ERR";
export const LIST_PLACES_ERRAr = "LIST_PLACES_ERRAr";
export const EDIT_PLACE_ERR = "EDIT_PLACE_ERR";
export const STATUS_PLACE_EDIT = "STATUS_PLACE_EDIT";
export const parking_DATA = "parking_DATA";
export const PLACE_DELETE = "PLACE_DELETE";
export const DELETE_PLACE_ERR = "DELETE_PLACE_ERR";
export const STATUS_PLACE_DELETE = "STATUS_PLACE_DELETE";
export const EDIT_TIMING_SUCCESS = "EDIT_TIMING_SUCCESS";


export const PLACE_CREATE = "PLACE_CREATE";
export const CREATE_PLACE_ERR = "CREATE_PLACE_ERR";
export const STATUS_PLACE_CREATE = "STATUS_PLACE_CREATE";

export const ACT_PLACES_DATA = "ACT_PLACES_DATA";
export const LIST_ACT_PLACES_ERR = "LIST_ACT_PLACES_ERR";
export const ACT_PLACES_FAC_DATA = "ACT_PLACES_FAC_DATA";
export const LIST_ACT_PLACES_FAC_ERR = "LIST_ACT_PLACES_FAC_ERR";

//CATEGORIES
export const CATEGORIES_DATA = "CATEGORIES_DATA";
export const CATEGORY_DATA = "CATEGORY_DATA";
export const CATEGORY_EDIT = "CATEGORY_EDIT";
export const CATEGORY_NB = "CATEGORY_NB";
export const LIST_CATEGORIES_ERR = "LIST_CATEGORIES_ERR";
export const EDIT_CATEGORY_ERR = "EDIT_CATEGORY_ERR";
export const STATUS_CATEGORY_EDIT = "STATUS_CATEGORY_EDIT";

export const CATEGORY_DELETE = "CATEGORY_DELETE";
export const DELETE_CATEGORY_ERR = "DELETE_CATEGORY_ERR";
export const STATUS_CATEGORY_DELETE = "STATUS_CATEGORY_DELETE";

export const CATEGORY_CREATE = "CATEGORY_CREATE";
export const CREATE_CATEGORY_ERR = "CREATE_CATEGORY_ERR";
export const STATUS_CATEGORY_CREATE = "STATUS_CATEGORY_CREATE";
export const LIST_ACT_CATEGORIES_ERR = "LIST_ACT_CATEGORIES_ERR";
export const ACT_CATEGORIES_DATA = "ACT_CATEGORIES_DATA";

//FACILITIES
export const FACILITIES_DATA = "FACILITIES_DATA";
export const FACILITY_DATA = "FACILITY_DATA";
export const FACILITY_EDIT = "FACILITY_EDIT";
export const FACILITY_NB = "FACILITY_NB";
export const LIST_FACILITIES_ERR = "LIST_FACILITIES_ERR";
export const EDIT_FACILITY_ERR = "EDIT_FACILITY_ERR";
export const STATUS_FACILITY_EDIT = "STATUS_FACILITY_EDIT";

export const FACILITY_DELETE = "FACILITY_DELETE";
export const DELETE_FACILITY_ERR = "DELETE_FACILITY_ERR";
export const STATUS_FACILITY_DELETE = "STATUS_FACILITY_DELETE";

export const FACILITY_CREATE = "FACILITY_CREATE";
export const CREATE_FACILITY_ERR = "CREATE_FACILITY_ERR";
export const STATUS_FACILITY_CREATE = "STATUS_FACILITY_CREATE";
export const LIST_ACT_Facilities_ERR = "LIST_ACT_Facilities_ERR";
export const ACT_Facilities_DATA = "ACT_Facilities_DATA";

//BANNERS
export const BANNERS_DATA = "BANNERS_DATA";
export const BANNER_DATA = "BANNER_DATA";
export const BANNER_EDIT = "BANNER_EDIT";
export const BANNERS_NB = "BANNERS_NB";
export const LIST_BANNERS_ERR = "LIST_BANNERS_ERR";
export const EDIT_BANNER_ERR = "EDIT_BANNER_ERR";
export const STATUS_BANNER_EDIT = "STATUS_BANNER_EDIT";

export const BANNER_EDIT_AVATAR = "BANNER_EDIT_AVATAR";
export const EDIT_BANNER_AVATAR_ERR = "EDIT_BANNER_AVATAR_ERR";
export const STATUS_BANNER_AVATAR_EDIT = "STATUS_BANNER_AVATAR_EDIT";

export const BANNER_DELETE = "BANNER_DELETE";
export const DELETE_BANNER_ERR = "DELETE_BANNER_ERR";
export const STATUS_BANNER_DELETE = "STATUS_BANNER_DELETE";

export const BANNER_CREATE = "BANNER_CREATE";
export const CREATE_BANNER_ERR = "CREATE_BANNER_ERR";
export const STATUS_BANNER_CREATE = "STATUS_BANNER_CREATE";

//images
export const PHOTO_CREATE = "PHOTO_CREATE";
export const CREATE_PHOTO_ERR = "CREATE_PHOTO_ERR";
export const STATUS_PHOTO_CREATE = "STATUS_PHOTO_CREATE";
export const PHOTOS_DATA = "PHOTOS_DATA";
export const PHOTOS_NBR = "PHOTOS_NBR";
export const PHOTOS_LIST_ERR = "PHOTOS_LIST_ERR";
export const PHOTO_DELETE = "PHOTO_DELETE";
export const PHOTO_DELETE_ERR = "PHOTO_DELETE_ERR";
export const PHOTO_DELETE_STATUS = "PHOTO_DELETE_STATUS";
export const PHOTO_DATA = "PHOTO_DATA";
export const PHOTO_ERR = "PHOTO_ERR";
export const PHOTO_EDIT_ERR = "PHOTO_EDIT_ERR";
export const PHOTO_EDIT = "PHOTO_EDIT";
export const PHOTO_EDIT_STATUS = "PHOTO_EDIT_STATUS";

//videos
export const VIDEO_CREATE = "VIDEO_CREATE";
export const CREATE_VIDEO_ERR = "CREATE_VIDEO_ERR";
export const STATUS_VIDEO_CREATE = "STATUS_VIDEO_CREATE";
export const VIDEOS_DATA = "VIDEOS_DATA";
export const VIDEOS_LIST_ERR = "VIDEOS_LIST_ERR";
export const VIDEOS_NBR = "VIDEOS_NBR";
export const VIDEO_DELETE = "VIDEO_DELETE";
export const VIDEO_DELETE_ERR = "VIDEO_DELETE_ERR";
export const VIDEO_DELETE_STATUS = "VIDEO_DELETE_STATUS";
export const VIDEO_DATA = "VIDEO_DATA";
export const VIDEO_ERR = "VIDEO_ERR";
export const VIDEO_EDIT_ERR = "PHOTO_EDIT_ERR";
export const VIDEO_EDIT = "VIDEO_EDIT";
export const VIDEO_EDIT_STATUS = "VIDEO_EDIT_STATUS";


//notifications
export const NOTIFICATIONS_DATA = "NOTIFICATIONS_DATA";
export const NOTIFICATION_DATA = "NOTIFICATION_DATA";
export const NOTIFICATION_EDIT = "NOTIFICATION_EDIT";
export const NOTIFICATIONS_NB = "NOTIFICATIONS_NB";
export const LIST_NOTIFICATIONS_ERR = "LIST_NOTIFICATIONS_ERR";
export const EDIT_NOTIFICATION_ERR = "EDIT_NOTIFICATION_ERR";
export const STATUS_NOTIFICATION_EDIT = "STATUS_NOTIFICATION_EDIT";

export const NOTIFICATION_DELETE = "NOTIFICATION_DELETE";
export const DELETE_NOTIFICATION_ERR = "DELETE_NOTIFICATION_ERR";
export const STATUS_NOTIFICATION_DELETE = "STATUS_NOTIFICATION_DELETE";

export const NOTIFICATION_SEND = "NOTIFICATION_SEND";
export const SEND_NOTIFICATION_ERR = "SEND_NOTIFICATION_ERR";
export const STATUS_NOTIFICATION_SEND = "STATUS_NOTIFICATION_SEND";

export const NOTIFICATION_CREATE = "NOTIFICATION_CREATE";
export const CREATE_NOTIFICATION_ERR = "CREATE_NOTIFICATION_ERR";
export const STATUS_NOTIFICATION_CREATE = "STATUS_NOTIFICATION_CREATE";

//Reservations
export const EV_RESERV_ERR = "EV_RESERV_ERR";
export const EV_RESERV = "EV_RESERV";
export const PL_RESERV_ERR = "PL_RESERV_ERR";
export const EventR_NB = "EventR_NB";
export const EvRes_Nbr = "EvRes_Nbr";
export const PL_RESERV = "PL_RESERV";
export const BY_EV_RESERV_ERR = "BY_EV_RESERV_ERR";
export const BY_EV_RESERV = "BY_EV_RESERV";
export const BY_PL_RESERV_ERR = "BY_PL_RESERV_ERR";
export const BY_PL_RESERV = "BY_PL_RESERV";
export const GET_EV_RESERV_ERR = "GET_EV_RESERV_ERR";
export const GET_EV_RESERV = "GET_EV_RESERV";
export const GET_PL_RESERV_ERR = "GET_PL_RESERV_ERR";
export const GET_PL_RESERV = "GET_PL_RESERV";
export const PLRes_Nbr = "PLRes_Nbr";
export const PlaceR_NB = "PlaceR_NB";
export const Clone_PLACE_DATA = "Clone_PLACE_DATA"
export const Clone_PLACE_ERR = "Clone_PLACE_ERR"
export const FIND_PLACE_ERR = "FIND_PLACE_ERR"
export const FIND_PLACE = "FIND_PLACE"
//News
export const NEWS_CREATE = "NEWS_CREATE ";
export const CREATE_NEWS_ERR = "CREATE_NEWS_ERR ";
export const STATUS_NEWS_CREATE = "STATUS_NEWS_CREATE ";
export const LIST_NEWS_ACT_DATA = "LIST_NEWS_ACT_DATA";
export const LIST_NEWS_ACT_ERR = "LIST_NEWS_ACT_ERR";
export const NEWSL_DATA = "NEWSL_DATA ";
export const NEWSL_LIST_ERR = "NEWSL_LIST_ERR ";
export const NEWSL_NBR = "NEWSL_NBR ";

export const NEWS_DATA = " NEWS_DATA";
export const NEWS_ERR = " NEWS_ERR";

export const NEWS_DELETE = " NEWS_DELETE";
export const NEWS_DELETE_ERR = "NEWS_DELETE_ERR ";
export const NEWS_DELETE_STATUS = "NEWS_DELETE_STATUS ";

export const NEWS_EDIT_ERR = " NEWS_EDIT_ERR";
export const NEWS_EDIT = "NEWS_EDIT ";
export const NEWS_EDIT_STATUS = " NEWS_EDIT_STATUS";

//Newscast
export const NEWSCAST_GET_DATA = "NEWSCAST_GET_DATA";
export const NEWSCAST_GET_ERR = "NEWSCAST_GET_ERR";
export const NEWSCAST_ADD_DATA = "NEWSCAST_ADD_DATA";
export const NEWSCAST_ADD_ERR = "NEWSCAST_ADD_ERR";
export const NEWSCAST_ADD_STATUS = "NEWSCAST_ADD_STATUS";

//PARKINGS
export const PARKINGS_DATA = "PARKINGS_DATA";
export const PARKING_DATA = "PARKING_DATA";
export const Bea_DATA = "Bea_DATA"
export const PARKING_EDIT = "PARKING_EDIT";
export const PARKINGS_NBR = "PARKINGS_NBR";
export const LIST_PARKINGS_ERR = "LIST_PARKINGS_ERR";
export const LIST_PARKINGS_ERRAr = "LIST_PARKINGS_ERRAr";
export const EDIT_PARKING_ERR = "EDIT_PARKING_ERR";
export const STATUS_PARKING_EDIT = "STATUS_PARKING_EDIT";

export const PARKING_DELETE = "PARKING_DELETE";
export const DELETE_PARKING_ERR = "DELETE_PARKING_ERR";
export const STATUS_PARKING_DELETE = "STATUS_PARKING_DELETE";

export const PARKING_CREATE = "PARKING_CREATE";
export const CREATE_PARKING_ERR = "CREATE_PARKING_ERR";
export const STATUS_PARKING_CREATE = "STATUS_PARKING_CREATE";

export const ACT_PARKINGS_DATA = "ACT_PARKINGS_DATA";
export const LIST_ACT_PARKINGS_ERR = "LIST_ACT_PARKINGS_ERR";
export const ACT_PARKINGS_DET_ERR = "ACT_PARKINGS_DET_ERR";
export const ACT_PARKINGS_DET_DATA = "ACT_PARKINGS_DET_DATA";

//FAQ
export const FAQ_CREATE = "FAQ_CREATE";
export const CREATE_FAQ_ERR = "CREATE_FAQ_ERR";
export const STATUS_FAQ_CREATE = "STATUS_FAQ_CREATE";

export const FAQS_DATA = "FAQS_DATA";
export const FAQS_NB = "FAQS_NB";
export const LIST_FAQS_ERR = "LIST_FAQS_ERR"

export const FAQ_DELETE = "FAQ_DELETE";
export const DELETE_FAQ_ERR = "DELETE_FAQ_ERR";
export const STATUS_FAQ_DELETE = "STATUS_FAQ_DELETE";
export const FAQ_EDIT = "FAQ_EDIT";
export const EDIT_FAQ_ERR = "EDIT_FAQ_ERR";
export const STATUS_FAQ_EDIT = "STATUS_FAQ_EDIT";
export const FAQ_DATA = "FAQ_DATA";

export const FAQDesc_EDIT = "FAQDesc_EDIT"
export const EDIT_FAQDesc_ERR = "EDIT_FAQDesc_ERR"
export const STATUS_FAQDesc_EDIT = "STATUS_FAQDesc_EDIT"
export const FAQDesc_Data = "FAQDesc_Data"

//contact
export const CONTACT_DATA = "CONTACT_DATA"
export const CONTACT_EDIT = "CONTACT_EDIT"
export const EDIT_CONTACT_ERR = "EDIT_CONTACT_ERR"
export const STATUS_CONTACT_EDIT = "STATUS_CONTACT_EDIT"

//Subject
export const SUBJECT_CREATE = "SUBJECT_CREATE";
export const CREATE_SUBJECT_ERR = "CREATE_SUBJECT_ERR";
export const STATUS_SUBJECT_CREATE = "STATUS_SUBJECT_CREATE";

export const SUBJECTS_DATA = "SUBJECTS_DATA";
export const SUBJECTS_NB = "SUBJECTS_NB";
export const LIST_SUBJECTS_ERR = "LIST_SUBJECTS_ERR"

export const SUBJECT_DELETE = "SUBJECT_DELETE";
export const DELETE_SUBJECT_ERR = "DELETE_SUBJECT_ERR";
export const STATUS_SUBJECT_DELETE = "STATUS_SUBJECT_DELETE";
export const SUBJECT_EDIT = "SUBJECT_EDIT";
export const EDIT_SUBJECT_ERR = "EDIT_SUBJECT_ERR";
export const STATUS_SUBJECT_EDIT = "STATUS_SUBJECT_EDIT";
export const SUBJECT_DATA = "SUBJECT_DATA";

//Timing

export const TIMING_CREATE = "TIMING_CREATE";
export const CREATE_TIMING_ERR = "CREATE_TIMING_ERR";
export const STATUS_TIMING_CREATE = "STATUS_TIMING_CREATE";

export const TIMINGS_DATA = "TIMINGS_DATA";
export const TIMINGS_NB = "TIMINGS_NB";
export const LIST_TIMINGS_ERR = "LIST_TIMINGS_ERR"

export const TIMING_DELETE = "TIMING_DELETE";
export const DELETE_TIMING_ERR = "DELETE_TIMING_ERR";
export const STATUS_TIMING_DELETE = "STATUS_TIMING_DELETE";
export const TIMING_EDIT = "TIMING_EDIT";
export const EDIT_TIMING_ERR = "EDIT_TIMING_ERR";
export const STATUS_TIMING_EDIT = "STATUS_TIMING_EDIT";
export const TIMING_DATA = "TIMING_DATA";

export const ABOUT_EDIT = "ABOUT_EDIT"
export const EDIT_ABOUT_ERR = "EDIT_ABOUT_ERR"
export const STATUS_ABOUT_EDIT = "STATUS_ABOUT_EDIT"
export const ABOUT_Data = "ABOUT_Data"

//REGULATION
export const REGULATION_CREATE = "REGULATION_CREATE";
export const CREATE_REGULATION_ERR = "CREATE_REGULATION_ERR";
export const STATUS_REGULATION_CREATE = "STATUS_REGULATION_CREATE";

export const REGULATIONS_DATA = "REGULATIONS_DATA";
export const REGULATIONS_NB = "REGULATIONS_NB";
export const LIST_REGULATIONS_ERR = "LIST_REGULATIONS_ERR"

export const REGULATION_DELETE = "REGULATION_DELETE";
export const DELETE_REGULATION_ERR = "DELETE_REGULATION_ERR";
export const STATUS_REGULATION_DELETE = "STATUS_REGULATION_DELETE";
export const REGULATION_EDIT = "REGULATION_EDIT";
export const EDIT_REGULATION_ERR = "EDIT_REGULATION_ERR";
export const STATUS_REGULATION_EDIT = "STATUS_REGULATION_EDIT";
export const REGULATION_DATA = "REGULATION_DATA";

export const Rules_EDIT = "Rules_EDIT"
export const EDIT_Rules_ERR = "EDIT_Rules_ERR"
export const STATUS_Rules_EDIT = "STATUS_Rules_EDIT"
export const Rules_Data = "Rules_Data"
// category
export const LIST_THINGS_ERR = "LIST_THINGS_ERR"
export const THINGS_DATA = "THINGS_DATA"
