import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Badge, Col, Row, Table, Tooltip, Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { getBanners, DeleteBanner } from "../../../store/actions";
import { compose } from "redux";
import addBtn from '../../../assets/img/brand/plus-button.png';
import history from "../../../history.js";
import { connect } from "react-redux";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Trans } from "react-i18next";
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import CircularProgress from "@material-ui/core/CircularProgress";

class Banners extends Component {

  constructor(props) {
    super(props)
    this.state = {
      tooltipOpen: false,
      current: 1,
      modal: false,
      bannerId: ''
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }
  onChange = (page) => {
    this.setState({
      current: page
    });
    const data = { pageNumber: page };
    this.props.getBanners(data)
  }

  componentDidMount() {
    var token = localStorage.getItem("adminToken")
    var jwtDecode = require('jwt-decode');
    var decoded = jwtDecode(token);
    var expirationDate = decoded.exp;
    var seconds = 1000;
    var d = new Date();
    var t = d.getTime();

    if (expirationDate < Math.round(t / seconds)) {
      localStorage.setItem("adminToken", "")
      history.push("/login");

    }
    else {
      const data = { pageNumber: 1 };
      this.props.getBanners(data)
    }

  }
  onDelete() {
    const data = { bannerID: this.state.bannerId };
    this.props.DeleteBanner(data);
  };
  toggleModal(bannerId) {
    this.setState({
      modal: !this.state.modal,
      bannerId: bannerId,
    });
  }

  componentWillReceiveProps(prevProps) {
    if (prevProps.deleteBannerStatus !== this.props.deleteBannerStatus) {
      if (prevProps.deleteBannerStatus === "success") {
        this.setState({
          modal: false
        });
        ToastsStore.info(<h6><Trans i18nKey="Banners.del" /></h6>, 3000)
        const data = { pageNumber: 1 };
        this.props.getBanners(data)
      }

    }

  }
  render() {
    const { isLoading } = this.props;

    let btnValidate = (
      <Button onClick={() => this.onDelete()} type="submit" className="btn-secondary" size="md"><i className="icon-check"></i>  <Trans i18nKey="yes" /></Button>
    )
    if (isLoading) {
      btnValidate = <CircularProgress size={30} style={{ color: "#083328" }} />;
    }
    return (
      <div className="animated fadeIn">
        <Row>

          <Col xl={12}>

            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i><Trans i18nKey="Banners.BannersList" /> <Link to={`/create/splash`}><img src={addBtn} alt="" style={{ color: "#235C4E", cursor: "pointer", marginTop: '0.5%', float: (localStorage.getItem('i18nextLng') === "en") ? 'right' : 'left' }} id={'Tooltip-' + this.props.id} /> </Link>

                <Tooltip isOpen={this.state.tooltipOpen} target={'Tooltip-' + this.props.id} toggle={this.toggle}>
                  <Trans i18nKey="Banners.AddBanner" />
                </Tooltip>

              </CardHeader>
              <CardBody>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col"><Trans i18nKey="Title" /></th>
                      <th scope="col"><Trans i18nKey="Active" /></th>
                      <th scope="col"><Trans i18nKey="Reservations.created_at" /></th>
                      <th scope="col"><Trans i18nKey="Reservations.modified_at" /></th>
                      <th scope="col"><Trans i18nKey="Actions" /></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.listBannersErr === "" ?
                      Object.values(this.props.bannersData).map(bannerData => {
                        var cre = bannerData.createdAt;
                        var upd = bannerData.updatedAt;

                        var date = new Date(cre);
                        var year = date.getFullYear();
                        var month = date.getMonth() + 1;
                        var dt = date.getDate();

                        if (dt < 10) {
                          dt = '0' + dt;
                        }
                        if (month < 10) {
                          month = '0' + month;
                        }
                        var dateUp = new Date(upd);
                        var yearUp = dateUp.getFullYear();
                        var monthUp = dateUp.getMonth() + 1;
                        var dtUp = dateUp.getDate();

                        if (dtUp < 10) {
                          dtUp = '0' + dtUp;
                        }
                        if (monthUp < 10) {
                          monthUp = '0' + monthUp;
                        }
                        if (localStorage.getItem('i18nextLng') === "en")
                          return <tr key={bannerData._id} id={bannerData._id}>
                            <td>{bannerData.title}</td>
                            <td><Badge pill color={bannerData.active === "no" ? "danger" : "success"} >{bannerData.active}</Badge></td>
                            <td>{dt + '-' + month + '-' + year}</td>
                            <td>{dtUp + '-' + monthUp + '-' + yearUp}</td>
                            <td>
                              <Link to={`/splash/${bannerData._id}`}><i className="icon-pencil" ></i></Link>
                              <i onClick={() => this.toggleModal(bannerData._id)} className="icon-trash" style={{ marginLeft: '10%', cursor: "pointer" }}></i>
                            </td>

                          </tr>
                        return <tr key={bannerData._id} id={bannerData._id}>
                          <td>{bannerData.translationAr[0].ARtitle}</td>
                          <td><Badge pill color={bannerData.active === "no" ? "danger" : "success"} >{bannerData.active === "no" ? <Trans i18nKey="no" /> : <Trans i18nKey="yes" />} </Badge></td>
                          <td>{dt + '-' + month + '-' + year}</td>
                          <td>{dtUp + '-' + monthUp + '-' + yearUp}</td>
                          <td>
                            <Link to={`/splash/${bannerData._id}`}><i className="icon-pencil" ></i></Link>
                            <i onClick={() => this.toggleModal(bannerData._id)} className="icon-trash" style={{ marginLeft: '10%', cursor: "pointer" }}></i>
                          </td>

                        </tr>
                      }

                      ) : <tr><td><Trans i18nKey="Banners.noBanner" /></td></tr>}

                    <Modal isOpen={this.state.modal} toggle={() => this.toggleModal()} className={this.props.className}
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)"
                      }}>
                      <ModalHeader toggle={() => this.toggleModal()}><Trans i18nKey="Banners.bannerdeltTitle" /></ModalHeader>

                      <ModalBody>
                        <br />
                        {(localStorage.getItem('i18nextLng') === "en") ? "Do you really want to delete this banner ?" : "هل تريد حقًا حذف هذه اللافتة ؟"}
                        <br />
                        <br />
                      </ModalBody>
                      <ModalFooter>
                        {btnValidate}{' '}
                        <Button style={{
                          color: "white",
                          backgroundColor: "#C6893F",
                          borderColor: "#C6893F",
                          marginRight: "10px"
                        }} onClick={() => this.toggleModal()}><i className="icon-ban"></i> <Trans i18nKey="cancel" /></Button>
                      </ModalFooter>
                    </Modal>

                  </tbody>
                </Table>
                <Pagination onChange={this.onChange} showTitle={false} current={this.state.current} total={this.props.bannersNbr} />
              </CardBody>
            </Card>
          </Col>
          <ToastsContainer store={ToastsStore} />
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    bannersData: state.bannersData.bannersData,
    bannersNbr: state.bannersNbr.bannersNbr,
    isLoading: state.ui.isLoading,
    listBannersErr: state.listBannersErr.listBannersErr,
    deleteBannerStatus: state.deleteBannerStatus.deleteBannerStatus

  };
};

const mapDispatchToProps = dispatch => {
  return {
    getBanners: (data) => dispatch(getBanners(data)),
    DeleteBanner: (data) => dispatch(DeleteBanner(data))
  };
};


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Banners);