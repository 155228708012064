import {
  uiStartLoading,
  uiStopLoading
} from "../index";
import {
  FAQS_DATA,
  FAQS_NB,
  LIST_FAQS_ERR,
  FAQ_DATA
} from "../actionTypes";
import {
  FAQ_DELETE,
  DELETE_FAQ_ERR,
  STATUS_FAQ_DELETE
} from "../actionTypes";
import {
  FAQ_CREATE
} from "../actionTypes";
import {
  CREATE_FAQ_ERR
} from "../actionTypes";
import {
  STATUS_FAQ_CREATE
} from "../actionTypes";
import {
  FAQ_EDIT,
  EDIT_FAQ_ERR,
  STATUS_FAQ_EDIT
} from "../actionTypes";
import {
  FAQDesc_EDIT,
  EDIT_FAQDesc_ERR,
  STATUS_FAQDesc_EDIT,
  FAQDesc_Data
} from "../actionTypes";


export const createFAQ = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(CreateFaqstatus(""));
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/FAQ/add`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          Response: data.Response,
          ARResponse: data.ARResponse,
          Question: data.Question,
          ARQuestion: data.ARQuestion,
          active: data.active
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(CreateFaqstatus(""));
        dispatch(uiStopLoading())

        if (parsedRes.status === "fail") {
          dispatch(CreateFaqerr(parsedRes.error));
        } else {
          dispatch(CreateFaqerr(""));
          dispatch(CreateFaq(parsedRes.data));
          dispatch(CreateFaqstatus(parsedRes.status));
        }
      });
  };
};

export const getFaqs = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/FAQ/getList`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          pageNumber: data.pageNumber

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        if (parsedRes.status === "failure") {
          dispatch(listFaqsErr(parsedRes.error));

        } else {
          dispatch(listFaqsErr(""));
          dispatch(faqsData(parsedRes.data));
          dispatch(faqsNb(parsedRes.Length));
        }

      });
  };
};
export const editFaq = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    fetch(`${process.env.REACT_APP_DOMAIN}/api/FAQ/edit`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          faqID: data.faqID,
          Question: data.Question,
          ARQuestion: data.ARQuestion,
          Response: data.Response,
          ARResponse: data.ARResponse,
          active: data.active
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(editFaqStatus(""));
        dispatch(uiStopLoading())

        if (parsedRes.status === "fail") {

          dispatch(EditFaqerr(parsedRes.error));
        } else {
          dispatch(EditFaqerr(""));
          dispatch(editfaq(parsedRes.data));
          dispatch(editFaqStatus(parsedRes.status));
        }

      });
  };


};
export const saveDescription = data => {

  return dispatch => {
    var token = localStorage.getItem("adminToken");

    fetch(`${process.env.REACT_APP_DOMAIN}/api/FAQ/AddFAQ_Desc`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          FAQdescription: data.FAQdescription,
          ARFAQdescription: data.ARFAQdescription
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(editFaqDescStatus(""));
        dispatch(uiStopLoading())

        if (parsedRes.status === "fail") {

          dispatch(EditFaqDescerr(parsedRes.error));
        } else {
          dispatch(editFaqDescStatus(""));
          dispatch(editfaqDesc(parsedRes.data));
          dispatch(editFaqDescStatus(parsedRes.status));
        }

      });
  };
};
export const getDescription = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/FAQ/getFAQ_Desc`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },

      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        dispatch(faqDescData(parsedRes.data));

      });
  };
};
export const faqDescData = value => {
  return {
    type: FAQDesc_Data,
    faqDescData: value
  };
};
export const EditFaqDescerr = value => {
  return {
    type: EDIT_FAQDesc_ERR,
    EditFaqDescerr: value
  };
};

export const editFaqDescStatus = value => {
  return {
    type: STATUS_FAQDesc_EDIT,
    editFaqDescStatus: value
  };
};
export const editfaqDesc = value => {
  return {
    type: FAQDesc_EDIT,
    editfaqDesc: value
  };
};
//
export const EditFaqerr = value => {
  return {
    type: EDIT_FAQ_ERR,
    EditFaqerr: value
  };
};

export const editFaqStatus = value => {
  return {
    type: STATUS_FAQ_EDIT,
    editFaqStatus: value
  };
};
export const editfaq = value => {
  return {
    type: FAQ_EDIT,
    editfaq: value
  };
};
export const getFaq = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/FAQ/get`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          faqID: data.faqID
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        dispatch(faqData(parsedRes.data));

      });
  };
};

export const faqData = value => {
  return {
    type: FAQ_DATA,
    faqData: value
  };
};

export const DeleteFaq = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(deleteFaqstatus(""));
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/FAQ/delete`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          faqID: data.faqID,

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(deleteFaqstatus(""));
        dispatch(uiStopLoading())
        if (parsedRes.status === "fail") {
          dispatch(DeleteFaqerr(parsedRes.error));
        } else {
          dispatch(DeleteFaqerr(""));
          dispatch(deleteFaq(parsedRes.data));
          dispatch(deleteFaqstatus(parsedRes.status));
        }

      });
  };
};
export const deleteFaq = value => {
  return {
    type: FAQ_DELETE,
    deleteFaq: value
  };
};
export const DeleteFaqerr = value => {
  return {
    type: DELETE_FAQ_ERR,
    DeleteFaqerr: value
  };
};
export const deleteFaqstatus = value => {
  return {
    type: STATUS_FAQ_DELETE,
    deleteFaqstatus: value
  };
};


export const faqsNb = value => {
  return {
    type: FAQS_NB,
    faqsNb: value
  }
}

export const faqsData = value => {
  return {
    type: FAQS_DATA,
    faqsData: value
  };
};
export const listFaqsErr = value => {
  return {
    type: LIST_FAQS_ERR,
    listFaqsErr: value
  };
};
export const CreateFaq = value => {
  return {
    type: FAQ_CREATE,
    CreateFaq: value
  };
};
export const CreateFaqerr = value => {
  return {
    type: CREATE_FAQ_ERR,
    CreateFaqerr: value
  };
};
export const CreateFaqstatus = value => {
  return {
    type: STATUS_FAQ_CREATE,
    CreateFaqstatus: value
  };
};