import {
  uiStartLoading,
  uiStopLoading
} from "../index";
import {
  SUBJECTS_DATA,
  SUBJECTS_NB,
  LIST_SUBJECTS_ERR,
  SUBJECT_DATA
} from "../actionTypes";
import {
  SUBJECT_DELETE,
  DELETE_SUBJECT_ERR,
  STATUS_SUBJECT_DELETE
} from "../actionTypes";
import {
  SUBJECT_CREATE
} from "../actionTypes";
import {
  CREATE_SUBJECT_ERR
} from "../actionTypes";
import {
  STATUS_SUBJECT_CREATE
} from "../actionTypes";
import {
  SUBJECT_EDIT,
  EDIT_SUBJECT_ERR,
  STATUS_SUBJECT_EDIT
} from "../actionTypes";

export const createSUBJECT = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(CreateSubjectstatus(""));
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/Subject/add`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          Subject: data.Subject,
          ARSubject: data.ARSubject,
          active: data.active
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(CreateSubjectstatus(""));
        dispatch(uiStopLoading())

        if (parsedRes.status === "fail") {
          dispatch(CreateSubjecterr(parsedRes.error));
        } else {
          dispatch(CreateSubjecterr(""));
          dispatch(CreateSubject(parsedRes.data));
          dispatch(CreateSubjectstatus(parsedRes.status));

        }

      });
  };
};

export const getSubjects = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/Subject/getList`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          pageNumber: data.pageNumber

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        if (parsedRes.status === "failure") {
          dispatch(listSubjectsErr(parsedRes.error));

        } else {
          dispatch(listSubjectsErr(""));
          dispatch(subjectsData(parsedRes.data));
          dispatch(subjectsNb(parsedRes.Length));

        }

      });
  };
};
export const editSubject = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/Subject/edit`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          SubjectID: data.SubjectID,
          Subject: data.Subject,
          ARSubject: data.ARSubject,
          active: data.active
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(editSubjectStatus(""));
        dispatch(uiStopLoading())

        if (parsedRes.status === "fail") {
          dispatch(EditSubjecterr(parsedRes.error));
        } else {
          dispatch(EditSubjecterr(""));
          dispatch(editsubject(parsedRes.data));
          dispatch(editSubjectStatus(parsedRes.status));
        }

      });
  };


};


export const EditSubjecterr = value => {
  return {
    type: EDIT_SUBJECT_ERR,
    EditSubjecterr: value
  };
};

export const editSubjectStatus = value => {
  return {
    type: STATUS_SUBJECT_EDIT,
    editSubjectStatus: value
  };
};
export const editsubject = value => {
  return {
    type: SUBJECT_EDIT,
    editsubject: value
  };
};
export const getSubject = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/Subject/get`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          SubjectID: data.SubjectID
        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json()

      )
      .then(parsedRes => {
        dispatch(uiStopLoading())
        dispatch(subjectData(parsedRes.data));

      });
  };
};

export const subjectData = value => {
  return {
    type: SUBJECT_DATA,
    subjectData: value
  };
};

export const DeleteSubject = data => {
  return dispatch => {
    var token = localStorage.getItem("adminToken");

    dispatch(deleteSubjectstatus(""));
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/api/Subject/delete`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token
        },
        body: JSON.stringify({
          SubjectID: data.SubjectID,

        })
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading())
      })
      .then(res => res.json())
      .then(parsedRes => {
        dispatch(deleteSubjectstatus(""));
        dispatch(uiStopLoading())
        if (parsedRes.status === "fail") {
          dispatch(DeleteSubjecterr(parsedRes.error));
        } else {
          dispatch(DeleteSubjecterr(""));
          dispatch(deleteSubject(parsedRes.data));
          dispatch(deleteSubjectstatus(parsedRes.status));
        }

      });
  };
};
export const deleteSubject = value => {
  return {
    type: SUBJECT_DELETE,
    deleteSubject: value
  };
};
export const DeleteSubjecterr = value => {
  return {
    type: DELETE_SUBJECT_ERR,
    DeleteSubjecterr: value
  };
};
export const deleteSubjectstatus = value => {
  return {
    type: STATUS_SUBJECT_DELETE,
    deleteSubjectstatus: value
  };
};


export const subjectsNb = value => {
  return {
    type: SUBJECTS_NB,
    subjectsNb: value
  }
}

export const subjectsData = value => {
  return {
    type: SUBJECTS_DATA,
    subjectsData: value
  };
};
export const listSubjectsErr = value => {
  return {
    type: LIST_SUBJECTS_ERR,
    listSubjectsErr: value
  };
};
export const CreateSubject = value => {
  return {
    type: SUBJECT_CREATE,
    CreateSubject: value
  };
};
export const CreateSubjecterr = value => {
  return {
    type: CREATE_SUBJECT_ERR,
    CreateSubjecterr: value
  };
};
export const CreateSubjectstatus = value => {
  return {
    type: STATUS_SUBJECT_CREATE,
    CreateSubjectstatus: value
  };
};